import { Action, combineReducers } from '@ngrx/store';
import * as fromDirtyState from './dirty-state.reducer';

export const navigationFeatureKey = 'navigation';

export interface NavigationState {
  [fromDirtyState.dirtyStateFeatureKey]: fromDirtyState.State;
}

export function reducer(state: NavigationState | undefined, action: Action) {
  return combineReducers({
    [fromDirtyState.dirtyStateFeatureKey]: fromDirtyState.reducer
  })(state, action);
}
