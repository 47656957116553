import { Injectable, Injector } from '@angular/core';
import { EntitiesResolver } from '@mri-platform/shared/entity';
import { AccessToken } from '../models';
import { AccessTokenEntityService } from './access-token-entity.service';

@Injectable({ providedIn: 'root' })
export class AccessTokensResolver extends EntitiesResolver<AccessToken> {
  constructor(injector: Injector, entityService: AccessTokenEntityService) {
    super(injector, entityService);
  }
}
