import { createCollection, withMetadata } from 'angular-in-memory-web-api';

const userpermissions = [
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View', 'ViewPage', 'PMPlatformOperations'],
    resourceName: 'Platform'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View', 'Refresh', 'SetLicense', 'ViewPage'],
    resourceName: 'ApiConnection'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View', 'ViewPage'],
    resourceName: 'User'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View'],
    resourceName: 'UserConnection'
  },
  {
    grantedOperations: [
      'Create',
      'Update',
      'Delete',
      'View',
      'DownloadMapper',
      'SetStandardMapper',
      'UploadMapper',
      'ViewPage'
    ],
    resourceName: 'Mapper'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View'],
    resourceName: 'DataSource'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View'],
    resourceName: 'DataSourceClient'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View', 'ViewPage'],
    resourceName: 'Client'
  },
  {
    grantedOperations: ['View'],
    resourceName: 'PlatformSummary'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View'],
    resourceName: 'Schedule'
  },
  {
    grantedOperations: ['View', 'DownloadJobLogs'],
    resourceName: 'JobLog'
  },
  {
    grantedOperations: ['View'],
    resourceName: 'AccessToken'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View'],
    resourceName: 'File'
  },
  {
    grantedOperations: ['Create', 'Update', 'Delete', 'View'],
    resourceName: 'Job'
  },
  {
    grantedOperations: ['DownloadAuditLogs'],
    resourceName: 'AuditLog'
  }
];

// note: angular-in-memory-web-api is forcing us to use all lower case for this collection names
export const testDb = {
  userinfo: {
    isAdmin: true,
    roleIds: [1],
    clientId: 'MRIQWEB',
    isOperation: true
  },
  userpermissions: createCollection(userpermissions, withMetadata({ applyQueryParams: false })),
  userPreference: {
    textLocale: 'en-us',
    numericLocale: 'en-us',
    dateLocale: 'en-us',
    timeFormat: 'h:mm a',
    timeOffset: 0,
    phoneFormat: 'On'
  }
};
