import { Component, HostBinding, Input } from '@angular/core';
import { SvgIconName } from '@mri/svg-icons/dist/ts/mri-icons';

const description = 'Remove item';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[mriDatalistRemoveIconBtn]',
  template: ` <mri-btn-icon [icon]="icon" [direction]="'none'"></mri-btn-icon> `,
  styles: []
})
export class DatalistRemoveIconBtnComponent {
  @HostBinding('class') readonly hostClass = 'mri-icon-button mri-icon-button--x-small';
  @HostBinding('attr.aria-label') readonly ariaRole = description;
  @HostBinding('attr.title') readonly titleRole = description;
  @Input() icon: SvgIconName = 'minus-sign';
}
