import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  BEFORE_SWITCH_CLIENT_FUNC,
  ClientSwitchService,
  FORCE_ROUTER_NAVIGATION,
  SWITCH_CLIENT_FUNC,
  SwitchClientFunc,
  clientIdKey
} from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';

@Injectable()
export class ImpersonateClientSwitchService implements ClientSwitchService {
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() @Inject(SWITCH_CLIENT_FUNC) private switchClientFuncs?: SwitchClientFunc[],
    @Optional() @Inject(BEFORE_SWITCH_CLIENT_FUNC) private beforeSwitchClientFuncs?: SwitchClientFunc[]
  ) {}

  switchClient(clientId: string, additionalQueryParams?: object) {
    this.executeClientSwitchFunctions(this.beforeSwitchClientFuncs, clientId);
    this.authService.impersonateClient(clientId);
    this.executeClientSwitchFunctions(this.switchClientFuncs, clientId);
    this.navigateToParent(clientId, additionalQueryParams);
  }

  private executeClientSwitchFunctions(switchClientFuncs: SwitchClientFunc[] | undefined, clientId: string) {
    if (!switchClientFuncs) return;

    for (const switchClientFunc of switchClientFuncs) {
      switchClientFunc(clientId);
    }
  }

  private navigateToParent(clientId: string, additionalQueryParams?: object) {
    const routeOptions: NavigationExtras = {
      relativeTo: this.route,
      state: FORCE_ROUTER_NAVIGATION,
      queryParams: { [clientIdKey]: clientId, ...(additionalQueryParams ?? {}) }
    };

    this.router.navigate(['/'], routeOptions);
  }
}
