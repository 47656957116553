import { NgModule } from '@angular/core';
import { ViewStateService } from '@mri-platform/shared/core';
import { StoreModule } from '@ngrx/store';
import * as fromViewState from './reducers';
import { DefaultViewStateService } from './services/default-view-state.service';

@NgModule({
  imports: [StoreModule.forFeature(fromViewState.viewStateFeatureKey, fromViewState.reducer)],
  exports: [],
  providers: [{ provide: ViewStateService, useClass: DefaultViewStateService }]
})
export class ViewStateModule {}
