import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { ClientEntityService } from '@mri-platform/import-export/clients-state';
import { isNotNullOrUndefined } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Mapper } from '../models';
import { MapperEntityService } from './mapper-entity.service';
@Injectable({ providedIn: 'root' })
export class MappersResolver implements Resolve<Mapper[]> {
  constructor(
    private authService: AuthService,
    private entityService: MapperEntityService,
    private errorPolicyService: ErrorPolicyService,
    private clientEntityService: ClientEntityService
  ) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Mapper[]> {
    this.entityService.clearCache();
    return this.authService.currentClientId$.pipe(
      isNotNullOrUndefined(),
      switchMap(licenseId =>
        this.clientEntityService.entitiesByQuery$({ licenseId }).pipe(
          map(clients => clients[0]),
          switchMap(client =>
            this.entityService
              .setWithQuery({ clientId: client?.id })
              .pipe(this.errorPolicyService.catchHandleReplace([]))
          )
        )
      ),
      first()
    );
  }
}
