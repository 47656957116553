/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: angular `ErrorHandler` uses `any` in it's public api, therefore we're following the same pattern here

import { ErrorHandler } from '@angular/core';
import { EMPTY, identity, Observable, of, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap, mergeMapTo, tap } from 'rxjs/operators';
import { SanitizedError, SanitizerFunc } from './sanitized-error';

export type TranslatorFunction = (err: SanitizedError) => Observable<SanitizedError> | PromiseLike<SanitizedError>;

export interface ErrorHandlerOptions {
  /**
   * function responsible for transforming the raw error into a SanitizedError
   *
   * Where function returns undefined, SanitizedError.genericError will be used
   * to create the sanitized error instead
   * */
  sanitizer: SanitizerFunc;
  /**
   * function responsible for transforming the resulting SanitizedError into a localized
   * version of this error
   */
  translator: TranslatorFunction;
  /** send error to ErrorHandler service (default: true)? */
  notify: boolean;
}

export function humanizeError<T>(options: Partial<ErrorHandlerOptions> = {}) {
  const { sanitizer, translator } = options;
  return (source$: Observable<T>) =>
    source$.pipe(
      map(err => SanitizedError.sanitize(err, sanitizer || identity)),
      translator ? mergeMap(translator) : identity
    );
}

export function notifyError<T extends SanitizedError>(handler: ErrorHandler) {
  return (source$: Observable<T>) =>
    source$.pipe(
      tap(err => {
        if (err.isLogged === true) {
          return;
        }
        err.isLogged = true;
        handler.handleError(err);
      })
    );
}

export function handleError(handler: ErrorHandler, options: Partial<ErrorHandlerOptions> = { notify: true }) {
  return (source$: Observable<any>) =>
    source$.pipe(humanizeError(options), options.notify ? notifyError(handler) : identity);
}

export function catchHandleError<T>(handler: ErrorHandler, options?: Partial<ErrorHandlerOptions>) {
  return (source$: Observable<T>) => source$.pipe(catchError(err => of(err).pipe(handleError(handler, options))));
}

export function catchHandleSwallowError<T>(handler: ErrorHandler, options?: Partial<ErrorHandlerOptions>) {
  return (source$: Observable<T>) =>
    source$.pipe(catchError(err => of(err).pipe(handleError(handler, options), mergeMapTo(EMPTY))));
}

export function catchHandleReplaceError<T, R = T>(
  handler: ErrorHandler,
  fallback: R,
  options?: Partial<ErrorHandlerOptions>
) {
  return (source$: Observable<T>) =>
    source$.pipe(catchError(err => of(err).pipe(handleError(handler, options), mapTo(fallback))));
}

export function catchHandleRethrowError<T>(handler: ErrorHandler, options?: Partial<ErrorHandlerOptions>) {
  return (source$: Observable<T>) =>
    source$.pipe(
      catchError(err =>
        of(err).pipe(
          handleError(handler, options),
          mergeMap(sanitizedErr => throwError(sanitizedErr))
        )
      )
    );
}
