import { AccessToken } from '../models';

const baseExample: AccessToken = {
  id: 1,
  platformId: 2,
  clientId: 3,
  clientName: 'MRIQWEB',
  userName: 'ImpExp1@mrisoftware.com',
  databaseName: 'ACTX5IM',
  databaseId: 1
};

export const createAccessTokenList = (list: Partial<AccessToken>[]): AccessToken[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i + 1 }));
