import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ItemStatus } from './datalist-item-status-enum';
import { FilterFunc } from './filter-func';
import { SortComparerFunc } from './sort-comparer-func';
import { xor } from './x-or';

//TODO: work out how to genericize this such that we don't need all the `any`s so we can re-enable this rule.
/* eslint-disable @typescript-eslint/no-explicit-any */
type KeySelectorFunc = (item: any) => any;
type Sorter<T = unknown> = (list: T[]) => T[];

const identity = <T>(value: T): T => value;

function createSorter<T = unknown>(comparer: SortComparerFunc<T>): Sorter<T> {
  return (list: T[]) => [...list].sort(comparer);
}

function safeKeySelector(selector: KeySelectorFunc): KeySelectorFunc {
  // intentional non-strict comparison
  return (item: any) => (item == null ? item : selector(item));
}

@Component({
  selector: 'mri-datalist',
  templateUrl: './datalist.component.html',
  styleUrls: ['./datalist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatalistComponent implements OnDestroy {
  constructor(
    /** @internal */
    public cd: ChangeDetectorRef
  ) {
    this.setFilterValue = this.setFilterValue.bind(this);
    this.addItemToList = this.addItemToList.bind(this);
    this.removeItemFromList = this.removeItemFromList.bind(this);

    const filterChangesSub = this.filterValueChanges.subscribe(value => {
      this.filterValue = value;
      this.filterValueChange.emit(value);
    });
    this.subscriptions.add(filterChangesSub);

    const retryLoadingOnError = this.retryOnError.subscribe(() => {
      this.loadingErrorRetry.emit();
    });
    this.subscriptions.add(retryLoadingOnError);
  }

  @HostBinding('class.mri-datalist') readonly hostClass = true;

  @HostBinding('class.default-cursor')
  @Input()
  defaultCursor = false;

  private _filterFunction?: FilterFunc;
  /**
   * The function that will accept the `filterValue` and the current `list`.
   * The array returned by this function becomes the list to display
   */
  @Input() set filterFunction(value: FilterFunc) {
    this._filterFunction = value;
    // todo: call this.applyFilter(); this will need to a new storybook example added
  }
  get filterFunction() {
    return this._filterFunction ?? identity;
  }

  private _filterValue = '';
  /**
   * The value that will be used to filter items in the list
   * This value will be supplied as a parameter to `filterFunction`
   */
  @Input() set filterValue(value: string) {
    const previous = this._filterValue;
    this._filterValue = value;
    if (previous !== value) {
      this.applyFilter();
    }
  }
  get filterValue() {
    return this._filterValue;
  }

  private _showFooter?: boolean;
  /**
   * Display the footer for datalist
   *
   * *Tip*: use the `mriDataListFooter` directive to replace the default
   * footer with your own template
   */
  @Input() set showFooter(value: boolean) {
    this._showFooter = value;
  }
  get showFooter() {
    if (this._showFooter !== undefined) {
      return this._showFooter;
    }

    return this.addItem.observers.length > 0 || this.removeItem.observers.length > 0;
  }

  /**
   * Set to true when the list items display an icon
   *
   * Note: by default this will automatically set to true by including an <mri-avatar> element within the item template
   */
  @Input() icon?: boolean;

  /**
   * Set to true (default) to display a border dividing each item
   *
   * @default true
   */
  @Input() lines = true;
  @HostBinding('class.mri-datalist--no-lines') get noLines() {
    return !this.lines;
  }

  /**
   * Set to true when the list items have a toolbar at the end
   *
   * Note: by default this will automatically set to true by including an <mri-toolbar> element within the item template
   */
  @Input() tools?: boolean;

  /**
   * Determines whether data area items should have a vertical (default) or
   * horizontal layout
   */
  @Input() @HostBinding('class.mri-datalist--horizontal') horizontal = false;

  private _list: unknown[] = [];
  @Input() set list(value: unknown[]) {
    this._list = this.sorter(value ?? []);
    const previousFilteredList = this._filteredList;
    this._filteredList = this._list;

    // re-evaluate selection
    if (this.selectedItem !== undefined) {
      this.selectItemInternal(this.findSelectedItem(this.selectedItem), true);
    }
    // re-evaluate filter
    if (this.filterValue) {
      this.applyFilter(previousFilteredList);
    }
  }
  get list() {
    return this._list;
  }

  /**
   * Defines the row height required by the virtual scrolling functionality
   */
  @Input() rowHeight = 0;

  private _searchable?: boolean;
  /**
   * Display the search box to allow user to enter a `filterValue`.
   * Defaults to true when a `filterFunction` is supplied.
   *
   * *Tip*: use the `mriDataListSearch` directive to replace the default
   * search box with your own template
   */
  @Input() set searchable(value: boolean) {
    this._searchable = value;
  }
  get searchable() {
    if (!this._filterFunction) return false;

    return this._searchable !== undefined ? this._searchable : this._filterFunction !== undefined;
  }

  /**
   * The text to display as a list header
   */
  @Input() headerTitle?: string;

  private placeholderValue?: string;
  @Input() set searchPlaceholder(value: string) {
    this.placeholderValue = value;
  }
  get searchPlaceholder() {
    return this.placeholderValue !== undefined ? this.placeholderValue : 'search';
  }
  private _selectedItem?: unknown;
  @Input() set selectedItem(value: unknown | undefined) {
    if (!this.selectable) {
      return;
    }

    this.controlledSelection = true;
    this._selectedItem = this.findSelectedItem(value);
  }
  get selectedItem() {
    return this._selectedItem;
  }

  private _selectable = true;
  /**
   * Enable/disable item selection
   *
   * @default true
   */
  @Input() set selectable(value: boolean) {
    this._selectable = value;
    if (this.selectedItem && !value) {
      this.selectItemInternal(undefined, true);
    }
  }
  get selectable() {
    return this._selectable;
  }

  @HostBinding('class.mri-datalist--no-selection') get selectionDisabled() {
    return !this.selectable;
  }

  trackByFunc?: TrackByFunction<any>;
  private keySelector: KeySelectorFunc = identity;
  get selectionKey() {
    return this.keySelector;
  }
  /**
   * Defines the item key that will be used to compare items in list
   * Supply this whenever items in list are immutable and can be
   * replaced with equivalent instances during the rendering life of
   * the list
   */
  @Input() set selectionKey(value: string | ((item: any) => any)) {
    if (typeof value === 'string') {
      this.keySelector = safeKeySelector((item: any) => item[value]);
    } else {
      this.keySelector = safeKeySelector(value);
    }
    this.trackByFunc = (_, item) => this.keySelector(item);
  }

  private _showStatistics?: boolean;
  /**
   * Display the statistics such as how many items are displayed
   * Defaults to true when `searchable` is true.
   *
   * *Tip*: use the `mriDataListStatistics` directive to replace the default
   * statistics with your own template
   */
  @Input() set showStatistics(value: boolean) {
    this._showStatistics = value;
  }
  get showStatistics() {
    return this._showStatistics !== undefined ? this._showStatistics : this.searchable;
  }

  /**
   *Display a loading spinner on true
   * @default false
   */
  @Input() loading = false;

  /**
   * Display Error template
   * @default false
   */
  @Input() showLoadingError = false;

  /**
   * Display retry button on error template
   * @default false
   */
  private _showLoadingRetry?: boolean;
  @Input() set showLoadingRetry(value: boolean) {
    this._showLoadingRetry = value;
  }

  get showLoadingRetry() {
    return this._showLoadingRetry === undefined ? this.loadingErrorRetry.observers.length > 0 : this._showLoadingRetry;
  }

  /**
   * Display retry button text on error template
   * @default false
   */

  private _loadingErrorRetryLabel?: string;
  /**
   * Display the error button message on error template
   * Defaults to `Try again` when not supplied
   */
  @Input() set loadingErrorRetryLabel(value: string) {
    this._loadingErrorRetryLabel = value;
  }

  get loadingErrorRetryLabel() {
    return this._loadingErrorRetryLabel !== undefined ? this._loadingErrorRetryLabel : 'Try again';
  }

  private _loadingErrorMessage?: string;
  /**
   * Display the error message on error template
   * Defaults to `Error Loading items` when not supplied
   */
  @Input() set loadingErrorMessage(value: string) {
    this._loadingErrorMessage = value;
  }
  get loadingErrorMessage() {
    return this._loadingErrorMessage !== undefined ? this._loadingErrorMessage : 'Error loading items';
  }
  /**
   * Display the the record count statistic
   * Defaults to true when a `showStatistics` is true.
   */
  private _showRecordCount?: boolean;
  @Input() set showRecordCount(value: boolean) {
    this._showRecordCount = value;
  }
  get showRecordCount() {
    return this._showRecordCount !== undefined ? this._showRecordCount : this.showStatistics;
  }

  /**
   * Display the the select count statistic
   * Defaults to true when a `showStatistics` is true.
   */
  private _showSelectCount?: boolean;
  @Input() set showSelectCount(value: boolean) {
    this._showSelectCount = value;
  }
  get showSelectCount() {
    return this._showSelectCount !== undefined ? this._showSelectCount : this.showStatistics;
  }

  private sorter: Sorter = identity;
  /**
   * A item comparer that when supplied will result in `list` being sorted in place
   */
  @Input() set sortKey(value: SortComparerFunc | undefined) {
    this.sorter = value ? createSorter(value) : identity;
    this._list = this.sorter(this._list);
    this._filteredList = this.sorter(this._filteredList);
  }

  /**
   * Inputs for customizing the footer to show/hide , enable/disable buttons
   */
  @Input() canAddItem = true;
  @Input() canRemoveItem = true;
  @Input() showAddItem = true;
  @Input() showRemoveItem = true;

  private _showItemsActions?: boolean;

  @Input() set showItemsActions(value: boolean) {
    this._showItemsActions = value;
  }
  get showItemsActions() {
    return this._showItemsActions !== undefined ? this._showItemsActions : !!this.actionsBtnTemplate;
  }

  /**
   * Display the status band for each li item.
   * It uses status property of list item to compare .
   * The value of status property should be @enum ItemStatus one.
   *
   * @example
   * const list = [
   *  {status: 'Succeeded', ...},
   *  {status: 'Failed', ...}
   * ];
   */
  @Input() @HostBinding('class.mri-datalist--status-band') showStatusBand = false;

  /**
   * Display the label after showing count
   */
  @Input() recordCountLabel = '';

  /**
   * Fires when the `filterValue` changes
   */
  @Output() filterValueChange = new EventEmitter<string>();
  /**
   * Fires when the `selectedItem` changes
   */
  @Output() selectedItemChange = new EventEmitter<unknown>();

  /**
   * Fires when the `filteredItems` have changed
   */
  @Output() filteredItemsChange = new EventEmitter<unknown[]>();

  /**
   * Fires when clicked on retry button
   */
  @Output() loadingErrorRetry = new EventEmitter<unknown>();

  /**
   * Fires when clicked on Add button in the footer
   */
  @Output() addItem = new EventEmitter<void>();

  /**
   * Fires when clicked on Remove button in the footer
   */
  @Output() removeItem = new EventEmitter<unknown>();

  @Input() actionsBtnTemplate?: TemplateRef<never>;

  @ViewChild('defaultSearchTemplate') defaultSearchTemplate!: TemplateRef<any>;
  customSearchTemplate?: TemplateRef<any>;
  @Input() itemTemplate?: TemplateRef<any>;
  get searchTemplate(): TemplateRef<any> {
    return this.customSearchTemplate ?? this.defaultSearchTemplate;
  }

  @ViewChild('defaultStatisticsTemplate') defaultStatisticsTemplate!: TemplateRef<any>;
  customStatisticsTemplate?: TemplateRef<any>;
  get statisticsTemplate(): TemplateRef<any> {
    return this.customStatisticsTemplate ?? this.defaultStatisticsTemplate;
  }

  @ViewChild('defaultErrorTemplate') defaultErrorTemplate!: TemplateRef<any>;
  customErrorTemplate?: TemplateRef<any>;
  get errorTemplate(): TemplateRef<any> {
    return this.customErrorTemplate ?? this.defaultErrorTemplate;
  }

  @ViewChild('defaultErrorWithDataTemplate') defaultErrorWithDataTemplate!: TemplateRef<any>;
  customErrorWithDataTemplate?: TemplateRef<any>;
  get errorWithDataTemplate(): TemplateRef<any> {
    return this.customErrorWithDataTemplate ?? this.defaultErrorWithDataTemplate;
  }

  @ViewChild('defaultFooterTemplate') defaultFooterTemplate!: TemplateRef<any>;
  customFooterTemplate?: TemplateRef<any>;
  get footerTemplate(): TemplateRef<any> {
    return this.customFooterTemplate ?? this.defaultFooterTemplate;
  }

  private _filteredList: unknown[] = [];
  get filteredList() {
    return this._filteredList;
  }

  get selectedCount(): number {
    return this.selectedItem ? 1 : 0;
  }

  get showSearch(): boolean {
    return this.searchable && this.list.length > 0;
  }

  get showHeader(): boolean {
    return (
      this.showSearch || !!this.stateMessage || (this.showStatistics && (this.showRecordCount || this.showSelectCount))
    );
  }

  get stateMessage(): string | undefined {
    if (this.loading) {
      return 'Loading items';
    }
    if (this.showLoadingError) {
      return 'Error loading items';
    }
    if (this.list.length === 0) {
      return 'No items';
    }
    return undefined;
  }

  private controlledSelection = false;
  private filterValueChanges = new Subject<string>();
  private retryOnError = new Subject<void>();
  private subscriptions = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  itemsEquivalent(left: unknown, right: unknown) {
    return this.keySelector(left) === this.keySelector(right);
  }

  selectItem(item: unknown | undefined) {
    this.selectItemInternal(item, true);
  }

  selectItemInternal(item: unknown | undefined, force: boolean) {
    if (!this.selectable && !force) {
      return;
    }

    if (item === this._selectedItem) return;

    const selected = item !== undefined ? this.findSelectedItem(item) : undefined;
    if (selected !== this._selectedItem && this.itemsEquivalent(selected, this._selectedItem)) {
      this._selectedItem = selected;
      return;
    }

    if (selected === this._selectedItem) return;

    if (force || !this.controlledSelection) {
      this._selectedItem = selected;
    }
    this.selectedItemChange.emit(selected);
  }

  setFilterValue(value: string) {
    this.filterValueChanges.next(value);
  }

  onRetryClick() {
    this.retryOnError.next();
  }

  addItemToList() {
    this.addItem.next();
  }

  removeItemFromList(item: unknown) {
    this.removeItem.next(item);
  }

  private applyFilter(previous?: unknown[]) {
    const previousList = previous || this._filteredList;
    if (!this.filterValue) {
      this._filteredList = this.list;
    } else {
      this._filteredList = this.filterFunction(this.list, this.filterValue);
    }
    const diff = xor(previousList, this._filteredList);
    if (diff.length > 0) {
      this.filteredItemsChange.emit(this._filteredList);
    }
  }

  private findSelectedItem(value: unknown | undefined) {
    return this.list.find(item => this.itemsEquivalent(item, value));
  }

  getItemClasses(item: { [key: string]: any }) {
    return {
      'mri-datalist__item--icon': this.icon,
      'mri-datalist__item--tools': this.tools,
      'mri-datalist__item--status-initiated': this.showStatusBand && item.status === ItemStatus.Initiated,
      'mri-datalist__item--status-inprogress': this.showStatusBand && item.status === ItemStatus.InProgress,
      'mri-datalist__item--status-success': this.showStatusBand && item.status === ItemStatus.Succeeded,
      'mri-datalist__item--status-failed': this.showStatusBand && item.status === ItemStatus.Failed,
      'mri-datalist__item--status-success-error': this.showStatusBand && item.status === ItemStatus.SucceededWithErrors,
      'mri-datalist__item--status-success-duplicates':
        this.showStatusBand && item.status === ItemStatus.SucceededWithDuplicates
    };
  }

  isEmptyList() {
    return this.list.length === 0;
  }
}
