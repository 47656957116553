<div>
  <button #anchor class="mri-filter-bar__filter-button" (click)="togglePopover()">
    <span class="mri-filter-bar__label">{{ title }}</span>
    <ng-container *ngIf="type === FilterType.List">
      <span class="mri-filter-bar__value" [title]="values">
        {{ values.length > 40 ? (values | slice: 0 : 40) + '...' : values }}
      </span>
    </ng-container>
    <ng-container *ngIf="type === FilterType.DateRange">
      <span class="mri-filter-bar__value">
        {{ values.from | localizeDateTime }} - {{ values.to | localizeDateTime }}
      </span>
    </ng-container>
  </button>
  <kendo-popup #popup class="mri-popover" *ngIf="showPopover">
    <div class="popover-content">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="mri-heading mri-heading--4">{{ title }}</h1>
        </div>
      </div>
      <div>
        <form [formGroup]="filterForm" class="k-form k-form-horizontal">
          <ng-container *ngIf="type === FilterType.List">
            <div class="row form-group">
              <kendo-formfield class="col-lg-12">
                <label for="select" class="mri-label mri-lg-3 text-right">{{ title }}</label>
                <kendo-dropdownlist
                  name="select"
                  #select
                  [formControlName]="fieldName"
                  [data]="listValues"
                  class="mri-lg-9 ng-pristine"
                  autoWidth="false"
                  textField="text"
                  valueField="value"
                  valuePrimitive="true"
                >
                </kendo-dropdownlist>
              </kendo-formfield>
            </div>
          </ng-container>
          <ng-container *ngIf="type === FilterType.DateRange">
            <div class="row form-group">
              <kendo-formfield orientation="horizontal" class="field-group">
                <label for="fromDate" class="field-label text-right">Start date</label>
                <kendo-datepicker
                  #fromDate
                  [popupSettings]="popupSettings"
                  formControlName="from"
                  mriIeKendoDateFormat
                  [max]="filterForm.value.to"
                  (click)="RetainPopUp()"
                ></kendo-datepicker>
              </kendo-formfield>
              <kendo-formfield orientation="horizontal" class="field-group mri-pt-small">
                <label for="toDate" class="field-label text-right">End date</label>
                <kendo-datepicker
                  #toDate
                  [popupSettings]="popupSettings"
                  formControlName="to"
                  mriIeKendoDateFormat
                  [max]="today"
                  [min]="filterForm.value.from"
                  (click)="RetainPopUp()"
                ></kendo-datepicker>
              </kendo-formfield>
            </div>
          </ng-container>
          <div class="d-flex ms-auto popover-actions mri-mv-small">
            <button type="button" class="mri-button" (click)="reset()">
              <span class="mri-button__label"> Reset </span>
            </button>
            <div class="action-group">
              <button type="button" class="mri-button mri-button--primary mri-button--primary" (click)="setValues()">
                <span class="mri-button__label"> Apply </span>
              </button>
              <button type="button" class="mri-button mri-button" (click)="cancel()">
                <span class="mri-button__label"> Cancel </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </kendo-popup>
</div>
