import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ErrorPolicyService, muteError } from '@mri-platform/angular-error-handling';
import { RetryBackoffConfig, retryBackoff } from 'backoff-rxjs';
import { Observable, Subscription, timer } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

const isHttpErrorResponse = (error: unknown): error is HttpErrorResponse => error instanceof HttpErrorResponse;
const isNotFound = (error: HttpErrorResponse): boolean => error.status === 404;
//If the status code is a 404, there is very little chance of it coming back, so bail out early.
const shouldRetry = (error: unknown): boolean => (isHttpErrorResponse(error) && !isNotFound(error)) || true;

const defaultPollingOptions: PollingOptions = {
  initialDelay: 1000,
  retryBehavior: {
    maxRetries: 3,
    shouldRetry
  }
};

interface PollingOptions {
  initialDelay: number | Date;
  retryBehavior?: Partial<RetryBackoffConfig>;
}

// return of this polling function could be any type
export type PollingFunc = () => Observable<unknown>;

@Injectable()
export class PollingService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(private errorPolicy: ErrorPolicyService) {}

  /**
   * Creates a poll with given parameters
   * @param pollingFunc - To be called on every given interval time
   * @param pollingInterval - Milliseconds
   * @param options - Other configurable object with some props like initialDelay & retryBehavior
   *
   * @example
   * ```
   * // Poll a function with intial delay of 3 sec and then every 5 sec
   *
   * register(() => console.log('called'), 5000, { initialDelay: 3000 });
   * ```
   */
  register(pollingFunc: PollingFunc, pollingInterval: number, options: PollingOptions = defaultPollingOptions): void {
    // Observable emits until either current page component gets destroyed
    // or error is thrown even after a given retry count
    // general idea is to mute the errors from being displayed on UI
    const polling$ = timer(options.initialDelay, pollingInterval).pipe(
      exhaustMap(pollingFunc),
      retryBackoff({
        initialInterval: pollingInterval,
        ...defaultPollingOptions.retryBehavior,
        ...options.retryBehavior
      }),
      this.errorPolicy.catchHandleSwallow(muteError)
    );

    const sub = polling$.subscribe();
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
