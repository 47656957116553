import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

export interface TimeAgoOptions {
  includeSeconds?: boolean;
  addSuffix?: boolean;
}

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  public transform(time: Date, { includeSeconds = true, addSuffix = true }: TimeAgoOptions = {}): string {
    // distanceInWordsToNow is deprecated. Refer: https://date-fns.org/v2.16.1/docs/formatDistanceToNow#v2.0.0-breaking-changes
    return formatDistanceToNow(new Date(time), { includeSeconds, addSuffix });
  }
}
