import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { OAuthModuleConfig, OAuthResourceServerConfig } from './oauth-module.config';

@Injectable()
export class ImpersonatedClientIdHttpInterceptor implements HttpInterceptor {
  serverConfig: OAuthResourceServerConfig;

  constructor(
    private authService: AuthService,
    @Optional() moduleConfig?: OAuthModuleConfig
  ) {
    this.serverConfig = { sendAccessToken: false, ...moduleConfig?.resourceServer };
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.sendClientIdFor(req)) {
      return next.handle(req);
    }

    const intercepted$ = this.authService.impersonatedClientId$.pipe(
      first(),
      map(clientId => {
        if (!clientId) {
          return req;
        }

        const headers = req.headers.append(
          this.serverConfig.impersonatedClientIdHeader || 'X-Current-MriClientId',
          clientId
        );
        return req.clone({ headers });
      }),
      mergeMap(r => next.handle(r))
    );

    return intercepted$;
  }

  private sendClientIdFor(req: HttpRequest<unknown>): boolean {
    const url = req.url.toLowerCase();

    if (!this.serverConfig.sendImpersonatedClientId) {
      return false;
    }

    if (this.serverConfig.customUrlValidation) {
      return this.serverConfig.customUrlValidation(url);
    }

    if (this.serverConfig.allowedUrls) {
      return this.serverConfig.allowedUrls.some(u => url.startsWith(u));
    }

    return true;
  }
}
