/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import { ApiConfig } from '../app-config/api.config';
import { HttpEndpoint } from '../app-config/http-endpoint';

export interface FeatureFlagsConfig {
  /**
   * Base url of the http endpoint that will return array of `ApiFeatureFlag`
   *
   * Defaults to `ApiConfig.apiUrl`
   */
  baseUrl: string;

  /**
   * Url Path of the http endpoint that will return array of `ApiFeatureFlag`
   *
   * Defaults to 'featureflags'
   */
  path: string;
}

export class FeatureFlagsConfig extends HttpEndpoint {
  constructor(json: Partial<FeatureFlagsConfig> = {}, apiConfig: ApiConfig) {
    super(json, { baseUrl: apiConfig.url, path: 'featureflags' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { baseUrl: _baseUrl, path: _path, ...rest } = json;
    Object.assign(this, rest);
  }
}
