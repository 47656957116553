import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarComponent } from './avatar.component';
import { ButtonIconComponent } from './button-icon.component';
import { FieldIconComponent } from './field-icon.component';
import { IconComponent } from './icon.component';
import { LoadingComponent } from './loading.component';

const COMPONENTS = [ButtonIconComponent, FieldIconComponent, AvatarComponent, LoadingComponent, IconComponent];

@NgModule({
  imports: [CommonModule],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: []
})
export class UiIconModule {}

export { AvatarComponent };
