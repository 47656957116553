import { CrudAction } from '@mri-platform/resource-authz';
import { RouteInfo } from '@mri-platform/shared/core';
import { EntityName } from './entity-name';

export const AppRoutes = {
  Enroll: RouteInfo.path('enroll'),
  Callback: RouteInfo.path('callback'),
  Error: RouteInfo.path('error'),
  PlatformManagement: RouteInfo.path('import-export-platforms-management')
    .withEntityAuthz([EntityName.Platform], 'ViewPage')
    .children({
      Detail: RouteInfo.detailPage()
        .withAuthz({ resource: EntityName.Platform, action: 'ViewPage', requiresResolve: true })
        .children({
          DataSource: RouteInfo.pathParam('datasourceId')
        })
    }),
  ImportExportJobs: RouteInfo.path('import-export-jobs')
    .withEntityAuthz([EntityName.Job])
    .withEntityAuthz([EntityName.Platform])
    .children({
      Detail: RouteInfo.detailPage().children({
        ScheduledJob: RouteInfo.detailPage(),
        ImportJob: RouteInfo.path(':id/import-job'),
        ExportJob: RouteInfo.path(':id/export-job')
      })
    }),
  RecentImportExport: RouteInfo.path('import-export-recent-job-logs')
    .withEntityAuthz([EntityName.JobLog])
    .withEntityAuthz([EntityName.Platform])
    .children({
      Detail: RouteInfo.detailPage()
    }),
  MappingManager: RouteInfo.path('import-export-mapping-management')
    .withEntityAuthz([EntityName.Mapper], 'ViewPage')
    .children({
      Detail: RouteInfo.detailPage().withAuthz({
        resource: EntityName.Mapper,
        action: CrudAction.Create,
        requiresResolve: true
      }),
      UploadMapper: RouteInfo.path('upload')
    }),
  UserSecuritySettings: RouteInfo.path('import-export-admin')
    .withEntityAuthz([EntityName.User], 'ViewPage')
    .children({
      Detail: RouteInfo.detailPage()
        .withAuthz({ resource: EntityName.User, action: 'ViewPage', requiresResolve: true })
        .children({ AddUser: RouteInfo.detailPage() })
    }),
  MappingConnections: RouteInfo.path('import-export-connection-management')
    .withEntityAuthz([EntityName.ApiConnection], 'ViewPage')
    .children({
      PlatformConnections: RouteInfo.detailPage()
        .withAuthz({ resource: EntityName.ApiConnection, action: CrudAction.Read, requiresResolve: true })
        .children({
          Connection: RouteInfo.pathParam('connectionId')
        })
    }),
  ClientManagement: RouteInfo.path('import-export-client-management')
    .withEntityAuthz([EntityName.Client], 'ViewPage')
    .children({
      Detail: RouteInfo.detailPage().withAuthz({
        resource: EntityName.Client,
        action: CrudAction.Create,
        requiresResolve: true
      })
    }),
  SwitchClient: RouteInfo.path('switch-client')
};
