import { Directive, Input, TemplateRef } from '@angular/core';
import { DatalistComponent } from './datalist.component';

@Directive({ selector: '[mriDataListItem]' })
export class DatalistItemDirective {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Input() set mriDataListItem(_value: unknown) {}

  constructor(itemTemplate: TemplateRef<never>, datalist: DatalistComponent) {
    datalist.itemTemplate = itemTemplate;
  }
}
