import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoadedUser } from './models/user';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(_user: LoadedUser): Observable<any[] | undefined> {
    return of(undefined);
  }
}
