import { EntityName } from '@mri-platform/import-export/core';
import { createModelMetadata } from '@mri-platform/shared/core';

export interface DataSourceClient {
  dataSourceId: number;
  clientId: number;
}

export const DataSourceClient = createModelMetadata<DataSourceClient>({
  entityName: EntityName.DataSourceClient,
  idFields: ['dataSourceId', 'clientId']
});
