import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { consoleErrorAppenderProviders } from './console-error-appender.service';
import { errorEventChannelProviders, ErrorEventChannelService } from './error-event-channel.service';
import { ErrorLoggerService } from './error-logger.service';
import { ErrorLoggingModuleConfig } from './error-logging-module.config';
import { ERROR_SELECTOR_FUNC_ARRAY } from './error-selector-func-token';
import { ngErrorHandlerProvider } from './ng-error-handler';

export function configureErrorHandler(
  errorEventChannelService: ErrorEventChannelService,
  errorLoggerService: ErrorLoggerService
) {
  return () =>
    errorEventChannelService.errors$
      // To avoid OAuth error log again
      .pipe(filter(e => !(e instanceof OAuthErrorEvent)))
      .subscribe(error => errorLoggerService.log(error));
}

@NgModule({})
export class AngularErrorLoggingModule {
  static forRoot(config: ErrorLoggingModuleConfig = {}): ModuleWithProviders<AngularErrorLoggingModule> {
    return {
      ngModule: AngularErrorLoggingModule,
      providers: [
        ngErrorHandlerProvider,
        errorEventChannelProviders,
        consoleErrorAppenderProviders,
        ErrorLoggerService,
        {
          provide: APP_INITIALIZER,
          useFactory: configureErrorHandler,
          deps: [ErrorEventChannelService, ErrorLoggerService],
          multi: true
        },
        config.selectors
          ? [
              {
                provide: ERROR_SELECTOR_FUNC_ARRAY,
                useValue: config.selectors
              }
            ]
          : []
      ]
    };
  }
}
