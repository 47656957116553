import { Directive, HostBinding, Optional } from '@angular/core';
import { DrawerComponent } from '../drawer/drawer.component';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'mri-toolbar' })
export class DrawerFooterToolbarDirective {
  @HostBinding('class.mri-drawer__footer-toolbar') get isFooterToolbar() {
    return this.parentDrawer != null;
  }
  constructor(@Optional() private parentDrawer: DrawerComponent) {}
}
