import { Injectable, Injector } from '@angular/core';
import { AppRoutes } from '@mri-platform/import-export/core';
import { LoadedEntityResolver } from '@mri-platform/shared/entity';
import { JobLog } from '../models';
import { JobLogEntityService } from './job-log-entity.service';

@Injectable({ providedIn: 'root' })
export class LoadedJobLogResolver extends LoadedEntityResolver<JobLog> {
  constructor(injector: Injector, entityService: JobLogEntityService) {
    super(injector, entityService, {
      notFoundFallbackUrl: AppRoutes.RecentImportExport.rootPath
    });
  }
}
