import {
  OAuthModuleConfig as LibOAuthModuleConfig,
  OAuthResourceServerConfig as LibOAuthResourceServerConfig
} from 'angular-oauth2-oidc';

export abstract class OAuthResourceServerConfig extends LibOAuthResourceServerConfig {
  /**
   * Determines whether to add the value of the currently impersonated MRI Client ID
   * as a header to the requests made to the resource server.
   *
   * Note: use the `allowedUrls` or `customUrlValidation` to define the url namespace
   * that makes up your resource server api
   *
   * @default true
   */
  abstract sendImpersonatedClientId?: boolean;

  /**
   * The name of the header to send the impersonated MRI Client ID
   *
   * @default 'X-Current-MriClientId'
   */
  abstract impersonatedClientIdHeader?: string;
}

export abstract class OAuthModuleConfig implements LibOAuthModuleConfig {
  abstract resourceServer: OAuthResourceServerConfig;
}
