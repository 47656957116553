import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AccessToken } from '@mri-platform/import-export/common-state';
import { FormModel } from '@mri-platform/shared/common-ui';

type AccessDetail = Pick<AccessToken, 'clientName' | 'databaseName' | 'userName'>;

const initialFormState: FormModel<AccessDetail> = {
  clientName: [''],
  databaseName: [''],
  userName: ['']
};

@Component({
  selector: 'mri-ie-access-detail',
  templateUrl: './access-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
      .form-container {
        width: 400px;
      }
    `
  ]
})
export class AccessDetailComponent {
  @Input() set model(model: AccessToken) {
    if (!model) {
      return;
    }

    this.form.patchValue({ ...model });
  }
  form = this.createForm();

  constructor(private fb: FormBuilder) {}

  createForm() {
    return this.fb.group(initialFormState);
  }
}
