import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadedUser } from '../models/user';
import * as fromAuth from '../reducers/auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const selectImpersonatedClientId = createSelector(selectAuthState, state => state.impersonatedClientId);

export const selectUser = createSelector(selectAuthState, state => state.user);

export const selectLoadedUser = createSelector(selectAuthState, state =>
  state.isLoaded ? (state.user as LoadedUser) : undefined
);

export const selectCurrentClientId = createSelector(
  selectLoadedUser,
  selectImpersonatedClientId,
  (loadedUser, impersonatedClientId) => impersonatedClientId || loadedUser?.clientId
);
