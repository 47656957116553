import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { SanitizedError } from '@mri-platform/angular-error-handling';
import { ErrorEventChannelService, ErrorLoggerService } from '@mri-platform/angular-error-logging';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';

function oauthErrorHandler(errorEventChannelService: ErrorEventChannelService, errorLoggerService: ErrorLoggerService) {
  return () =>
    errorEventChannelService.errors$.subscribe(error => {
      if (error instanceof OAuthErrorEvent) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { params } = error as any;
        const santizedError = new SanitizedError({
          message: params.error_description,
          isError: true,
          ngOriginalError: error
        });
        errorLoggerService.log(santizedError);
      }
    });
}
export const TokenErrorHandlerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: oauthErrorHandler,
  deps: [ErrorEventChannelService, ErrorLoggerService],
  multi: true
};
