import { Action, combineReducers } from '@ngrx/store';
import * as fromUserEnrollState from './user-enroll.reducer';

export const userEnrollFeatureKey = 'userEnroll';

export interface UserEnrollState {
  [fromUserEnrollState.userEnrollStateFeatureKey]: fromUserEnrollState.State;
}

export function reducer(state: UserEnrollState | undefined, action: Action) {
  return combineReducers({
    [fromUserEnrollState.userEnrollStateFeatureKey]: fromUserEnrollState.reducer
  })(state, action);
}
