export const argusLeaseApiEndpoint = '/Integrations/CM/ArgusLeaseSync';
export const journalAllPeriodsApiEndpoint = '/Integrations/GL/Journal/All';
export const journalClosedPeriodsApiEndpoint = '/Integrations/GL/Journal/History';
export const journalOpenPeriodsApiEndpoint = '/Integrations/GL/Journal/V2/Journals';

export const argusIntegrationsApis = [
  argusLeaseApiEndpoint,
  journalAllPeriodsApiEndpoint,
  journalClosedPeriodsApiEndpoint,
  journalOpenPeriodsApiEndpoint
];
