import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { EntityName } from '@mri-platform/import-export/core';
import { safeOmit } from '@mri-platform/shared/core';
import { DefaultEntityService, EntityIdType, entityNumberIdParser, isEntityNew } from '@mri-platform/shared/entity';
import { ChangeSetItem, changeSetItemFactory as cif, DefaultDataServiceConfig, MergeStrategy } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { finalize, mapTo, tap } from 'rxjs/operators';
import { DataSource, DataSourceClient, Platform, Portfolio, Scenario, certificate, createPlatform } from '../models';
import { platformSelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class PlatformEntityService extends DefaultEntityService<Platform> {
  parseId = entityNumberIdParser;

  constructor(injector: Injector, private apiConfig: DefaultDataServiceConfig, private http: HttpClient) {
    super(Platform.entityName, injector);
  }

  create(): Observable<Platform> {
    return of(createPlatform());
  }

  save(entity: Platform) {
    // todo:
    // call this.cancel(correlationId) on unsubscribe from the observerable returned by `add` and `update` below
    // not: do this with the rxjs `using` observable creation function

    // const opts = {
    //   correlationId: getUuid()
    // };
    // const cancelation = this.createCancelation(correlationId || );
    if (isEntityNew(entity, this.selectId)) {
      const values = safeOmit(Platform.omitId(entity), 'isNew', 'clientId');
      return this.add(values as Platform);
    } else {
      return this.update(entity);
    }
    // can't use upsert - it ALWAYS issues a POST even for updates (this is a bug in ngrx/data)
    // return this.upsert(instance);
  }

  saveWithCertificate(entity: Platform) {
    const formData = new FormData();
    const api = `${this.apiConfig.root}/platforms/withCertificate`;
    if (isEntityNew(entity, this.selectId)) {
      const values = JSON.parse(JSON.stringify(safeOmit(Platform.omitId(entity), 'isNew')));
      values.isBatchingSupported = false;
      values.concurrencyLimit = 1;
      values.certificateFile = entity.certificateFile[0];

      for (const key in values) {
        formData.append(key, values[key]);
      }
      return this.http.post<Platform>(api, formData).pipe(
        tap(platform => {
          this.addOneToCache(platform, { mergeStrategy: MergeStrategy.IgnoreChanges });
        })
      );
    } else {
      const values = JSON.parse(JSON.stringify(entity));
      values['isBatchingSupported'] = false;
      values['concurrencyLimit'] = 1;
      if (entity.certificateFile) {
        values['certificateFile'] = entity.certificateFile[0];
      }

      for (const key in values) {
        formData.append(key, values[key]);
      }
      return this.http.put<Platform>(`${api}/${entity.id}`, formData).pipe(
        tap(platform => {
          this.updateOneInCache(platform, { mergeStrategy: MergeStrategy.IgnoreChanges });
        })
      );
    }
  }

  deleteEntity(entity: Platform): Observable<Platform> {
    // todo:
    // call this.cancel(correlationId) on unsubscribe from the observerable returned by `delete` below
    // not: do this with the rxjs `using` observable creation function

    // const opts = {
    //   correlationId: getUuid()
    // };

    return super.delete(entity).pipe(mapTo(entity));
  }

  entityById$(id: EntityIdType) {
    return this.store.select(platformSelectors.selectEntityById(id));
  }

  saveDataSources(
    addedDataSources: Omit<DataSource, 'id'>[],
    updatedDataSources: DataSource[],
    deletedDataSources: DataSource[]
  ) {
    const changes = this.createDataSourceChangeSet(addedDataSources, updatedDataSources, deletedDataSources);
    const api = `${this.apiConfig.root}/datasources`;
    return this.entityDispatcher.saveEntities(changes, api);
  }

  private createDataSourceChangeSet(
    addedDataSources: Omit<DataSource, 'id'>[],
    updatedDataSources: DataSource[],
    deletedDataSources: DataSource[]
  ) {
    const changes: ChangeSetItem[] = [];
    if (addedDataSources.length) {
      changes.push(cif.add(EntityName.DataSource, addedDataSources));
    }

    if (updatedDataSources.length) {
      changes.push(cif.upsert(EntityName.DataSource, updatedDataSources));
    }

    if (deletedDataSources.length) {
      const deleteDataSources = deletedDataSources.map(d => DataSource.selectIdAs<string>(d));
      changes.push(cif.delete(EntityName.DataSource, deleteDataSources));
    }
    return changes;
  }

  saveDataSourcesClients(addedDataSourceCients: DataSourceClient[], deletedDataSourceClients: DataSourceClient[]) {
    const changes = this.createDataSourceClientChangeSet(addedDataSourceCients, deletedDataSourceClients);
    const api = `${this.apiConfig.root}/datasourceclients`;
    return this.entityDispatcher.saveEntities(changes, api);
  }

  uploadCertificate(platformId: number, certificate: certificate) {
    const formData = new FormData();
    const api = `${this.apiConfig.root}/platforms/${platformId}/certificate`;
    if (certificate.file) {
      formData.append('Password', `${certificate.password}`);
      formData.append('File', certificate.file);
    }
    return this.http.post<certificate>(api, formData);
  }

  private createDataSourceClientChangeSet(
    addedDataSourceCients: DataSourceClient[],
    deletedDataSourceClients: DataSourceClient[]
  ) {
    const deletes = deletedDataSourceClients.map(d => DataSourceClient.selectIdAs<string>(d));
    const changes: ChangeSetItem[] = [
      cif.add(EntityName.DataSourceClient, addedDataSourceCients),
      cif.delete(EntityName.DataSourceClient, deletes)
    ];
    return changes;
  }

  getPortfolios(platformId: number) {
    this.setLoading(true);
    const url = `${this.apiConfig.root}/platforms/${platformId}/portfolios`;
    return this.http.get<Portfolio[]>(url).pipe(finalize(() => this.setLoading(false)));
  }

  getScenarios(platformId: number, portfolioId: number) {
    this.setLoading(true);
    const url = `${this.apiConfig.root}/platforms/${platformId}/scenarios?portfolioId=${portfolioId}`;
    return this.http.get<Scenario[]>(url).pipe(finalize(() => this.setLoading(false)));
  }
}
