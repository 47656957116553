import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment, FAKE_DB_COLLECTIONS_TOKEN } from '@mri-platform/shared/core';
import { EntityServices, ENTITY_METADATA_TOKEN } from '@ngrx/data';
import { ClientEntityService, ClientSummaryEntityService, entityMetadata } from './data-access';
import { devDb } from './testing/dev-dataset';
import { testDb } from './testing/test-dataset';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: ENTITY_METADATA_TOKEN, multi: true, useValue: entityMetadata },
    { provide: FAKE_DB_COLLECTIONS_TOKEN, multi: true, useValue: environment.dev ? devDb : testDb }
  ]
})
export class ClientsStateModule {
  constructor(
    entityServices: EntityServices,
    entityService: ClientEntityService,
    clientSummaryService: ClientSummaryEntityService
  ) {
    entityServices.registerEntityCollectionServices([entityService, clientSummaryService]);
  }
}
