import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { UserPreference } from '@mri-platform/import-export/common-state';
import { Subscription } from 'rxjs';
import { LocalizationService } from '../services/Localization.service';

@Pipe({
  name: 'localizeNumber',
  pure: false
})
export class LocalizeNumberPipe implements PipeTransform, OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private localizationService: LocalizationService
  ) {
    const sub = this.localizationService.currentUserPreference$.subscribe(_ => {
      this.cdr.markForCheck();
    });
    this.subscriptions.add(sub);
  }

  transform(value: string | number, options?: Intl.NumberFormatOptions, userPreference?: UserPreference): string {
    return this.localizationService.formatNumber(value, options, userPreference);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
