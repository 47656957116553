<form class="k-form k-form-horizontal form-container" [formGroup]="form">
  <div class="row">
    <kendo-formfield class="col-lg-12">
      <kendo-label [for]="clientName" text="Client ID" class="text-right"></kendo-label>
      <input kendoTextBox formControlName="clientName" #clientName readonly [title]="form.get('clientName')?.value" />
    </kendo-formfield>
  </div>
  <div class="row">
    <kendo-formfield class="col-lg-12">
      <kendo-label [for]="database" text="Database" class="text-right"></kendo-label>
      <input kendoTextBox formControlName="databaseName" #database readonly [title]="form.get('databaseName')?.value" />
    </kendo-formfield>
  </div>
  <div class="row">
    <kendo-formfield class="col-lg-12 mb-0">
      <kendo-label [for]="userName" text="Username" class="text-right"></kendo-label>
      <input kendoTextBox formControlName="userName" #userName readonly [title]="form.get('userName')?.value" />
    </kendo-formfield>
  </div>
</form>
