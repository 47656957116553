export interface FilterDataListOptions<T> {
  searchFields?: (keyof T)[];
  computedFields?: ((value: T) => unknown)[];
  /**
   * Set this option to `true` where an empty search term
   * should cause the search to be skipped and the full
   * list being returned instead.
   *
   * @default false
   */
  noSearchTermReturnsList?: boolean;
}

const isMatch = (searchTerm: string, value: unknown) => {
  if (!searchTerm) {
    return searchTerm === value;
  }

  value = value ?? '';
  const comparableValue = (typeof value === 'string' ? value : String(value)).toLocaleUpperCase();
  return comparableValue.indexOf(searchTerm) !== -1;
};

/**
 * Return a search function that will perform a case-insenstive search matching any item
 * in the list of items supplied whose `searchFields` or any `computedFields` have a value that
 * "contains" the search string supplied.
 *
 * Where the listing of `searchFields` and `computedFields` supplied are both empty, the search function
 * will sample the first item in the list to establish a list of fields whose values are string.
 * These discovered fields will then be used as the `searchFields` to find matching items
 *
 */
export function findBySearch<T>({
  searchFields = [],
  computedFields = [],
  noSearchTermReturnsList
}: FilterDataListOptions<T> = {}) {
  let descoverFields = !searchFields.length && !computedFields.length;

  return (list: T[], searchTerm: string) => {
    if (!list.length || (noSearchTermReturnsList === true && !searchTerm)) return list;

    if (descoverFields) {
      const sample = list.find(x => !!x);
      searchFields = Object.entries(sample ?? {})
        .filter(([, value]) => typeof value === 'string')
        .map(([key]) => key) as Array<keyof T>;
      descoverFields = false;
    }

    const comparableFilterValue = searchTerm.toLocaleUpperCase();
    return list.filter(
      x =>
        searchFields.some(field => isMatch(comparableFilterValue, x[field])) ||
        computedFields.some(compute => isMatch(comparableFilterValue, compute(x)))
    );
  };
}
