import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mri-shared-loading',
  template: '',
  styles: [
    `
      /** Note: styles for mri-k-i-loading--overlay are defined in app-bootstrap.scss */
      :host(.k-i-loading) {
        display: block;
        background-color: rgba(255, 255, 255, 0.4);
        z-index: 1000;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {
  @HostBinding('class.k-i-loading')
  @HostBinding('class.mri-k-i-loading--overlay')
  get loadingClass() {
    return this.show;
  }

  @HostBinding('attr.aria-busy')
  get busy() {
    return this.show;
  }

  @Input() show = false;
}
