import { createEntityMetadataMap } from '@mri-platform/shared/entity';
import {
  AccessToken,
  ApiConnection,
  DataSource,
  DataSourceClient,
  FileData,
  MetaData,
  Platform,
  RefreshApiHistory,
  User,
  UserConnection
} from '../models';

export const entityMetadata = createEntityMetadataMap(
  AccessToken,
  ApiConnection,
  DataSource,
  DataSourceClient,
  Platform,
  MetaData,
  RefreshApiHistory,
  FileData,
  User,
  UserConnection
);

export const pluralNames = {
  // Case matters. Match the case of the entity name.
  MetaData: 'metadata',
  RefreshApiHistory: 'refreshapihistories',
  FileData: 'filedata'
};
