import { FileData } from '../models';

const baseExample: FileData = {
  id: 1,
  rows: [
    {
      rowNumber: 1,
      columns: [
        'First Name',
        'Last Name',
        'Middle Name',
        'Role',
        'Account Number',
        'Account Name',
        'Bank Name',
        'Account Type'
      ]
    }
  ]
};

export const createFileData = (list: Partial<FileData>[]): FileData[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i + 27750 }));
