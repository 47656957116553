import { Injectable, OnDestroy } from '@angular/core';
import { ErrorPolicyService, showAlways } from '@mri-platform/angular-error-handling';
import { DirtyStatePromptService, DirtyStateService } from '@mri-platform/shared/core';
import { defaultSelectId } from '@ngrx/data';
import { RxState } from '@rx-angular/state';
import defaultsDeep from 'lodash-es/defaultsDeep';
import { BehaviorSubject, EMPTY, NEVER, of } from 'rxjs';
import { DefaultExtraState, EntityCrudFacadeExtraState, EntityCrudFacadeState } from './entity-crud-facade-state';
import {
  ChildEntityFacadeStategy,
  EntityCrudFacadeStrategy,
  EntityFacadeStrategy,
  RequiredEntityCrudFacadeStrategy,
  defaultChildrenChanges$,
  defaultChildrenDirtyChanges$
} from './entity-crud-facade-strategy';
import { EntityCrudFacadeService } from './entity-crud-facade.service';
import { EntityChanges } from './tracked-entity-state';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const NO_OP = () => {};

const childrenStrategyDefaults: Required<ChildEntityFacadeStategy<unknown, unknown>> = {
  crud: {
    entitiesByParentId$: () => new BehaviorSubject([]),
    save: () => EMPTY
  },
  changes$: defaultChildrenChanges$,
  dirtyChanges$: defaultChildrenDirtyChanges$,
  getChangesToSave: (changes: EntityChanges<unknown>) => of(changes),
  selectId: defaultSelectId,
  initialEntities$: new BehaviorSubject([]),
  validChanges$: new BehaviorSubject(true) // ensure Save is enabled
};

const entityStrategyDefaults: Pick<
  Required<EntityFacadeStrategy<unknown>>,
  'canCancel' | 'canDelete' | 'changes$' | 'dirtyChanges$' | 'getChangesToSave' | 'validChanges$'
> = {
  canCancel: (dirty: boolean, isNew: boolean) => dirty || isNew,
  canDelete: () => true,
  changes$: NEVER,
  dirtyChanges$: NEVER,
  getChangesToSave: (changes: unknown) => of(changes),
  validChanges$: new BehaviorSubject(true) // ensure Save is enabled
};

const strategyDefaults = {
  allowPristineCancellation: false,
  children: childrenStrategyDefaults,
  cancel$: NEVER,
  delete$: NEVER,
  onDeleteSuccess: NO_OP,
  onSaveSuccess: NO_OP,
  onCancel: NO_OP,
  saveErrorOptions: showAlways
};

@Injectable()
export class EntityCrudFacadeFactoryService implements OnDestroy {
  private facade: OnDestroy | undefined;

  constructor(
    private errorPolicy: ErrorPolicyService,
    private dirtyService: DirtyStateService,
    private dirtyPromptService: DirtyStatePromptService
  ) {}

  ngOnDestroy(): void {
    if (this.facade) {
      this.facade.ngOnDestroy();
    }
  }

  get<E, C = never, S extends EntityCrudFacadeExtraState<S> = DefaultExtraState>(
    strategy: RequiredEntityCrudFacadeStrategy<E, C, S>
  ): EntityCrudFacadeService<E, C, S> {
    if (!this.facade) {
      this.facade = new EntityCrudFacadeService<E, C, S>(
        this.errorPolicy,
        this.dirtyService,
        this.dirtyPromptService,
        strategy,
        new RxState<EntityCrudFacadeState<E, C, S>>()
      );
    }
    return this.facade as EntityCrudFacadeService<E, C, S>;
  }

  /**
   * @summary Create a page level orchestration service
   *
   * Create a service that will handle common page level orchestration tasks such as:
   * * Knowing when to enable/disable the Save, Cancel and Delete buttons based on if the form is dirty or when a save is already in progress
   * * Undoing form changes when the user cancels the save
   * * Preventing the user navigating away from the page when the form is dirty
   * * Knowing when a save is in progress so that the page can show a spinner and/or overlay
   * * Coordinating the save of a parent with one or more children, and handling api failures for the parent and/or child
   * * Dirty tracking for either a single entity or multiple entities in a parent-child entity graph
   */
  createStrategy<E, C = never, S extends EntityCrudFacadeExtraState<S> = DefaultExtraState>(
    strategy: EntityCrudFacadeStrategy<E, C, S>
  ): RequiredEntityCrudFacadeStrategy<E, C, S> {
    this.validateStrategy(strategy);

    // todo: replace manual merge of defaults below with call to lodash-es `defaultsDeep`
    // (problem referencing lodash-es needs to be resolved)
    const showDelete = {
      showDelete: () => strategy.delete$ != null
    };

    return defaultsDeep(strategy, {
      ...strategyDefaults,
      entity: { ...entityStrategyDefaults, ...showDelete },
      children: childrenStrategyDefaults
    });
  }

  private validateStrategy<E, C = never, S extends EntityCrudFacadeExtraState<S> = DefaultExtraState>(
    strategy: EntityCrudFacadeStrategy<E, C, S>
  ) {
    if (strategy.delete$ != null && strategy.entity.crud.deleteEntity == null) {
      throw new Error('Supplied `delete$` without an implementation for `entity.crud.deleteEntity`');
    }
  }
}

export const EntityCrudFacadeFactoryServiceProviders = [EntityCrudFacadeFactoryService, RxState];
