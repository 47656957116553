import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { Subscription } from 'rxjs';
import { LocalizationService } from '../services/Localization.service';

@Directive({
  selector: '[mriIeKendoDateFormat]'
})
export class KendoDateFormatDirective implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  constructor(
    private datePicker: DatePickerComponent,
    private localizationService: LocalizationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const sub = this.localizationService.dateFormat$.subscribe(f => {
      this.datePicker.format = f;
      this.cdr.markForCheck();
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
