import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataServiceConfig } from '@ngrx/data';
import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import { AuditLogFilter, FileType, JobUsageFilters } from '../models';

@Injectable({ providedIn: 'root' })
export class AuditService {
  constructor(
    private apiConfig: DefaultDataServiceConfig,
    private http: HttpClient
  ) {}

  async downloadUsageReport(filters: Omit<JobUsageFilters, 'allClients' | 'allDates'>) {
    const url = `${this.apiConfig.root}/audit/usage`;
    await this.downloadFile(url, filters, 'usage_report');
  }

  async downloadAuditLogs(filters: AuditLogFilter) {
    const url = `${this.apiConfig.root}/audit/logs`;
    await this.downloadFile(url, filters, 'Audit_logs');
  }

  async downloadFile(url: string, filters: JobUsageFilters | Partial<AuditLogFilter>, saveAsName?: string) {
    const blob = await this.http.post(url, filters, { responseType: 'blob' }).toPromise();
    if (blob) {
      saveAs(blob, `${saveAsName}_${format(new Date(), 'ddMMyyyyHHmmss')}.${FileType.Csv}`);
    }
    // TODO: show error message if blob is null
  }
}
