import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'mri-shared-copywrite',
  template: `
    &copy; {{ now | date: 'yyyy' }} MRI Software, LLC. Any unauthorized use, reproduction or transfer of this product is
    strictly prohibited. All rights reserved.
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopywriteComponent {
  now = new Date(Date.now());
  @HostBinding('class.mri-font-size-paragraphs-small') hostClass = true;
}
