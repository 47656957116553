import { Injectable } from '@angular/core';
import { ResourceAuthzSessionService } from '@mri-platform/angular-resource-authz';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { UserPermission } from '@mri-platform/resource-authz';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable()
export class DefaultResourceAuthzSessionService implements ResourceAuthzSessionService {
  permissions$: Observable<UserPermission[] | undefined>;
  constructor(authService: AuthService) {
    this.permissions$ = authService.userLoaded$.pipe(pluck('permissions'));
  }
}
