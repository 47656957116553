import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'mri-ie-stepper-wizard',
  template: `
    <kendo-stepper
      [steps]="steps"
      [stepType]="type"
      [linear]="isLinear"
      [(currentStep)]="currentStep"
      (currentStepChange)="tabChanges.emit($event)"
      [class.mri-k-process-tabs]="isProcessTabs"
    >
    </kendo-stepper>
    <div class="wizard-body">
      <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>
    <div class="wizard-footer">
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        height: 100%;
        display: grid;
        grid-template-areas:
          'wizard-header'
          'wizard-body'
          'wizard-footer';
        grid-template-rows: max-content 1fr max-content;
        overflow: auto;
      }
      kendo-stepper {
        grid-area: wizard-header;
      }
      .wizard-body {
        grid-area: wizard-body;
        overflow: auto;
        /* horizontal scroll bar appears due to row class margins if view has more no.of contents
      we reported to Scott about this
      todo: once fix has been made remove below line */
        overflow-x: hidden;
      }
      .wizard-footer {
        grid-area: wizard-footer;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperWizardComponent {
  @Input() type = 'full';
  @Input() currentStep = 0;
  @Input() steps = [];
  @Input() isLinear = true;
  @Input() isProcessTabs = true;
  @Output() tabChanges = new EventEmitter();

  bodyTemplate?: TemplateRef<unknown>;
  footerTemplate?: TemplateRef<unknown>;
}
