import { Observable } from 'rxjs';

export abstract class DirtyStateService {
  abstract readonly dirty$: Observable<boolean>;

  abstract getDirty(key: string): Observable<boolean>;

  abstract setDirty(key: string, dirty: boolean): void;

  abstract confirmWhenDirty$(confirmationSelector: () => Observable<boolean>): Observable<boolean>;
}
