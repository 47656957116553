import { Directive, TemplateRef } from '@angular/core';
import { DrawerComponent } from './drawer.component';

@Directive({
  selector: '[mriSharedDrawerBody]'
})
export class DrawerBodyDirective {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(drawerComponent: DrawerComponent, templateRef: TemplateRef<any>) {
    drawerComponent.drawerBodyTemplate = templateRef;
  }
}

@Directive({
  selector: '[mriSharedDrawerFooter]'
})
export class DrawerFooterDirective {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(drawerComponent: DrawerComponent, templateRef: TemplateRef<any>) {
    drawerComponent.drawerFooterTemplate = templateRef;
  }
}
