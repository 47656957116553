import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { clientIdKey } from './client-switch-info';

@Injectable()
export abstract class ClientSwitchService {
  /**
   * This function will rerun guards and resolvers when switch client event triggered
   */
  static runGuardsAndResolversOnSwitchClient = (from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot) =>
    from.queryParamMap.get(clientIdKey) !== to.queryParamMap.get(clientIdKey);

  abstract switchClient(clientId: string, additionalQueryParams?: object): void;
}
