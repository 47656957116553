import { Injectable, Injector } from '@angular/core';
import { safeOmit } from '@mri-platform/shared/core';
import { DefaultEntityService, EntityIdType } from '@mri-platform/shared/entity';
import { RefreshApiHistory } from '../models';
import { refreshApiHistorySelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class RefreshApiHistoryEntityService extends DefaultEntityService<RefreshApiHistory> {
  constructor(injector: Injector) {
    super(RefreshApiHistory.entityName, injector);
  }

  entitiesByPlatformId$(platformId: EntityIdType) {
    return this.store.select(refreshApiHistorySelectors.selectEntitiesByPlatformId(platformId));
  }

  protected prepareForInsert(entity: RefreshApiHistory) {
    return safeOmit(
      RefreshApiHistory.omitId(entity),
      'clientId',
      'status',
      'startDatetime',
      'remarks',
      'active',
      'endDatetime'
    );
  }
}
