import { InjectionToken } from '@angular/core';
import { EntityDispatcherDefaultOptions } from '@ngrx/data';
import { ExtendedEntityMetadata } from './extended-entity-meta';

export const pessimisticUiDispatch: Partial<EntityDispatcherDefaultOptions> = {
  optimisticAdd: false,
  optimisticUpdate: false,
  optimisticDelete: false
};

export const optimisticUiClientGenIdDispatch: Partial<EntityDispatcherDefaultOptions> = {
  optimisticAdd: true,
  optimisticUpdate: true,
  optimisticDelete: true
};

export const optimisticUiServerGenIdDispatch: Partial<EntityDispatcherDefaultOptions> = {
  optimisticAdd: false,
  optimisticUpdate: true,
  optimisticDelete: true
};

export type EntityMetadataFactory<T extends object> = (
  metadata: ExtendedEntityMetadata<T>
) => Partial<ExtendedEntityMetadata<T>>;

export const DEFAULT_ENTITY_METADATA_TOKEN = new InjectionToken<
  EntityMetadataFactory<never> | Partial<ExtendedEntityMetadata<never>>
>('DefaultEntityMetadata');

export const defaultEntityMetadata: Partial<ExtendedEntityMetadata<never>> = {
  entityDispatcherOptions: pessimisticUiDispatch
};
