import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@mri-platform/shared/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { UserEnrollmentCallBackResponse, UserEnrollmentRequest, UserEnrollmentResponse } from '../models';
import { UserEnrollActions } from './actions';
import * as fromUserEnroll from './reducers';
import { selectUserEnrollState } from './selectors';

@Injectable({
  providedIn: 'root'
})
export class UserEnrollmentService {
  constructor(
    private store: Store<fromUserEnroll.UserEnrollState>,
    private http: HttpClient,
    private apiConfig: ApiConfig
  ) {}

  postUserEnroll(params: UserEnrollmentRequest) {
    const updateParams = {
      ...params,
      platformType: +params.platformType,
      callBackUrl: `${window.location.origin}/callback`
    };
    return this.http.post<UserEnrollmentResponse>(`${this.apiConfig.url}/welcome/enroll`, updateParams, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getCallBack(params: UserEnrollmentCallBackResponse) {
    const { code, state, scope } = params;
    return this.http.get<UserEnrollmentResponse>(
      `${this.apiConfig.url}/welcome/callback?code=${code}&state=${state}&scope=${scope}`
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setError(error: any) {
    if (error) {
      this.store.dispatch(UserEnrollActions.showError({ error }));
    } else {
      this.store.dispatch(UserEnrollActions.removeError());
    }
  }

  displayError() {
    return this.store.select(selectUserEnrollState).pipe(
      take(1),
      tap(_ => this.setError(''))
    );
  }
}
