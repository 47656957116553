import { Inject, Injectable, Optional } from '@angular/core';
import {
  CollectionRequestInfo,
  Db,
  InMemoryDbService,
  ResponseOptions,
  STATUS,
  getStatusText
} from 'angular-in-memory-web-api';
import { Observable } from 'rxjs';
import { parseRequestUrl } from './fake-api-common-functions';
import { FAKE_DB_COLLECTIONS_TOKEN } from './fake-db-collections-token';

/**
 * Supply faked data to inmemory-api
 *
 * For examples of how to customize see:
 * - https://medium.com/@ohadinho25/boost-front-end-development-part-1-angular-in-memory-web-api-c3a0dd46f7a6
 * - https://github.com/angular/in-memory-web-api
 * - https://github.com/angular/in-memory-web-api/blob/36b5f6777f7688ae9109a2df687a89dbfd4a2066/src/app/hero-in-mem-data.service.ts
 */
@Injectable()
export class FakeDbImplService implements InMemoryDbService {
  constructor(@Optional() @Inject(FAKE_DB_COLLECTIONS_TOKEN) collections: Db[]) {
    (collections || []).forEach(db => this.registerCollections(db));
  }

  private db = {};

  parseRequestUrl = parseRequestUrl;

  createDb() {
    return this.db;
  }

  // HTTP GET interceptor
  get(reqInfo: CollectionRequestInfo): Observable<unknown> | undefined {
    if (reqInfo.collectionName === 'connection' && reqInfo.id != null) {
      // Need to override this request because api endpoint is /connection/{id}
      // connection collection already declared in another page
      // so need to return exportjobfilters collection value for this request
      return this.getCollections(reqInfo, 'exportjobfilters');
    } else if (reqInfo.collectionName === 'welcome') {
      return this.getEnroll(reqInfo);
    } else if (reqInfo.url.includes('/datasources/enrollment')) {
      return this.getCollections(reqInfo, 'enrollmentList');
    } else if (reqInfo.url.includes('/datasources/platform')) {
      return this.getCollections(reqInfo, 'databaseList');
    }
    return undefined; // let the default GET handle all others
  }

  post(reqInfo: CollectionRequestInfo): Observable<unknown> | undefined {
    if (reqInfo.collectionName === 'clients' && reqInfo.id?.id === 'logo') {
      //id will be undefined for import/export case
      return reqInfo.utils.getPassThruBackend().handle(reqInfo.req);
    } else if (reqInfo.collectionName === 'jobs') {
      const job = reqInfo.utils.getJsonBody(reqInfo.req);
      if (job.type === 'Export') {
        return this.getCreatedJob(reqInfo, job);
      }
    } else if (reqInfo.collectionName === 'users') {
      return this.getCreatedUser(reqInfo);
    } else if (reqInfo.collectionName === 'joblogs') {
      return this.getCollections(reqInfo, reqInfo.collectionName);
    }
    return undefined; // let default POST handle things
  }

  put(reqInfo: CollectionRequestInfo): Observable<unknown> | undefined {
    if (reqInfo.collectionName === 'jobs') {
      return this.getUpdatedJob(reqInfo);
    }
    return undefined; // let default POST handle things
  }

  registerCollections(collections: Db) {
    console.log('THERE IS A FAKE DB. REGISTERING NOW.', collections);
    Object.assign(this.db, collections);
  }

  private getCollections(reqInfo: CollectionRequestInfo, collectionName: string) {
    return reqInfo.utils.createResponse$(() => {
      const options: ResponseOptions = {
        body: this.getCollectionFromDb(collectionName),
        status: STATUS.OK
      };
      return this.finishOptions(options, reqInfo);
    });
  }
  private getEnroll(reqInfo: CollectionRequestInfo) {
    return reqInfo.utils.createResponse$(() => {
      const enrollResponse = {
        url: 'https://dev-agadmin-int.redmz.mrisoftware.com/'
      };
      const options: ResponseOptions = {
        body: enrollResponse,
        status: STATUS.OK
      };
      return this.finishOptions(options, reqInfo);
    });
  }

  private getUpdatedJob(reqInfo: CollectionRequestInfo) {
    const job = reqInfo.utils.getJsonBody(reqInfo.req);
    return reqInfo.utils.createResponse$(() => {
      const options: ResponseOptions = {
        body: { ...job, ...{ frequency: job.schedule.recurrenceType } },
        status: STATUS.OK
      };
      return this.finishOptions(options, reqInfo);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getCreatedJob(reqInfo: CollectionRequestInfo, job: any) {
    const apiConnection = this.getCollectionFromDb('apiconnections').find(
      c => parseInt(c.id) === job.sourceConnectionId
    );
    return reqInfo.utils.createResponse$(() => {
      const options: ResponseOptions = {
        body: {
          ...job,
          ...{
            id: reqInfo.collection?.length,
            connection: apiConnection.displayName,
            toTarget: 'sample.csv',
            frequency: job.schedule.recurrenceType
          }
        },
        status: STATUS.OK
      };
      return this.finishOptions(options, reqInfo);
    });
  }

  private getCreatedUser(reqInfo: CollectionRequestInfo) {
    const user = reqInfo.utils.getJsonBody(reqInfo.req);
    return reqInfo.utils.createResponse$(() => {
      const options: ResponseOptions = {
        body: { ...user, ...{ type: 'Internal' } },
        status: STATUS.OK
      };
      return this.finishOptions(options, reqInfo);
    });
  }

  private getCollectionFromDb(collectionName: string) {
    return Object.entries(this.db).find(
      ([path, collection]) => path === collectionName && collection instanceof Array
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    )?.[1] as Array<any>;
  }

  private finishOptions(options: ResponseOptions, { headers, url }: CollectionRequestInfo) {
    options.statusText = options.status == null ? undefined : getStatusText(options.status);
    options.headers = headers;
    options.url = url;
    return options;
  }
}
