import { Injectable, Injector } from '@angular/core';
import { EntitiesResolver } from '@mri-platform/shared/entity';
import { DataSource } from '../models';
import { DataSourceEntityService } from './data-source-entity.service';

@Injectable({ providedIn: 'root' })
export class DataSourcesResolver extends EntitiesResolver<DataSource> {
  constructor(injector: Injector, entityService: DataSourceEntityService) {
    super(injector, entityService);
  }
}
