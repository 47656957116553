import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService, entityNumberIdParser, isEntityNew } from '@mri-platform/shared/entity';
import { ChangeSet, ChangeSetItem, changeSetItemFactory as cif, DefaultDataServiceConfig } from '@ngrx/data';
import { Observable, of } from 'rxjs';
import { Client, createClient } from '../models';

@Injectable({ providedIn: 'root' })
export class ClientEntityService extends DefaultEntityService<Client> {
  parseId = entityNumberIdParser;

  constructor(injector: Injector, private apiConfig: DefaultDataServiceConfig, private http: HttpClient) {
    super(Client.entityName, injector);
  }

  create(): Observable<Client> {
    return of(createClient());
  }

  save(entity: Client) {
    if (isEntityNew(entity, this.selectId)) {
      return this.add(Client.omitId(entity));
    } else {
      return this.update(entity);
    }
  }

  deleteClients(clients: Client[]) {
    const ids = [...clients.map(client => client.id)];
    const changes: ChangeSetItem[] = [cif.delete(Client.entityName, ids)];
    const api = `${this.apiConfig.root}/clients/changeset`;
    const changeSet: ChangeSet = { changes, tag: 'Delete Clients' };
    return this.entityDispatcher.saveEntities(changeSet, api);
  }

  getClientsByUser() {
    const url = `${this.apiConfig.root}/clients/?isLinkedToUser=true`;
    return this.http.get<Client[]>(url);
  }
}
