import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { defineCustomElements } from '@mri/ask-agora-insights/loader';
import { DIRECTIVES } from '../generated';
import { AskAgoraConfig, AskAgoraConfigData } from './ask-agora-config';

defineCustomElements();

@NgModule({
  imports: [CommonModule],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES]
})
export class AskAgoraInsightsAngularModule {
  static forRoot(config?: AskAgoraConfigData): ModuleWithProviders<AskAgoraInsightsAngularModule> {
    return {
      ngModule: AskAgoraInsightsAngularModule,
      providers: [config ? [{ provide: AskAgoraConfig, useValue: new AskAgoraConfig(config) }] : []]
    };
  }
}
