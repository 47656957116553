import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  AccessToken,
  ApiConnection,
  ApiEntityService,
  ConnectionMode,
  JobConfiguration,
  JobsConnectionData,
  argusIntegrationsApis
} from '@mri-platform/import-export/common-state';
import { FormState } from '@mri-platform/shared/common-ui';
import { RxState } from '@rx-angular/state';
import { Observable, Subject, asapScheduler, combineLatest, merge, of } from 'rxjs';
import { filter, map, observeOn, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

const initialFormState = {
  endpoint: ['']
};

interface ConfigurationState extends FormState<JobConfiguration> {
  connectionList: ApiConnection[];
  loading: boolean;
  accesstokens: AccessToken[];
  isLoading: boolean;
}
@Component({
  selector: 'mri-ie-access-token-configuration',
  templateUrl: './access-token-configuration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class AccessJobConfigurationComponent {
  @Input() platformId!: number;
  private _configFormState!: JobConfiguration;
  get loading() {
    return this.state.get('loading');
  }

  get accesstokens() {
    return this.state.get('accesstokens');
  }
  @Input() set accesstokens(accesstokens: AccessToken[]) {
    if (!accesstokens) {
      return;
    }
    this.state.set({
      accesstokens,
      connectionList: [],
      model: { ...this.state.get('model'), connection: Object.assign({}) }
    });
  }

  get formControls() {
    return this.form.controls;
  }

  get configFormState() {
    return this._configFormState;
  }
  @Input() set configFormState(model: JobConfiguration | undefined) {
    if (!model) {
      return;
    }
    this._configFormState = model;
    this.state.set({ model });
    const endpoint = model.connection?.connection?.name || '';
    this.form.patchValue({ endpoint });
  }
  @Input() mode: ConnectionMode = ConnectionMode.Export;
  @Input() showDownloadBtn = false;

  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');
  @Output() isLoading = this.state.select('isLoading');

  accessTokenFormState$ = new Subject<AccessToken>();
  connectionFormState$ = new Subject<JobsConnectionData>();
  accessTokenFormValid$ = new Subject<boolean>();
  connectionFormValid$ = new Subject<boolean>();
  accessTokenFormDirty$ = new Subject<boolean>();
  connectionFormDirty$ = new Subject<boolean>();

  filteredConnections$: Observable<ApiConnection[]> = this.state.select('connectionList');
  form = this.createForm();

  constructor(private state: RxState<ConfigurationState>, private apiService: ApiEntityService,  private fb: FormBuilder) {
    this.state.connect(
      'model',
      this.accessTokenFormState$.pipe(tap(() => this.connectionFormValid$.next(false))),
      ({ model }, context) => ({ ...model, context })
    );

    const resetConnection$ = this.accessTokenFormState$.pipe(
      withLatestFrom(this.state.select('model')),
      filter(
        ([a, m]) =>
          a.clientId !== m.context?.clientId ||
          a.userName !== m.context.userName ||
          a.databaseId !== m.context.databaseId
      )
    );
    this.state.connect('model', resetConnection$, ({ model }, [context]) => ({
      ...model,
      context,
      connection: undefined
    }));

    this.state.connect(
      'model',
      this.connectionFormState$.pipe(tap(() => this.connectionFormValid$.next(true))),
      ({ model }, connection) => ({ ...model, connection })
    );

    const isDirty$ = merge(this.accessTokenFormDirty$, this.connectionFormDirty$).pipe(take(1));
    this.state.connect('isDirty', isDirty$);

    const isValid$ = combineLatest([this.accessTokenFormValid$, this.connectionFormValid$]).pipe(
      map(([contextValid, connectionValid]) => contextValid && connectionValid)
    );
    this.state.connect('isValid', isValid$);
    this.state.connect('loading', this.apiService.loading$);

    this.setFilteredConnections();
  }

  setLoading(loading: boolean) {
    this.state.set({ isLoading: loading });
  }

  setFilteredConnections() {
    const connectionList$ = combineLatest([this.accessTokenFormState$, this.accessTokenFormValid$]).pipe(
      map(([accessTokenFormState, valid]) => ({
        accessTokenFormState,
        valid
      })),
      filter(({ valid }) => valid),
      switchMap(({ accessTokenFormState }) => {
        if (accessTokenFormState && this.platformId) {
          const queryParams = {
            ...accessTokenFormState,
            platformId: this.platformId
          };
          return this.apiService.apisByAccessToken$(queryParams, this.mode);
        }
        return of([]);
      }),
      map(connections => {
        const connectionList = connections.filter(
          connection => !argusIntegrationsApis.includes(connection.displayName)
        );
        return connectionList.sort((a, b) => a.displayName.localeCompare(b.displayName));
      }),
      startWith([])
    );
    this.state.connect('connectionList', connectionList$);
  }

  private createForm() {
    return this.fb.group(initialFormState);
  }
}
