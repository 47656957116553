import { FactoryProvider } from '@angular/core';
import { OAuthModuleConfig } from '@mri/angular-wfe-proxy-oidc';
import { ApiConfig } from '@mri-platform/shared/core';

const OAuthModuleConfigFactory = (apiConfig: ApiConfig): OAuthModuleConfig => {
  return {
    resourceServer: {
      allowedUrls: [apiConfig.baseUrl],
      sendAccessToken: true,
      sendImpersonatedClientId: true
    }
  };
};

export const OAuthModuleConfigProvider: FactoryProvider = {
  provide: OAuthModuleConfig,
  useFactory: OAuthModuleConfigFactory,
  deps: [ApiConfig]
};
