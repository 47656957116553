import { Injectable, Optional } from '@angular/core';
import { AuthzContextLong, AuthzContextShort, ClaimsAuthzService } from '@mri-platform/resource-authz';
import {
  FeatureFlagsService,
  RegisteredEntityMetaDataService,
  RouteMetadata,
  TreeNodeFilterOptions,
  ValidFeatureFlags,
  treeNodeFilter
} from '@mri-platform/shared/core';
import { DrawerMenuItem, MenuItem } from '@mri-platform/shared/ui-main-nav-bar';
import { AuthService, LoadedUser } from '@mri/angular-wfe-proxy-oidc';
import { Observable, combineLatest, isObservable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MainMenuItemService, MenuItemService } from './menu-item.service';

interface HasRouteMetadata {
  metadata?: RouteMetadata;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  mainMenuItems$: Observable<MenuItem[]>;

  constructor(
    private flagService: FeatureFlagsService,
    private authService: AuthService,
    private claimsAuthzService: ClaimsAuthzService,
    private registeredEntityMetaDataService: RegisteredEntityMetaDataService,
    @Optional() mainMenu?: MainMenuItemService
  ) {
    this.mainMenuItems$ = this.createItems$(mainMenu);
  }

  private createItems$(service: MenuItemService | undefined): Observable<MenuItem<RouteMetadata>[]> {
    const items$ = service ? (isObservable(service.items) ? service.items : of(service.items)) : of([]);

    return combineLatest([
      items$,
      this.flagService.flags$,
      this.authService.userLoaded$,
      this.registeredEntityMetaDataService.registeredEntityNames$
    ]).pipe(
      map(([items, flags, user, registeredEntityNames]) => {
        return this.filterMenuItems(flags, user, items, registeredEntityNames);
      }),
      // this IS a long lived event stream, hence `refCount: false`
      shareReplay({ refCount: false, bufferSize: 1 })
    );
  }

  isMenuItemAuthz(
    { permissions }: LoadedUser,
    { authzContext }: RouteMetadata,
    registeredEntityNames: string[]
  ): boolean {
    const isEntityExists = authzContext == null || this.checkEntityExists({ authzContext }, registeredEntityNames);
    return (
      authzContext == null ||
      permissions == null ||
      (isEntityExists && this.claimsAuthzService.checkAccess(authzContext, permissions).isGranted)
    );
  }

  private isMenuItemFeatureEnabled(flags: ValidFeatureFlags, { featureName }: RouteMetadata): boolean {
    return featureName == null || flags[featureName] === true;
  }

  private checkEntityExists({ authzContext }: RouteMetadata, registeredEntityNames: string[]) {
    if (authzContext) {
      const resources = Object.prototype.hasOwnProperty.call(authzContext, 'r')
        ? [...(authzContext as AuthzContextShort).r]
        : [...(authzContext as AuthzContextLong).resource];
      return resources.every(resourceName => registeredEntityNames.some(entityName => entityName === resourceName));
    }
    return true;
  }

  private filterMenuItems(
    flags: ValidFeatureFlags,
    user: LoadedUser,
    items: MenuItem<RouteMetadata>[],
    registeredEntityNames: string[]
  ): MenuItem<RouteMetadata>[] {
    const predicate = ({ metadata }: HasRouteMetadata) =>
      metadata == null ||
      (this.isMenuItemFeatureEnabled(flags, metadata) && this.isMenuItemAuthz(user, metadata, registeredEntityNames));

    const filterOptions: TreeNodeFilterOptions<DrawerMenuItem> = { removeEmpty: true, itemsField: 'links' };
    return items
      .map(item => treeNodeFilter(item, predicate, filterOptions))
      .filter((item): item is MenuItem<RouteMetadata> => item !== null);
  }
}
