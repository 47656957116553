import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'mri-loading-error-banner',
  template: `
    <mri-btn-icon
      icon="critical-diamond"
      size="large"
      class="mri-icon--critical mri-banner-message__icon"
    ></mri-btn-icon>
    <p class="mri-banner-message__text">
      <span class="mri-banner-message__primary-message">{{ loadingErrorMessage }}</span>
    </p>
    <div class="mri-toolbar mri-banner-message__toolbar">
      <button
        *ngIf="showLoadingRetry"
        (click)="clickRetry()"
        type="button"
        class="mri-button mri-button--small mri-banner-message__button"
      >
        <span class="mri-button__label">{{ loadingErrorRetryLabel }}</span>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingErrorBannerComponent {
  @Input() loadingErrorMessage?: string;
  @Input() loadingErrorRetryLabel?: string;
  @Input() showLoadingRetry = false;
  @Output() retry = new EventEmitter();

  @HostBinding('class.mri-banner-message')
  @HostBinding('class.mri-banner-message--critical')
  readonly hostClass = true;

  @HostBinding('attr.role') readonly ariaRole = 'complementary';

  clickRetry() {
    this.retry.emit();
  }
}
