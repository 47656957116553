import { GlobalInMemoryApiOptions } from './interfaces';

export type RequiredGlobalInMemoryApiOptions =
  Required<Omit<GlobalInMemoryApiOptions, 'delay'>> &
  Pick<GlobalInMemoryApiOptions, 'delay'>;

const defaultGlobalOptions: RequiredGlobalInMemoryApiOptions = {
  overrides: {},
  passThrough: []
};

function getGlobalOptionsImpl(value: GlobalInMemoryApiOptions | undefined): RequiredGlobalInMemoryApiOptions {
  if (!value) return defaultGlobalOptions;

  const { passThrough } = value;

  return {
    ...defaultGlobalOptions,
    ...value,
    passThrough: passThrough ? passThrough.map(n => n.toLowerCase()) : defaultGlobalOptions.passThrough
  };
}

// poor mans memoization of getGlobalOptionsImpl for the most recent value of window.__inmemoryApiOptions...

let prevWindowOptions: GlobalInMemoryApiOptions | undefined;
let prevGlobalOptions = defaultGlobalOptions;

export function getGlobalOptions() {
  if (prevWindowOptions === window.__inmemoryApiOptions) return prevGlobalOptions;

  const currentOptions = getGlobalOptionsImpl(window.__inmemoryApiOptions);
  prevWindowOptions = window.__inmemoryApiOptions;
  prevGlobalOptions = currentOptions;

  return currentOptions;
}
