import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { EntityIdType, isEntityNewId } from './entity-functions';
import { ResolveContext } from './resolver-context';

export interface LoadedEntityResolverService<Entity> {
  create?(context: ResolveContext): Observable<Entity>;
  entityById$(id: EntityIdType): Observable<Entity | undefined>;
}

export interface LoadedEntityResolverOptions {
  idParamName?: string;
  notFoundFallbackUrl: string;
}

const defaultOptions: Required<Pick<LoadedEntityResolverOptions, 'idParamName'>> = {
  idParamName: 'id'
};

export class LoadedEntityResolver<Entity extends object> implements Resolve<Entity> {
  private options: Required<LoadedEntityResolverOptions>;
  private router: Router;

  constructor(
    injector: Injector,
    private entityService: LoadedEntityResolverService<Entity>,
    options: LoadedEntityResolverOptions
  ) {
    // we're using injector as service lookup so that future services can be acquired without having
    // to modify constructors of inheriting classes (I do NOT like this pattern! but also don't like
    // having to force inheritors having to declare constructor params that are internal details of this
    // LoadedEntityResolver class)
    this.router = injector.get(Router);

    this.options = { ...defaultOptions, ...options };
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Entity> {
    const id = route.paramMap.get(this.options.idParamName) || '';

    if (isEntityNewId(id)) {
      if (this.entityService.create == null) {
        throw new Error('Routing to a new entity not supported');
      }
      return this.entityService.create({ route, state });
    }

    const entity$ = this.entityService.entityById$(id).pipe(
      switchMap(entity => {
        if (!entity) {
          this.router.navigateByUrl(this.options.notFoundFallbackUrl);
          return EMPTY;
        }
        return of(entity);
      }),
      first()
    );
    return entity$;
  }
}
