import { Injectable } from '@angular/core';
import { DialogCloseResult, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { map } from 'rxjs/operators';

const confirmText = 'Yes';

const defaultOptions: DialogSettings = {
  title: 'Please confirm',
  content: 'Are you sure you want to continue?',
  actionsLayout: 'end',
  actions: [{ text: 'No' }, { text: confirmText, themeColor: 'primary' }]
};

@Injectable({
  providedIn: 'root'
})
export class ConfirmationPromptService {
  constructor(private dialogService: DialogService) {}

  confirm(options: DialogSettings = {}) {
    const finalOptions: DialogSettings = {
      ...defaultOptions,
      ...options,
      actions: defaultOptions.actions
    };

    const dialog = this.dialogService.open(finalOptions);
    return dialog.result.pipe(
      map(result => (result instanceof DialogCloseResult ? false : result.text === confirmText))
    );
  }
}
