import { createEntitiesForIdSelector, createEntitiesGroupedBySelector } from '@mri-platform/shared/entity';
import { jobLogSelectors } from './job-log-selectors';

const selectEntitiesByPlatformMap = createEntitiesGroupedBySelector(jobLogSelectors.selectEntities, 'platformId');
const selectEntitiesForPlatformId = createEntitiesForIdSelector(selectEntitiesByPlatformMap);

export const platformJobLogsSelectors = {
  ...jobLogSelectors,
  selectEntitiesByPlatformMap,
  selectEntitiesForPlatformId
};
