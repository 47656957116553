import get from 'lodash-es/get';
import isFunction from 'lodash-es/isFunction';

export interface AdditionalProperty {
  find: <T>(args: T) => T;
  findWhere: <U>(args: U) => U;
}
export const findPropertyWhere = <T extends AdditionalProperty, U, K extends keyof T>(
  list: T,
  attrsOrFn: U,
  propertyPath: string,
  missingPropertyValue: K[]
) => {
  const fnName = isFunction(attrsOrFn) ? 'find' : 'findWhere';
  const match = list[fnName](attrsOrFn);
  return match != null ? get(match, propertyPath) : missingPropertyValue;
};
