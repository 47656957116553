<ng-container *ngIf="accesstokens && accesstokens.length > 0">
  <mri-ie-access-token
    [accesstokens]="accesstokens"
    [formState]="configFormState?.context"
    (valueChanges)="accessTokenFormState$.next($event)"
    (validChanges)="accessTokenFormValid$.next($event)"
    (dirtyChanges)="accessTokenFormDirty$.next($event)"
  >
  </mri-ie-access-token>
  <mri-ie-connection
    [list]="filteredConnections$ | async"
    [enabled]="accessTokenFormValid$ | async"
    [loading]="loading"
    [context]="configFormState?.context"
    [model]="configFormState?.connection"
    [showDownloadBtn]="showDownloadBtn"
    (valueChanges)="connectionFormState$.next($event)"
    (validChanges)="connectionFormValid$.next($event)"
    (dirtyChanges)="connectionFormDirty$.next($event)"
    (isLoading)="setLoading($event)"
    [displayParent]="false"
  ></mri-ie-connection>
  <form class="k-form k-form-horizontal" [formGroup]="form" *ngIf="formControls?.endpoint?.value">
    <div class="row mri-pb-medium">
      <div class="col-lg-12">
        <div class="row">
          <kendo-formfield class="col-lg-12">
            <kendo-label [for]="endpoint" text="API endpoint" class="text-right"></kendo-label>
            <input [title]="endpoint.value" formControlName="endpoint" kendoTextBox #endpoint readonly />
          </kendo-formfield>
        </div>
      </div>
    </div>
  </form>
</ng-container>
