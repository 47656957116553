import { Injectable } from '@angular/core';
import type { DirtyStateService } from '@mri-platform/shared/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, exhaustMap, first } from 'rxjs/operators';
import { DirtyStateActions } from './../actions';
import * as fromNavigation from './../reducers';
import { selectIsDirty, selectIsKeyDirty } from './../selectors';

@Injectable()
export class DefaultDirtyStateService implements DirtyStateService {
  constructor(private store: Store<fromNavigation.NavigationState>) {}

  readonly dirty$ = this.store.select(selectIsDirty).pipe(distinctUntilChanged());

  getDirty(key: string): Observable<boolean> {
    return this.store.select(selectIsKeyDirty(key)).pipe(distinctUntilChanged());
  }

  setDirty(key: string, dirty: boolean) {
    if (dirty) {
      this.store.dispatch(DirtyStateActions.markDirty({ key }));
    } else {
      this.store.dispatch(DirtyStateActions.markClean({ key }));
    }
  }

  confirmWhenDirty$(confirmationSelector: () => Observable<boolean>) {
    return this.dirty$.pipe(
      first(),
      exhaustMap(dirty => (dirty ? confirmationSelector() : of(true)))
    );
  }
}
