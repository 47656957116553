import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';

/**
 * ApiParameter, ApiProperty, ApiDropdownOptions are composite relationship with metadata
 * never created from UI side
 * so we didn't use normalize state here
 */
export interface MetaData {
  id: number;
  path: string;
  parameters?: ApiParameter[];
  requestProperties?: ApiProperty[];
  responseProperties?: ApiProperty[];
  apiConnectionId: number;
  apiType: ApiType;
}

export enum ApiType {
  HeaderDetail = 'HeaderDetail',
  Standard = 'Standard'
}

export const MetaData = createModelMetadata<MetaData>({
  entityName: EntityName.MetaData,
  authorization: AuthzContextsActionMap.crudFor(EntityName.MetaData),
  selectId: (metaData: MetaData) => metaData.apiConnectionId
});

export interface ApiParameter {
  id: number;
  key: string;
  value: string;
  description?: string;
  label?: string;
  isRequired?: boolean;
  isReadonly?: boolean;
  maxLength?: number;
  maxValue?: number;
  minValue?: number;
  type?: string;
  order?: number;
  fromDate?: string;
  toDate?: string;
  options?: ApiDropdownOptions[];
  fromLabel?: string;
  toLabel?: string;
}

export enum PropertyType {
  Header = 'Header',
  Detail = 'Detail'
}

export interface ApiProperty {
  path: string;
  type: string;
  propertyType: PropertyType;
  isRequired: boolean;
  maxLength?: number;
}

export interface ApiDropdownOptions {
  key: string;
  value: string;
}
