import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';

export interface ClientSummary {
  id: number;
  clientId: number;
  recordLimit: number;
  totalRecordCount: number;
}

export const ClientSummary = createModelMetadata<ClientSummary>({
  entityName: EntityName.ClientSummary,
  idField: 'clientId',
  authorization: AuthzContextsActionMap.crudFor(EntityName.ClientSummary)
});
