import { User, UserType } from '@mri-platform/import-export/common-state';
import { createCollection, withMetadata } from 'angular-in-memory-web-api';
import { JobLog, JobLogError, JobStatus, JobType } from '../models';
import { createJobLogErrors } from './job-log-errors';
import { createJobLogs } from './job-logs';

/*
IMPORTANT: keep the number of example instances in each collection/list small(ish)
This is to make tests run faster
*/

const jobLogs: Partial<JobLog>[] = [
  {
    name: 'Demo-1',
    connection: 'Sample-Connection-1',
    destination: 'Sample-Destination-3',
    status: JobStatus.InProgress,
  },
  {
    name: 'Demo-2',
    connection: 'Sample-Connection-2',
    destination: 'Sample-Destination-2'
  },
  {
    name: 'Demo-Argus-Cloud',
    connection: 'Sample-Connection-11',
    destination: 'Sample-Destination-12',
    status: JobStatus.Succeeded,
    type: JobType.Export,
    mapperName: '',
    mapperType: '',
    totalRecords: 0
  },
  {
    name: 'Demo-3',
    connection: 'Sample-Connection-3',
    destination: 'Sample-Destination-3',
    status: JobStatus.Succeeded
  },
  {
    name: 'Demo-4',
    connection: 'Sample-Connection-4',
    destination: 'Sample-Destination-4',
    status: JobStatus.Succeeded,
    type: JobType.Export
  },
  {
    name: 'Demo-5',
    connection: 'Sample-Connection-4',
    destination: 'Sample-Destination-4',
    status: JobStatus.Failed,
    type: JobType.Export
  },
  {
    name: 'Demo-6',
    connection: 'Sample-Connection-4',
    destination: 'Sample-Destination-4',
    status: JobStatus.Failed,
    type: JobType.Import
  },
  {
    name: 'Demo-7',
    connection: 'Sample-Connection-7',
    destination: 'Sample-Destination-7',
    status: JobStatus.Succeeded,
    type: JobType.Export,
    totalRecords: 0
  },
  {
    name: 'Demo-8',
    connection: 'Sample-Connection-8',
    destination: 'Sample-Destination-8',
    status: JobStatus.Succeeded,
    type: JobType.Export,
    totalRecords: 0
  },
  {
    name: 'Demo-9',
    connection: 'Sample-Connection-9',
    destination: 'Sample-Destination-9',
    status: JobStatus.InProgress,
    type: JobType.Export,
    totalRecords: 0
  },
];

const joblogerrors: Partial<JobLogError>[] = [
  {
    jobLogId: 2,
    name: 'Invalid User',
    message:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    errorType: 'BadRequest'
  },
  {
    jobLogId: 2,
    name: 'Bad Request',
    message:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    errorType: 'InternalServerError',
    errorCount: 100
  },
  {
    jobLogId: 2,
    name: 'Server Error',
    message:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    errorType: 'MethodNotAllowed',
    errorCount: 50
  },
  {
    jobLogId: 2,
    name: 'Session Timeout',
    message:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
    errorType: 'NotFound',
    errorCount: 60
  },
  {
    jobLogId: 4,
    name: 'Validation error(s)',
    message:
      'Some records experienced validation errors, please resubmit or review log for more information.',
    errorType: 'BadRequest',
    errorCount: 1
  },
  {
    jobLogId: 5,
    name: 'Uncategorized error(s)',
    message:
      'Unidentified error has occurred on the job.',
    errorType: 'InternalServerError',
    errorCount: 1
  }
];

const users: Partial<User>[] = [
  {
    username: 'ImpExp1@mrisoftware.com',
    email: 'ImpExp1@mrisoftware.com',
    firstName: 'Import',
    lastName: 'Export1'
  },
  {
    username: 'ImpExp2@mrisoftware.com',
    email: 'ImpExp2@mrisoftware.com',
    firstName: 'Import',
    lastName: 'Export2',
    type: UserType.External
  },
  {
    email: 'ImpExp3@mrisoftware.com',
    username: 'ImpExp3@mrisoftware.com',
    firstName: 'Import',
    lastName: 'Export3'
  },
  {
    email: 'ImpExp4@mrisoftware.com',
    username: 'ImpExp4@mrisoftware.com',
    firstName: 'Import',
    lastName: 'Export4'
  }
];

export const testDb = {
  users: createCollection(users, withMetadata({ applyQueryParams: false })),
  joblogs: createJobLogs(jobLogs),
  joblogerrors: createJobLogErrors(joblogerrors)
};
