import { Injectable, Injector } from '@angular/core';
import { EntityQueryResolver, QueryDef } from '@mri-platform/shared/entity';
import { User, UserType } from '../models';
import { UserEntityService } from './user-entity.service';

@Injectable({ providedIn: 'root' })
export class UsersResolver extends EntityQueryResolver<User> {
  constructor(injector: Injector, entityService: UserEntityService) {
    super(injector, entityService, () => QueryDef.getWithQuery({ type: UserType.Internal }));
  }
}
