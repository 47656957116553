import {
  createEntitiesGroupedBySelector,
  createEntityListForIdSelector,
  createSelectors
} from '@mri-platform/shared/entity';
import { createSelector } from '@ngrx/store';
import { DataSource } from '../../models';

const selectors = createSelectors(DataSource);
const selectEnrolledEntities = createSelector(selectors.selectEntities, datasources =>
  datasources.filter(datasource => datasource.isEnrolled)
);
const selectEntitiesByPlatformMap = createEntitiesGroupedBySelector(selectEnrolledEntities, 'platformId');
const selectEntitiesByPlatformMapWithoutEnrolled = createEntitiesGroupedBySelector(
  selectors.selectEntities,
  'platformId'
);
const selectEntityListForPlatformId = createEntityListForIdSelector(selectEntitiesByPlatformMap, selectors.selectId);
const selectEntityListForPlatformIdWithoutEnrolled = createEntityListForIdSelector(
  selectEntitiesByPlatformMapWithoutEnrolled,
  selectors.selectId
);

export const dataSourceSelectors = {
  ...selectors,
  selectEntitiesByPlatformMap,
  selectEntitiesByPlatformMapWithoutEnrolled,
  selectEntityListForPlatformIdWithoutEnrolled,
  selectEntityListForPlatformId
};
