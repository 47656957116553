import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { DataSourceEntityService } from './data-source-entity.service';

@Injectable({ providedIn: 'root' })
export class EnrolledDatasourcesResolver implements Resolve<string[]> {
  constructor(private dataSourceEntityService: DataSourceEntityService) {
  }
  resolve(route: ActivatedRouteSnapshot) {
    const id = route.paramMap.get('id') || '';
    return this.dataSourceEntityService.getEnrolledDatabases$(id);
  }
}
