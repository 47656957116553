import kebabCase from 'lodash-es/kebabCase';
import upperFirst from 'lodash-es/upperFirst';

export interface DisplayField {
  label: string;
  value: string;
}
const humanizeKey = (key: string) => (key.includes(' ') ? key : kebabCase(key).split('-').map(upperFirst).join(' '));
export const humanizeVersionString = (vs: unknown) => {
  if (typeof vs !== 'string') return `${vs}`;
  if (!vs.includes('-')) return vs;

  const [release, build] = vs.split('-');
  return `${release} (${build})`;
};

interface DisplayFieldOptions {
  valueSelector: (value: unknown) => string;
  keySelector: (key: string) => string;
}

const defaultDisplayFieldOptions: DisplayFieldOptions = {
  valueSelector: (value: unknown) => `${value}`,
  keySelector: humanizeKey
};

export function toDisplayFields(
  entries: Array<[string, unknown]>,
  options: Partial<DisplayFieldOptions> = {}
): DisplayField[] {
  const { valueSelector, keySelector } = { ...defaultDisplayFieldOptions, ...options };
  const initialFields: DisplayField[] = [];
  return entries.reduce(
    (fields, [key, value]) => [...fields, { label: keySelector(key), value: valueSelector(value) }],
    initialFields
  );
}
