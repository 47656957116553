import { APP_INITIALIZER, FactoryProvider } from '@angular/core';
import { AuthService } from './auth.service';

const TryLogin = (authService: AuthService) => async () => {
  // note: route guards will NOT wait on this promise being resolved
  await authService.tryLogin();
};

export const TryLoginInitializerProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: TryLogin,
  deps: [AuthService],
  multi: true
};
