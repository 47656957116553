import { Injectable, Injector } from '@angular/core';
import { EntityQueryResolver } from '@mri-platform/shared/entity';
import { RefreshApiHistory } from '../models';
import { RefreshApiHistoryEntityService } from './refresh-api-history-entity.service';

@Injectable({ providedIn: 'root' })
export class RefreshApiHistoriesResolver extends EntityQueryResolver<RefreshApiHistory> {
  constructor(injector: Injector, entityService: RefreshApiHistoryEntityService) {
    super(injector, entityService);
  }
}
