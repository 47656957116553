import { LicenseType } from '@mri-platform/import-export/common-state';
import { Client } from '../models';

const baseExample: Client = {
  id: 1,
  name: 'MRIQWEB',
  licenseId: 'MRIQWEB',
  licenseType: LicenseType.Standard
};

export const createClientList = (list: Partial<Client>[]): Client[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i }));
