import { DataSource } from '../models';

const baseExample: DataSource = {
  id: 1,
  platformId: 2,
  name: 'Sample db',
  isEnrolled: false
};

export const createExampleDataSources = (list: Partial<DataSource>[]): DataSource[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i + 1 }));
