import { Injectable } from '@angular/core';
import {
  EntityAction,
  EntityActionDataServiceError,
  EntityActionFactory,
  EntityActionGuard,
  EntityDefinitionService,
  EntityOp,
  ofEntityOp
} from '@ngrx/data';
import { Actions, createEffect } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { NonQueryEntityAction } from './non-query-entity-action';

export const singleEntityErrorActions: EntityOp[] = [
  EntityOp.SAVE_ADD_ONE_ERROR,
  EntityOp.SAVE_DELETE_ONE_ERROR,
  EntityOp.SAVE_UPDATE_ONE_ERROR,
  EntityOp.SAVE_UPSERT_ONE_ERROR
];

@Injectable()
export class AutoUndoEffects {
  // eslint-disable-next-line @typescript-eslint/ban-types
  actionGuards: { [entityName: string]: EntityActionGuard<object> } = {};

  constructor(
    private actions: Actions<NonQueryEntityAction<EntityActionDataServiceError>>,
    private actionFactory: EntityActionFactory,
    private eds: EntityDefinitionService
  ) {}

  undoOne$: Observable<EntityAction> = createEffect(() =>
    this.actions.pipe(
      ofEntityOp<NonQueryEntityAction<EntityActionDataServiceError>>(singleEntityErrorActions),
      filter(
        action =>
          action.payload.isOptimistic === true &&
          action.payload.autoUndoOnError !== false &&
          action.payload.data?.originalAction != null
      ),
      map(action => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const unsuccessfulOriginalAction = action.payload.data!.originalAction;
        const entityOrKey = this.extractEntityOrKey(unsuccessfulOriginalAction);
        const undoActionProps = { entityOp: EntityOp.UNDO_ONE, data: entityOrKey };
        return this.actionFactory.createFromAction(unsuccessfulOriginalAction, undoActionProps);
      })
    )
  );

  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  private extractEntityOrKey(action: EntityAction<any>): string | number | object {
    const guard = this.getEntityActionGuard(action.payload.entityName);
    const {
      payload: { entityOp }
    } = action;
    if (entityOp === EntityOp.SAVE_UPDATE_ONE) {
      const { changes } = guard.mustBeUpdate(action);
      return changes;
    } else if (entityOp === EntityOp.SAVE_DELETE_ONE) {
      return guard.mustBeKey(action);
    } else if (entityOp === EntityOp.SAVE_ADD_ONE || entityOp === EntityOp.SAVE_UPSERT_ONE) {
      return guard.mustBeEntity(action);
    } else {
      throw new Error('Do not understand format of persist action required to undo entity action error');
    }
  }

  private getEntityActionGuard(entityName: string) {
    const resolvedGuard = this.actionGuards[entityName];
    if (resolvedGuard) {
      return resolvedGuard;
    }

    const def = this.eds.getDefinition(entityName, true);
    // eslint-disable-next-line @typescript-eslint/ban-types
    const guard = new EntityActionGuard<object>(entityName, def.selectId);
    this.actionGuards = { ...this.actionGuards, [entityName]: guard };
    return guard;
  }
}
