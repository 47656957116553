import { Injectable } from '@angular/core';
import {
  AuthzContext,
  AuthzResult,
  ClaimsAuthzCtxParserService,
  ClaimsAuthzService,
  getAuthzContextAction,
  getAuthzContextResourceTypes,
  UserPermission,
  WhiteListDataService
} from '@mri-platform/resource-authz';
import { ExtendedEntityDefinitionService } from './extended-entity-definition.service';

@Injectable({ providedIn: 'root' })
export class EntityClaimsAuthzService extends ClaimsAuthzService {
  constructor(
    private entityDefintionService: ExtendedEntityDefinitionService,
    claimsAuthzCtxParserService: ClaimsAuthzCtxParserService,
    whiteListDataService: WhiteListDataService
  ) {
    super(claimsAuthzCtxParserService, whiteListDataService);
  }

  checkAccess(authzContext: AuthzContext | AuthzContext[], userPermissions: UserPermission[]): AuthzResult {
    const resolvedAuthzContext = this.resolveAuthzContexts(super.getSuppliedAuthzCtx(authzContext));
    return super.checkAccess(resolvedAuthzContext, userPermissions);
  }

  private resolveAuthzContexts(authzContext: AuthzContext | AuthzContext[]) {
    const contexts = Array.isArray(authzContext) ? authzContext : [authzContext];
    return contexts.flatMap(c => this.resolveAuthzContext(c));
  }

  private resolveAuthzContext(authzContext: AuthzContext) {
    const entityNames = getAuthzContextResourceTypes(authzContext);
    // todo: support resolving more than one action type
    const action = getAuthzContextAction(authzContext);
    if (Array.isArray(action)) {
      throw new Error('resolving multiple actions not currently implemented');
    }
    return entityNames
      .flatMap(e => this.entityDefintionService.getExtendedDefinition(e, true).authorization[action])
      .filter(a => a != null);
  }
}
