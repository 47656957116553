/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: angular `ErrorHandler` uses `any` in it's public api, therefore we're following the same pattern here

import { Injectable, Provider } from '@angular/core';
import { SanitizedError } from '@mri-platform/angular-error-handling';
import { ERROR_APPENDERS, ErrorAppenderService } from '@mri-platform/angular-error-logging';
import { NotificationService } from '@progress/kendo-angular-notification';
import { KendoUiErrorState, KendoUiErrorToastComponent } from './kendo-ui-error-toast.component';

export const errorDetailEntries = ({
  sanitizedMessage: _,
  versionInfo,
  ...rest
}: KendoUiErrorState): [Array<[string, unknown]>, Array<[string, unknown]>] => {
  return [
    Object.entries(rest).filter(([, value]) => !!value),
    Object.entries(versionInfo ?? {}).filter(([, value]) => !!value)
  ];
};

@Injectable()
export class KendoToastErrorAppenderService implements ErrorAppenderService {
  constructor(private notificationService: NotificationService) {}

  handleError(error: any) {
    if (!error || error.isSilent) return;

    const sanitizedErr = SanitizedError.is(error) ? error : SanitizedError.genericError(error);
    const sanitizedErrorInfo: KendoUiErrorState = {
      sanitizedMessage: sanitizedErr.message,
      traceId: sanitizedErr.traceId,
      correlationId: sanitizedErr.detail?.traceId,
      versionInfo: sanitizedErr.versionInfo
    };

    const notificationRef = this.notificationService.show({
      content: KendoUiErrorToastComponent,
      hideAfter: 5000,
      position: { horizontal: 'center', vertical: 'top' },
      animation: { type: 'fade', duration: 400 },
      type: { style: 'error', icon: true },
      closable: errorDetailEntries(sanitizedErrorInfo).flat().length > 0
    });

    if (notificationRef.content) {
      notificationRef.content.instance.kendoError = sanitizedErrorInfo;
      notificationRef.content.instance.hide$.subscribe(() => {
        notificationRef.hide();
      });
    }
  }
}

const KendoToastErrorAppenderProvider: Provider = {
  provide: ERROR_APPENDERS,
  useExisting: KendoToastErrorAppenderService,
  multi: true
};

export const KendoToastErrorAppenderProviders = [KendoToastErrorAppenderService, KendoToastErrorAppenderProvider];
