import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mri-ie-session-timeout-dialog',
  templateUrl: './session-timeout-dialog.component.html',
  styleUrls: ['./session-timeout-dialog.component.scss']
})
export class SessionTimeoutDialogComponent {
  @Input() show = false;
  @Output() action = new EventEmitter<boolean>();
}
