export enum FilterType {
  List = 'List',
  DateRange = 'DateRange'
}

export interface DateRange {
  from: Date;
  to: Date;
}

export interface FilterConfig {
  title: string;
  value?: string | null;
  dateRange?: DateRange;
  type: FilterType;
  listValues?: Array<{ text: string; value: string | null }>;
}

export interface FilterBarConfig {
  filters: FilterConfig[];
  enableDrawer?: boolean;
  showReportDrawer?: boolean;
}
