import { Injectable } from '@angular/core';
import { trimPath } from '@mri-platform/shared/core';
import { DefaultDataService, DefaultDataServiceFactory, EntityCollectionDataService } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { Observable } from 'rxjs';
import { EntityActionRequestOptions } from './entity-action-options';

export class CustomDefaultDataService<T> extends DefaultDataService<T> {
  add(entity: T): Observable<T> {
    const { url } = this.extractRequestOptions(entity);
    const entityOrError = entity || new Error(`No "${this.entityName}" entity to add`);
    return this.execute('POST', url ?? this.entityUrl, entityOrError);
  }

  update(update: Update<T>): Observable<T> {
    const { url } = this.extractRequestOptions(update.changes);
    const id = update && update.id;
    const updateOrError = id == null ? new Error(`No "${this.entityName}" update data or id`) : update.changes;
    return this.execute('PUT', `${trimPath(url ?? this.entityUrl)}/${id}`, updateOrError);
  }

  private extractRequestOptions(entity: T | Partial<T>): EntityActionRequestOptions {
    const proto = Object.getPrototypeOf(entity);
    return proto.requestOptions ?? {};
  }
}

@Injectable()
export class CustomDefaultDataServiceFactory extends DefaultDataServiceFactory {
  create<T>(entityName: string): EntityCollectionDataService<T> {
    return new CustomDefaultDataService<T>(entityName, this.http, this.httpUrlGenerator, this.config);
  }
}
