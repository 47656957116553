import { Injectable, OnDestroy } from '@angular/core';
import { DropdownOptions, UserPreference, UserPreferenceEntityService } from '@mri-platform/import-export/common-state';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { map, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalizationService implements OnDestroy {
  private subscriptions = new Subscription();

  currentUserPreference$ = this.userPreferenceEntityService.userPreferences$;
  timeFormat$ = this.currentUserPreference$.pipe(map(up => up.timeFormat));
  dateFormat$ = this.currentUserPreference$.pipe(map(up => this.getDateFormat(up.dateLocale)));

  constructor(
    private userPreferenceEntityService: UserPreferenceEntityService,
    public intlService: IntlService
  ) {
    const sub = this.userPreferenceEntityService.userPreferences$.subscribe(up => {
      (<CldrIntlService>this.intlService).localeId = up.dateLocale;
    });
    this.subscriptions.add(sub);
  }

  public formatDateTime(value: string | Date, options?: Intl.DateTimeFormatOptions, userPreference?: UserPreference) {
    const preference = userPreference ?? this.currentUserPreference$.value;
    let date = typeof value !== 'string' ? value : new Date(value);
    if (preference.timeOffset) {
      date = new Date(date.getTime() + preference.timeOffset * 60 * 60 * 1000);
    }
    if (options) options.hour12 = preference.timeFormat === 'h:mm a' ? true : false;
    return Intl.DateTimeFormat(preference.dateLocale, options).format(date);
  }

  public formatNumber(value: string | number, options?: Intl.NumberFormatOptions, userPreference?: UserPreference) {
    const local = userPreference?.numericLocale ?? this.currentUserPreference$.value.numericLocale;
    return Intl.NumberFormat(local, options).format(+value);
  }

  public getLocalOptions(locals: string[]) {
    return locals.map(local => {
      return { text: this.getNativeName(local), value: local } as DropdownOptions;
    });
  }

  private getNativeName(locale: string) {
    if (typeof Intl.DisplayNames === 'undefined') {
      console.error('Intl.DisplayNames is not supported in this environment');
    } else {
      const localeObj = new Intl.Locale(locale);
      const languageDisplayNames = new Intl.DisplayNames([localeObj], { type: 'language' });
      const regionDisplayNames = new Intl.DisplayNames([localeObj], { type: 'region' });
      const nativeLanguageName = languageDisplayNames.of(localeObj.language);
      const nativeRegionName = regionDisplayNames.of(localeObj.region ?? localeObj.language);
      return `${nativeLanguageName} (${nativeRegionName})`;
    }
    return;
  }

  public getDateFormat(locale: string): string {
    switch (locale) {
      case 'en-GB':
        return 'dd/MM/yyyy';
      case 'en-us':
        return 'M/d/yyyy';
      case 'es-CL':
        return 'dd-MM-yyyy';
      case 'es-MX':
        return 'd/M/yyyy';
      case 'ja-JP':
        return 'yyyy/M/d';
      case 'ko-KR':
        return 'yyyy. M. d';
      case 'pl-PL':
        return 'd.MM.yyyy';
      case 'th-TH':
        return 'd/M/yyyy';
      case 'zh-CN':
        return 'yyyy/M/d';
      default:
        return 'dd/MM/yyyy';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
