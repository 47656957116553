import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonUiModule } from '@mri-platform/import-export/common-ui';
import { SharedCommonUiModule } from '@mri-platform/shared/common-ui';
import { MainMenuItemService, SharedShellModule } from '@mri-platform/shared/shell';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FeatureToggleModule } from 'ngx-feature-toggle';
import { ShellComponent } from './shell/shell.component';
import { AppMainMenuItemService } from './ui-services';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    FeatureToggleModule,
    ButtonsModule,
    SharedCommonUiModule,
    FeatureToggleModule,
    SharedShellModule,
    CommonUiModule,
    DialogModule
  ],
  providers: [{ provide: MainMenuItemService, useClass: AppMainMenuItemService }],
  declarations: [ShellComponent]
})
export class IEShellModule {}

// make public (ie available outside of this library)...
export { ShellComponent };
