import { FactoryProvider } from '@angular/core';
import { ApiConfig } from '@mri-platform/shared/core';
import { DefaultDataServiceConfig } from '@ngrx/data';

export const DataServiceConfigProvider: FactoryProvider = {
  provide: DefaultDataServiceConfig,
  useFactory: (apiConfig: ApiConfig): DefaultDataServiceConfig => ({
    root: apiConfig.url
  }),
  deps: [ApiConfig]
};
