<form class="k-form k-form-horizontal" [formGroup]="form" *ngIf="enabled">
  <div class="row">
    <div class="col-lg-12">
      <kendo-formfield class="">
        <kendo-label class="text-right" [for]="connection" *ngIf="!labelText" text="Selected connection"></kendo-label>
        <kendo-label class="text-right" [for]="connection" *ngIf="labelText" text="{{ labelText }}"></kendo-label>
        <kendo-dropdownlist
          #parentConnection
          [data]="apiPrefixes"
          [loading]="loading"
          formControlName="parent"
          [title]="parentConnection?.text ? parentConnection.text : ''"
          class="k-required"
          [readonly]="list.length === 1"
          [ngClass]="{ 'k-readonly': list.length === 1 }"
          *ngIf="displayParent"
        >
        </kendo-dropdownlist>
        <kendo-dropdownlist
          #connection
          [data]="apiConnectionList"
          [loading]="loading"
          formControlName="connection"
          textField="tableName"
          valueField="id"
          [title]="form.get('connection').value?.description || ''"
          [class.k-required]="true"
          [readonly]="list.length === 1"
          [ngClass]="{ 'k-readonly': list.length === 1, 'mri-k-show-validation': list.length !== 1 }"
          [popupSettings]="{
            width: 'max-content'
          }"
          *ngIf="!displayParent"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span [title]="dataItem.description || ''">{{ dataItem.tableName }}</span>
          </ng-template>
        </kendo-dropdownlist>
        <button
          kendoButton
          look="flat"
          class="mri-mt-small"
          [disabled]="!form.get('connection').value?.id"
          *ngIf="!displayParent && showDownloadBtn && apiConnectionList?.length > 0 && showDownLoadButton"
          (click)="downloadBtnClicked(form.get('connection').value)"
        >
          <mri-btn-icon icon="download"></mri-btn-icon>
          Download connection field list
        </button>
      </kendo-formfield>
    </div>
  </div>
  <div class="row" *ngIf="displayParent">
    <div class="col-lg-12">
      <kendo-formfield *ngIf="apiConnectionList?.length > 0">
        <kendo-label class="text-right"></kendo-label>
        <kendo-dropdownlist
          #connection
          [data]="apiConnectionList"
          [loading]="loading"
          formControlName="connection"
          textField="displayName"
          valueField="id"
          [title]="
            form.get('connection').value
              ? form.get('connection').value?.verb + ' ' + form.get('connection').value?.displayName
              : ''
          "
          [class.k-required]="true"
          [readonly]="list.length === 1"
          [ngClass]="{ 'k-readonly': list.length === 1 }"
          [popupSettings]="{
            width: 'max-content'
          }"
          class="mri-k-show-validation"
        >
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span [title]="dataItem.verb + ' ' + dataItem.displayName">{{ dataItem.displayName }}</span>
          </ng-template>
        </kendo-dropdownlist>
        <button
          kendoButton
          look="flat"
          class="mri-mt-small"
          [disabled]="!form.get('connection').value?.id"
          *ngIf="showDownloadBtn && apiConnectionList?.length > 0 && showDownLoadButton"
          (click)="downloadBtnClicked(form.get('connection').value)"
        >
          <mri-btn-icon icon="download"></mri-btn-icon>
          Download connection field list
        </button>
      </kendo-formfield>
    </div>
  </div>
</form>
