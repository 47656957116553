import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  AccessTokenEntityService,
  DataSourceEntityService,
  PlatformEnrollment
} from '@mri-platform/import-export/common-state';
import { some } from '@mri-platform/shared/core';
import { AuthService, User } from '@mri/angular-wfe-proxy-oidc';
import { DialogAction, DialogCloseResult, DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { NotificationService } from '@progress/kendo-angular-notification';
import { RxState } from '@rx-angular/state';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

interface ComponentState {
  gridData: PlatformEnrollment[];
  isLoading: boolean;
  currentUser: User;
}

type ViewModel = Omit<ComponentState, 'currentUser'>;

const initialState: ComponentState = {
  gridData: [],
  isLoading: false,
  currentUser: undefined as never
};

@Component({
  selector: 'mri-ie-enrollment',
  templateUrl: './enrollment-dialog.component.html',
  styleUrls: ['./enrollment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class EnrollmentDialogComponent implements OnDestroy {
  @ViewChild('confirmDialogContainerRef', { read: ViewContainerRef })
  confirmDialogContainerRef?: ViewContainerRef;
  @ViewChild('confirmDialogContentRef') confirmDialogContentRef?: TemplateRef<unknown>;
  @Input() platformId?: number;
  vm$: Observable<ViewModel>;
  subscriptions = new Subscription();
  dialogText = '';

  constructor(
    private dataSourceEntityService: DataSourceEntityService,
    private accessTokenEntityService: AccessTokenEntityService,
    private authService: AuthService,
    private state: RxState<ComponentState>,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {
    this.state.set(initialState);
    this.vm$ = this.state.select();
    this.state.connect(
      'gridData',
      this.dataSourceEntityService
        .getDataSourcesForEnrollment$()
        .pipe(
          map(dataSources =>
            this.platformId ? dataSources.filter(dataSource => dataSource.platformId === this.platformId) : dataSources
          )
        )
    );
    this.state.connect(
      'isLoading',
      combineLatest([this.dataSourceEntityService.loading$, this.accessTokenEntityService.loading$]).pipe(some(true))
    );
    this.state.connect('currentUser', this.authService.currentUser$);
  }

  enrollOrRenew(dataSource: PlatformEnrollment) {
    if (dataSource.accessTokenId) {
      this.renewAccess(dataSource);
    } else {
      this.enroll(this.getEnrollConfirmationOptions(dataSource.dataSourceName, dataSource.name), dataSource);
    }
  }

  redirectToEnrollment(platformEnrollment: PlatformEnrollment) {
    const user = this.state.get('currentUser');
    const host = window.location.origin;
    const externalIdentity = user.clientId + '/' + user.email;
    const internalIdentity = user.clientId + '/' + platformEnrollment.dataSourceName + '/' + user.name;
    const url = `${host}/enroll?platformIdentifier=${
      platformEnrollment.identifier
    }&amp;externalIdentity=${encodeURIComponent(externalIdentity)}&amp;internalIdentity=${encodeURIComponent(
      internalIdentity
    )}&amp;callbackUrl=${encodeURIComponent(host)}`;
    window.location.href = url;
  }

  enroll(options: DialogSettings, dataSource: PlatformEnrollment) {
    const sub = this.showConfirmationDialog(options)
      .pipe(
        filter(result => result),
        tap(_ => this.redirectToEnrollment(dataSource))
      )
      .subscribe();
    this.subscriptions.add(sub);
  }

  showConfirmationDialog(options: DialogSettings) {
    const commonOptions: DialogSettings = {
      appendTo: this.confirmDialogContainerRef,
      content: this.confirmDialogContentRef,
      actionsLayout: 'end'
    };
    const finalSettings = { ...options, ...commonOptions };
    this.dialogText = options.content as string;
    const dialog = this.dialogService.open(finalSettings);
    const actionButtons = options.actions as DialogAction[];
    const primaryButtonText = actionButtons.find(a => a.themeColor === 'primary')?.text;
    return dialog.result.pipe(
      map(result => (result instanceof DialogCloseResult ? false : result.text === primaryButtonText))
    );
  }

  renewAccess(platformEnrollment: PlatformEnrollment) {
    if (platformEnrollment.accessTokenId) {
      const renewAccessSub = this.accessTokenEntityService.tryRenewAccess(platformEnrollment.accessTokenId).subscribe(
        _ => {
          this.accessTokenEntityService
            .getByKey(platformEnrollment.accessTokenId)
            .subscribe(_ => this.showSuccessNotification());
        },
        _ => {
          this.enroll(
            this.getRenewFailedOptions(platformEnrollment.dataSourceName, platformEnrollment.name),
            platformEnrollment
          );
        }
      );
      this.subscriptions.add(renewAccessSub);
    }
  }

  showSuccessNotification() {
    this.notificationService.show({
      content: 'Access renewed successfully',
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: 'success', icon: true },
      hideAfter: 5000
    });
  }

  getEnrollConfirmationOptions = (dataSourceName: string, platformName: string): DialogSettings => ({
    title: 'Restart Browser',
    content: `In order for changes to take effect, please close and reopen your browser upon completion of this process.\n\nNote: You have selected to enroll to the ${dataSourceName} database located on platform ${platformName}.  Please ensure that you select this same database again when presented with the data source selection screen.`,
    maxWidth: 700,
    actions: [{ text: 'Cancel' }, { text: 'Continue', themeColor: 'primary' }]
  });

  getRenewFailedOptions = (dataSourceName: string, platformName: string): DialogSettings => ({
    title: 'Attention',
    content: `You have been redirected (in a new tab) to the enrollment process on the database which should resolve the issue. Please restart your browser on completion of the process.\n\nNote: You have selected to enroll to the ${dataSourceName} database located on platform ${platformName}.  Please ensure that you select this same database again when presented with the data source selection screen.`,
    actionsLayout: 'end',
    maxWidth: 700,
    actions: [{ text: 'OK', themeColor: 'primary' }]
  });

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
