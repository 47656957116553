import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClaimsAuthzCtxParserService, ClaimsAuthzService, WhiteListDataService } from '@mri-platform/resource-authz';
import { AngularResourceAuthzConfig } from './angular-resource-authz-config';
import { ResourceAuthzCheckDirective } from './resource-authz-check/resource-authz-check.directive';
import { ResourceAuthzSessionService } from './resource-authz-session.service';

@NgModule({
  imports: [CommonModule],
  declarations: [ResourceAuthzCheckDirective],
  exports: [ResourceAuthzCheckDirective]
})
export class AngularResourceAuthzModule {
  static forRoot(config?: AngularResourceAuthzConfig): ModuleWithProviders<AngularResourceAuthzModule> {
    return {
      ngModule: AngularResourceAuthzModule,
      providers: [
        ClaimsAuthzCtxParserService,
        WhiteListDataService,
        {
          provide: ClaimsAuthzService,
          useFactory: (parserService: ClaimsAuthzCtxParserService, whitelistDataService: WhiteListDataService) =>
            new ClaimsAuthzService(parserService, whitelistDataService),
          deps: [ClaimsAuthzCtxParserService, WhiteListDataService]
        },
        config ? [{ provide: ResourceAuthzSessionService, useClass: config.sessionServiceType }] : []
      ]
    };
  }
}
