import { OptionsFactoryFunction } from '@mri-platform/angular-error-handling';
import { IdSelector } from '@ngrx/entity';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultExtraState, EntityCrudFacadeExtraState } from './entity-crud-facade-state';
import { EntityCrudService } from './entity-crud.service';
import { EntityIdType } from './entity-functions';
import { EntityChanges } from './tracked-entity-state';

export const defaultChildrenChanges$ = new BehaviorSubject([]);
export const defaultChildrenDirtyChanges$ = new BehaviorSubject(false);

interface ChildEntityCrudStrategy<E, C = never> {
  entitiesByParentId$: (id: EntityIdType) => Observable<C[]>;
  save: (changes: EntityChanges<C>, parent: E) => Observable<unknown>;
}

export interface ChildEntityFacadeStategy<E, C = never> {
  crud: ChildEntityCrudStrategy<E, C>;
  changes$?: Observable<C[]>;
  dirtyChanges$?: Observable<boolean>;
  getChangesToSave?: (changes: EntityChanges<C>) => Observable<EntityChanges<C>>;
  initialEntities$: Observable<C[]>;
  selectId?: IdSelector<C>;
  validChanges$?: Observable<boolean>;
}

export interface EntityFacadeStrategy<E> {
  crud: EntityCrudService<E>;
  canCancel?: (dirty: boolean, isNew: boolean, entity: E) => boolean;
  canDelete?: (entity: E) => boolean;
  changes$?: Observable<E>;
  dirtyChanges$?: Observable<boolean>;
  getChangesToSave?: (changes: E) => Observable<E>;
  initialEntity$: Observable<E>;
  showDelete?: (entity: E) => boolean;
  validChanges$?: Observable<boolean>;
}

export enum EntityCrudCancellationReasons {
  routeChange = 'routeChange'
}

export interface EntityCrudCancellationOptions {
  force?: boolean;
  reason?: string;
}

export interface EntityCrudCancellation<E> {
  entity: E;
  currentEntityValue: E;
  options: EntityCrudCancellationOptions;
}

export interface EntityCrudFacadeStrategy<E, C = never, S extends EntityCrudFacadeExtraState<S> = DefaultExtraState> {
  componentName: string;
  entity: EntityFacadeStrategy<E>;
  children?: ChildEntityFacadeStategy<E, C>;
  onDeleteSuccess?: (entity: E) => void;
  onSaveSuccess?: (entity: E) => void;
  onCancel?: (response: EntityCrudCancellation<E>) => void;
  cancel$?: Observable<EntityCrudCancellationOptions | undefined>;
  delete$?: Observable<E | boolean | void>;
  save$: Observable<E | void>;
  saveErrorOptions?: OptionsFactoryFunction;
  extraState: S;
}

export const EntityCrudFacadeStrategy = {
  allowPristineCancellation: () => true
};

export interface RequiredEntityCrudFacadeStrategy<
  E,
  C = never,
  S extends EntityCrudFacadeExtraState<S> = DefaultExtraState
> extends Required<EntityCrudFacadeStrategy<E, C, S>> {
  children: Required<ChildEntityFacadeStategy<E, C>>;
  entity: Required<EntityFacadeStrategy<E>>;
}
