import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'mri-shared-page-message-box',
  template: `
    <h1 class="mri-heading mri-heading--3">
      <ng-content select=".header-text"></ng-content>
    </h1>
    <mri-shared-copywrite></mri-shared-copywrite>
  `,
  styles: [
    `
      :host {
        background-color: white;
        border-radius: 6px;
        width: 30%;
        min-width: 400px;
        padding: 32px;
      }
      :host > *:first-child {
        margin-bottom: 32px;
      }
      :host > *:last-child {
        margin-bottom: 0;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageMessageBoxComponent {}
