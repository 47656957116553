/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NonQueryEntityActionOptions } from './entity-action-options';

export interface UpdateCommand<T> {
  entity: T;
  options: NonQueryEntityActionOptions;
}

export interface UpdateCommandResult<T> {
  entity: T | undefined;
  originalEntity: T;
  error?: any;
}

export function toUpdateResult<T>(entity: T) {
  return (source$: Observable<T>): Observable<UpdateCommandResult<T>> =>
    source$.pipe(
      map(persistedEntity => ({ entity: persistedEntity, originalEntity: entity, error: undefined })),
      catchError(error => of({ entity: undefined, originalEntity: entity, error }))
    );
}

export function unwrapUpdateResult<T>() {
  return (source$: Observable<UpdateCommandResult<T>>): Observable<T> =>
    source$.pipe(mergeMap(({ entity, error }) => (error || !entity ? throwError(error) : of(entity))));
}
