import { DefaultHttpUrlGenerator, HttpUrlGenerator } from '@ngrx/data';
import { Injectable, Provider } from '@angular/core';

@Injectable()
export class CustomHttpUrlGenerator extends DefaultHttpUrlGenerator {
  /**
   * Create the path to a single entity resource
   * @param entityName {string} Name of the entity type, e.g, 'Application'
   * @param root {string} Root path to the resource, e.g., 'some-api`
   * @returns complete path to resource, e.g, 'some-api/applications'
   */
  entityResource(entityName: string, root: string): string {
    // our app convention is the path to single resource is pluralized
    return this.getResourceUrls(entityName, root).collectionResourceUrl;
  }
}

export const CustomHttpUrlGeneratorProvider: Provider = {
  provide: HttpUrlGenerator,
  useClass: CustomHttpUrlGenerator
};
