import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'mri-datalist-search-box',
  template: `
    <input
      type="text"
      class="mri-input mri-input--small mri-field__control"
      value="{{ value }}"
      role="searchbox"
      (input)="changeValue($event.target)"
      placeholder="{{ placeholder }}"
      [disabled]="disabled"
    />
    <mri-fld-icon icon="search" size="small"></mri-fld-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatalistSearchBoxComponent {
  @HostBinding('class') readonly hostClass =
    'mri-field mri-field--no-label mri-field--small mri-datalist__search-field';

  @Input() value = '';
  @Input() placeholder = 'search';
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<string>();

  changeValue(input: unknown) {
    const elem = input as HTMLInputElement;
    this.valueChange.emit(elem.value);
  }
}
