import { ChangeType, EntityChangeTrackerBase, EntityCollection, MergeStrategy } from '@ngrx/data';

/**
 * todo: delete this once bug has been fixed in ngrx-data
 */
export class EntityChangeTrackerBugWorkaround<T> extends EntityChangeTrackerBase<T> {
  /**
   * local fix for bug in the built-in method that mutates state by accident and
   * resulting in the error:
   *
   * `TypeError: Cannot assign to read only property 'changeType' of object '[object Object]'`
   */
  trackDeleteMany(
    keys: (number | string)[],
    collection: EntityCollection<T>,
    mergeStrategy?: MergeStrategy
  ): EntityCollection<T> {
    if (mergeStrategy === MergeStrategy.IgnoreChanges || keys == null || keys.length === 0) {
      return collection; // nothing to track
    }
    let didMutate = false;
    const entityMap = collection.entities;
    const changeState = keys.reduce((chgState, id) => {
      const originalValue = entityMap[id];
      if (originalValue) {
        const trackedChange = chgState[id];
        if (trackedChange) {
          if (trackedChange.changeType === ChangeType.Added) {
            // Special case: stop tracking an added entity that you delete
            // The caller must also detect this, remove it immediately from the collection
            // and skip attempt to delete on the server.
            cloneChgStateOnce();
            delete chgState[id];
          } else if (trackedChange.changeType === ChangeType.Updated) {
            // Special case: switch change type from Updated to Deleted.
            cloneChgStateOnce();
            /* Start: bug fix */
            // trackedChange.changeType = ChangeType.Deleted; // <- this throws an error because it's mutating state
            chgState[id] = { ...trackedChange, changeType: ChangeType.Deleted };
            /* End: bug fix*/
          }
        } else {
          // Start tracking this entity
          cloneChgStateOnce();
          chgState[id] = { changeType: ChangeType.Deleted, originalValue };
        }
      }
      return chgState;

      function cloneChgStateOnce() {
        if (!didMutate) {
          didMutate = true;
          chgState = { ...chgState };
        }
      }
    }, collection.changeState);

    return didMutate ? { ...collection, changeState } : collection;
  }
}
