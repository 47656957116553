import { UserInfo } from 'angular-oauth2-oidc';

export interface UserAttributes {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  roleIds?: Array<string | number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissions?: any[];
  clientId?: string;
}

export interface User extends UserInfo {
  hasValidAccessToken: boolean;
}

export const anonymousUser: User = {
  sub: '',
  hasValidAccessToken: false
};

export type LoadedUser = User & UserAttributes;

export const ImpersonatedClientId = 'impersonatedClientId';
