import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ClientSwitchInfo } from './client-switch-info';

@Injectable({
  providedIn: 'root'
})
export class ClientDataService {
  get(): Observable<ClientSwitchInfo[]> {
    return of([]);
  }
}
