/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: angular `ErrorHandler` uses `any` in it's public api, therefore we're following the same pattern here

import { ErrorHandler, Injectable, NgZone, Provider } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ErrorSelectorFactoryService } from './error-selector-factory.service';
import { ErrorSelectorFunc } from './error-selector-func-token';
import { generateUUId } from './generate-uuid';

export abstract class ErrorEventChannelService {
  abstract errors$: Observable<any>;
  abstract publish(error: any): void;
}

interface EnrichedError {
  traceId: string;
}

@Injectable()
export class ErrorEventChannelServiceImpl implements ErrorEventChannelService, ErrorHandler {
  private errorsSubject = new Subject<any>();
  errors$: Observable<any>;
  private readonly errorSelectorFunc: ErrorSelectorFunc;

  constructor(
    private ngZone: NgZone,
    errorSelectorFactory: ErrorSelectorFactoryService
  ) {
    this.errorSelectorFunc = errorSelectorFactory.getSelector();

    this.errors$ = this.errorsSubject.pipe(
      filter(err => err.isError !== false || err.isSilent === false),
      distinctUntilChanged()
    );
  }

  publish(error: any) {
    error = error?.rejection || error;
    const selectedError = this.errorSelectorFunc(error);
    if (selectedError) {
      const enrichedErr = selectedError as EnrichedError;
      enrichedErr.traceId = enrichedErr.traceId || generateUUId();
      this.errorsSubject.next(enrichedErr);
    }
  }

  handleError(error: any) {
    // note: as of angular 4.3.2 `handleError` will be run outside of the angular zone
    // whenever angular itself is calling `handleError`
    // (see https://github.com/angular/angular/pull/18269)
    // However, direct calls to `handleError` might be made by user land code that
    // is running inside the angular zone.
    // So to maintain consistency we want to ensure all call are running outside of the zone
    this.ngZone.runOutsideAngular(() => {
      this.publish(error);
    });
  }
}

const errorEventChannelProvider: Provider = {
  provide: ErrorEventChannelService,
  useClass: ErrorEventChannelServiceImpl
};
const errorHandlerProvider: Provider = {
  provide: ErrorHandler,
  useExisting: ErrorEventChannelService
};

export const errorEventChannelProviders = [errorEventChannelProvider, errorHandlerProvider];
