import { LicenseType } from '@mri-platform/import-export/common-state';
import { appendDataset } from '@mri-platform/shared/core';
import { Client, ClientSummary } from '../models';
import { createClientSummaryList } from './client-summaries';
import { createClientList } from './clients';
import { testDb } from './test-dataset';

const clients = [...Array(50).keys()].map(
  i =>
    ({
      licenseId: i === 1 ? 'MRIQWEB' : `Sample license ${i}`,
      licenseType: i % 2 === 0 ? LicenseType.Standard : LicenseType.Premium
    } as Client)
);
const clientSummaries = [...Array(50).keys()].map(
  i =>
    ({
      id: i + 1,
      clientId: i + 1
    } as ClientSummary)
);

export const devDb = appendDataset(testDb, {
  clients: {
    additionalData: clients,
    creator: createClientList
  },
  clientsummaries: {
    additionalData: clientSummaries,
    creator: createClientSummaryList
  }
});
