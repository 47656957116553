import { ActivatedRouteSnapshot, Route, RunGuardsAndResolvers } from '@angular/router';
import { AuthzContext, CrudAction } from '@mri-platform/resource-authz';
import mapValues from 'lodash-es/mapValues';
import { ResourceAuthzGuardInjectionToken } from '../auth/resource-authz-guard-injection-token';
import { ClientSwitchService } from '../client-switch';
import { ValidFeatureFlags } from '../feature-flags/feature-flags-service';
import { createWith } from '../lodash-extensions/create-with';
import { RouteMetadata } from './route-meta-data';
import { equalParamsAndUrlSegments, equalPath, equalQueryParamsWithoutToggle } from './route-params-change';

const paramsChangeOrClientSwitch = (from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot) =>
  !equalParamsAndUrlSegments(from, to) || ClientSwitchService.runGuardsAndResolversOnSwitchClient(from, to);

const pathParamsChangeOrClientSwitch = (from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot) =>
  !equalPath(from.url, to.url) || ClientSwitchService.runGuardsAndResolversOnSwitchClient(from, to);

const pathParamsOrQueryParamsChangeExceptMenuToggle = (from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot) =>
  !equalPath(from.url, to.url) || !equalQueryParamsWithoutToggle(from, to);

const getRunGuardsAndResolvers = (value?: RunGuardsAndResolvers) => {
  if (value === 'paramsChange' || !value) {
    return paramsChangeOrClientSwitch;
  } else if (value === 'pathParamsChange') {
    return pathParamsChangeOrClientSwitch;
  } else if (value === 'pathParamsOrQueryParamsChange') {
    return pathParamsOrQueryParamsChangeExceptMenuToggle;
  }

  return value;
};

export interface RoutedMenuInfo {
  metadata: RouteMetadata;
  url: string;
}

export class RouteInfo {
  readonly authzContext?: AuthzContext;
  readonly featureName?: keyof ValidFeatureFlags;

  static path(value: string) {
    return new PathRouteInfo(value);
  }

  static pathParam(paramName: string, pathPrefix?: string) {
    return new ParamaterizedRouteInfo(paramName, undefined, pathPrefix);
  }

  static detailPage(pathPrefix?: string) {
    return RouteInfo.pathParam('id', pathPrefix);
  }

  constructor(
    public readonly path: string,
    public readonly parent?: RouteInfo
  ) {}

  /**
   * Get the base URL as a root-based path with a preceding '/'
   */
  get rootPath(): string {
    return `${this.parent?.rootPath || ''}/${this.path}`;
  }

  applyTo(route: Route) {
    const result: Route = {
      path: this.path,
      ...route
    };
    const data = route.data ? { ...this.toRouteMetadata(), ...route.data } : this.toRouteMetadata();
    if (data) {
      result.data = data;
      if (data.authzContext) {
        result.canActivate = [...(route.canActivate ?? []), ResourceAuthzGuardInjectionToken];
      }
    }

    result.runGuardsAndResolvers = getRunGuardsAndResolvers(route.runGuardsAndResolvers);

    return result;
  }

  children<T extends { [key: string]: RouteInfo }>(routes: T): this & T {
    return Object.assign(
      Object.create(this),
      mapValues(routes, r => createWith(r, { parent: this }))
    );
  }

  toRouteMetadata(): RouteMetadata | undefined {
    if (!this.authzContext && !this.featureName) {
      return undefined;
    }

    return {
      authzContext: this.authzContext,
      featureName: this.featureName
    };
  }

  /**
   * Authorize the user against the authorization context supplied
   */
  withAuthz(authzContext: AuthzContext): this {
    return createWith(this, { authzContext });
  }

  withNoAuthz(): this {
    return createWith(this, { authzContext: undefined });
  }

  /**
   * Authorize the user against the `ModelMetadata.authorization`
   * associated with the entities whose name are listed in `entityNames`
   * @param entityNames
   * the entity names whose `ModelMetadata.authorization` to use
   * @param entityAction
   *  the `AuthzContextsActionMap` key from each `ModelMetadata.authorization` to lookup the authorization contexts to authorize the user against
   */
  withEntityAuthz(entityNames: string[], entityAction: string = CrudAction.Read): this {
    return createWith(this, {
      authzContext: { resource: entityNames, action: entityAction, requiresResolve: true }
    });
  }

  withFeatureName(featureName: keyof ValidFeatureFlags): this {
    return createWith(this, { featureName });
  }

  protected toCoreMenuInfo(menuInfo: Pick<RoutedMenuInfo, 'url'>): RoutedMenuInfo {
    return {
      metadata: this.toRouteMetadata() ?? {},
      ...menuInfo
    };
  }
}

const pathSegment = (paramName: string) => `:${paramName}`;

export class ParamaterizedRouteInfo extends RouteInfo {
  constructor(
    public readonly paramName: string,
    parent?: RouteInfo,
    public pathPrefix?: string
  ) {
    super(pathPrefix ? `${pathPrefix}/${pathSegment(paramName)}` : pathSegment(paramName), parent);
  }

  /**
   * Get the base URL as a root-based path with a preceding '/' resolving the path segment with the `paramValue`
   */
  resolvedRootPath(paramValue: string | number, queryParams?: object) {
    const url = `${this.parent?.rootPath || ''}/${this.resolvePath(paramValue)}`;
    if (queryParams) {
      const queryParamStr = new URLSearchParams(queryParams as never).toString();
      return `${url}?${queryParamStr}`;
    } else {
      return url;
    }
  }

  /**
   * Resolve the path segment with the `paramValue`
   */
  resolvePath(paramValue: string | number) {
    return this.path.replace(pathSegment(this.paramName), paramValue.toString());
  }

  toMenuInfo(paramValue: string | number): RoutedMenuInfo {
    return this.toCoreMenuInfo({ url: this.resolvedRootPath(paramValue) });
  }
}

export class PathRouteInfo extends RouteInfo {
  toMenuInfo(): RoutedMenuInfo {
    return this.toCoreMenuInfo({ url: this.rootPath });
  }
}
