import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EntityList, createEntityList } from '@mri-platform/shared/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSource, UserDataSource } from '../models';
import { DataSourceEntityService } from './data-source-entity.service';

@Injectable({ providedIn: 'root' })
export class LoadedDataSourcesResolver implements Resolve<EntityList<DataSource>> {
  constructor(private entityService: DataSourceEntityService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<EntityList<DataSource>> {
    const id = route.paramMap.get('id') || '';
    return this.entityService.getDataSourceByPlatform$(+id).pipe(
      map((dataSources: UserDataSource[]) => {
        const dataSourceList = dataSources.map((dataSource: UserDataSource) => {
          return {
            id: dataSource.id,
            name: dataSource.databaseName,
            platformId: dataSource.platformId,
            isEnrolled: dataSource.isEnrolled
          } as DataSource;
        });
        return createEntityList(dataSourceList, DataSource.selectId);
      })
    );
  }
}
