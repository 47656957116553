import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SanitizedError } from './sanitized-error';

@Injectable()
export abstract class ErrorMessageTranslationService {
  abstract translate(err: SanitizedError): Observable<SanitizedError> | PromiseLike<SanitizedError>;
}

@Injectable()
export class DefaultErrorMessageTranslationService extends ErrorMessageTranslationService {
  translate(err: SanitizedError): Observable<SanitizedError> {
    return of(err);
  }
}
