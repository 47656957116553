import { ErrorHandler, Injectable, NgZone, Provider } from '@angular/core';
import { SanitizedError } from '@mri-platform/angular-error-handling';
import { ErrorEventChannelService } from '@mri-platform/angular-error-logging';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

interface ErrorResponse {
  isError: boolean;
  status: number;
  error: {
    Message: string;
  };
}
@Injectable()
export class IEErrorEventChannelServiceImpl implements ErrorEventChannelService, ErrorHandler {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private errorsSubject = new Subject<any>();
  errors$: Observable<ErrorResponse | SanitizedError>;

  constructor(private ngZone: NgZone) {
    this.errors$ = this.errorsSubject.pipe(
      filter(err => err.isError !== false || err.isSilent === false),
      distinctUntilChanged()
    );
  }

  publish(error: ErrorResponse | SanitizedError) {
    this.errorsSubject.next(error);
  }

  handleError(error: ErrorResponse) {
    this.ngZone.runOutsideAngular(() => {
      if (error.status === 403) {
        const santizedError = new SanitizedError({
          message: error.error.Message,
          isError: true,
          ngOriginalError: error
        });
        this.publish(santizedError);
      } else {
        this.publish(error);
      }
    });
  }
}

export const ieerrorEventChannelProvider: Provider = {
  provide: ErrorEventChannelService,
  useClass: IEErrorEventChannelServiceImpl
};
