import { Component } from '@angular/core';
import { RoutingEventsService } from '@mri-platform/shared/core';
import { asapScheduler, Observable } from 'rxjs';
import { observeOn } from 'rxjs/operators';

@Component({
  selector: 'mri-shared-page-loading',
  template: ` <mri-shared-loading [show]="pageLoading$ | async"></mri-shared-loading> `
})
export class PageLoadingComponent {
  pageLoading$: Observable<boolean> = this.routerEvents.isNavigationPending$.pipe(
    // avoid change detection fail by listening at end of current event loop
    observeOn(asapScheduler)
  );
  constructor(private routerEvents: RoutingEventsService) {}
}
