import { Injectable } from '@angular/core';
import { EntityActionFactory, EntityActionPayload } from '@ngrx/data';
import { Action } from '@ngrx/store';
import { QueryOptions } from '../entity-action-options';
import { getQueryHash } from '../query-def';

export interface EntityActionPayloadExtras extends QueryOptions {
  queryHash?: string;
}

export type QueryEntityActionPayload<P> = EntityActionPayload<P> & EntityActionPayloadExtras;

export interface QueryCacheEntityAction<P> extends Action {
  readonly type: string;
  readonly payload: QueryEntityActionPayload<P>;
}

@Injectable()
export class QueryCacheEntityActionFactory extends EntityActionFactory {
  protected createCore<P>(payload: EntityActionPayload<P>): QueryCacheEntityAction<P> {
    const queryHash = getQueryHash(payload.data, payload.entityOp);
    if (queryHash) {
      const { type, payload: basePayload } = super.createCore(payload);
      return { type, payload: { ...basePayload, queryHash } };
    } else {
      return super.createCore(payload);
    }
  }
}
