import { SourceType } from '@mri-platform/import-export/common-state';
import { Mapper } from '../models';

const baseExample: Mapper = {
  id: 1,
  name: 'Sample Mapper 1',
  context: 'PMX/MriQweb/connection/1',
  sourceType: SourceType.CONNECTION,
  destinationType: SourceType.FILE,
  sourceConnectionId: 1,
  destinationConnectionId: 1,
  sourceFileId: 1,
  destinationFileId: 1,
  application: 'PMX',
  connectionAlias: 'connection/1',
  columnMaps: [
    {
      sourcePath: 'incomeLimitName',
      sourceType: 'string',
      destinationPath: 'incomeLimitName',
      destinationType: 'string',
      transformationMetadata: {
        transformations: [
          {
            type: 'concat',
            scaleToRound: 2,
            roundingOption: 'up'
          }
        ]
      }
    }
  ]
};

export const createMapperList = (list: Partial<Mapper>[]): Mapper[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i, destinationConnectionId: i }));
