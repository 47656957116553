import unsafeOmit from 'lodash-es/omit';

/**
 * creates an object composed of the  own and inherited enumerable properties
 * of `obj` that are not omitted.
 *
 * This is a type safe version of lodash-es/omit that checks that each key
 * is a member of `obj`
 *
 * @param obj The source object.
 * @param paths The property names to omit, specified individually or in arrays..
 * @returns Returns the new object.
 */
export function safeOmit<T extends object, K extends [...(keyof T)[]]>(
  obj: T,
  ...paths: K
): {
  [K2 in Exclude<keyof T, K[number]>]: T[K2];
} {
  return unsafeOmit(obj, ...paths);
}
