import { EntityState, IdSelector } from '@ngrx/entity';
import difference from 'lodash-es/difference';
import intersectionBy from 'lodash-es/intersectionBy';
import unionBy from 'lodash-es/unionBy';

export interface EntityChanges<T> {
  inserts: T[];
  updates: T[];
  deletes: T[];
}

export interface EntityStateTracking<T> {
  changeState: EntityChanges<T>;
}

export type TrackedEntityState<T> = EntityState<T> & EntityStateTracking<T>;

export const entityChanges = <T>({ deletes, inserts, updates }: Partial<EntityChanges<T>>): EntityChanges<T> => ({
  deletes: deletes ?? [],
  inserts: inserts ?? [],
  updates: updates ?? []
});

export const hasEntityChanges = <T>({ deletes, inserts, updates }: EntityChanges<T>) =>
  deletes.length > 0 || inserts.length > 0 || updates.length > 0;

export const initialEntityStateTracking = <T>(): EntityStateTracking<T> => ({
  changeState: noEntityChanges()
});

export const initialTrackedEntityState = <T>(): TrackedEntityState<T> => ({
  ...initialEntityStateTracking(),
  ids: [],
  entities: {}
});

export const noEntityChanges = <T>(): EntityChanges<T> => ({
  inserts: [],
  updates: [],
  deletes: []
});

export const setEntityChanges = <T>(
  existing: EntityChanges<T>,
  revisions: Partial<EntityChanges<T>>,
  selectId: IdSelector<T>
): EntityChanges<T> => {
  const candidateDeletes = revisions.deletes ? [...existing.deletes, ...revisions.deletes] : existing.deletes;
  const candidateInserts = revisions.inserts
    ? unionBy(revisions.inserts, existing.inserts, selectId)
    : existing.inserts;
  const candidateUpdates = revisions.updates
    ? unionBy(revisions.updates, existing.updates, selectId)
    : existing.updates;

  const cancelledDeletes = intersectionBy(candidateDeletes, candidateInserts, selectId);
  const cancelledInserts = intersectionBy(candidateInserts, candidateDeletes, selectId);
  const cancelledUpdates = intersectionBy(candidateUpdates, candidateDeletes, selectId);
  const inserts = cancelledInserts.length > 0 ? difference(candidateInserts, cancelledInserts) : candidateInserts;
  const updates = cancelledUpdates.length > 0 ? difference(candidateUpdates, cancelledUpdates) : candidateUpdates;
  const updatedInserts = intersectionBy(updates, candidateInserts, selectId);

  return {
    deletes: cancelledDeletes.length > 0 ? difference(candidateDeletes, cancelledDeletes) : candidateDeletes,
    inserts: updatedInserts.length > 0 ? unionBy(updatedInserts, inserts, selectId) : inserts,
    updates: updatedInserts.length > 0 ? difference(updates, updatedInserts) : updates
  };
};
