import { HttpErrorResponse } from '@angular/common/http';
import { getProblemDetail, ProblemDetail, SanitizedError } from '@mri-platform/angular-error-handling';
import { DataServiceError, RequestData } from '@ngrx/data';

const standardMessages = {
  saveErrorMsg: 'We are unable to save your changes. Please, try again.',
  fetchErrorMsg: 'We are unable to fetch the data. Please, try again.'
};

// note: consider adding the `errors` object to enrich message returned
const formatProblemDetailMessage = ({ detail, title }: ProblemDetail) => (detail ? detail : title);

export function sanitizeNgrxDataHttpError(err: unknown) {
  const ngRxError = getNgRxDataError(err);
  if (!ngRxError) {
    return undefined;
  }

  const { error, requestData } = ngRxError;
  if (!requestData) return undefined;

  const detail = error instanceof HttpErrorResponse ? getProblemDetail(error) : undefined;
  return new SanitizedError({
    message: getErrorMessage(requestData, detail),
    isError: isHttpError(error, requestData),
    ngOriginalError: error,
    detail
  });
}

function getErrorMessage(requestData: RequestData, problem: ProblemDetail | undefined): string {
  if (requestData.method === 'GET') {
    return standardMessages.fetchErrorMsg;
  } else if (problem?.status === 400) {
    return formatProblemDetailMessage(problem);
  } else {
    return standardMessages.saveErrorMsg;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getNgRxDataError(err: any): DataServiceError | undefined {
  if (!err) {
    return undefined;
  }

  if (err instanceof DataServiceError) {
    return err;
  }
  if (err.error instanceof DataServiceError) {
    return err.error;
  }
  return undefined;
}

function isHttpError(error: unknown, requestData: RequestData) {
  if (requestData && error instanceof HttpErrorResponse) {
    const isModelWriteValidation = error.status === 400 && requestData.method !== 'GET';
    return !isModelWriteValidation;
  } else {
    return true;
  }
}
