import {
  createEntitiesForIdSelector,
  createEntitiesGroupedBySelector,
  createSelectors
} from '@mri-platform/shared/entity';
import { UserConnection } from '../../models';

const selectors = createSelectors(UserConnection);

const selectEntitiesByUserMap = createEntitiesGroupedBySelector(selectors.selectEntities, 'username');
const selectEntitiesForUserId = createEntitiesForIdSelector(selectEntitiesByUserMap);

export const userConnectionSelectors = {
  ...selectors,
  selectEntitiesByUserMap,
  selectEntitiesForUserId
};
