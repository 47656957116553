import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { UserPreference } from '@mri-platform/import-export/common-state';
import { Subscription } from 'rxjs';
import { LocalizationService } from '../services/Localization.service';
@Pipe({
  name: 'localizeDateTime',
  pure: false
})
export class LocalizeDateTimePipe implements PipeTransform, OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private localizationService: LocalizationService
  ) {
    const sub = this.localizationService.currentUserPreference$.subscribe(_ => {
      this.cdr.markForCheck();
    });
    this.subscriptions.add(sub);
  }

  transform(value: string | Date, options?: Intl.DateTimeFormatOptions, userPreference?: UserPreference): string {
    return this.localizationService.formatDateTime(value, options, userPreference);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
