import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconBaseComponent, IconDirection } from './icon-base.component';

@Component({
  selector: 'mri-btn-icon',
  templateUrl: './icon-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonIconComponent extends IconBaseComponent {
  @Input() direction: IconDirection = 'left';

  constructor() {
    super();
  }

  @HostBinding('class.mri-button__icon') readonly buttonIconHostClass = true;
  @HostBinding('class.mri-button__icon--left') get leftIcon() {
    return this.direction === 'left';
  }
  @HostBinding('class.mri-button__icon--right') get rightIcon() {
    return this.direction === 'right';
  }
}
