import { FactoryProvider } from '@angular/core';
import { environment, Settings } from '@mri-platform/shared/core';

export const SettingsProvider: FactoryProvider = {
  provide: Settings,
  useFactory: () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const browserWindow: any = window || {};
    const settingsObjOrSetter = browserWindow['__settings'] || {};
    if (typeof settingsObjOrSetter === 'function') {
      const settings = {};
      settingsObjOrSetter(settings, environment);
      return settings;
    }
    return settingsObjOrSetter;
  },
  deps: []
};
