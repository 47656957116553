<form [formGroup]="form" class="k-form k-form-horizontal">
  <kendo-formfield>
    <kendo-label for="selection" text="{{ label }} type" class="text-right"></kendo-label>
    <ul class="k-input-list k-input-list--horizontal">
      <li>
        <input
          #useConnection
          kendoRadioButton
          type="radio"
          [value]="sourceType.CONNECTION"
          formControlName="selection"
        />
        <kendo-label [for]="useConnection" class="k-radio-label" text="Connection (Export)"></kendo-label>
      </li>
      <li>
        <input #useFile kendoRadioButton type="radio" [value]="sourceType.FILE" formControlName="selection" />
        <kendo-label [for]="useFile" class="k-radio-label" text="File (Import)"></kendo-label>
      </li>
    </ul>
  </kendo-formfield>
</form>
