import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { createModelMetadata, findBySearch } from '@mri-platform/shared/core';
import { ConnectionMode, ConnectionVerb } from './api-connection';

export enum UserType {
  Internal = 'Internal',
  External = 'External'
}

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  providerUniqueId: string;
  isEnabled: boolean;
  isAdmin: boolean;
  isImportExportUser: boolean;
  type: UserType;
  roles: Role[];
  isNew?: boolean;
}

export const User = createModelMetadata<User>({
  entityName: 'User',
  idField: 'username',
  authorization: {
    ...AuthzContextsActionMap.crudFor(EntityName.User),
    ['ViewPage']: {
      action: ['ViewPage'],
      resource: EntityName.User
    }
  },
  sortComparer: (a, b) => userFullname(a).localeCompare(userFullname(b)),
  filterFn: findBySearch<User>({ searchFields: ['email'] })
});

export interface Role {
  userId: number;
  roleId: number;
  enabled: boolean;
  role: RoleInfo;
}

export interface RoleInfo {
  id: number;
  name: string;
  description: string;
}

export interface UserConnection {
  userId: number;
  apiConnectionId: number;
  enabled: boolean;
  connectionName?: string;
  username?: string;
  displayName?: string;
  mode?: ConnectionMode;
  verb?: ConnectionVerb;
  platformName?: string;
  clientId?: string;
  dataSourceName?: string;
  clientName?: string;
  tableName?: string;
  description?: string;
}

export const UserConnection = createModelMetadata<UserConnection>({
  entityName: 'UserConnection',
  idFields: ['apiConnectionId', 'userId'],
  filterFn: findBySearch<UserConnection>({ searchFields: ['username'] })
});

export const userFullname = ({ firstName, lastName }: User) => `${firstName} ${lastName}`;

export const filterUserList = findBySearch<User>({ searchFields: ['email', 'firstName', 'lastName'] });

export const createUser = () => ({
  id: -1,
  email: '',
  username: '',
  providerUniqueId: '',
  firstName: '',
  lastName: '',
  isEnabled: true,
  isAdmin: true,
  isImportExportUser: false,
  type: UserType.External,
  roles: []
});
