import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AgBarComponent } from './ag-bar.component';
import { AppGatewayConfig, AppGatewayConfigData } from './app-gateway-config';

const COMPONENTS = [AgBarComponent];

@NgModule({
  imports: [CommonModule],
  exports: COMPONENTS,
  declarations: COMPONENTS
})
export class AppGatewayBarModule {
  static forRoot(config?: AppGatewayConfigData): ModuleWithProviders<AppGatewayBarModule> {
    return {
      ngModule: AppGatewayBarModule,
      providers: [config ? [{ provide: AppGatewayConfig, useValue: new AppGatewayConfig(config) }] : []]
    };
  }
}
