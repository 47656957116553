import {
  AccessToken,
  ApiConnection,
  ApiType,
  ConnectionMode,
  ConnectionVerb,
  DataSource,
  DataSourceClient,
  FileData,
  LicenseType,
  MetaData,
  Platform,
  PlatformEnrollment,
  PlatformType,
  RefreshApiHistory,
  RefreshApiStatus,
  UserDataSource
} from '../models';
import { createAccessTokenList } from './access-token-list';
import { createApis } from './apis';
import { createExampleDataSourceClients } from './data-source-clients';
import { createExampleDataSources } from './data-sources';
import { createFileData } from './file-data';
import { createMetaData } from './meta-data';
import { createPlatforms } from './platforms';
import { createExampleRefreshApiHistories } from './refresh-api-history';

/*
IMPORTANT: keep the number of example instances in each collection/list small(ish)
This is to make tests run faster
*/
const contexts: Partial<AccessToken>[] = [
  {
    platformId: 1,
    databaseName: 'ABD_X5INT',
    databaseId: 2,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    platformId: 2,
    databaseName: 'ABD_X6ICR',
    databaseId: 2,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    platformId: 3,
    databaseName: 'ACTX3_ANZ',
    databaseId: 3,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    platformId: 4,
    databaseName: 'ACTX5_ANZ',
    databaseId: 4,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  }
];

const enrollmentList: Partial<PlatformEnrollment>[] = [
  {
    accessTokenId: 18,
    dataSourceId: 2,
    dataSourceName: 'RMX6A_AdmDev',
    identifier: 'pmx',
    name: 'PMX'
  }
];

const userDataSourceList: Partial<UserDataSource>[] = [
  {
    id: 1,
    databaseName: 'ABD_X5INT',
    isEnrolled: true
  },
  {
    id: 2,
    databaseName: 'ABD_X6ICR',
    isEnrolled: true
  },
  {
    id: 3,
    databaseName: 'ACTX3_ANZ',
    isEnrolled: true
  },
  {
    id: 4,
    databaseName: 'ACTX5_ANZ',
    isEnrolled: true
  }
];

const apis: Partial<ApiConnection>[] = [
  {
    name: 'ApiConnection1',
    licenseType: LicenseType.Premium,
    platformLicenseType: LicenseType.Standard,
    platformId: 1,
    databaseId: 1,
    mode: ConnectionMode.Import,
    verb: ConnectionVerb.Post
  },
  {
    name: 'ApiConnection2',
    licenseType: LicenseType.Premium,
    platformLicenseType: LicenseType.Premium,
    platformId: 2,
    databaseId: 2,
    mode: ConnectionMode.Import,
    verb: ConnectionVerb.Post
  },
  {
    name: 'ApiConnection3',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Premium,
    platformId: 3,
    databaseId: 3,
    mode: ConnectionMode.Import,
    verb: ConnectionVerb.Post
  },
  {
    name: 'ApiConnection4',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Standard,
    platformId: 4,
    databaseId: 4,
    mode: ConnectionMode.Import,
    verb: ConnectionVerb.Post
  },
  {
    name: 'ApiConnection5',
    licenseType: LicenseType.Premium,
    platformLicenseType: LicenseType.Standard,
    platformId: 1,
    databaseId: 1,
    mode: ConnectionMode.Export,
    verb: ConnectionVerb.Get
  },
  {
    name: 'ApiConnection6',
    licenseType: LicenseType.Premium,
    platformLicenseType: LicenseType.Premium,
    platformId: 2,
    databaseId: 2,
    mode: ConnectionMode.Export,
    verb: ConnectionVerb.Get
  },
  {
    name: 'ApiConnection7',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Premium,
    platformId: 3,
    databaseId: 3,
    mode: ConnectionMode.Export,
    verb: ConnectionVerb.Get
  },
  {
    name: 'ApiConnection8',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Standard,
    platformId: 4,
    databaseId: 4,
    mode: ConnectionMode.Export,
    verb: ConnectionVerb.Get
  },
  {
    name: 'ApiConnection9',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Premium,
    platformId: 3,
    databaseId: 3,
    mode: ConnectionMode.Import,
    verb: ConnectionVerb.Post
  },
  {
    name: 'ApiConnection10',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Premium,
    platformId: 5,
    mode: ConnectionMode.Import,
    verb: ConnectionVerb.Post
  },
  {
    displayName: '/ApiConnection11',
    name: '/ApiConnection11',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Premium,
    platformId: 1,
    mode: ConnectionMode.Export,
    verb: ConnectionVerb.Get
  },
  {
    displayName: '/Integrations/GL/ClosedJournal/ClosedJournals',
    name: '/Integrations/GL/ClosedJournal/ClosedJournals',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Premium,
    platformId: 1,
    mode: ConnectionMode.Export,
    verb: ConnectionVerb.Get
  },
  {
    displayName: '/ApiConnection_Export_13',
    name: '/ApiConnection_Export_13',
    licenseType: LicenseType.Standard,
    platformLicenseType: LicenseType.Premium,
    platformId: 1,
    mode: ConnectionMode.Export,
    verb: ConnectionVerb.Get
  }
];

const platforms: Partial<Platform>[] = [
  {
    name: 'Okta Alloy X.6 FF'
  },
  {
    name: 'Okta Alloy X.5 FF'
  },
  {
    name: 'Alloy Release X5 QA SaaS'
  },
  {
    name: 'Alloy X6 DEV SaaS'
  },
  {
    name: 'Argus Cloud SandBox',
    type: PlatformType.ArgusCloud,
    clientId: 3,
    clientLicenseId: 'p123456'
  }
];

const dataSources: Partial<DataSource>[] = [
  {
    name: 'ABD_X5INT',
    platformId: 1,
    isEnrolled: true
  },
  {
    name: 'ABD_X6ICR',
    platformId: 2,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ1',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ2',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ3',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ4',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ5',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ6',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ7',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ8',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ9',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ10',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ11',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ12',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ13',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ14',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ15',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ16',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ17',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ18',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ19',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ20',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ21',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ22',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ23',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ24',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ25',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ26',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ27',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ28',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ29',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ30',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ31',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ32',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ33',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ34',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ35',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ36',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ37',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ38',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ39',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ40',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX3_ANZ41',
    platformId: 3,
    isEnrolled: true
  },
  {
    name: 'ACTX5_DEV',
    platformId: 4,
    isEnrolled: true
  }
];

const dataSourceClients: Partial<DataSourceClient>[] = [
  {
    clientId: 3,
    dataSourceId: 1
  },
  {
    clientId: 3,
    dataSourceId: 2
  },
  {
    clientId: 3,
    dataSourceId: 3
  },
  {
    dataSourceId: 4,
    clientId: 3
  }
];

const metaData: MetaData[] = [
  {
    id: 1,
    apiConnectionId: 1,
    path: 'api/test1',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 2,
    apiConnectionId: 2,
    path: 'api/test2',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 3,
    apiConnectionId: 3,
    path: 'api/test3',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 4,
    apiConnectionId: 4,
    path: 'api/test4',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 5,
    apiConnectionId: 5,
    path: 'api/test5',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 6,
    apiConnectionId: 6,
    path: 'api/test6',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 7,
    apiConnectionId: 7,
    path: 'api/test7',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 8,
    apiConnectionId: 8,
    path: 'api/test8',
    apiType: ApiType.HeaderDetail
  },
  {
    id: 9,
    apiConnectionId: 9,
    path: 'api/test9',
    apiType: ApiType.Standard,
    parameters: []
  },
  {
    id: 10,
    apiConnectionId: 10,
    path: 'api/test10',
    apiType: ApiType.Standard,
    parameters: []
  },
  {
    id: 11,
    apiConnectionId: 11,
    path: 'api/test11',
    apiType: ApiType.Standard,
    parameters: []
  },
  {
    id: 12,
    apiConnectionId: 12,
    path: 'api/test12',
    apiType: ApiType.Standard,
    parameters: []
  },
  {
    id: 13,
    apiConnectionId: 13,
    path: 'api/test13',
    apiType: ApiType.Standard,
    parameters: [
      {
        id: 1,
        key: 'id',
        value: 'Test13',
        label: 'Scenario Id',
        type: 'Text'
      },
      {
        id: 1,
        key: 'environment_id',
        value: 'Test13',
        label: 'Environment Id',
        type: 'Text'
      }
    ]
  }
];

const refreshApiHistory: Partial<RefreshApiHistory>[] = [
  {
    dataSourceId: 1,
    platformId: 1,
    status: RefreshApiStatus.InProgress
  },
  {
    dataSourceId: 2,
    platformId: 2,
    status: RefreshApiStatus.InProgress
  },
  {
    dataSourceId: 3,
    platformId: 3
  },
  {
    dataSourceId: 4,
    platformId: 4
  }
];
const fileData: Partial<FileData>[] = [
  {
    rows: [
      {
        rowNumber: 1,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      },
      {
        rowNumber: 2,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      },
      {
        rowNumber: 3,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      }
    ]
  },
  {
    rows: [
      {
        rowNumber: 1,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      }
    ]
  },
  {
    rows: [
      {
        rowNumber: 3,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      }
    ]
  },
  {
    rows: [
      {
        rowNumber: 3,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      }
    ]
  },
  {
    rows: [
      {
        rowNumber: 3,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      }
    ]
  },
  {
    rows: [
      {
        rowNumber: 3,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      },
      {
        rowNumber: 3,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      },
      {
        rowNumber: 3,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      }
    ]
  },
  {
    rows: [
      {
        rowNumber: 1,
        columns: [
          'First Name',
          'Last Name',
          'Middle Name',
          'Role',
          'Account Number',
          'Account Name',
          'Bank Name',
          'Account Type'
        ]
      }
    ]
  }
];

export const testDb = {
  accesstokens: createAccessTokenList(contexts),
  apiconnections: createApis(apis),
  platforms: createPlatforms(platforms),
  datasources: createExampleDataSources(dataSources),
  datasourceclients: createExampleDataSourceClients(dataSourceClients),
  metadata: createMetaData(metaData),
  refreshapihistories: createExampleRefreshApiHistories(refreshApiHistory),
  filedata: createFileData(fileData),
  enrollmentList: enrollmentList,
  databaseList: userDataSourceList
};
