import { SourceType } from '@mri-platform/import-export/common-state';
import { Mapper } from '../models';
import { createMapperList } from './mappers';

const mappers: Partial<Mapper>[] = [
  { name: 'Sample mapper 0',
    clientId: 3,
    clientName: 'MRIQWEB',
    sourceType: SourceType.CONNECTION,
    destinationType: SourceType.FILE,
    displayName: 'Import from cypress',
    platformName: 'Alloy Release X5 QA SaaS',
    standard: true 
  },
  {
    name: 'Sample mapper 1',
    clientId: 3,
    clientName: 'MRIQWEB',
    sourceType: SourceType.CONNECTION,
    destinationType: SourceType.FILE,
    displayName: 'ApiConnection_Export_5',
    platformName: 'Alloy Release X5 QA SaaS',
    standard: true
  },
  {
    name: 'Sample mapper 10',
    clientId: 3,
    clientName: 'MRIQWEB',
    sourceType: SourceType.CONNECTION,
    destinationType: SourceType.FILE,
    displayName: '/ApiConnection11',
    platformName: 'Alloy Release X5 QA SaaS',
    standard: true
  },
  {
    name: 'Sample mapper 2',
    clientId: 3,
    clientName: 'MRIQWEB',
    sourceType: SourceType.CONNECTION,
    destinationType: SourceType.FILE,
    displayName: 'ApiConnection_Export_6',
    platformName: 'Alloy Release X5 QA SaaS',
    standard: true
  },
  {
    id: 15,
    name: 'Sample mapper 3',
    clientId: 3,
    clientName: 'MRIQWEB',
    sourceType: SourceType.FILE,
    displayName: 'ApiConnection_Import_1',
    platformName: 'Alloy Release X5 QA SaaS',
    standard: true,
    destinationType: SourceType.CONNECTION
  },
  {
    id: 16,
    name: 'Sample mapper 4',
    clientId: 3,
    clientName: 'MRIQWEB',
    sourceType: SourceType.FILE,
    displayName: 'ApiConnection_Import_2',
    platformName: 'Alloy Release X5 QA SaaS',
    standard: true,
    destinationType: SourceType.CONNECTION
  }
];

export const testDb = {
  mappers: createMapperList(mappers)
};
