import { ModuleWithProviders, NgModule } from '@angular/core';
import { ClaimsAuthzService } from '@mri-platform/resource-authz';
import { EntityClaimsAuthzService, EntityModule } from '@mri-platform/shared/entity';
import { EntityDataModule, EntityDataModuleConfig } from '@ngrx/data';
import { ClearEntityCacheProvider } from './clear-entity-cache';
import { DataServiceConfigProvider } from './data-service-config.provider';

@NgModule({
  imports: [EntityDataModule, EntityModule]
})
export class AppNgrxDataIntegrationModule {
  static forRoot(config: EntityDataModuleConfig = {}): ModuleWithProviders<AppNgrxDataIntegrationModule> {
    return {
      ngModule: AppNgrxDataIntegrationModule,
      providers: [
        EntityDataModule.forRoot(config ?? {}).providers ?? [],
        [
          DataServiceConfigProvider,
          ClearEntityCacheProvider,
          { provide: ClaimsAuthzService, useExisting: EntityClaimsAuthzService }
        ]
      ]
    };
  }
}
