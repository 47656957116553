/**
 * Pseudo guid generator
 */
export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4();
};

/**
 * return true if can determine that the collection's key field is a number
 * where the collection is empty assume true
 */
export const isCollectionNumeric = <T>(collection: T[], key: keyof T) => 
  collection.length === 0 ? true : typeof collection[0][key] === 'number';

/**
 * Generate the next id value based on the current maximum value of the
 * key field of all items in the supplied collection or the number of items in that
 * collection whichever is greater
 * @param collection entity collection
 * @param key entity primary key
 */
export const generateNextId = <T>(collection: T[], key: keyof T): number | string => {
  const maxId = collection.reduce((prev: number, item: T) => {
    const id = +item[key];
    return Math.max(prev, isNaN(id) ? 0 : id);
  }, 0);
  const nextId = Math.max(maxId, collection.length) + 1;
  return isCollectionNumeric(collection, key) ? nextId : `${nextId}`;
};
