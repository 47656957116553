import { trimPath } from '../lodash-extensions/trim-path';

interface UrlInfo {
  baseUrl: string;
  path: string;
}

export class HttpEndpoint implements UrlInfo {
  baseUrl: string;
  path: string;

  get domain() {
    return this.baseUrl ? this.baseUrl.split('//')[1] : 'localhost';
  }

  get url() {
    return this.path ? `${this.baseUrl}/${this.path}` : this.baseUrl;
  }

  constructor(data: Partial<UrlInfo>, defaults?: UrlInfo) {
    this.baseUrl = trimPath(data.baseUrl) || (defaults ? defaults.baseUrl : '');
    this.path = trimPath(data.path) || (defaults ? defaults.path : '');
  }

  static getUrl(data: Partial<UrlInfo>, defaults?: UrlInfo) {
    return new HttpEndpoint(data, defaults).url;
  }
}
