
import * as d from './proxies';

export const DIRECTIVES = [
  d.ActionToolbar,
  d.AskAgoraErrorSplash,
  d.AskAgoraInsights,
  d.AskAgoraInsightsAuth,
  d.AskAgoraInsightsChatWindow,
  d.AskAgoraInsightsContainer,
  d.AskAgoraInsightsSidebar,
  d.AskAgoraInsightsSparkleButton,
  d.AskAgoraLandingSplash,
  d.ChatBubbleQuery,
  d.ChatBubbleResponse,
  d.ChatConversationContainer,
  d.ChatHistoryItem,
  d.ChatWindowBody,
  d.ChatWindowFooter,
  d.ChatWindowInput,
  d.ChatWindowSendButton,
  d.CompletionFeedbackPanel,
  d.ConfirmationModal,
  d.DeleteIcon,
  d.ErrorImage,
  d.ErrorResponseContainer,
  d.HistoryItemEllipsis,
  d.LoadingAnimation,
  d.NavigationActionItem,
  d.NavigationHistoryList,
  d.NewChatAction,
  d.ProductLogo,
  d.QuerySuggestion,
  d.QuerySuggestionContainer,
  d.RecentChatHistory,
  d.RefreshButton,
  d.RefreshIcon,
  d.ResendQueryButton,
  d.ResponseAvatar,
  d.ResponseCopyButton,
  d.ResponseDislikeButton,
  d.ResponseDownloadButton,
  d.ResponseLikeButton,
  d.ResponseSpeakButton,
  d.SidebarNavContainer,
  d.SpeechDisabledIcon,
  d.SpeechInputIcon,
  d.SpeechListeningIcon,
  d.StopIcon,
  d.ToolbarInlineNotification
];
