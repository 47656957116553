/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: angular `ErrorHandler` uses `any` in it's public api, therefore we're following the same pattern here

import { InjectionToken } from '@angular/core';

export interface ErrorAppenderService {
  /**
   * Append (eg log) error to a destination (aka sink)
   * @param error Error to append
   */
  handleError(error: any): void;
}

export const ERROR_APPENDERS = new InjectionToken('error appenders');
