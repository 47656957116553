import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { TimePickerComponent } from '@progress/kendo-angular-dateinputs';
import { Subscription } from 'rxjs';
import { LocalizationService } from '../services/Localization.service';

@Directive({
  selector: '[mriIeKendoTimeFormat]'
})
export class KendoTimeFormatDirective implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  constructor(
    private timePicker: TimePickerComponent,
    private localizationService: LocalizationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    const sub = this.localizationService.timeFormat$.subscribe(f => {
      this.timePicker.format = f;
      this.cdr.markForCheck();
    });
    this.subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
