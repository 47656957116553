import { Directive, TemplateRef } from '@angular/core';
import { StepperWizardComponent } from './stepper-wizard.component';

@Directive({
  selector: '[mriIeStepperWizardBody]'
})
export class StepperWizardBodyDirective {
  constructor(component: StepperWizardComponent, templateRef: TemplateRef<unknown>) {
    component.bodyTemplate = templateRef;
  }
}

@Directive({
  selector: '[mriIeStepperWizardFooter]'
})
export class StepperWizardFooterDirective {
  constructor(component: StepperWizardComponent, templateRef: TemplateRef<unknown>) {
    component.footerTemplate = templateRef;
  }
}
