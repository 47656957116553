import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Platform } from '../models';
import { PlatformEntityService } from './platform-entity.service';

@Injectable({ providedIn: 'root' })
export class PlatformsResolver implements Resolve<Platform[]> {
  constructor(private platformEntityService: PlatformEntityService) {}
  resolve(route: ActivatedRouteSnapshot) {
    const params = {
      ...(route.data.clientOnly && { clientOnly: route.data.clientOnly }),
      ...(route.data.platformType && { platformType: route.data.platformType })
    };
    return this.platformEntityService.getWithQuery(params, { replaceCache: true });
  }
}
