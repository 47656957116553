<form [formGroup]="form" class="k-form k-form-horizontal" *ngIf="vm$ | async as vm">
  <kendo-formfield>
    <kendo-label class="text-right" [for]="platformId" text="Application"></kendo-label>
    <kendo-dropdownlist
      #platformId
      class="mri-k-show-validation"
      [data]="vm.platforms"
      textField="name"
      valueField="id"
      formControlName="platformId"
      [valuePrimitive]="true"
      [class.k-required]="true"
      (valueChange)="onUserInputChange()"
    >
    </kendo-dropdownlist>
  </kendo-formfield>
  <mri-ie-access-token-configuration
    [configFormState]="vm.model"
    [mode]="vm.mode"
    [platformId]="vm.platformId"
    [accesstokens]="vm.accesstokens"
    [showDownloadBtn]="true"
    (valueChanges)="accessTokenFormState$.next($event)"
    (validChanges)="accessTokenFormValid$.next($event)"
    (dirtyChanges)="accessTokenFormDirty$.next($event)"
    (isLoading)="setLoading($event)"
  >
  </mri-ie-access-token-configuration>
</form>
