import { Injectable, Injector } from '@angular/core';
import { ifNullOrUndefined, safeOmit } from '@mri-platform/shared/core';
import {
  DefaultEntityService,
  EntityIdType,
  entityStringIdParser,
  isEntityNew,
  NEW_ENTITY_ID
} from '@mri-platform/shared/entity';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { createUser, User, UserConnection, UserType } from '../models';
import { ApiEntityService } from './api-entity.service';
import { userConnectionSelectors, userSelectors } from './selectors';
import { UserConnectionsEntityService } from './user-connections-entity.service';

@Injectable({ providedIn: 'root' })
export class UserEntityService extends DefaultEntityService<User> {
  parseId = entityStringIdParser;

  constructor(injector: Injector, private userConnectionEntityService: UserConnectionsEntityService,
    private apiEntityService: ApiEntityService
  ) {
    super(User.entityName, injector);
  }

  entityById$(id: EntityIdType) {
    return this.store.select(userSelectors.selectEntityById(id));
  }

  userConnectionsByUserId$(id: EntityIdType): Observable<UserConnection[]> {
    return this.store.select(userConnectionSelectors.selectEntitiesForUserId(id));
  }

  save(entity: User) {
    const updatedEntity = entity.id === NEW_ENTITY_ID ? { ...entity, isNew: true } : { ...entity };
    if (isEntityNew(updatedEntity, this.selectId)) {
      // Since username is id so never mit for api call
      const values = safeOmit(updatedEntity, 'id', 'type', 'isNew');
      return this.add(values as User);
    } else {
      return this.update(updatedEntity);
    }
  }

  create(): Observable<User> {
    return of(createUser());
  }

  entitesByUserType$(userType = UserType.External) {
    return this.store.select(userSelectors.selectEntitiesByUserType(userType)).pipe(
      ifNullOrUndefined<User[]>([]),
      // only emit when our specific entity has changed
      distinctUntilChanged()
    );
  }

  entityListByUserType$(userType = UserType.External) {
    return this.store.select(userSelectors.selectEntityListByUserType(userType)).pipe(
      // only emit when our specific entity has changed
      distinctUntilChanged()
    );
  }

  entityList$() {
    return this.store.select(userSelectors.selectEntityListByUserType()).pipe(
      // only emit when our specific entity has changed
      distinctUntilChanged()
    );
  }

  saveUserConnections(entities: UserConnection[]) {
    const upserts = entities.map(entity => ({
      apiConnectionId: entity.apiConnectionId,
      userId: entity.userId,
      enabled: entity.enabled
    }));
    this.apiEntityService.clearCache();
    return this.userConnectionEntityService.saveEntities({ upserts });
  }
}
