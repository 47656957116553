import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { impersonateClient, reloadUserInfo, userInfoSuccess } from '../actions/auth.actions';
import { AuthManagedStorageService } from '../auth-managed-storage.service';
import { AuthService } from '../auth.service';
import { ImpersonatedClientId } from '../models/user';
import { UserInfoService } from '../user-info.service';

interface ImpersonateClient extends Action {
  impersonatedClientId: string;
  rehydrated: boolean;
}

@Injectable()
export class ImpersonateClientEffects {
  constructor(
    private actions$: Actions,
    private storage: AuthManagedStorageService,
    private userInfoService: UserInfoService,
    private authService: AuthService
  ) {}

  setImpersonateClient$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ImpersonateClient>(impersonateClient),
        filter(action => !action.rehydrated),
        tap(({ impersonatedClientId }) => this.storage.setItem(ImpersonatedClientId, impersonatedClientId))
      ),
    { dispatch: false }
  );

  reloadUserInfo$ = this.authService.config.reloadAppUserInfoOnClientImpersonation
    ? createEffect(() => this.actions$.pipe(ofType<ImpersonateClient>(impersonateClient), map(reloadUserInfo)))
    : EMPTY;

  updateInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ImpersonateClient>(reloadUserInfo),
      switchMap(() => this.userInfoService.get()),
      map(attributes => userInfoSuccess({ attributes }))
    )
  );
}
