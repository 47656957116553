import { Injectable } from '@angular/core';
import { DialogService, DialogSettings } from '@progress/kendo-angular-dialog';
import { UserPreferenceDialogComponent } from '../components/user-preference-dialog/user-preference-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceDialogService {
  constructor(private dialogService: DialogService) {}

  openUserPreferenceDialog() {
    const options: DialogSettings = {
      title: 'User Preferences',
      content: UserPreferenceDialogComponent,
      minWidth: '55%',
      maxWidth: '60%'
    };
    this.dialogService.open(options);
  }
}
