import { createReducer, on } from '@ngrx/store';
import { DirtyStateActions } from '../actions';

export const dirtyStateFeatureKey = 'dirtyState';

export type State = string[];

export const initialState: State = [];

export const reducer = createReducer(
  initialState,
  on(DirtyStateActions.markDirty, (state, { key }) => (!state.includes(key) ? [...state, key] : state)),
  on(DirtyStateActions.markClean, (state, { key }) => (state.includes(key) ? state.filter(k => k !== key) : state))
);
