import { HttpClient } from '@angular/common/http';
import { forwardRef, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthConfig } from './auth.config';
import { UserAttributes } from './models/user';

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => DefaultUserInfoService)
})
export abstract class UserInfoService {
  abstract get(): Observable<UserAttributes> | Promise<UserAttributes>;
}

@Injectable({
  providedIn: 'root'
})
export class DefaultUserInfoService implements UserInfoService {
  constructor(
    protected http: HttpClient,
    protected config: AuthConfig
  ) {}

  get<T extends UserAttributes = UserAttributes>(): Observable<T> {
    if (!this.config.appUserInfoEndpoint) {
      return of({} as T);
    }

    return this.http.get<T>(this.config.appUserInfoEndpoint);
  }
}
