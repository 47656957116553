/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';

/**
 * function copied from angular to implement 'paramsChange' branch of `shouldRunGuardsAndResolvers`
 * See: https://github.com/angular/angular/blob/f7ea275dc47d2fc1efbb3a83fe433c75ac3ed837/packages/router/src/utils/preactivation.ts#L167-L169
 * */
export function equalParamsAndUrlSegments(a: ActivatedRouteSnapshot, b: ActivatedRouteSnapshot): boolean {
  const equalUrlParams = shallowEqual(a.params, b.params) && equalSegments(a.url, b.url);
  const parentsMismatch = !a.parent !== !b.parent;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return equalUrlParams && !parentsMismatch && (!a.parent || equalParamsAndUrlSegments(a.parent, b.parent!));
}

/**
 * function copied from angular to implement 'pathParamsChange' branch of `shouldRunGuardsAndResolvers`
 * See: https://github.com/angular/angular/blob/f7ea275dc47d2fc1efbb3a83fe433c75ac3ed837/packages/router/src/utils/preactivation.ts#L153-L154
 * */
export function equalPath(as: UrlSegment[], bs: UrlSegment[]): boolean {
  if (as.length !== bs.length) return false;
  return as.every((a, i) => a.path === bs[i].path);
}

function equalSegments(as: UrlSegment[], bs: UrlSegment[]): boolean {
  return equalPath(as, bs) && as.every((a, i) => shallowEqual(a.parameters, bs[i].parameters));
}

export function equalQueryParamsWithoutToggle(a: ActivatedRouteSnapshot, b: ActivatedRouteSnapshot): boolean {
  return shallowEqual(removeToggle(a.queryParams), removeToggle(b.queryParams));
}

export const removeToggle = (params: Params) => {
  const { menuToggle: _, ...rest } = params;
  return rest;
};

type Params = {
  [key: string]: any;
};

function shallowEqual(a: Params, b: Params): boolean {
  // While `undefined` should never be possible, it would sometimes be the case in IE 11
  // and pre-chromium Edge. The check below accounts for this edge case.
  const k1 = a ? Object.keys(a) : undefined;
  const k2 = b ? Object.keys(b) : undefined;
  if (!k1 || !k2 || k1.length != k2.length) {
    return false;
  }
  let key: string;
  for (let i = 0; i < k1.length; i++) {
    key = k1[i];
    if (!equalArraysOrString(a[key], b[key])) {
      return false;
    }
  }
  return true;
}

/**
 * Test equality for arrays of strings or a string.
 */
function equalArraysOrString(a: string | string[], b: string | string[]) {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    const aSorted = [...a].sort();
    const bSorted = [...b].sort();
    return aSorted.every((val, index) => bSorted[index] === val);
  } else {
    return a === b;
  }
}
