import { Inject, Injectable, Optional } from '@angular/core';
import { ERROR_SELECTOR_FUNC, ERROR_SELECTOR_FUNC_ARRAY, ErrorSelectorFunc } from './error-selector-func-token';

@Injectable({ providedIn: 'root' })
export class ErrorSelectorFactoryService {
  private readonly selectorFuncs: ErrorSelectorFunc[];
  constructor(
    @Optional() @Inject(ERROR_SELECTOR_FUNC) selectorFuncs: ErrorSelectorFunc[],
    @Optional() @Inject(ERROR_SELECTOR_FUNC_ARRAY) selectorFuncsFromRoot: ErrorSelectorFunc[]
  ) {
    this.selectorFuncs = [...(selectorFuncsFromRoot || []), ...(selectorFuncs || [])];
  }

  getSelector() {
    return this.createCompositeSelector(this.selectorFuncs);
  }

  createCompositeSelector(selectors: Array<ErrorSelectorFunc | undefined>): ErrorSelectorFunc {
    const availableSelectors = (selectors || []).filter((s): s is ErrorSelectorFunc => s != null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (err: any) => {
      if (!err) return err;

      let selectedErr = err;

      for (const selector of availableSelectors) {
        selectedErr = selector(selectedErr);
        if (!selectedErr) {
          return undefined;
        }
      }
      return selectedErr;
    };
  }
}
