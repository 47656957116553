import { createEntitiesForIdSelector, createEntitiesGroupedBySelector } from '@mri-platform/shared/entity';
import { createSelector } from '@ngrx/store';
import { AccessToken, ConnectionMode, ConnectionVerb } from '../../models';
import { apiConnectionsSelectors } from './api-connection-selectors';

export const selectByAccessToken = (queryParams: Partial<AccessToken>, mode: ConnectionMode) => {
  return createSelector(apiConnectionsSelectors.selectEntities, entities => {
    const { platformId, clientId, databaseId, userName } = queryParams;

    return entities.filter(entity => {
      const isClientAvailable = entity.clientId === clientId || !entity.clientId;
      const isDatabase = entity.databaseId === databaseId || !entity.databaseId;
      const isUserName = entity.userName === userName || !entity.userName;
      const isUserSelectedMode =
        mode === ConnectionMode.Export
          ? entity.verb === ConnectionVerb.Get
          : entity.verb === ConnectionVerb.Post || entity.verb === ConnectionVerb.Put;
      return (
        entity.isEnabled &&
        isClientAvailable &&
        isDatabase &&
        isUserName &&
        isUserSelectedMode &&
        entity.platformId === platformId
      );
    });
  });
};

const selectEntitiesByPlatformMap = createEntitiesGroupedBySelector(
  apiConnectionsSelectors.selectEntities,
  'platformId'
);
const selectEntitiesForPlatformId = createEntitiesForIdSelector(selectEntitiesByPlatformMap);

export const accesstokenConnectionSelectors = {
  ...apiConnectionsSelectors,
  selectByAccessToken,
  selectEntitiesByPlatformMap,
  selectEntitiesForPlatformId
};
