import { RefreshApiHistory, RefreshApiStatus } from '../models';

const baseExample: RefreshApiHistory = {
  id: 1,
  platformId: 2,
  clientId: 3,
  active: false,
  status: RefreshApiStatus.Refreshed,
  remarks: 'Sample remarks',
  startDatetime: '2021-04-08T08:12:00-04:00',
  endDatetime: '2021-04-08T09:12:00-04:00'
};

export const createExampleRefreshApiHistories = (list: Partial<RefreshApiHistory>[]): RefreshApiHistory[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i + 1 }));
