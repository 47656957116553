import compact from 'lodash-es/compact';
import concat from 'lodash-es/concat';
import difference from 'lodash-es/difference';
import every from 'lodash-es/every';
import extend from 'lodash-es/extend';
import filter from 'lodash-es/filter';
import flatMap from 'lodash-es/flatMap';
import groupBy from 'lodash-es/groupBy';
import intersection from 'lodash-es/intersection';
import isEmpty from 'lodash-es/isEmpty';
import isObject from 'lodash-es/isObject';
import isString from 'lodash-es/isString';
import map from 'lodash-es/map';
import negate from 'lodash-es/negate';
import omit from 'lodash-es/omit';
import reduce from 'lodash-es/reduce';
import some from 'lodash-es/some';
import sortBy from 'lodash-es/sortBy';
import trim from 'lodash-es/trim';
import union from 'lodash-es/union';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';
import uniqueId from 'lodash-es/uniqueId';
import { AuthzContext, AuthzResource } from './authz-context';
import { isBreezeEntity } from './lodash-extensions/is-breeze-entity';
import { isMissingOrWhitespace } from './lodash-extensions/is-missing-or-whitespace';
import { toCamelCase } from './lodash-extensions/to-camel-case';
import { UserPermission } from './user-permission';
import { WhitelistDirectionEnum } from './white-list-direction-enum';

//TODO: work out how to genericize this such that we don't need all the `any`s so we can re-enable this rule.
/* eslint-disable @typescript-eslint/no-explicit-any */
export class ClaimsAuthzCtxParserService {
  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=413&lineStyle=plain&lineEnd=414&lineStartColumn=1&lineEndColumn=1
   * @param authContext
   * @param userPermissions
   */
  parseAuthzContext(authContext: AuthzContext | AuthzContext[], userPermissions?: UserPermission[]) {
    const suppliedCxt = this.getSuppliedAuthzCtx(authContext);
    const currentUser = suppliedCxt.user || { rolePermissions: userPermissions };
    if (suppliedCxt.__isParsed) {
      return extend({}, suppliedCxt, { user: currentUser });
    }
    const authzCtx = {
      action: this.parseActions(suppliedCxt.action || suppliedCxt.a),
      resource: this.parseResources(suppliedCxt.resource || suppliedCxt.r),
      user: currentUser
    };
    this.setHiddenIsParsedProperty(authzCtx);
    return authzCtx;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=706&lineStyle=plain&lineEnd=707&lineStartColumn=1&lineEndColumn=1
   * @param authCtx
   */
  setHiddenIsParsedProperty(authCtx: AuthzContext) {
    Object.defineProperty(authCtx, '__isParsed', { value: true, enumerable: false });
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=314&lineStyle=plain&lineEnd=315&lineStartColumn=1&lineEndColumn=1
   * @param resource
   */
  assetResourceSupplied(resource: string | string[] | AuthzResource | AuthzResource[]) {
    if ((isString(resource) && isMissingOrWhitespace(resource)) || (Array.isArray(resource) && !resource.length)) {
      throw new Error('Resource required');
    }
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=577&lineStyle=plain&lineEnd=578&lineStartColumn=1&lineEndColumn=1
   * @param resource
   */
  isResourceInstanceDef(resource: any) {
    const expectedMemberNames = ['instance', 'prop'];
    const foundMemberNames = intersection(Object.keys(resource), expectedMemberNames);
    return resource && foundMemberNames.length === expectedMemberNames.length;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=320&lineStyle=plain&lineEnd=321&lineStartColumn=1&lineEndColumn=1
   * @param objs
   * @param predicate
   */
  assertSameTypes(objs: any, predicate: any) {
    const not = negate(predicate);

    if (some(objs, not)) {
      throw new Error('Mix of resource types not supported; either supply all objects or all strings');
    }
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=589&lineStyle=plain&lineEnd=590&lineStartColumn=1&lineEndColumn=1
   * @param resource
   */
  isConvertibleToResourceTypeDef(resource: any) {
    return isString(resource) || (Array.isArray(resource) && isString(resource[0]));
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=710&lineStyle=plain&lineEnd=711&lineStartColumn=1&lineEndColumn=1
   * @param items
   */
  trimCompactAndDedup(items: any) {
    return uniqBy(
      compact(
        map(items, item => {
          if (isString(item)) {
            return trim(item);
          } else if (isEmpty(item)) {
            return null;
          }
          return item;
        })
      ),
      JSON.stringify
    );
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=630&lineStyle=plain&lineEnd=631&lineStartColumn=1&lineEndColumn=1
   * @param resource
   */
  normalizedAndDedupResourceDefTypes(resource: AuthzResource) {
    const groupByType = groupBy(resource, 'type');
    return map(
      map(groupByType, (defs: any) => {
        const isEmptyPropertiesListFound = map(defs, 'prop').some(properties => !properties.length);
        if (isEmptyPropertiesListFound) {
          return [extend({}, omit(defs[0], 'prop'), { prop: [] })];
        } else {
          return defs;
        }
      }),
      (defs: any) =>
        extend({}, omit(defs[0], 'prop'), {
          prop: this.trimCompactAndDedup(flatMap(defs, 'prop'))
        })
    );
  }

  /**
   * Porting from  - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=544&lineStyle=plain&lineEnd=545&lineStartColumn=1&lineEndColumn=1
   * @param resourceNames
   */
  parseResourcePropertyNames(resourceNames: string[]) {
    const resourceInfos = uniq(
      map(resourceNames, name => {
        const nameParts = name.split('.');
        return { typeName: nameParts[0], propName: nameParts[1] };
      })
    );

    const propertyResourcesInfos = resourceInfos.filter(info => info.propName);
    const typeResourcesInfos = difference(resourceInfos, propertyResourcesInfos);
    const propertyResources = map(groupBy(propertyResourcesInfos, 'typeName'), (typeProps: any, typeName: string) => ({
      type: typeName,
      prop: this.trimCompactAndDedup(map(typeProps, 'propName'))
    }));
    const typeResources = map(typeResourcesInfos, info => ({ type: info.typeName, prop: [] }));
    return map(union(propertyResources, typeResources), resource => ({
      type: this.unescapeResourcePropertyNameSeparator(resource.type),
      prop: resource.prop ? map(resource.prop, this.unescapeResourcePropertyNameSeparator) : ''
    }));
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=721&lineStyle=plain&lineEnd=722&lineStartColumn=1&lineEndColumn=1
   * @param name
   */
  escapeResourcePropertyNameSeparator(name: string) {
    return name.replace(/\./g, '~~');
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=725&lineStyle=plain&lineEnd=726&lineStartColumn=1&lineEndColumn=1
   * @param name
   */
  unescapeResourcePropertyNameSeparator(name: string) {
    return name.replace(/~~/g, '.');
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=583&lineStyle=plain&lineEnd=584&lineStartColumn=1&lineEndColumn=1
   * @param resource
   */
  isResourceTypeDef(resource: AuthzResource) {
    const expectedMemberNames = ['type', 'prop'];
    const foundMemberNames = intersection(Object.keys(resource), expectedMemberNames);
    return resource && foundMemberNames.length === expectedMemberNames.length;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=391&lineStyle=plain&lineEnd=392&lineStartColumn=1&lineEndColumn=1
   * @param obj
   */
  getEntityTypeName(obj: any) {
    return toCamelCase(obj.entityAspect.getKey().entityType.shortName);
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=649&lineStyle=plain&lineEnd=650&lineStartColumn=1&lineEndColumn=1
   * @param resource
   */
  parseResources(resource: string[] | AuthzResource[]) {
    this.assetResourceSupplied(resource);

    if (Array.isArray(resource)) {
      resource = uniq(resource as string[]);
    } else if (isString(resource) || isBreezeEntity(resource) || this.isResourceInstanceDef(resource)) {
      resource = [resource];
    }

    if (this.isConvertibleToResourceTypeDef(resource)) {
      this.assertSameTypes(resource, isString);
      resource = this.trimCompactAndDedup(resource);
      this.assetResourceSupplied(resource);
      const parsedResource = this.parseResourcePropertyNames(resource as string[]);
      return this.normalizedAndDedupResourceDefTypes(parsedResource as any);
    }

    if (Array.isArray(resource) && this.isResourceTypeDef(resource[0] as AuthzResource)) {
      this.assertSameTypes(resource, this.isResourceTypeDef);
      resource = this.normalizedAndDedupResourceDefTypes(resource as any) as any;
      this.assetResourceSupplied(resource);

      return resource;
    }

    if (Array.isArray(resource) && isBreezeEntity(resource[0] as any)) {
      this.assertSameTypes(resource, isBreezeEntity);
      return reduce(
        groupBy(resource, breezeInstance => {
          return this.getEntityTypeName(breezeInstance);
        }),
        (result: any, breezeInstances: any, resourceTypeName: any) => {
          result[resourceTypeName] = [
            {
              instance: breezeInstances,
              prop: []
            }
          ];
          return result;
        },
        {}
      );
    }

    if (Array.isArray(resource) && this.isResourceInstanceDef(resource[0])) {
      this.assertSameTypes(resource, this.isResourceInstanceDef);
      const groupedResourceDefs = groupBy(resource, (def: any) =>
        this.getEntityTypeName([].concat(def.instance as any)[0])
      );
      return this.parseResourceInstanceDefsGroupedByType(groupedResourceDefs, isBreezeEntity);
    }

    if (isObject(resource)) {
      const keys = Object.keys(resource);
      if (!every(keys)) {
        throw new Error('Empty Resource key detected');
      }
      return this.parseResourceInstanceDefsGroupedByType(resource, isObject);
    }

    throw new Error('Cannot parse resource supplied');
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=593&lineStyle=plain&lineEnd=594&lineStartColumn=1&lineEndColumn=1
   * @param groupedResourceDefs
   * @param typeSelector
   */
  parseResourceInstanceDefsGroupedByType(groupedResourceDefs: any, typeSelector: any) {
    const result = groupedResourceDefs.reduce((parsed: any, defs: any, typeName: any) => {
      defs = [].concat(defs);

      if (!this.isResourceInstanceDef(defs[0])) {
        defs = [{ instance: defs, prop: [] }];
      }

      const defsWithSameRequestedProperties = map(
        groupBy(
          JSON.stringify(
            map(defs, (def: any) => {
              return {
                instance: [].concat(def.instance),
                prop: [].concat(def.prop)
              };
            })
          )
        ),
        (defList: any) => {
          return { instance: flatMap(defList, 'instance'), prop: defList[0].prop };
        }
      );

      parsed[typeName] = defsWithSameRequestedProperties;
      return parsed;
    }, {});

    const allInstances = flatMap(flatMap(result), 'instance');
    if (!Object.keys(result).length || !allInstances.length) {
      throw new Error('Resource required');
    }
    this.assertSameTypes(allInstances, typeSelector);

    return result;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=529&lineStyle=plain&lineEnd=530&lineStartColumn=1&lineEndColumn=1
   * @param action
   */
  parseActions(action: string | string[]) {
    action = concat([], action);
    if (!action.every(isString)) {
      throw new Error('Action names must all be strings');
    }

    action = this.trimCompactAndDedup(action);

    if (!action.length) {
      throw new Error('Action required');
    }

    return action;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=395&lineStyle=plain&lineEnd=396&lineStartColumn=1&lineEndColumn=1
   * @param args
   */
  getSuppliedAuthzCtx(args: any) {
    let result = args;
    if (Array.isArray(args) && args.length) {
      const [firstArg] = args;
      if (isObject(firstArg)) {
        result = args.reduce(
          (acc: any, arg: any) => ({
            action: uniq(acc['action'].concat(arg['action'] ? arg['action'] : arg['a'])),
            resource: acc['resource'].concat(arg['resource'] ? arg['resource'] : arg['r']),
            user: ''
          }),
          {
            action: [],
            resource: [],
            user: ''
          }
        );
      } else if (args.length === 2) {
        result = {
          action: args[0],
          resource: Array.isArray(args[1]) ? args[1] : [args[1]],
          user: '' // will add user info
        };
      }
    }
    return result;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=328&lineStyle=plain&lineEnd=329&lineStartColumn=1&lineEndColumn=1
   * @param context
   * @param whitelistData
   * @param whitelistDirection
   */
  excludeWhitlistedResources(context: any, whitelistData: any, whitelistDirection: any) {
    const parsedAuthzCtx = this.parseAuthzContext(context);

    if (!whitelistData || isEmpty(whitelistData.entities)) {
      return [parsedAuthzCtx];
    }

    let actionsToWhitelist: string[];
    if (whitelistDirection == null || whitelistDirection === WhitelistDirectionEnum.read) {
      actionsToWhitelist = ['View'];
    } else if (whitelistDirection === WhitelistDirectionEnum.write) {
      actionsToWhitelist = ['Create', 'Update', 'Delete'];
    } else {
      actionsToWhitelist = ['Create', 'View', 'Update', 'Delete'];
    }

    const flattenedResourceAndActions = concat(
      parsedAuthzCtx.resource,
      map(parsedAuthzCtx.resource, resource => {
        const resourceKey = uniqueId();
        return map(parsedAuthzCtx.action, action => ({ action: action, resource: resource, resourceKey: resourceKey }));
      })
    );
    const securedResourceAndActions = filter(flattenedResourceAndActions, x => {
      return !(whitelistData.entities.includes(x.resource.type) && actionsToWhitelist.includes(x.action));
    });

    const reassembledCtxs = map(
      groupBy(
        reduce(
          groupBy(securedResourceAndActions, 'resourceKey'),
          (results, entries, resourceKey) => {
            const actionKey = map(sortBy(entries, 'action'), 'action').join(',');
            results = { [resourceKey]: { resource: entries[0].resource, actionKey: actionKey } };
            return results;
          },
          {}
        ),
        'actionKey'
      ),
      (entries, actionKey) => {
        const reassmbedCtx = {
          resource: map(entries, 'resource'),
          action: actionKey.split(','),
          user: undefined
        };
        const ctx = extend({}, parsedAuthzCtx, reassmbedCtx);
        this.setHiddenIsParsedProperty(ctx);
        return ctx;
      }
    );

    return reassembledCtxs;
  }

  /**
   * Porting from - https://tfs.mrisoftware.net/tfs/MRI/PD/Enterprise%20Framework%20WIP/_git/Series5?path=%2Fsrc%2FRam.Series5.Spa%2Fapp%2Fplatform%2Fsecurity%2Fauthorization%2FclaimsAuthzService.js&version=GBcc%2Fsecurity-infrastructure&_a=contents&line=378&lineStyle=plain&lineEnd=379&lineStartColumn=1&lineEndColumn=1
   * @param authzCtxs
   * @param whitelistData
   * @param whitelistDirection
   */
  excludeWhitelistedFromAuthzCtxs(
    authzCtxs: AuthzContext | AuthzContext[],
    whitelistData: any,
    whitelistDirection: any
  ) {
    return reduce(
      authzCtxs,
      (result, contexts: any, ctxKey) => {
        contexts = concat([], contexts);
        result = {
          [ctxKey]: concat(
            contexts,
            map(contexts, ctx => this.excludeWhitlistedResources(ctx, whitelistData, whitelistDirection))
          )
        };
        return result;
      },
      {}
    );
  }
}
