import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService } from '@mri-platform/shared/entity';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { FileData } from '../models/file-data';

@Injectable({ providedIn: 'root' })
export class FileDataEntityService extends DefaultEntityService<FileData> {
  constructor(
    injector: Injector,
    private http: HttpClient,
    private apiConfig: DefaultDataServiceConfig
  ) {
    super(FileData.entityName, injector);
  }

  getByKeyAndWorksheet$(id: number, worksheetName?: string) {
    const params = worksheetName ? { worksheet: worksheetName } : undefined;
    const api = `${this.apiConfig.root}/filedata/${id}`;
    return this.http.get<FileData>(api, { params });
  }
}
