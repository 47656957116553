import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonProgressState } from '@mri-platform/ui-buttons';

@Component({
  selector: 'mri-master-detail-footer-toolbar',
  template: `
    <div class="mri-toolbar__group">
      <button
        *ngIf="showDelete"
        class="mri-button"
        [mriButtonProgress]="deleteProgressState"
        (resetProgress)="deleteResetProgress.emit(true)"
        [disabled]="!canDelete"
        (click)="delete.emit()"
      >
        <mri-btn-icon icon="trash-can"></mri-btn-icon>
        {{ deleteLabel }}
      </button>
      <button
        class="mri-button mri-button--primary"
        [mriButtonProgress]="saveProgressState"
        (resetProgress)="saveResetProgress.emit(true)"
        [disabled]="!canSave"
        [title]="saveTooltip"
        (click)="save.emit()"
      >
        <mri-btn-icon icon="save"></mri-btn-icon>
        Save
      </button>
      <button class="mri-button" [disabled]="!canCancel" (click)="cancel.emit()">
        <mri-btn-icon icon="x"></mri-btn-icon>Cancel
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterDetailFooterToolbarComponent {
  @Input() canCancel = false;
  @Input() canDelete = false;
  @Input() canSave = false;
  @Input() saveTooltip = '';
  @Input() deleteLabel = 'Delete';
  @Input() deleteProgressState?: ButtonProgressState;
  @Input() saveProgressState?: ButtonProgressState;
  @Input() showDelete = false;
  @Output() cancel = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() deleteResetProgress = new EventEmitter<true>();
  @Output() save = new EventEmitter<void>();
  @Output() saveResetProgress = new EventEmitter<true>();
  @HostBinding('class.mri-toolbar')
  @HostBinding('class.mri-master-detail__footer-toolbar')
  hostClass = true;
}
