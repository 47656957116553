import { QueryParams } from '@ngrx/data';
import type { IdSelector } from '@ngrx/entity';
import { identity } from 'rxjs';

export type EntityIdType = string | number;

export type EntityIdParser = ((value: string) => string) | ((value: string) => number);

export const entityNumberIdParser = (value: string) => +value;
export const entityStringIdParser = (value: string) => value;

export const NEW_ENTITY_ID = -1;

export const isEntityNewId = (id: EntityIdType) => Number(id) < 0;

export function isEntityNew<T>(entity: T, selectId: IdSelector<T>): boolean {
  return (entity as never)['isNew'] === true || isEntityNewId(selectId(entity));
}

export type SerializableValues = string | number | boolean | string[] | undefined;
export type StringKeyedSerializablePartial<T> = Partial<T> & Record<string, SerializableValues>;
export const partialAsSerializable = <T>(partial: Partial<T>) => partial as StringKeyedSerializablePartial<T>;

/**
 * Converts an object by serializing each field value into a string
 * note: this implementation is naive. It assumes the `params` field values
 * can be converted to a suitable value by calling their `toString` method
 * @param params an object whose values are to be converted into strings
 * @param stringSelector
 */
export function toQueryParams<T = never>(
  params: QueryParams | StringKeyedSerializablePartial<T> | string,
  stringSelector: (value: string, key: string) => string = identity
): string | QueryParams {
  if (typeof params === 'string') {
    return stringSelector(params, '');
  }
  return Object.entries(params).reduce((result, [key, value]) => {
    const v = (value ?? '').toString();
    return Object.assign(result, { [key]: stringSelector(v, key) });
  }, {} as QueryParams);
}
