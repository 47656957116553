import { Provider } from '@angular/core';
import { SwitchClientFunc, SWITCH_CLIENT_FUNC } from '@mri-platform/shared/core';
import { EntityCacheDispatcher } from '@ngrx/data';

export function clearEntityCache(entityCacheDispatcher: EntityCacheDispatcher): SwitchClientFunc {
  return () => {
    entityCacheDispatcher.clearCollections();
  };
}

export const ClearEntityCacheProvider: Provider = {
  provide: SWITCH_CLIENT_FUNC,
  useFactory: clearEntityCache,
  multi: true,
  deps: [EntityCacheDispatcher]
};
