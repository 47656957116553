import { ViewStateEntry } from '@mri-platform/shared/core';
import { createReducer, on } from '@ngrx/store';
import { ViewStateActions } from '../actions';

export const viewStateFeatureKey = 'state';

export type State = ViewStateEntry[];

export const initialState: State = [];

export const reducer = createReducer(
  initialState,

  on(ViewStateActions.set, (state, { entry }) => {
    const index = state.findIndex(v => v.componentName === entry.componentName && v.key === entry.key);

    if (index !== -1) {
      const newState = [...state];
      const obj = { ...newState[index], value: entry.value };
      newState[index] = obj;
      return newState;
    }

    return [...state, entry];
  }),

  on(ViewStateActions.clean, (state, { componentName, key }) =>
    state.filter(k => k.componentName !== componentName && k.key !== key)
  )
);
