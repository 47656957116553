import { FileInfo } from '@progress/kendo-angular-upload';
import { Platform } from '../models/platform';
import { JobConfiguration } from './export-jobs-form';

export enum SourceType {
  CONNECTION = 'Api',
  FILE = 'File'
}

export enum FileType {
  Csv = 'csv',
  Json = 'json',
  Xml = 'xml',
  Xls = 'xls',
  Xlsx = 'xlsx',
  Txt = 'txt'
}
export interface FileSelectorFormData {
  id: number;
  name?: string;
  metaData?: FileMetaData;
  fileUploaded?: FileInfo[];
  fileSelected: boolean;
  sheetName?: string;
}

export interface ConnectionSelectorFormData {
  selectConnection?: string;
}

export interface SelectionType {
  selection: SourceType;
}

export type ConnectionFileMapper = FileSelectorFormData & ConnectionSelectorFormData;

export interface File {
  id: number;
  name: string;
  metaData: FileMetaData;
}

export interface FileMetaData {
  contentType: string;
  fileColumns: FileColumn[];
  headerIndices: number[];
  maxNonEmptyHeaderColumnIndex: number;
  worksheets?: string[];
}
export interface FileColumn {
  columnName: string;
  dataType: string;
  id: number;
  rowNumber?: number;
  columnNumber?: number;
}

export interface UserSelection {
  titleRowNumber?: number;
  titleColumns?: string[];
  headerIndices?: number[];
  worksheetName?: string;
}
export interface PlatformState {
  platforms: Platform[];
}

export interface PlatformFormState {
  platformId: string;
}

export type ConnectionConfig = PlatformFormState & JobConfiguration;

export interface FileTypeModel {
  fileType: FileType;
}
