import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AccessToken,
  ConnectionConfig,
  ConnectionMode,
  DataSourceEntityService,
  JobConfiguration,
  Platform,
  PlatformFormState,
  PlatformState
} from '@mri-platform/import-export/common-state';
import { createFormObservables, FormControls, FormModel, FormState } from '@mri-platform/shared/common-ui';
import { isNotNullOrUndefined } from '@mri-platform/shared/core';
import { RxState } from '@rx-angular/state';
import { asapScheduler, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, observeOn, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';

const initialState: FormModel<PlatformFormState> = {
  platformId: ['', Validators.required]
};

interface ViewModel {
  accesstokens: AccessToken[];
  platformId: string;
  mode: ConnectionMode;
  isLoading: boolean;
  clientId: number;
}

type ComponentState = ViewModel & PlatformState & FormState<ConnectionConfig>;
type PlatformViewModel = Pick<ComponentState, keyof ViewModel | keyof PlatformState | 'model'>;

@Component({
  selector: 'mri-ie-connection-configuration',
  templateUrl: './connection-configuration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ConnectionConfigurationComponent {
  @Input() set platforms(value: Platform[]) {
    this.state.set({ platforms: value ?? [] });
  }

  get model() {
    return this.state.get('model');
  }
  @Input() set model(value: ConnectionConfig) {
    if (!value) {
      return;
    }

    const { platformId } = value;
    this.state.set({ model: value });
    this.form.patchValue({ platformId });
    if (value.platformId) {
      this.form.controls['platformId'].markAsDirty();
    }
  }

  @Input() set mode(mode: ConnectionMode) {
    this.state.set({ mode });
  }

  get clientId() {
    return this.state.get('clientId');
  }
  @Input() set clientId(clientId: number) {
    if (!clientId) {
      return;
    }
    this.state.set({ clientId });
  }
  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');
  @Output() isLoading = this.state.select('isLoading');

  accessTokenFormState$ = new Subject<JobConfiguration>();
  accessTokenFormValid$ = new Subject<boolean>();
  accessTokenFormDirty$ = new Subject<boolean>();

  vm$: Observable<PlatformViewModel>;
  form = this.createForm();
  constructor(
    private fb: FormBuilder,
    private state: RxState<ComponentState>,
    private dataSourceEntityService: DataSourceEntityService
  ) {
    const { dirtyChanges$, validChanges$, valueChanges$ } = createFormObservables(this.form, {
      tagPrefix: ConnectionConfigurationComponent.name
    });

    const platformChanges$ = valueChanges$.pipe(shareReplay({ refCount: true, bufferSize: 1 }));

    this.state.connect('model', platformChanges$, ({ model }, update) => ({ ...model, ...update }));
    this.state.connect(
      'model',
      platformChanges$.pipe(
        withLatestFrom(this.state.select('model')),
        filter(([platformChanges, model]) => platformChanges.platformId !== model.platformId),
        map(([platformChanges]) => platformChanges)
      ),
      ({ model }, update) => ({ ...model, ...update, ...Object.assign({ context: undefined, connection: undefined }) })
    );
    this.state.connect('model', this.accessTokenFormState$, ({ model }, update) => ({ ...model, ...update }));

    const formDirtyChanges$ = combineLatest([dirtyChanges$, this.accessTokenFormDirty$]).pipe(
      map(([platformDirtyState, accessTokenDirtyState]) => platformDirtyState || accessTokenDirtyState)
    );
    this.state.connect('isDirty', formDirtyChanges$);
    const formValidChanges$ = combineLatest([validChanges$, this.accessTokenFormValid$]).pipe(
      map(([platformValidState, accessTokenValidState]) => platformValidState && accessTokenValidState)
    );
    this.state.connect('isValid', formValidChanges$);
    this.state.connect(
      'accesstokens',
      platformChanges$.pipe(
        map(({ platformId }) => platformId),
        isNotNullOrUndefined(),
        switchMap(platformId =>
          this.dataSourceEntityService.getDataSourceByPlatform$(platformId).pipe(map(d => d as AccessToken[]))
        )
      )
    );
    this.state.connect('platformId', this.controls.platformId.valueChanges);
    this.state.connect('isLoading', this.dataSourceEntityService.loading$);
    this.vm$ = this.state.select();
  }

  setLoading(loading: boolean) {
    this.state.set({ isLoading: loading });
  }

  private createForm() {
    return this.fb.group(initialState);
  }

  get controls(): FormControls<PlatformFormState> {
    return this.form.controls as FormControls<PlatformFormState>;
  }

  onUserInputChange() {
    let model = this.state.get('model');
    model = { ...model, ...Object.assign({ context: undefined }) };
    this.state.set({ model });
  }
}
