import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'mri-datalist-footer',
  template: `
    <div class="mri-toolbar mri-datalist__footer-toolbar">
      <div class="mri-toolbar__group">
        <ng-container [ngTemplateOutlet]="addBtnTemplate || defaultAddBtnTemplate" *ngIf="showAdd"></ng-container>
        <ng-template #defaultAddBtnTemplate>
          <button [disabled]="!canAdd" mriDatalistAddIconBtn (click)="addItemToList.emit()"></button>
        </ng-template>
        <ng-container
          [ngTemplateOutlet]="removeBtnTemplate || defaultRemoveBtnTemplate"
          *ngIf="showRemove"
        ></ng-container>
        <ng-template #defaultRemoveBtnTemplate>
          <button
            [disabled]="!canRemove"
            mriDatalistRemoveIconBtn
            (click)="removeItemFromList.emit(selectedItem)"
          ></button>
        </ng-template>
      </div>
      <div class="mri-toolbar__group" *ngIf="actionsBtnTemplate && showActions">
        <ng-container [ngTemplateOutlet]="actionsBtnTemplate"></ng-container>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatalistFooterComponent {
  @Input() selectedItem?: unknown;
  @Input() canRemove = true;
  @Input() canHaveActions = true;
  @Input() canAdd = true;
  @Input() showRemove = true;
  @Input() showAdd = true;
  @Input() showActions = true;
  @Output() addItemToList = new EventEmitter();
  @Output() removeItemFromList = new EventEmitter<unknown>();
  @Output() actions = new EventEmitter();

  @Input() actionsBtnTemplate?: TemplateRef<never>;
  addBtnTemplate?: TemplateRef<never>;
  removeBtnTemplate?: TemplateRef<never>;

  @HostBinding('class') readonly hostClass = 'mri-datalist__footer';
  @HostBinding('attr.role') readonly ariaRole = 'contentinfo';
  @HostBinding('attr.aria-label') readonly ariaLabel = 'Data list toolbar';
}
