import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { UiButtonsModule } from '@mri-platform/ui-buttons';
import { UiIconModule } from '@mri-platform/ui-icon';
import { ElementsCommonModule } from '@mri/ng-elements-common';
import { CopywriteComponent } from './components/copywrite/copywrite.component';
import { DrawerFooterToolbarComponent } from './components/drawer-footer-toolbar/drawer-footer-toolbar.component';
import { DrawerFooterToolbarDirective } from './components/drawer-footer-toolbar/drawer-footer-toolbar.directive';
import { DrawerComponent } from './components/drawer/drawer.component';
import { DrawerBodyDirective, DrawerFooterDirective } from './components/drawer/drawer.directive';
import { LoadingComponent } from './components/loading/loading.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageMessageBoxComponent } from './components/page-message-box/page-message-box.component';
import { PrefixUrlProtocolPipe } from './pipes/prefix-url-protcol.pipe';

const PUBLIC_DECLARATIONS = [
  CopywriteComponent,
  DrawerComponent,
  DrawerFooterToolbarComponent,
  DrawerFooterToolbarDirective,
  DrawerFooterDirective,
  DrawerBodyDirective,
  LoadingComponent,
  PageHeaderComponent,
  PageMessageBoxComponent,
  PrefixUrlProtocolPipe
];

@NgModule({
  imports: [CommonModule, UiIconModule, ElementsCommonModule, UiButtonsModule],
  declarations: PUBLIC_DECLARATIONS,
  exports: PUBLIC_DECLARATIONS,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreUiModule {}
