import { Observable } from 'rxjs';

export interface ViewStateEntry {
  /**
   * name of component whose view state is being persisted
   */
  componentName: string;
  /**
   * optional key name for the entry. This must be supplied where `componentName`
   * is not unique (for example multiple instances of the same component can store view state)
   */
  key?: string;
  /**
   * the view state to be persisted. Note: this must be serializable (eg no `Date` fields)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export type ViewStateKey = Pick<ViewStateEntry, 'componentName' | 'key'>;

export abstract class ViewStateService {
  abstract readonly states$: Observable<ViewStateEntry[]>;

  abstract stateByKey$<T>(key: ViewStateKey): Observable<T | undefined>;

  abstract setState(entry: ViewStateEntry): void;

  abstract removeState<T extends ViewStateKey>(key: T): void;
}
