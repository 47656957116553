import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { UserEnrollmentCallBackResponse } from '../models';
import { UserEnrollmentService } from './user-enrollment.service';

@Injectable({
  providedIn: 'root'
})
export class UserEnrollmentCallbackGuard implements CanActivate {
  constructor(
    private userEnrollService: UserEnrollmentService,
    private router: Router,
    public errorPolicy: ErrorPolicyService
  ) {}
  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    const params = _next.queryParams as UserEnrollmentCallBackResponse;
    const errorOptions = {
      // avoid kendo notification error
      notify: false
    };
    return this.userEnrollService.getCallBack(params).pipe(
      this.errorPolicy.catchHandle(() => errorOptions),
      map(userCallBack => {
        this.router.navigate(['/']);
        return userCallBack;
      }),
      mapTo(false)
    );
  }
}
