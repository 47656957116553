import xor from 'lodash-es/xor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TreeNodeFilterOptions<T = any> {
  removeEmpty: boolean;
  itemsField: keyof T;
}

/**
 * Recursively filter the nodes in a tree starting from the root `node` supplied
 * @param node the node to recursively filter
 * @param predicate test whether node should be included/excluded from the result
 * @param options settings that control the filtering behaviour
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function treeNodeFilter<T extends { [key: string]: any }, C = T, P = T | C>(
  node: T,
  predicate: (node: P) => boolean,
  { removeEmpty = false, itemsField: childrenField = 'items' }: Partial<TreeNodeFilterOptions> = {}
): T | null {
  // given up trying to make this typesafe (and I have tried quite hard!)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (!predicate(node as unknown as any)) {
    return null;
  }

  if (node === undefined || node === null || !Object.getOwnPropertyDescriptor(node, childrenField)) {
    return node;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items: any[] = node[childrenField as string];
  const matchedChildren = items.map(item => treeNodeFilter(item, predicate)).filter(item => item != null);

  if (removeEmpty && matchedChildren.length === 0 && items.length > 0) {
    return null;
  } else if (matchedChildren.length !== items.length || xor(matchedChildren, items).length > 0) {
    return {
      ...node,
      [childrenField]: matchedChildren
    };
  } else {
    return node;
  }
}
