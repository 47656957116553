import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContext, AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';
import { NEW_ENTITY_ID } from '@mri-platform/shared/entity';

export enum PlatformType {
  PropertyManagement = 'PropertyManagement',
  ArgusCloud = 'ArgusCloud'
}

export interface Platform {
  id: number;
  name: string;
  type?: string;
  identifier: string;
  oAuth2ClientId: string;
  oAuth2Secret: string;
  uri: string;
  tokenProviderUrl: string;
  isNew?: boolean;
  isEnrolled: boolean;
  concurrencyLimit: number;
  isBatchingSupported: boolean;
  username: string;
  hasCertificate?: boolean;
  hasOAuth2credentials?: boolean;
  certificatePassword?: string;
  certificateFile: File[];
  clientId: number;
  clientLicenseId?: string;
  environmentId: string;
}

export function createPlatform(): Platform {
  return {
    id: NEW_ENTITY_ID,
    name: '',
    type: undefined,
    identifier: '',
    oAuth2ClientId: '',
    oAuth2Secret: '',
    uri: '',
    tokenProviderUrl: '',
    isNew: true,
    isEnrolled: false,
    concurrencyLimit: 20,
    isBatchingSupported: false,
    username: '',
    hasCertificate: false,
    certificatePassword: '',
    certificateFile: [],
    clientId: -1,
    clientLicenseId: '',
    environmentId: ''
  };
}

export enum PlatformCustomAction {
  PMPlatformOperations = 'PMPlatformOperations',
  ViewPage = 'ViewPage'
}

export type PlatformAction = PlatformCustomAction | CrudAction;

export const PlatformAction = { ...PlatformCustomAction, ...CrudAction };

export function canDeletePlatform({ id }: Platform) {
  return +id !== -1;
}

export const Platform = createModelMetadata<Platform>({
  entityName: EntityName.Platform,
  authorization: {
    ...AuthzContextsActionMap.crudFor(EntityName.Platform),
    [CrudAction.Delete]: AuthzContext.deleteFor(EntityName.Platform, EntityName.DataSource),
    [PlatformAction.ViewPage]: {
      action: [PlatformAction.ViewPage],
      resource: EntityName.Platform
    },
    [PlatformAction.PMPlatformOperations]: {
      action: [PlatformAction.PMPlatformOperations],
      resource: EntityName.Platform
    }
  },
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});
