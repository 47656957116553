/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpContextToken, HttpRequest } from '@angular/common/http';

/**
 Add to the context of a httpclient request to indicate that any non-success responses returned by the server
 should be enriched by transforming that response into a `DataServiceError`
 @example
 this.http.get<YourClass>(`your-url`, { context: new HttpContext().set(ENRICH_HTTP_ERROR, true) })
 **/
export const ENRICH_HTTP_ERROR = new HttpContextToken(() => false);

export interface EnrichedHttpErrorResponse {
  error: any;
  request: HttpRequest<any>;
}
export class EnrichedHttpErrorResponse {
  constructor(data: EnrichedHttpErrorResponse) {
    Object.assign(this, data);
  }
}
