import { Injectable } from '@angular/core';
import { ViewStateEntry, ViewStateKey, ViewStateService } from '@mri-platform/shared/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ViewStateActions } from './../actions';
import * as fromViewState from './../reducers';
import { selectStateByKey, selectStates } from './../selectors';

@Injectable()
export class DefaultViewStateService implements ViewStateService {
  constructor(private store: Store<fromViewState.ViewState>) {}

  readonly states$ = this.store.select(selectStates);

  stateByKey$<T>(key: ViewStateKey): Observable<T | undefined> {
    return this.store.select(selectStateByKey(key));
  }

  setState(entry: ViewStateEntry) {
    this.store.dispatch(ViewStateActions.set({ entry }));
  }

  removeState<T extends ViewStateKey>(key: T) {
    this.store.dispatch(ViewStateActions.clean(key));
  }
}
