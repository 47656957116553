import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAttributes, UserPermissionService } from '@mri/angular-wfe-proxy-oidc';
import { UserPermission } from '@mri-platform/resource-authz';
import { ApiConfig } from '@mri-platform/shared/core';
import { retryBackoff } from 'backoff-rxjs';
import { Observable, of } from 'rxjs';

const isHttpErrorResponse = (error: unknown): error is HttpErrorResponse => error instanceof HttpErrorResponse;
const isNotFound = (error: HttpErrorResponse): boolean => error.status === 404;
//If the status code is a 404, there is very little chance of it coming back, so bail out early.
const shouldRetry = (error: unknown) => (isHttpErrorResponse(error) && !isNotFound(error)) || true;

@Injectable()
export class AppUserPermissionService implements UserPermissionService {
  permissions$?: Observable<UserPermission[]>;

  constructor(private http: HttpClient, private apiConfig: ApiConfig) {}

  get(user: UserAttributes): Observable<UserPermission[]> {
    if (!user.roleIds?.length) return of([]);

    return this.http
      .get<UserPermission[]>(`${this.apiConfig.url}/userpermissions`, {
        params: {
          roleIds: user.roleIds.map(id => id.toString())
        }
      })
      .pipe(retryBackoff({ initialInterval: 1000, maxRetries: 3, shouldRetry }));
  }
}
