import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mri-toolbar, [mriToolbar]'
})
export class ToolbarDirective {
  @HostBinding('class.mri-toolbar') readonly hostClass = true;

  @HostBinding('class.mri-toolbar--align-end')
  @Input()
  alignEnd = false;

  @HostBinding('class.mri-toolbar--align-start')
  @Input()
  alignStart = false;
}
