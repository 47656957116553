import { EntityName } from '@mri-platform/import-export/core';

export const AuditLogEntityList = [
  EntityName.ApiConnection,
  EntityName.Client,
  EntityName.DataSource,
  EntityName.Platform,
  EntityName.RefreshApiHistory,
  EntityName.User,
  EntityName.UserConnection
];
