import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonsModule } from '@mri-platform/ui-buttons';
import { UiIconModule } from '@mri-platform/ui-icon';
import { MasterDetailDetailPaneDirective } from './master-detail-detail-pane.directive';
import { MasterDetailFooterToolbarComponent } from './master-detail-footer-toolbar';
import { MasterDetailHeaderIconDirective } from './master-detail-header-icon.directive';
import { MasterDetailHeaderTitleComponent } from './master-detail-header-title.component';
import { MasterDetailHeaderToolbarComponent } from './master-detail-header-toolbar';
import { MasterDetailHeaderToolbarDirective } from './master-detail-header-toolbar.directive';
import { MasterDetailHeaderComponent } from './master-detail-header.component';
import { MasterDetailMasterPaneDirective } from './master-detail-master-pane.directive';
import { MasterDetailSectionComponent } from './master-detail-section.component';
import { MasterDetailSectionDirective } from './master-detail-section.directive';
import { MasterDetailDirective } from './master-detail.directive';

const COMPONENTS = [
  MasterDetailHeaderIconDirective,
  MasterDetailDetailPaneDirective,
  MasterDetailFooterToolbarComponent,
  MasterDetailHeaderTitleComponent,
  MasterDetailHeaderComponent,
  MasterDetailSectionDirective,
  MasterDetailSectionComponent,
  MasterDetailMasterPaneDirective,
  MasterDetailDirective,
  MasterDetailHeaderToolbarComponent,
  MasterDetailHeaderToolbarDirective
];

@NgModule({
  imports: [CommonModule, UiIconModule, UiButtonsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class UiMasterDetailModule {}
