import { Directive, HostBinding, Input } from '@angular/core';
import type { SvgIconName } from '@mri/svg-icons/dist/ts/mri-icons';

export type IconSize = 'x-small' | 'small' | 'medium' | 'large';
export type IconDirection = 'left' | 'right' | 'none';

@Directive()
export abstract class IconBaseComponent {
  @HostBinding('class.mri-icon') readonly hostClass = true;
  @HostBinding('class.mri-icon--x-small') get xsmall() {
    return this.size === 'x-small';
  }
  @HostBinding('class.mri-icon--small') get small() {
    return this.size === 'small';
  }
  @HostBinding('class.mri-icon--large') get large() {
    return this.size === 'large';
  }

  @Input() direction: IconDirection = 'none';
  @Input() size: IconSize = 'medium';

  url = '';

  @Input() set icon(value: SvgIconName) {
    // todo: extract base url and make it configurable EG: IconModule.forRoot(iconBaseUrl)
    this.url = `assets/@mri/svg-icons/mri-icon-sprite.svg#${value}`;
  }
}
