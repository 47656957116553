import { JobLog, JobStatus, JobType } from '../models';

const baseExample: JobLog = {
  id: 1,
  platformId: 2,
  name: 'Demo Data Name',
  runTime: '10/11/2016 10:00:00 PM',
  type: JobType.Import,
  identifier: 'Sample Context',
  connection: 'Sample Connection',
  destination: 'Sample platform destination',
  status: JobStatus.Initiated,
  uri: 'www.test.com',
  context: 'file1',
  exportFileId: 2,
  scheduleFrequency: 'NEVER',
  runImmediately: true,
  accessTokenId: 1,
  totalRecords: 1000,
  mapperType: 'MapperName',
  mapperName: 'Test1',
  createdByFirstName: 'Impexp',
  createdByLastName: 'Tester01',
  username: 'ImpExp.Tester01@mrisoftware.com',
  dataSourceName: 'RMX6A_Admin',
  licenseId: 'MRIQWEB'
};

export const createJobLogs = (list: Partial<JobLog>[]): JobLog[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i }));
