import { ChangeDetectionStrategy, Component, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import {
  AccessToken,
  ApiConnection,
  ApiType,
  FileService,
  JobsConnectionData,
  MetaData,
  MetaDataEntityService,
  Platform,
  PlatformEntityService
} from '@mri-platform/import-export/common-state';
import { FormModel, FormState, connectForm } from '@mri-platform/shared/common-ui';
import { EntityIdType, QueryDef } from '@mri-platform/shared/entity';
import { RxState } from '@rx-angular/state';
import { groupBy } from 'lodash-es';
import { Observable, asapScheduler, combineLatest } from 'rxjs';
import { distinctUntilKeyChanged, filter, map, observeOn, switchMap, tap } from 'rxjs/operators';

const initialState: FormModel<JobsConnectionData> = {
  parent: ['', Validators.required],
  connection: [null, Validators.required]
};

interface ConnectionUpdate {
  connection: ApiConnection;
}

interface ConnectionState extends FormState<JobsConnectionData> {
  context: AccessToken;
  connectionUpdate: ConnectionUpdate;
  isLoading: boolean;
}

interface ApiConnectionSegment {
  [key: string]: ApiConnection[];
}

@Component({
  selector: 'mri-ie-connection',
  templateUrl: './connection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ConnectionComponent implements OnChanges {
  @Input() enabled = false;
  //Todo: Refactor the name to adapt the kendo dropdown in future
  @Input() loading = false;
  _list: ApiConnection[] = [];
  _model?: JobsConnectionData;
  @Input() set list(list: ApiConnection[]) {
    this._list = list;
  }
  get list() {
    return this._list;
  }

  @Input() set model(value: JobsConnectionData) {
    if (value && value.connection && value.connection.id) {
      if (!value.connection.metaData) {
        this._model = value;
      }
      // For enabled next button again when we back to previous tab
      this.state.set({ connectionUpdate: value, isDirty: true, isValid: true });
    }
  }

  @Input() set context(value: AccessToken) {
    if (this.state.get('context')?.id === value?.id) {
      return;
    }
    //We will have to think of a better approach in future for working around _model
    if (!this._model) {
      this.form.reset(undefined, { emitEvent: false });
    }
    this.state.set({ context: value });
  }

  @Input() showDownloadBtn = false;
  @Input() labelText = '';
  @Input() displayParent = true;

  // make sure to emit dirtyChange and validChanges on the next turn of the event loop
  // (required when switching between instances to cause change detection to "see" our changes)
  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('connectionUpdate');
  @Output() isLoading = this.state.select('isLoading');

  apiPrefixes: string[] = [];
  apiConnectionSegments!: ApiConnectionSegment;
  apiConnectionList: ApiConnection[] = [];
  form = this.createForm();
  showDownLoadButton = false;

  constructor(
    private fb: FormBuilder,
    private state: RxState<ConnectionState>,
    private metaDataEntityService: MetaDataEntityService,
    private platformEntityService: PlatformEntityService,
    private errorPolicy: ErrorPolicyService,
    private fileService: FileService
  ) {
    connectForm(this.form, this.state, { tagPrefix: ConnectionComponent.name });
    /**
     * using model hits dual call so moved separate property for update connection
     * data
     */
    const connectionData$ = this.form.valueChanges.pipe(
      distinctUntilKeyChanged('connection'),
      filter(formData => formData && formData.connection && formData.connection.id),
      tap(() => this.state.set({ isLoading: true })),
      switchMap(formData =>
        combineLatest([
          this.getMetaDataByConnectionId$(formData.connection.id),
          this.getPlatformById$(formData.connection.platformId)
        ]).pipe(
          map(([metaData, platform]) => {
            const [selectedConnection] = this.list.filter(c => c.id === formData.connection.id);
            if (!!platform && !!metaData) {
              this.showDownLoadButton = metaData.apiType === ApiType.Standard;
              return {
                connection: {
                  ...formData.connection,
                  metaData,
                  platform,
                  displayName: selectedConnection.displayName
                }
              };
            } else if (metaData) {
              this.showDownLoadButton = metaData.apiType === ApiType.Standard;
              // Initially platform summaries only loaded not platform for jobs page so  need this
              return {
                connection: {
                  ...formData.connection,
                  metaData,
                  displayName: selectedConnection.displayName
                }
              };
            }
            return formData;
          })
        )
      ),
      tap(() => this.state.set({ isLoading: false }))
    );
    this.state.connect('connectionUpdate', connectionData$, (state, connectionData) => {
      return { ...state.connectionUpdate, ...connectionData };
    });
    this.form.controls['parent'].valueChanges.subscribe(p => {
      this.form.controls['connection'].reset();
      this.apiConnectionList = this.apiConnectionSegments[p];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model?.currentValue?.connection && changes.model.currentValue?.connection?.id) {
      const { connection } = changes.model.currentValue ?? {};
      this.updateParentandChildren(connection);
    }
    if (changes.list?.currentValue) {
      if (changes.list?.currentValue.length > 0) {
        this.updateList(changes.list.currentValue);
        if (this._model) {
          this.form.controls['connection'].updateValueAndValidity({ emitEvent: true });
          this._model = undefined;
        }
      } else if (!changes.list.isFirstChange()) {
        this.apiConnectionSegments = {};
        this.apiPrefixes = [];
        this.form.controls['parent'].reset();
      }
    }
  }

  private updateParentandChildren(connection: ApiConnection) {
    const { prefix, segmentName } = this.getPrefixAndSegment(connection.displayName);
    this.form.patchValue(
      {
        connection: { ...connection, displayName: segmentName }
      },
      { emitEvent: false }
    );
    this.form.controls['connection'].markAsDirty();
    const parent = connection.displayName ? prefix : '';
    if (parent) {
      this.form.controls['parent'].patchValue(parent, { emitEvent: false });
      this.form.controls['parent'].markAsDirty();
    }
  }

  private updateList(list: ApiConnection[]) {
    // pre-selects the option when there is only one.
    if (list.length === 1) {
      if (this.displayParent) {
        const { prefix, segmentName } = this.getPrefixAndSegment(list[0]?.displayName);
        this.form.patchValue({ parent: prefix }, { emitEvent: false });
        this.form.patchValue({
          connection: {
            ...list[0],
            displayName: segmentName
          }
        });
      } else {
        this.form.patchValue({
          connection: list[0]
        });
      }
    }
    if (this.displayParent) {
      this.setApiPrefixandApiConnectionSegment(list);
      this.apiConnectionList =
        this.apiConnectionSegments && this.form.controls['parent'].value
          ? this.apiConnectionSegments[this.form.controls['parent'].value]
          : [];
    } else {
      this.apiConnectionList = list;
    }
  }

  private createForm() {
    const form = this.fb.group(initialState);
    if (!this.displayParent) {
      this.form.controls['parent'].disable();
    }
    return form;
  }

  private setApiPrefixandApiConnectionSegment(list: ApiConnection[]) {
    const apiPrefixesAndConnectionSegments = list.map(l => {
      const { prefix, segmentName } = this.getPrefixAndSegment(l.displayName);
      return {
        parent: prefix,
        ...l,
        displayName: segmentName
      };
    });
    this.apiConnectionSegments = groupBy(apiPrefixesAndConnectionSegments, l => l.parent);
    this.apiPrefixes = Object.keys(this.apiConnectionSegments);
  }

  private getPrefixAndSegment(displayName: string) {
    const filteredNames = this.getSplitedDisplayName(displayName);
    return {
      prefix: this.getApiPrefixName(filteredNames),
      segmentName: this.getApiConnectionSegmentName(filteredNames)
    };
  }

  private getSplitedDisplayName = (displayName: string) => displayName.split('/').filter(d => d);

  private getApiPrefixName = (filteredNames: string[]) => filteredNames[0];

  private getApiConnectionSegmentName(filteredNames: string[]) {
    if (filteredNames.length > 1) {
      const [, ...rest] = filteredNames;
      return rest.join('/');
    }
    return filteredNames[0];
  }

  getMetaDataByConnectionId$(id: number): Observable<MetaData | undefined> {
    return this.metaDataEntityService.setWithQuery(QueryDef.getByKey(id)).pipe(
      this.errorPolicy.catchHandleReplace([]),
      map(entities => entities[0])
    );
  }

  getPlatformById$(id: EntityIdType): Observable<Platform | undefined> {
    return this.platformEntityService.entityById$(id);
  }

  downloadBtnClicked(value: ApiConnection) {
    const params = { apiConnectionId: value.id };
    this.fileService.downloadConnectionFields(value.name, params);
  }
}
