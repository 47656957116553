import {
  createEntitiesGroupedBySelector,
  createEntityListForIdSelector,
  createSelectors
} from '@mri-platform/shared/entity';
import { DataSourceClient } from '../../models';

const selectors = createSelectors(DataSourceClient);
const selectEntitiesByDataSourceMap = createEntitiesGroupedBySelector(selectors.selectEntities, 'dataSourceId');
const selectEntityListForDataSourceId = createEntityListForIdSelector(
  selectEntitiesByDataSourceMap,
  selectors.selectId
);

export const dataSourceClientSelectors = {
  ...selectors,
  selectEntitiesByDataSourceMap,
  selectEntityListForDataSourceId
};
