/* eslint-disable @typescript-eslint/no-explicit-any */
// NOTE: angular `ErrorHandler` uses `any` in it's public api, therefore we're following the same pattern here

import { generateUUId } from './generate-uuid';
import { ProblemDetail } from './problem-detail';

export type SanitizerFunc = (err: any) => SanitizedError | undefined;

const mandatoryPropNames: Array<keyof SanitizedError> = ['message', 'ngOriginalError'];

export type ErrorVersionInfo = { [key: string]: string };

export interface SanitizedErrorData {
  /**
   * Not all exceptions are errors. Set this to true to hint that this should not
   * be published / logged as an error
   */
  isError?: boolean;
  /**
   * Set to true when this error has already been logged. Used to hint that this
   * error should not again be logged
   */
  isLogged?: boolean;
  /**
   * Hint as to whether this error should be shown to the user
   */
  isSilent?: boolean;
  /**
   * The error message that should be suitable to be shown to the user
   */
  message: string;
  /**
   * The original unsanitized error
   *
   * note: this name is important - do not rename
   *
   * this name is used internally by angular to create error chains
   */
  ngOriginalError: any;

  /**
   * Any Problem Details formatted response body sent by the server that describe the details of the problem
   */
  detail?: ProblemDetail;

  /**
   * Unique identifier
   */
  traceId?: string;

  versionInfo?: ErrorVersionInfo;
}

export class SanitizedError implements SanitizedErrorData {
  static readonly fallbackMessage = 'Sorry there was a problem encountered. The problem has been logged.';
  static readonly fetchMessage = 'We are unable to fetch the data. Please, try again.';
  static readonly saveMessage = 'We are unable to save your changes. Please, try again.';
  /**
   * Object that contains the version information of the application (eg client version, server version etc) that will
   * be added to all instances of `SanitizedError`
   * */
  private static _currentVersionInfo?: ErrorVersionInfo;
  static get currentVersionInfo() {
    return this._currentVersionInfo;
  }

  isError?: boolean;
  isLogged?: boolean;
  isSilent?: boolean;
  message = '';
  ngOriginalError: any = {};
  detail?: ProblemDetail;
  traceId: string;
  versionInfo?: ErrorVersionInfo;

  static createCompositeSanitizer(sanitizers: Array<SanitizerFunc | undefined>): SanitizerFunc {
    const availableSanitizers = (sanitizers || []).filter((s): s is SanitizerFunc => s != null);
    return (err: any) => {
      if (!err) return undefined;

      for (const sanitizer of availableSanitizers) {
        const sanitized = sanitizer(err);
        if (SanitizedError.is(sanitized)) {
          return sanitized;
        }
      }
      return undefined;
    };
  }

  static genericError(originalError: any) {
    return new SanitizedError({
      message: SanitizedError.fallbackMessage,
      ngOriginalError: originalError
    });
  }

  /**
   * Return a default message suitable to display to the user based on the reason supplied
   */
  static getDefaultMessage(reason: 'fetch' | 'save' | 'unknown', problem?: ProblemDetail): string {
    if (problem?.detail) return problem.detail;

    switch (reason) {
      case 'fetch':
        return SanitizedError.fetchMessage;
      case 'save':
        return SanitizedError.saveMessage;
      default:
        return SanitizedError.fallbackMessage;
    }
  }

  static getErrorLogEntry(error: any): [any, { [key: string]: any }] | undefined {
    if (error?.isError === false) {
      return undefined;
    }

    const sanitizedErr = SanitizedError.is(error) ? error : SanitizedError.genericError(error);
    const correlationId = sanitizedErr.detail?.traceId ? { correlationId: sanitizedErr.detail.traceId } : {};
    const props = {
      sanitizedMessage: sanitizedErr.message,
      traceId: sanitizedErr.traceId,
      ...correlationId,
      ...sanitizedErr.versionInfo
    };
    return [error.ngOriginalError, props];
  }

  static is(value: any): value is SanitizedError {
    if (value instanceof SanitizedError) {
      return true;
    }
    if (!value) {
      return false;
    }
    if (typeof value !== 'object') {
      return false;
    }

    const propNames = Object.getOwnPropertyNames(value);
    return mandatoryPropNames.every(n => propNames.includes(n));
  }

  static sanitize(err: any, sanitizer: SanitizerFunc) {
    return sanitizer(err) || SanitizedError.genericError(err);
  }

  static setVersionInfo(versionInfo: ErrorVersionInfo | null) {
    if (!versionInfo) {
      this._currentVersionInfo = undefined;
      return;
    }

    const assignedVersionEntries = Object.entries({ ...this._currentVersionInfo, ...versionInfo }).filter(
      ([_, value]) => value
    );
    if (assignedVersionEntries.length === 0) {
      this._currentVersionInfo = undefined;
      return;
    }
    this._currentVersionInfo = assignedVersionEntries.reduce(
      (acc, [key, value]) => Object.assign(acc, { [key]: value }),
      {}
    );
  }

  constructor(data: SanitizedErrorData) {
    const initialValues: Partial<SanitizedErrorData> = { isLogged: false };
    Object.assign(this, data, initialValues);
    this.traceId = data.traceId ?? data.ngOriginalError?.traceId ?? generateUUId();
    this.versionInfo = this.versionInfo ?? SanitizedError.currentVersionInfo;
  }

  asError() {
    return this.isError ? this : this.as({ isError: true });
  }

  asMuted() {
    return this.isSilent ? this : this.as({ isSilent: true });
  }

  asUnmuted() {
    return this.isSilent === false ? this : this.as({ isSilent: false });
  }

  as(data: Partial<SanitizedErrorData>) {
    return new SanitizedError({ ...this, ...data });
  }
}
