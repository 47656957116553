import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { anonymousUser, User } from '../models/user';

export const authFeatureKey = 'auth';

export interface State {
  user: User;
  impersonatedClientId?: string;
  isLoaded: boolean;
}

export const initialState: State = {
  user: anonymousUser,
  impersonatedClientId: undefined,
  isLoaded: false
};

export const reducer = createReducer(
  initialState,

  on(AuthActions.loginSuccess, (state, { userInfo, hasValidAccessToken }) => {
    const user = { ...state.user, ...userInfo, hasValidAccessToken };
    return { ...state, user };
  }),

  on(AuthActions.tokenInvalidated, state => {
    const user = { ...state.user, hasValidAccessToken: false };
    return { ...state, user };
  }),

  on(AuthActions.userProfileSuccess, (state, { userInfo }) => {
    const user = { ...state.user, ...userInfo };
    return { ...state, user };
  }),

  on(AuthActions.userInfoSuccess, (state, { attributes }) => {
    const user = { ...state.user, ...attributes };
    return { ...state, user, isLoaded: true };
  }),

  on(AuthActions.impersonateClient, (state, { impersonatedClientId }) => ({
    ...state,
    impersonatedClientId,
    isLoaded: false
  }))
);
