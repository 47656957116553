import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';

export interface DataSource {
  id: number;
  name: string;
  platformId: number;
  isEnrolled?: boolean;
  concurrencyLimit?: number;
}

export const DataSource = createModelMetadata<DataSource>({
  entityName: EntityName.DataSource,
  authorization: AuthzContextsActionMap.crudFor(EntityName.DataSource)
});
