import { appendDataset } from '@mri-platform/shared/core';
import { AccessToken, ApiConnection, FileData, Platform } from '../models';
import { createAccessTokenList } from './access-token-list';
import { createApis } from './apis';
import { createFileData } from './file-data';
import { createPlatforms } from './platforms';
import { testDb } from './test-dataset';

const contexts: Partial<AccessToken>[] = [
  {
    clientId: 1,
    clientName: 'P123456',
    userName: 'Haresh',
    databaseName: 'ACT_X540',
    databaseId: 3,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    clientId: 1,
    clientName: 'P123456',
    userName: 'Haresh',
    databaseName: 'ACTX4P_NOPO',
    databaseId: 4,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    clientId: 1,
    clientName: 'P123456',
    userName: 'Kasun',
    databaseName: 'ACTX4R_NOPO',
    databaseId: 5,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    clientId: 1,
    clientName: 'P123456',
    userName: 'Kasun',
    databaseName: 'ACTX5_ANZ',
    databaseId: 6,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    clientId: 2,
    clientName: 'P123456',
    userName: 'Haresh',
    databaseName: 'ACTX4P_NOJC',
    databaseId: 2,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    clientId: 2,
    clientName: 'P123456',
    userName: 'Haresh',
    databaseName: 'ACTX4P_NOPO',
    databaseId: 4,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    clientId: 2,
    clientName: 'P123456',
    userName: 'Kasun',
    databaseName: 'ACTX4R_NOPO',
    databaseId: 5,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    clientId: 2,
    clientName: 'P123456',
    userName: 'Kasun',
    databaseName: 'ACTX5_ANZ',
    databaseId: 6,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  },
  {
    platformId: 1,
    clientId: 3,
    clientName: 'VIRTUSA',
    userName: 'amansoor',
    databaseName: 'MRI_ImpExp',
    databaseId: 7,
    tokenExpiryTime: '9999-01-01T00:00:00.0000000+05:30'
  }
];

const apis: Partial<ApiConnection>[] = [
  {
    name: 'Birthdays Connection'
  },
  {
    name: 'CoverPhotos Connection'
  },
  {
    platformId: 1,
    isEnabled: false,
    name: 'EmailAddresses Connection'
  },
  {
    platformId: 1,
    name: 'Events Connection'
  },
  {
    platformId: 1,
    isEnabled: false,
    name: 'ImClients Connection'
  },
  {
    platformId: 1,
    name: 'Locales Connection'
  },
  {
    platformId: 0,
    name: 'Memberships Connection'
  },
  {
    platformId: 0,
    name: 'Metadata Connection'
  },
  {
    platformId: 0,
    name: 'Occupations Connection'
  }
];

//adding extra 100 entities to test the scrollbar functionality
const anonymousApis: Partial<ApiConnection>[] = [...Array(100).keys()].map(_ => ({}));

const anonymousPlatforms: Partial<Platform>[] = [...Array(5).keys()].map(_ => ({}));

const anonymousFileData: Partial<FileData>[] = [...Array(100).keys()].map(_ => ({}));

export const devDb = appendDataset(testDb, {
  accesstokens: {
    additionalData: contexts,
    creator: createAccessTokenList
  },
  apiconnections: {
    additionalData: [...apis, ...anonymousApis],
    creator: createApis
  },
  platforms: {
    additionalData: anonymousPlatforms,
    creator: createPlatforms
  },
  filedata: {
    additionalData: anonymousFileData,
    creator: createFileData
  }
});
