import { Injectable } from '@angular/core';
import { OAuthStorage } from 'angular-oauth2-oidc';

/**
 * Wrapper around `OAuthStorage` that will remove all storage entries that
 * were added via this service on logout
 */
@Injectable({
  providedIn: 'root'
})
export class AuthManagedStorageService implements OAuthStorage {
  private managedKeys = new Set<string>();

  constructor(private impl: OAuthStorage) {}

  /**
   * remove all storage entries that were added through calls to `setItem`
   */
  clear() {
    this.managedKeys.forEach(key => {
      this.impl.removeItem(key);
    });
    this.managedKeys = new Set<string>();
  }

  getItem(key: string): string | null {
    return this.impl.getItem(key);
  }

  removeItem(key: string): void {
    this.managedKeys.delete(key);
    this.impl.removeItem(key);
  }

  setItem(key: string, data: string): void {
    this.managedKeys.add(key);
    this.impl.setItem(key, data);
  }
}
