import { Directive, TemplateRef } from '@angular/core';
import { MasterDetailHeaderComponent } from './master-detail-header.component';

@Directive({
  selector: '[mriHeaderToolbar]'
})
export class MasterDetailHeaderToolbarDirective {
  constructor(masterDetailHeaderComponent: MasterDetailHeaderComponent, templateRef: TemplateRef<never>) {
    masterDetailHeaderComponent.toolbarTemplate = templateRef;
  }
}
