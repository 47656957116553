import { Injectable, OnDestroy, Predicate } from '@angular/core';
import { ErrorPolicyService, SanitizedError } from '@mri-platform/angular-error-handling';
import {
  all,
  clone,
  DirtyStatePromptService,
  DirtyStateService,
  isNotNullOrUndefined,
  keysOf,
  Nullable,
  some,
  valueStartingWith
} from '@mri-platform/shared/core';
import { createEntityAdapter } from '@ngrx/entity';
import { select as ngrxSelect } from '@ngrx/store';
import { RxState } from '@rx-angular/state';
import { distinctUntilSomeChanged } from '@rx-angular/state/selections';
import { asapScheduler, combineLatest, defer, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import {
  delay,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mapTo,
  mergeMap,
  share,
  shareReplay,
  skipWhile,
  startWith,
  subscribeOn,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { createChildrenStateSelectors } from './create-children-state-selectors';
import {
  DefaultExtraState,
  EntityCrudFacadeExtraState,
  EntityCrudFacadeState,
  SaveState
} from './entity-crud-facade-state';
import {
  defaultChildrenChanges$,
  defaultChildrenDirtyChanges$,
  EntityCrudCancellation,
  EntityCrudCancellationOptions,
  RequiredEntityCrudFacadeStrategy
} from './entity-crud-facade-strategy';
import {
  EntityCrudFacadeNonOptionalPublicState,
  EntityCrudFacadeProjections,
  EntityCrudFacadeStatePublicState,
  EntityCrudFacadeViewModel
} from './entity-crud-facade-view-model';
import { EntityIdType, isEntityNew } from './entity-functions';
import {
  EntityChanges,
  entityChanges,
  hasEntityChanges,
  initialEntityStateTracking,
  noEntityChanges,
  setEntityChanges
} from './tracked-entity-state';

type ProjectValueReducer<T, K extends keyof T, V> = (oldState: T, value: V) => T[K];
type ProjectValueFn<T, K extends keyof T> = (oldState: T) => T[K];

function hasUndefinedKeys<T, R extends T = T>(keys: Array<keyof T>): Predicate<R> {
  return (obj: R) => keys.some(key => obj[key] === undefined);
}

interface EntityChildrenChanges<E, C = never> {
  entity: E;
  childrenChanges: EntityChanges<C>;
}

interface EntitySaveContext<E, C = never> extends EntityChildrenChanges<E, C> {
  originalEntity: E;
  entityDirty: boolean;
}

type TypeOfSave = 'delete' | 'save';
const deleteType: TypeOfSave = 'delete';
const saveType: TypeOfSave = 'save';

type SaveResult<T> = { result: T | SanitizedError; saveType: TypeOfSave };

export const initialSaveState: SaveState = Object.freeze({
  errored: false,
  started: false,
  succeeded: false
});

/**
 * todo: add `connectExtraState` overload to support accessing the current value of state
 * todo: add `connectExtraState` overload to support setting multiple slices of state at once
 */
@Injectable()
export class EntityCrudFacadeService<E, C = never, S extends EntityCrudFacadeExtraState<S> = DefaultExtraState>
  implements OnDestroy
{
  cancellation$: Observable<EntityCrudCancellationOptions>;
  currentId$: Observable<EntityIdType>;
  currentEntity$: Observable<E>;
  dirty$: Observable<boolean>;
  editingSessionStart$: Observable<E | EntityCrudCancellationOptions>;
  hasChanges$: Observable<boolean>;
  newOrUnmodifiedEntity$: Observable<E>;
  saveState$: Observable<Pick<EntityCrudFacadeState<E, C, S>, 'deleteState' | 'error' | 'saveState'>>;
  startingEntity$: Observable<E>;
  startingChildren$: Observable<C[]>;
  valid$: Observable<boolean>;
  viewModel$: Observable<EntityCrudFacadeViewModel<E, C, S>>;

  private adapter = createEntityAdapter<C>({
    selectId: this.strategy.children.selectId
  });
  private childrenSelectors = createChildrenStateSelectors(this.adapter);

  private cancelledChildren$: Observable<C[]>;
  private readonly cancelledEntity$: Observable<EntityCrudCancellation<E>>;
  private readonly childrenChanges$: Observable<EntityChanges<C>>;
  private childrenDirty$: Observable<boolean>;
  private readonly delete$: Observable<E | boolean | void>;
  private readonly detailDirty$: Observable<boolean>;
  private idChanges = new Subject<EntityIdType>();
  private initialEntity$: Observable<E>;
  private readonly newOrUnmodifiedChildren$: Observable<C[]>;
  private persistStart$: Observable<TypeOfSave>;
  private persistEnd$: Observable<SaveResult<E>>;
  private persistedChildren$: Observable<C[]>;
  private persistedEntity$: Observable<E>;

  constructor(
    private errorPolicy: ErrorPolicyService,
    private dirtyService: DirtyStateService,
    private dirtyPromptService: DirtyStatePromptService,
    private strategy: RequiredEntityCrudFacadeStrategy<E, C, S>,
    private state: RxState<EntityCrudFacadeState<E, C, S>>
  ) {
    this.delete$ = this.strategy.delete$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this.initialEntity$ = this.strategy.entity.initialEntity$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
    this.detailDirty$ = this.strategy.entity.dirtyChanges$.pipe(valueStartingWith<boolean>(false));
    this.childrenDirty$ = this.createChildrenDirty$();
    this.dirty$ = this.createDirty$();
    this.currentId$ = this.createCurrentId$();
    this.cancellation$ = this.createCancellation$();
    this.persistedEntity$ = this.createPersistedEntity$();
    this.newOrUnmodifiedEntity$ = this.createNewOrUnmodifiedEntity$();
    this.currentEntity$ = merge(this.newOrUnmodifiedEntity$, this.strategy.entity.changes$);
    this.cancelledEntity$ = this.createCancelledEntity$();
    this.startingEntity$ = this.createStartingEntity$();
    this.childrenChanges$ = this.createChildrenChanges$();
    this.persistedChildren$ = this.createPersistedChildren$();
    this.newOrUnmodifiedChildren$ = this.createNewOrUnmodifiedChildren$();
    this.editingSessionStart$ = merge(this.newOrUnmodifiedEntity$, this.cancellation$);
    this.cancelledChildren$ = this.createCancelledChildren$();
    this.startingChildren$ = this.createStartingChildren$();
    this.persistStart$ = merge(this.strategy.save$.pipe(mapTo(saveType)), this.delete$.pipe(mapTo(deleteType)));
    this.persistEnd$ = merge(this.createSaves$(), this.createDeletes$());
    this.saveState$ = this.createSaveState$();
    this.hasChanges$ = this.createHasChanges$();
    this.valid$ = this.createValid$();

    const initialState: EntityCrudFacadeStatePublicState<E, C> = {
      deleteState: initialSaveState,
      dirty: false,
      // at runtime `entity` will be supplied and therefore ok(ish) to force typescript to accept `undefined`
      entity: undefined as unknown as E,
      error: null,
      saveState: initialSaveState,
      saving: initialSaveState.started,
      valid: false
    };

    // Set initial state in RxState
    this.state.set({
      ...initialState,
      childrenState: this.adapter.getInitialState(initialEntityStateTracking<C>()),
      ...this.strategy.extraState
    });

    // Connect any observable-driven items to state for items in ComponentState...

    this.connect('entity', this.startingEntity$);

    this.connectAs('childrenState', this.startingChildren$, ({ childrenState }, children) =>
      this.adapter.setAll(children, { ...childrenState, ...initialEntityStateTracking<C>() })
    );
    this.connect('dirty', this.dirty$);
    this.connect('valid', this.valid$);
    const saveStateChange$ = this.saveState$.pipe(
      map(({ deleteState, error, saveState }) => ({
        deleteState,
        error,
        saveState,
        saving: deleteState.started || saveState.started
      }))
    );
    this.connectSlice(saveStateChange$);

    // Create projections (calculations from ComponentState)...
    const projectionSelector = this.selectProjectedState.bind(this);

    const publicStateKeys: Array<keyof EntityCrudFacadeStatePublicState<E, C, S>> = [
      ...keysOf(initialState),
      ...keysOf(this.strategy.extraState).filter(k => this.strategy.extraState[k] !== undefined)
    ];

    // Create ViewModel (Projections + PublicState)...
    this.viewModel$ = this.createViewModel$(projectionSelector, publicStateKeys);

    // side effects if any...
    this.state.hold(this.cancelledEntity$, this.strategy.onCancel);
  }

  /**
   *
   * @description
   * Add one or more children to the `childrenState` collection
   */
  addChildToState(...child: C[]) {
    this.checkCanChangeChildren();

    this.setState('childrenState', ({ childrenState: state }) =>
      this.adapter.addMany(child, {
        ...state,
        changeState: setEntityChanges(state.changeState, { inserts: [...child] }, this.strategy.children.selectId)
      })
    );
  }

  /**
   *
   * @description
   * Connect an `Observable<S[K]>` source to a specific property `K` in the state `S`. Any emitted change will update
   * this specific property in the state.
   * Subscription handling is done automatically.
   *
   * @example
   * const myTimer$ = interval(250);
   * state.connect('timer', myTimer$);
   * // every 250ms the property timer will get updated
   */
  connectExtraState<K extends keyof S>(key: K, slice$: Observable<S[K]>) {
    this.state.connect(key, slice$);
  }

  /**
   * @description
   * Read from the state in imperative manner. Returns the state object in its current state.
   *
   * @example
   * const { dirty } = state.get();
   * if (!dirty) {
   *   doStuff();
   * }
   *
   * @return EntityCrudFacadeState<E, C, S>
   */
  getState(): EntityCrudFacadeState<E, C, S> {
    return this.state.get();
  }

  isEntityNew(entity: E) {
    return isEntityNew(entity, this.strategy.entity.crud.selectId);
  }

  ngOnDestroy(): void {
    this.state.ngOnDestroy();
    this.dirtyService.setDirty(this.strategy.componentName, false);
  }

  /**
   * @description
   * Manipulate one or many extra state properties by providing a `Partial<S>` state
   *
   * @example
   * // Update one or many properties of the state by providing a `Partial<S>`
   *
   * const partialState = {
   *   foo: 'bar',
   *   bar: 5
   * };
   * state.setExtraState(partialState);
   */
  setExtraState(state: Partial<S>) {
    return this.state.set(state as Partial<EntityCrudFacadeState<E, C, S>>);
  }

  /**
   * @description
   * Manages side-effects of your state. Provide an `Observable<any>` **side-effect** and an optional
   * `sideEffectFunction`.
   * Subscription handling is done automatically.
   *
   * @example
   * // Directly pass an observable side-effect
   * const localStorageEffect$ = changes$.pipe(
   *  tap(changes => storeChanges(changes))
   * );
   * state.hold(localStorageEffect$);
   *
   * // Pass an additional `sideEffectFunction`
   *
   * const localStorageEffectFn = changes => storeChanges(changes);
   * state.hold(changes$, localStorageEffectFn);
   *
   * @param {Observable<A>} obsOrObsWithSideEffect
   * @param {function} [sideEffectFn]
   */
  hold<A>(obsOrObsWithSideEffect: Observable<A>, sideEffectFn?: (arg: A) => void) {
    this.state.hold(obsOrObsWithSideEffect, sideEffectFn);
  }

  /**
   *
   * @description
   * Remove one or more children from the `childrenState` collection
   */
  removeChildFromState(...child: C[]) {
    this.checkCanChangeChildren();

    this.setState('childrenState', ({ childrenState: state }) =>
      this.adapter.removeMany(c => child.includes(c), {
        ...state,
        changeState: setEntityChanges(state.changeState, { deletes: [...child] }, this.strategy.children.selectId)
      })
    );
  }

  /**
   *
   * @description
   * Update one or more children in the `childrenState` collection
   */
  updateChildInState(...child: C[]) {
    this.checkCanChangeChildren();

    const changes = child.map(c => ({ id: this.adapter.selectId(c) as string, changes: c }));
    this.setState('childrenState', ({ childrenState: state }) =>
      this.adapter.updateMany(changes, {
        ...state,
        changeState: setEntityChanges(state.changeState, { updates: [...child] }, this.strategy.children.selectId)
      })
    );
  }

  private checkCanChangeChildren() {
    if (this.strategy.children.changes$ !== defaultChildrenChanges$) {
      throw new Error(`The 'children' collection is managed by consumer.
       To allow the facade to modify 'children' do NOT supply a children.changes$ observable when creating the EntityCrudFacadeStrategy`);
    }
  }

  private connect<K extends keyof EntityCrudFacadeState<E, C, S>>(
    key: K,
    slice$: Observable<EntityCrudFacadeState<E, C, S>[K]>
  ) {
    this.state.connect(key, slice$);
  }

  private connectSlice(inputOrSlice$: Observable<Partial<EntityCrudFacadeState<E, C>>>) {
    // use of `unknown` here is because I give up on trying to coerce the TS compiler to be happy with the type!
    this.state.connect(inputOrSlice$ as unknown as Observable<Partial<EntityCrudFacadeState<E, C, S>>>);
  }

  private connectAs<K extends keyof EntityCrudFacadeState<E, C, S>, V>(
    key: K,
    input$: Observable<V>,
    projectSliceFn: ProjectValueReducer<EntityCrudFacadeState<E, C, S>, K, V>
  ) {
    this.state.connect(
      key,
      // Q: why `subscribeOn(asapScheduler)`
      // A: we're causing RxState to wait to the end of the current micro task before
      // subscribing to input$; doing this is to avoid a race condition where `input$`
      // can emit ealier than the initial state set via `state.set` has been "propogated",
      // and therefore result in `projectSliceFn` receiving an undefined initial state
      // this workaround is potentially due to a bug in RxState
      input$.pipe(subscribeOn(asapScheduler)),
      // use of `unknown` here is because I give up on trying to coerce the TS compiler to be happy with the type!
      projectSliceFn as unknown as ProjectValueReducer<EntityCrudFacadeState<E, C, S>, K, V>
    );
  }

  private createCancellation$(): Observable<EntityCrudCancellationOptions> {
    // note: we're using `defer` to break the circular dependency:
    // cancellation$ -> hasChanges$ -> persisting$ -> cancellation$
    return this.strategy.cancel$.pipe(
      map(options => options ?? {}),
      withLatestFrom(defer(() => this.hasChanges$)),
      switchMap(([options, hasChanges]) => {
        if (!this.shouldPromptOnCancel(options, hasChanges)) return of(options);

        return this.dirtyPromptService.showCancelPrompt$().pipe(
          filter(accept => accept),
          mapTo(options)
        );
      }),
      share()
    );
  }

  private createCancelledChildren$() {
    return this.cancellation$.pipe(withLatestFrom(this.newOrUnmodifiedChildren$, (_, children) => children));
  }

  private createCancelledEntity$() {
    return this.cancellation$.pipe(
      withLatestFrom(this.newOrUnmodifiedEntity$, this.currentEntity$, (options, entity, currentEntityValue) => ({
        entity,
        options,
        currentEntityValue
      }))
    );
  }

  private createChildrenChanges$(): Observable<EntityChanges<C>> {
    if (this.strategy.children.changes$ === defaultChildrenChanges$) {
      return this.state.select(ngrxSelect(this.childrenSelectors.selectChanges));
    } else {
      return this.strategy.children.changes$.pipe(
        map(updates => entityChanges({ updates })),
        startWith(noEntityChanges<C>())
      );
    }
  }

  private createChildrenDirty$(): Observable<boolean> {
    if (this.strategy.children.dirtyChanges$ === defaultChildrenDirtyChanges$) {
      return this.state.select(ngrxSelect(this.childrenSelectors.selectHasChanges));
    } else {
      return this.strategy.children.dirtyChanges$.pipe(startWith(false));
    }
  }

  private createNewOrUnmodifiedChildren$(): Observable<C[]> {
    return merge(this.strategy.children.initialEntities$, this.persistedChildren$).pipe(
      distinctUntilChanged(), // de-dup initialEntities$ and persistedChildren$ emissions
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private createNewOrUnmodifiedEntity$(): Observable<E> {
    return merge(this.initialEntity$, this.persistedEntity$).pipe(
      distinctUntilChanged(), // de-dup initialEntity$ and persistedEntity$ emissions
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private createCurrentId$() {
    const initialId$ = this.initialEntity$.pipe(map(entity => this.strategy.entity.crud.selectId(entity)));
    return merge(initialId$, this.idChanges).pipe(
      this.tag('currentId'),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private createDeletes$(): Observable<SaveResult<E>> {
    const data$ = this.delete$.pipe(withLatestFrom(this.startingEntity$, (_, entity) => entity));

    const doDeleteEntity = this.strategy.entity.crud.deleteEntity
      ? this.strategy.entity.crud.deleteEntity.bind(this.strategy.entity.crud)
      : (entity: E) => of(entity);

    return data$.pipe(
      this.persist(doDeleteEntity),
      tap(result => {
        if (SanitizedError.is(result)) return;

        this.strategy.onDeleteSuccess(result as E);
      }),
      map(result => ({
        result: result as E,
        saveType: deleteType
      })),
      this.tag('deletes'),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private createDirty$(): Observable<boolean> {
    return combineLatest([this.detailDirty$, this.childrenDirty$]).pipe(
      some(true),
      distinctUntilChanged(),
      tap(dirty => this.dirtyService.setDirty(this.strategy.componentName, dirty)),
      this.tag('vm-dirty'),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private createHasChanges$(): Observable<boolean> {
    return combineLatest([
      this.dirty$,
      this.saveState$.pipe(
        map(({ deleteState, saveState }) => deleteState.started || saveState.started),
        distinctUntilChanged()
      )
    ]).pipe(some(true));
  }

  private createPersistedChildren$(): Observable<C[]> {
    const existingCurrentEntityId$ = this.startingEntity$.pipe(
      filter(entity => !this.isEntityNew(entity)),
      map(entity => this.strategy.entity.crud.selectId(entity)),
      distinctUntilChanged()
    );

    return existingCurrentEntityId$.pipe(switchMap(id => this.strategy.children.crud.entitiesByParentId$(id)));
  }

  private createPersistedEntity$(): Observable<E> {
    return this.currentId$.pipe(
      switchMap(id => this.strategy.entity.crud.entityById$(id)),
      isNotNullOrUndefined()
    );
  }

  private createSaveState$(): Observable<Pick<EntityCrudFacadeState<E, C, S>, 'deleteState' | 'error' | 'saveState'>> {
    const saveStarted: SaveState = { errored: false, started: true, succeeded: false };
    const initialState = {
      deleteState: initialSaveState,
      error: null,
      saveState: initialSaveState
    };
    return merge(
      this.persistStart$.pipe(
        map(saveType => ({
          deleteState: saveType === 'delete' ? saveStarted : initialSaveState,
          error: null,
          saveState: saveType !== 'delete' ? saveStarted : initialSaveState
        }))
      ),
      this.persistEnd$.pipe(
        map(({ result, saveType }) => {
          const error = SanitizedError.is(result) ? result : null;
          const errored = !!error;
          const erroredState = {
            errored,
            started: false,
            succeeded: !errored
          };
          return {
            deleteState: saveType === 'delete' ? erroredState : initialSaveState,
            error,
            saveState: saveType !== 'delete' ? erroredState : initialSaveState
          };
        })
      ),
      this.cancellation$.pipe(mapTo(initialState)),
      this.initialEntity$.pipe(mapTo(initialState))
    ).pipe(
      // `delay` avoids flicker of save button enabled/disabled
      // this is due to a (necessary) delay before our child components emits their dirtyChanges events
      delay(1),
      startWith(initialState),
      distinctUntilChanged(),
      this.tag('vm-saveState'),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private selectProjectedState(state: EntityCrudFacadeState<E, C, S>): EntityCrudFacadeProjections<C> {
    const { saving, dirty, valid, entity } = state;
    const isNew = this.isEntityNew(entity);
    return {
      canCancel: !saving && this.strategy.entity.canCancel(dirty, isNew, entity),
      canDelete: !saving && this.strategy.entity.canDelete(entity),
      canSave: !saving && (dirty || isNew) && valid,
      isNew,
      showDelete: !isNew && this.strategy.entity.showDelete(entity),
      children: this.childrenSelectors.selectAll(state)
    };
  }

  private createSaves$(): Observable<SaveResult<E>> {
    const data$ = this.strategy.save$.pipe(
      withLatestFrom(
        this.currentEntity$,
        this.childrenChanges$,
        (_, entity, childrenChanges): EntityChildrenChanges<E, C> => ({
          entity,
          childrenChanges
        })
      ),
      mergeMap(({ entity, childrenChanges }) => this.getChanges(entity, childrenChanges))
    );

    return data$.pipe(
      withLatestFrom(this.detailDirty$, this.newOrUnmodifiedEntity$, (data, entityDirty, originalEntity) => ({
        ...data,
        entityDirty,
        originalEntity
      })),

      this.persist(saveContext => this.save(saveContext)),

      // todo: make onSaveSuccess return an obserable that we can catch errors for and
      // handle cancellation then remove the tap operator below
      // EG:
      /*
      this.afterSave(this.strategy.onSaveSuccess),
      */
      tap(result => {
        if (SanitizedError.is(result)) return;

        this.strategy.onSaveSuccess(result);
      }),
      map(result => ({
        result,
        saveType
      })),
      this.tag('saves'),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private createStartingChildren$(): Observable<C[]> {
    return merge(this.newOrUnmodifiedChildren$, this.cancelledChildren$).pipe(
      this.tag('vm-children'),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private createStartingEntity$(): Observable<E> {
    return merge(
      this.newOrUnmodifiedEntity$,
      this.cancelledEntity$.pipe(
        map(({ entity }) => entity),
        // clone required to force change detection on presentation component
        clone()
      )
    ).pipe(this.tag('vm-entity'), shareReplay({ bufferSize: 1, refCount: true }));
  }

  private createValid$(): Observable<boolean> {
    return combineLatest([
      this.strategy.entity.validChanges$.pipe(startWith(false)),
      this.strategy.children.validChanges$.pipe(startWith(false))
    ]).pipe(all(true), distinctUntilChanged(), this.tag('vm-valid'), shareReplay({ bufferSize: 1, refCount: true }));
  }

  private createViewModel$(
    projectionSelector: (state: EntityCrudFacadeState<E, C, S>) => EntityCrudFacadeProjections<C>,
    nonOptionalPublicStateKeys: Array<keyof EntityCrudFacadeNonOptionalPublicState<E, C, S>>
  ) {
    const projectionKeys: Array<keyof Nullable<EntityCrudFacadeProjections<C>>> = keysOf({
      canCancel: null,
      canDelete: null,
      canSave: null,
      children: null,
      isNew: null,
      showDelete: null
    });

    const vmKeys: Array<keyof EntityCrudFacadeViewModel<E, C, S>> = [...nonOptionalPublicStateKeys, ...projectionKeys];

    return this.state
      .select(skipWhile(hasUndefinedKeys<EntityCrudFacadeState<E, C, S>>(nonOptionalPublicStateKeys)))
      .pipe(
        map(state => ({
          ...(state as EntityCrudFacadeStatePublicState<E, C, S>),
          ...projectionSelector(state)
        })),
        distinctUntilSomeChanged(vmKeys),
        this.tag('vm'),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  private tag(name: string) {
    return <Result>(source$: Observable<Result>) => source$.pipe(tag(`${this.strategy.componentName}-${name}`));
  }

  private getChanges(entity: E, childrenChanges: EntityChanges<C>): Observable<EntityChildrenChanges<E, C>> {
    return forkJoin({
      entity: this.strategy.entity.getChangesToSave(entity).pipe(take(1)),
      childrenChanges: this.strategy.children.getChangesToSave(childrenChanges).pipe(take(1))
    });
  }

  private persist<TData, TResult>(saveAction: (saveContext: TData) => Observable<TResult | SanitizedError>) {
    return (source$: Observable<TData>) =>
      source$.pipe(
        exhaustMap(saveContext =>
          saveAction(saveContext).pipe(
            takeUntil(this.cancellation$),
            this.errorPolicy.catchHandle(this.strategy.saveErrorOptions)
          )
        )
      );
  }

  private save(saveContext: EntitySaveContext<E, C>): Observable<E> {
    const { entity, originalEntity, entityDirty, childrenChanges } = saveContext;
    return this.saveDetail(entity, originalEntity, entityDirty).pipe(
      mergeMap(saved => this.saveChildren(childrenChanges, saved))
    );
  }

  private saveChildren(changes: EntityChanges<C>, parent: E): Observable<E> {
    if (!hasEntityChanges(changes)) {
      return of(parent);
    }

    return this.strategy.children.crud.save(changes, parent).pipe(mapTo(parent));
  }

  private saveDetail(entity: E, originalEntity: E, entityDirty: boolean): Observable<E> {
    if (!entityDirty && !this.isEntityNew(entity)) {
      return of(entity);
    }

    const originalId = this.strategy.entity.crud.selectId(entity);

    return this.strategy.entity.crud.save(entity, originalEntity).pipe(
      tap(saved => {
        const id = this.strategy.entity.crud.selectId(saved);
        if (originalId !== id) {
          this.idChanges.next(id);
        }
      })
    );
  }

  /**
   *
   * @description
   * Manipulate a single property of the state by the property name and a `ProjectionFunction<T>`.
   *
   * @example
   * const reduceFn = oldState => oldState.bar + 5;
   * state.set('bar', reduceFn);
   */
  private setState<K extends keyof EntityCrudFacadeState<E, C, S>>(
    key: K,
    projectSlice: ProjectValueFn<EntityCrudFacadeState<E, C, S>, K>
  ) {
    // use of `unknown` here is because I give up on trying to coerce the TS compiler to be happy with the type!
    this.state.set(key, projectSlice as unknown as ProjectValueFn<EntityCrudFacadeState<E, C, S>, K>);
  }

  private shouldPromptOnCancel({ force }: EntityCrudCancellationOptions, hasChanges: boolean): boolean {
    return hasChanges && !force;
  }
}
