export enum RecurrenceType {
  Never = 'Never',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly'
}

export enum RelativeWeekOption {
  First = 'First',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Last = 'Last'
}

export enum RepeatDailyOptions {
  NumberOfDays = 'NumberOfDays',
  Weekday = 'Weekday'
}

export enum RepeatMonthlyOption {
  SpecificDay = 'SpecificDay',
  RelativeDay = 'RelativeDay'
}

export enum RepeatUntil {
  Forever = 'Forever',
  Date = 'Date',
  NumberOfRuns = 'NumberOfRuns'
}

export enum RelativeDayOption {
  Day = 'Day',
  Weekday = 'Weekday',
  WeekendDay = 'WeekendDay',
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday'
}

export enum JobStatus {
  Completed = 'Completed',
  Initiated = 'Initiated',
  InProgress = 'InProgress',
  Failed = 'Failed',
  Succeeded = 'Succeeded',
  MappingCompleted = 'MappingCompleted',
  Scheduled = 'Scheduled',
  SucceededWithErrors = 'SucceededWithErrors',
  SucceededWithDuplicates = 'SucceededWithDuplicates'
}

export enum JobMode {
  Import = 'import',
  Export = 'export'
}
