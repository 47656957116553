import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@mri-platform/shared/core';
import { EntityIdType } from '@mri-platform/shared/entity';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { finalize } from 'rxjs/operators';
import { DataSource, PlatformEnrollment, UserDataSource } from '../models';
import { dataSourceSelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class DataSourceEntityService extends EntityCollectionServiceBase<DataSource> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private http: HttpClient,
    private apiConfig: ApiConfig
  ) {
    super(DataSource.entityName, serviceElementsFactory);
  }

  entityListByPlatformId$(platformId: EntityIdType) {
    return this.store.select(dataSourceSelectors.selectEntityListForPlatformId(platformId));
  }

  entityListByPlatformIdWithoutEnrolled$(platformId: EntityIdType) {
    return this.store.select(dataSourceSelectors.selectEntityListForPlatformIdWithoutEnrolled(platformId));
  }

  getEnrolledDatabases$(platformId: string) {
    let url = `${this.apiConfig.url}/datasources/byclient`;
    if (platformId) {
      url = url.concat('?platformId=' + platformId);
    }
    return this.http.get<string[]>(url);
  }

  getDataSourceByPlatform$(platformId: number) {
    this.setLoading(true);
    const url = `${this.apiConfig.url}/datasources/platform/${platformId}`;
    return this.http.get<UserDataSource[]>(url).pipe(finalize(() => this.setLoading(false)));
  }

  getDataSourcesForEnrollment$() {
    this.setLoading(true);
    const url = `${this.apiConfig.url}/datasources/enrollment`;
    return this.http.get<PlatformEnrollment[]>(url).pipe(finalize(() => this.setLoading(false)));
  }
}
