import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService, SanitizedError } from '@mri-platform/angular-error-handling';
import { ClientDataService, ClientSwitchInfo } from '@mri-platform/shared/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientsResolver implements Resolve<ClientSwitchInfo[] | SanitizedError> {
  constructor(private errorPolicyService: ErrorPolicyService, private clientDataService: ClientDataService) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<ClientSwitchInfo[] | SanitizedError> {
    return this.clientDataService.get().pipe(this.errorPolicyService.catchHandleRethrow(), take(1));
  }
}
