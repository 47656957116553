import { Injectable, Injector } from '@angular/core';
import { EntityQueryResolver } from '@mri-platform/shared/entity';
import { Platform } from '../models';
import { PlatformEntityService } from './platform-entity.service';

@Injectable({ providedIn: 'root' })
export class DefaultPlatformsResolver extends EntityQueryResolver<Platform> {
  constructor(injector: Injector, entityService: PlatformEntityService) {
    super(injector, entityService);
  }
}
