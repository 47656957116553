import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@mri-platform/ui-icon';
import { ButtonProgressComponent } from './button-progress/button-progress.component';
import { CloseButtonComponent } from './close-button/close-button.component';
import { ToolbarGroupDirective } from './toolbar/toolbar-group.directive';
import { ToolbarDirective } from './toolbar/toolbar.directive';

const COMPONENTS = [ButtonProgressComponent, ToolbarDirective, ToolbarGroupDirective, CloseButtonComponent];

@NgModule({
  imports: [CommonModule, UiIconModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class UiButtonsModule {}
