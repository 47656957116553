import { Pipe, PipeTransform } from '@angular/core';

interface DatabaseModelArray {
  key: string | number;
}
type DatabaseProperty = keyof DatabaseModelArray;
@Pipe({
  name: 'showTitle'
})
export class ShowTitlePipe implements PipeTransform {
  transform(
    value: DatabaseModelArray[],
    idField: DatabaseProperty,
    valueField: DatabaseProperty,
    selectValue: string | number
  ) {
    if (!value || (value && !value.length) || !selectValue) {
      return '';
    }
    const res = (value as []).find(v => v && v[idField] === selectValue);
    return res ? res[valueField] : '';
  }
}
