import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { routeResolveEntities, RoutingEventsService } from '@mri-platform/shared/core';
import { EntityActionOptions } from '@ngrx/data';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { QueryDef } from './query-def';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface EntityQueryResolverService<T extends {}> {
  setWithQuery(query: QueryDef<T> | Partial<T>, options?: EntityActionOptions): Observable<T[]>;
}

export type ResolverQueryDefSelector<T> = (context: {
  route: ActivatedRouteSnapshot;
  state: RouterStateSnapshot;
}) => QueryDef<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
export class EntityQueryResolver<T extends {}> implements Resolve<boolean> {
  private errorPolicyService: ErrorPolicyService;
  private routingEvents: RoutingEventsService;
  router: Router;
  constructor(
    injector: Injector,
    private entitiesService: EntityQueryResolverService<T>,
    private queryDefSelector: ResolverQueryDefSelector<T> = QueryDef.getAll
  ) {
    // we're using injector as service lookup so that future services can be acquired without having
    // to modify constructors of inheriting classes (I do NOT like this pattern! but also don't like
    // having to force inheritors having to declare constructor params that are internal details of this
    // EntitiesResolver class)
    this.errorPolicyService = injector.get(ErrorPolicyService);
    this.routingEvents = injector.get(RoutingEventsService);
    this.router = injector.get(Router);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const resultsSelector = () => {
      const query = this.queryDefSelector({ route, state });
      return this.entitiesService.setWithQuery(query, undefined);
    };
    return this.routingEvents.isInitialNavigation$.pipe(
      routeResolveEntities(resultsSelector, this.router, this.errorPolicyService, '/error'),
      mapTo(true)
    );
  }
}
