import { Platform } from '../models';

const baseExample: Platform = {
  id: 1,
  name: 'Sample platform name',
  type: 'PropertyManagement',
  identifier: 'sample_identifier',
  oAuth2ClientId: 'sample_client_id',
  oAuth2Secret: 'sample_client_secret',
  uri: 'sampleuri.com',
  tokenProviderUrl: 'sample_token_provider.com',
  isEnrolled: true,
  concurrencyLimit: 20,
  isBatchingSupported: true,
  username: '',
  certificatePassword: '',
  certificateFile: [],
  clientId: 1,
  clientLicenseId: 'MRIQWEB',
  environmentId: 'sample_environment_id'
};

export const createPlatforms = (list: Partial<Platform>[]): Platform[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: i + 1 }));
