import { Pipe, PipeTransform } from '@angular/core';
import type { FileRestrictions } from '@progress/kendo-angular-upload';

@Pipe({
  name: 'fileRestrictionsMessage'
})
export class FileRestrictionsMessagePipe implements PipeTransform {
  transform(value: FileRestrictions): string {
    const ruleMessages = [];
    if (value.maxFileSize != null) {
      ruleMessages.push(`Max. file size: ${this.bytesToKb(value.maxFileSize)}KB`);
    }
    if (value.minFileSize != null) {
      ruleMessages.push(`Min. file size: ${this.bytesToKb(value.minFileSize)}KB`);
    }
    if (value.allowedExtensions?.length) {
      ruleMessages.push(`Allowed file types: ${value.allowedExtensions.join(', ')}`);
    }
    return value ? ruleMessages.join('; ') : '';
  }

  private bytesToKb(value: number) {
    return Math.floor(value / 1024);
  }
}
