import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService } from '@mri-platform/shared/entity';
import { JobLogError } from '../models';

@Injectable({
  providedIn: 'root'
})
export class JobLogErrorEntityService extends DefaultEntityService<JobLogError> {
  constructor(injector: Injector) {
    super(JobLogError.entityName, injector);
  }
}
