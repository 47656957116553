import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService } from '@mri-platform/shared/entity';
import { ClientSummary } from '../models/client-summary';

@Injectable({ providedIn: 'root' })
export class ClientSummaryEntityService extends DefaultEntityService<ClientSummary> {
  constructor(injector: Injector) {
    super(ClientSummary.entityName, injector);
  }
}
