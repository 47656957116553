import { FilterBarConfig, User } from '@mri-platform/import-export/common-state';
import { LoadedUser } from '@mri/angular-wfe-proxy-oidc';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

export function setUsersList(
  users$: Observable<User[]>,
  userLoaded$: Observable<LoadedUser>,
  filterBarConfig: FilterBarConfig
) {
  return combineLatest([users$, userLoaded$]).pipe(
    tap(([users, currentUser]) => {
      const initialUsers = [
        {
          text: 'All',
          value: null
        },
        {
          text: currentUser.name,
          value: currentUser.email
        }
      ];
      const userList = users
        .sort((u1, u2) => (u1.firstName > u2.firstName ? 1 : -1))
        .map(user => ({ text: `${user.firstName} ${user.lastName}`, value: user.email }))
        .filter((u: { text: string; value: string }) => u.value.toLowerCase() !== currentUser.email.toLowerCase());
      const filters = filterBarConfig.filters;
      filters[2].listValues = initialUsers.concat(userList);
      filterBarConfig = { ...filterBarConfig, ...{ filters: filters } };
    })
  );
}

export function setDataSourcesList(enrolledDatasources: Observable<string[]>, filterBarConfig: FilterBarConfig) {
  return enrolledDatasources.pipe(
    distinctUntilChanged(),
    map(databaseName =>
      databaseName.map(databaseName => {
        return { text: databaseName, value: databaseName };
      })
    ),
    //TODO: refactor this with better approach
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map((d: any) => {
      const initialDatabase = [
        {
          text: 'All',
          value: null
        }
      ];
      const filters = filterBarConfig.filters;
      filters[3].listValues = initialDatabase.concat(d);
      return (filterBarConfig = { ...filterBarConfig, filters: filters });
    })
  );
}

export function updateJobLogs(filterBarValue: Observable<{ [key: string]: unknown }>, platformId?: number) {
  return filterBarValue.pipe(
    filter(changes => Object.keys(changes).length > 0),
    distinctUntilChanged(),
    map(value => {
      return {
        type: value.type || undefined,
        status: value.status || undefined,
        fromDate: getDateOnly(value.from as Date),
        toDate: getDateOnly(value.to as Date),
        users: value.user ? [value.user] : undefined,
        dataSources: value.database ? [value.database] : undefined,
        platformId
      };
    })
  );
}

function getDateOnly(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}
