import { Client, ClientSummary } from '../models';
import { createClientSummaryList } from './client-summaries';
import { createClientList } from './clients';

const clients: Partial<Client>[] = [
  { licenseId: 'Sample license 0', name: 'P123456' },
  { licenseId: 'Sample license 1' },
  { licenseId: 'Sample license 2' },
  { licenseId: 'MRIQWEB' }
];

const clientSummaries: Partial<ClientSummary>[] = [
  { recordLimit: 1000, totalRecordCount: 30000 },
  { recordLimit: 1300, totalRecordCount: 20000 },
  { recordLimit: 1500, totalRecordCount: 300000 },
  { recordLimit: 1200, totalRecordCount: 50000 }
];

export const testDb = {
  clients: createClientList(clients),
  clientsummaries: createClientSummaryList(clientSummaries)
};
