import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientSwitchInfo, ClientSwitchService, findBySearch } from '@mri-platform/shared/core';
import { RxState } from '@rx-angular/state';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

interface RouteData {
  clients: ClientSwitchInfo[];
}
type ComponentState = RouteData & {
  selectedClient?: ClientSwitchInfo;
};
type PublicState = ComponentState;

// interface Projections {}

type ViewModel = PublicState; // & Projections;

const initialPublicState: PublicState = {
  clients: []
};

const initialState: ComponentState = {
  ...initialPublicState,
  selectedClient: undefined
};

@Component({
  selector: 'mri-shared-client-list',
  templateUrl: './client-switch-list-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ClientSwitchListPageComponent {
  @Output() routeToParent = new EventEmitter();
  vm$: Observable<ViewModel>;

  applyFilter = findBySearch<ClientSwitchInfo>({
    searchFields: ['licenseId']
  });
  constructor(
    private route: ActivatedRoute,
    private state: RxState<ComponentState>,
    private clientSwitchService: ClientSwitchService,
    private location: Location
  ) {
    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...

    const data$ = this.route.data as Observable<RouteData>;
    this.state.connect('clients', data$.pipe(pluck('clients')));

    // Create projections (calculations from ComponentState)...
    // none

    // Create ViewModel (Projections + PublicState)...

    this.vm$ = this.state.select();

    // side effects if any...
    // none
  }

  closeDrawer() {
    this.location.back();
  }

  selectItem(selectedClient: ClientSwitchInfo) {
    this.state.set({ selectedClient });
  }

  switchClient() {
    const selectedClient = this.state.get('selectedClient');
    if (!selectedClient) {
      return;
    }
    const { licenseId } = selectedClient;
    this.clientSwitchService.switchClient(licenseId);
  }
}
