import { ClientSummary } from '../models';

const baseExample: ClientSummary = {
  id: 1,
  clientId: 1,
  recordLimit: 1000,
  totalRecordCount: 30000
};

export const createClientSummaryList = (list: Partial<ClientSummary>[]): ClientSummary[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: (i + 1) }));
