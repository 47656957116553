import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { JobLog } from '../models';
import { JobLogEntityService } from './job-log-entity.service';

@Injectable({ providedIn: 'root' })
export class FilteredJobLogsResolver implements Resolve<JobLog[]> {
  constructor(private jobLogEntityService: JobLogEntityService) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<JobLog[]> {
    const id = _route.paramMap.get('id') || '';
    const today = new Date();
    const previousMonth = new Date();
    previousMonth.setMonth(today.getMonth() - 1);
    today.setHours(0, 0, 0, 0);
    previousMonth.setHours(0, 0, 0, 0);
    const options: { [key: string]: unknown } = {
      fromDate: previousMonth,
      toDate: today
    };
    if (id) {
      options.platformId = +id;
    }
    return this.jobLogEntityService.entitiesByFilter$(options);
  }
}
