import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Client } from '@mri-platform/import-export/clients-state';
import { FilterBarConfig, FilterConfig, FilterType, JobUsageFilters } from '@mri-platform/import-export/common-state';
import { FormModel, FormState } from '@mri-platform/shared/common-ui';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable, asapScheduler } from 'rxjs';
import { observeOn, tap } from 'rxjs/operators';

interface FilterFormState extends FormState<FilterBarConfig> {
  showDrawer: boolean;
  showReportDrawer: boolean;
  values: { [key: string]: unknown };
}

const initialState: FilterFormState = {
  model: undefined as never,
  isValid: false,
  isDirty: false,
  showDrawer: false,
  showReportDrawer: false,
  values: {}
};

type ViewModel = Pick<FilterFormState, 'model' | 'showDrawer' | 'showReportDrawer'>;

@Component({
  selector: 'mri-ie-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarComponent {
  @Input() set config(value: FilterBarConfig) {
    if (!value) {
      return;
    }
    const filters = JSON.parse(JSON.stringify(value.filters));
    const enableDrawer = value.enableDrawer;
    this.form = this.createForm(filters);
    this.filterDrawerForm = this.createForm(filters);

    const model = { filters, enableDrawer };
    this.state.set({ model: model });
    this.initialFormState = { ...this.form.value };
    this.state.connect('values', this.form.valueChanges);
  }
  @Input() enableDownload = false;
  @Input() enableReport = false;
  @Input() clients: Client[] = [];
  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('values').pipe(tap(values => this.filterDrawerForm.patchValue(values)));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() reportDrawerValueChanges = new EventEmitter<JobUsageFilters>();
  @Output() download = new EventEmitter<void>();

  vm$: Observable<ViewModel>;
  form: FormGroup = new FormGroup({});
  filterDrawerForm: FormGroup = new FormGroup({});
  FilterType: typeof FilterType = FilterType;
  initialFormState = {};
  today = new Date();

  constructor(
    private state: RxState<FilterFormState>,
    private fb: FormBuilder
  ) {
    this.state.set(initialState);
    this.vm$ = this.state.select(selectSlice(['model', 'showDrawer', 'showReportDrawer']));
  }

  createForm(filters: Array<FilterConfig>) {
    const group: FormModel<{ [key: string]: unknown }> = {};
    filters.forEach(filter => {
      const fieldName = filter.title.toLocaleLowerCase();
      if (filter.type === FilterType.DateRange && filter.dateRange) {
        group['from'] = [new Date(filter.dateRange.from)];
        group['to'] = [new Date(filter.dateRange.to)];
      } else {
        group[fieldName] = [filter.value];
      }
    });
    return this.fb.group(group);
  }

  openDrawer(drawerName: string) {
    this.state.set({ [drawerName]: true });
  }

  closeDrawer() {
    this.filterDrawerForm.patchValue(this.form.value);
    this.state.set({ showDrawer: false });
  }

  closeReportDrawer() {
    this.state.set({ showReportDrawer: false });
  }

  setFilters() {
    this.form.patchValue(this.filterDrawerForm.value);
    this.state.set({ showDrawer: false });
  }

  resetFilters() {
    this.filterDrawerForm.patchValue(this.initialFormState);
  }
}
