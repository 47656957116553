import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const isLoggedIn$ = from(this.authService.ensureLoggedIn(state.url));
    return isLoggedIn$.pipe(map(loggedIn => loggedIn != null && loggedIn && (this.popRedirectUrlAfterLogin() ?? true)));
  }

  private popRedirectUrlAfterLogin() {
    if (this.authService.targetUrlOnLogin === undefined) {
      return;
    }

    const targetUrl = this.authService.targetUrlOnLogin;
    this.authService.targetUrlOnLogin = undefined;
    return this.router.parseUrl(targetUrl);
  }
}
