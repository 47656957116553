import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Client } from '@mri-platform/import-export/clients-state';
import { JobUsageFilters } from '@mri-platform/import-export/common-state';
import { FormControls, FormModel, FormState, connectForm, enableControl } from '@mri-platform/shared/common-ui';
import { keysOf } from '@mri-platform/shared/core';
import { RxState } from '@rx-angular/state';
import { selectSlice } from '@rx-angular/state/selections';
import { Observable } from 'rxjs';

const today = new Date();
const previousMonth = new Date();
previousMonth.setMonth(today.getMonth() - 1);

const initialFormState: FormModel<JobUsageFilters> = {
  allDates: [true],
  allClients: [true],
  clientIds: [{ value: '', disabled: true }, Validators.required],
  fromDate: [{ value: previousMonth, disabled: true }, Validators.required],
  toDate: [{ value: today, disabled: true }, Validators.required]
};

interface ComponentState extends FormState<JobUsageFilters> {
  clients: Client[];
}

type PublicState = Pick<ComponentState, 'clients' | 'isValid' | 'isDirty'>;

const initialPublicState: PublicState = {
  clients: [],
  isValid: true,
  isDirty: false
};

type ViewModel = PublicState;

const initialState: ComponentState = {
  ...initialPublicState,
  model: undefined as never
};

@Component({
  selector: 'mri-ie-report-drawer',
  templateUrl: './report-drawer.component.html',
  styleUrls: ['./report-drawer.component.scss'],
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportDrawerComponent {
  get model() {
    return this.state.get('model');
  }

  get formControls() {
    return this.form.controls as FormControls<JobUsageFilters>;
  }

  @Input() set clients(clients: Client[]) {
    this.state.set({ clients });
  }

  @Output() valueChanges = new EventEmitter<JobUsageFilters>();
  @Output() closeDrawer = new EventEmitter<void>();

  vm$: Observable<ViewModel>;

  form = this.createForm();

  constructor(
    private fb: FormBuilder,
    private state: RxState<ComponentState>
  ) {
    this.state.set(initialState);
    connectForm(this.form, this.state);
    this.vm$ = this.state.select(selectSlice(keysOf(initialPublicState)));
    this.updateFormState();
  }

  private createForm() {
    return this.fb.group(initialFormState);
  }

  save() {
    this.valueChanges.emit(this.model);
  }

  updateFormState() {
    const skipEvents = { emitEvent: false };

    this.state.hold(this.formControls.allClients.valueChanges, isDisable => {
      enableControl(this.formControls.clientIds, !isDisable, skipEvents);
      if (isDisable) {
        this.formControls.clientIds.reset('');
      }
    });

    this.state.hold(this.formControls.allDates.valueChanges, isDisable => {
      enableControl(this.formControls.fromDate, !isDisable, skipEvents);
      enableControl(this.formControls.toDate, !isDisable, skipEvents);
      if (isDisable) {
        this.formControls.fromDate.reset(previousMonth);
        this.formControls.toDate.reset(today);
      }
    });
  }
}
