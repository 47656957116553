import { Injectable } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router';
import { EMPTY, Observable, of, zip } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, scan, shareReplay, startWith, switchMap } from 'rxjs/operators';

function isNavigationStart(event: Event): boolean {
  return event instanceof NavigationStart;
}

function isNavigationEnd(event: Event): boolean {
  return event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError;
}

@Injectable({ providedIn: 'root' })
export class RoutingEventsService {
  isNavigationPending$: Observable<boolean>;
  navigationCounter$: Observable<number>;
  isInitialNavigation$: Observable<boolean>;

  constructor(router: Router) {
    const navigationStart$ = router.events.pipe(
      switchMap(event => (isNavigationStart(event) ? of(event as NavigationStart) : EMPTY))
    );
    const navigationEnd$ = router.events.pipe(
      switchMap(event => (isNavigationEnd(event) ? of(event as RouterEvent) : EMPTY))
    );

    this.navigationCounter$ = zip(navigationStart$, navigationEnd$).pipe(
      mapTo(1),
      scan(count => ++count),
      startWith(0),
      shareReplay(1)
    );

    this.isInitialNavigation$ = this.navigationCounter$.pipe(
      map(count => count === 0),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.isNavigationPending$ = router.events.pipe(
      filter(event => isNavigationStart(event) || isNavigationEnd(event)),
      map(event => isNavigationStart(event)),
      distinctUntilChanged()
    );
  }
}
