<ng-container *ngIf="vm$ | async as vm">
  <div class="mri-filter-bar mri-ph-medium">
    <div class="mri-filter-bar__filter-buttons">
      <mri-ie-filter-bar-item *ngFor="let filter of vm.model.filters" [config]="filter" [form]="form">
      </mri-ie-filter-bar-item>
    </div>
    <button
      (click)="openDrawer('showReportDrawer')"
      type="button"
      class="mri-button mri-button--quiet"
      *ngIf="enableReport"
    >
      <mri-btn-icon icon="description" size="default"></mri-btn-icon>
      <span class="mri-button__label">Report</span>
    </button>
    <button (click)="openDrawer('showDrawer')" type="button" class="mri-button mri-button--quiet">
      <mri-btn-icon icon="filter_alt"></mri-btn-icon>
      <span class="mri-button__label">All filters</span>
      <span class="mri-counter mri-button__icon--right">{{ vm.model.filters.length }}</span>
    </button>
    <button (click)="download.emit()" type="button" class="mri-button mri-button--quiet" *ngIf="enableDownload">
      <mri-btn-icon class="download-buttons" icon="download"></mri-btn-icon>
      <span class="mri-button__label">Download</span>
    </button>
  </div>
  <mri-shared-drawer headerTitle="All filters" [isOpen]="vm.showDrawer" (closeDrawer)="closeDrawer()">
    <div *mriSharedDrawerBody class="container">
      <form [formGroup]="filterDrawerForm" class="k-form k-form-horizontal">
        <div *ngFor="let filter of vm.model.filters">
          <div class="filter-title">
            <span>{{ filter.title }}</span>
          </div>
          <ng-container *ngIf="filter.type === FilterType.List">
            <div class="row">
              <kendo-formfield class="col-lg-12 mri-pv-medium">
                <label for="select" class="mri-lg-3 text-right">{{ filter.title }}</label>
                <kendo-dropdownlist
                  name="select"
                  #select
                  [formControlName]="filter.title.toLocaleLowerCase()"
                  [data]="filter.listValues"
                  class="mri-lg-9 mri-p-none ng-pristine"
                  autoWidth="false"
                  textField="text"
                  valueField="value"
                  valuePrimitive="true"
                >
                </kendo-dropdownlist>
              </kendo-formfield>
            </div>
          </ng-container>
          <ng-container *ngIf="filter.type === FilterType.DateRange">
            <div class="row">
              <kendo-formfield class="col-lg-12 mri-pv-medium">
                <label for="fromDate" class="mri-lg-3 text-right">Start date</label>
                <kendo-datepicker
                  #fromDate
                  formControlName="from"
                  [max]="filterDrawerForm.value.to"
                  mriIeKendoDateFormat
                ></kendo-datepicker>
              </kendo-formfield>
            </div>
            <div class="row">
              <kendo-formfield class="col-lg-12 mri-pb-medium">
                <label for="toDate" class="mri-lg-3 text-right">End date</label>
                <kendo-datepicker
                  #toDate
                  formControlName="to"
                  [max]="today"
                  [min]="filterDrawerForm.value.from"
                  mriIeKendoDateFormat
                ></kendo-datepicker>
              </kendo-formfield>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
    <div *mriSharedDrawerFooter class="footer-content">
      <div class="action-group">
        <button class="mri-button" (click)="resetFilters()">
          <mri-btn-icon icon="undo"></mri-btn-icon>
          Reset all
        </button>
        <button class="mri-button mri-button--primary" (click)="setFilters()">
          <mri-btn-icon icon="done"></mri-btn-icon>
          Apply
        </button>
        <button class="mri-button" (click)="closeDrawer()">
          <mri-btn-icon icon="x"></mri-btn-icon>
          Cancel
        </button>
      </div>
    </div>
  </mri-shared-drawer>
  <!-- TODO: move the below drawer out of this component -->
  <mri-ie-report-drawer
    *ngIf="vm.showReportDrawer"
    [clients]="clients"
    (closeDrawer)="closeReportDrawer()"
    (valueChanges)="reportDrawerValueChanges.next($event)"
  >
  </mri-ie-report-drawer>
</ng-container>
