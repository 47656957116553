import { HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AvatarIcon,
  CdnFile,
  avatarDefaultCaptionBgColor,
  avatarDefaultImageBgColor,
  avatarIconFileFromCdnFile
} from '@mri-platform/shared/common-types';
import { ErrorEvent, SuccessEvent } from '@progress/kendo-angular-upload';
import { RxState } from '@rx-angular/state';
import { asapScheduler } from 'rxjs';
import { distinctUntilChanged, observeOn } from 'rxjs/operators';
import { FormControls, FormEditableControls, FormModel } from '../../functions/forms/form-model';
import { FormState, connectForm } from '../../functions/forms/form-observables';
import { FormValidators, enableControl, setSyncValidators } from '../../functions/forms/form-validation';
import { ValidateAlphanumeric } from '../../functions/forms/validate-alphanumeric';
import { AvatarImageUploadService } from '../../services/avatar-image-upload.service';

type IconType = 'image' | 'icon';

const defaultSelection: IconType = 'image';

function getBackgroundColor(iconType: IconType, model: AvatarIcon) {
  switch (iconType) {
    case 'icon':
      return model.iconInitials ? model.iconBackgroundColor : avatarDefaultCaptionBgColor;
    case 'image':
      return model.iconSource ? model.iconBackgroundColor : avatarDefaultImageBgColor;
  }
}

interface IconSelectorFormControls extends AvatarIcon {
  selection: IconType;
  fileUploaded?: CdnFile;
}

const initialFormState: FormModel<IconSelectorFormControls> = {
  selection: [defaultSelection],
  fileUploaded: [null],
  iconInitials: [''],
  iconSource: [''],
  iconFileName: [''],
  iconPath: [''],
  iconBackgroundColor: [avatarDefaultCaptionBgColor]
};

const noValidators: FormValidators<IconSelectorFormControls> = {};
const validationState: Record<IconType, FormValidators<IconSelectorFormControls>> = {
  icon: {
    iconInitials: [Validators.required, ValidateAlphanumeric, Validators.maxLength(3), Validators.minLength(1)],
    iconBackgroundColor: [Validators.required]
  },
  image: {
    fileUploaded: [Validators.required]
  }
};

type EditableControls = Required<FormEditableControls<IconSelectorFormControls>>;

const allReadonlyControls: EditableControls = {
  fileUploaded: false,
  iconBackgroundColor: false,
  iconInitials: false,
  iconFileName: false,
  iconSource: false,
  iconPath: false,
  selection: false
};
const editableState: Record<IconType, EditableControls> = {
  icon: {
    ...allReadonlyControls,
    iconBackgroundColor: true,
    iconInitials: true
  },
  image: {
    ...allReadonlyControls,
    fileUploaded: true
  }
};

interface ComponentState extends FormState<AvatarIcon> {
  // todo: remove this line once background color can be picked for an icon *image*
  originalModel: AvatarIcon;
}

const initialState: ComponentState = {
  isDirty: false,
  isValid: false,
  // OK(ish) as by the time our component is initialized, model will be assigned
  model: undefined as never,
  // todo: remove this line once background color can be picked for an icon *image*
  originalModel: undefined as never
};

@Component({
  selector: 'mri-shared-avatar-icon-selector-form',
  templateUrl: './avatar-icon-selector-form.component.html',
  styles: [
    `
      :host {
        display: block;
      }

      .avatar-initials {
        max-width: 66px;
      }

      @media screen and (max-width: 640px) {
        kendo-formfield.mri-ml-medium {
          margin-left: 0px;
        }
      }

      /* todo: remove width once Scott has fixed mri/kendo-theme */
      kendo-upload {
        max-width: 387px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class AvatarIconSelectorFormComponent {
  @HostBinding('attr.data-testid') testId = AvatarIconSelectorFormComponent.name;

  @Input() set value(model: AvatarIcon | undefined) {
    this.state.set({ model, originalModel: model });

    // todo: when `value` is undefined reset the form to `initialState`
    this.form.reset(undefined, { emitEvent: false });

    const iconType = this.getIconType(model);
    if (model) {
      const formValue: Partial<IconSelectorFormControls> = {
        ...model,
        selection: iconType,
        iconBackgroundColor: getBackgroundColor(iconType, model)
      };
      this.form.patchValue(formValue);
    }
  }

  // make sure to emit dirtyChange and validChanges on the next turn of the event loop
  // (required when switching between instances to cause change detection to "see" our changes)
  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');

  imageUploadUrl = this.avatarUploadService.uploadUrl;
  editableControls: Required<FormEditableControls<IconSelectorFormControls>> = allReadonlyControls;
  form = this.createForm();
  uploadFileRestrictions = this.avatarUploadService.uploadFileRestrictions;

  get formControls() {
    return this.form.controls as FormControls<IconSelectorFormControls>;
  }

  private _validators = noValidators;
  get validators() {
    return this._validators;
  }

  set validators(value: FormValidators<IconSelectorFormControls>) {
    if (this._validators === value) return;

    this._validators = value;
    setSyncValidators(this.form, this._validators);
  }

  uploadHeaders?: HttpHeaders;

  constructor(
    private fb: FormBuilder,
    private avatarUploadService: AvatarImageUploadService,
    private state: RxState<ComponentState>
  ) {
    this.uploadHeaders = avatarUploadService.uploadHeaders;

    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...

    connectForm(this.form, this.state, {
      tagPrefix: AvatarIconSelectorFormComponent.name,
      includeDisabledControlValues: true,
      valueSelector: this.getCurrentValue.bind(this)
    });

    // side effects if any...

    const iconType$ = this.formControls.selection.valueChanges.pipe(distinctUntilChanged());
    this.state.hold(iconType$, iconType => this.setFormState(iconType));
  }

  uploadFailure(e: ErrorEvent) {
    this.avatarUploadService.handleUploadFailure(e);
  }

  uploadSuccess(data: SuccessEvent) {
    const { iconFileName, iconSource, iconPath } = avatarIconFileFromCdnFile(data.response.body as CdnFile);
    this.form.patchValue({ iconSource, iconFileName, iconPath });
  }

  private createForm() {
    return this.fb.group(initialFormState);
  }

  private getCurrentValue({
    iconBackgroundColor,
    iconInitials,
    iconFileName,
    iconSource,
    iconPath,
    selection
  }: IconSelectorFormControls): AvatarIcon {
    switch (selection) {
      case 'icon':
        return {
          iconBackgroundColor,
          iconInitials,
          iconSource: iconSource == null ? iconSource : undefined,
          iconPath: iconPath == null ? iconPath : undefined,
          iconFileName: iconFileName == null ? iconFileName : undefined
        };
      case 'image': {
        // todo: remove this line once background color can be picked for an icon *image*
        iconBackgroundColor = getBackgroundColor(selection, this.state.get('originalModel'));

        return {
          iconBackgroundColor,
          iconInitials: iconInitials == null ? iconInitials : undefined,
          iconSource,
          iconPath,
          iconFileName
        };
      }
    }
  }

  private getIconType(icon: AvatarIcon | undefined): IconType {
    if (!icon) return defaultSelection;

    return icon.iconSource ? 'image' : 'icon';
  }

  private setFormState(iconType: IconType) {
    this.validators = validationState[iconType];

    this.editableControls = editableState[iconType];
    // some FormControl's use disabled rather than readOnly html property to control there editability; set these here
    const skipEvents = { emitEvent: false };
    enableControl(this.formControls.iconBackgroundColor, this.editableControls.iconBackgroundColor, skipEvents);
    enableControl(this.formControls.fileUploaded, this.editableControls.fileUploaded, skipEvents);
  }
}
