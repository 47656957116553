import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService, SanitizedError } from '@mri-platform/angular-error-handling';
import { RoutingEventsService } from '@mri-platform/shared/core';
import { EntityActionOptions } from '@ngrx/data';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { exhaustMap, map, mapTo, switchMap, take } from 'rxjs/operators';
import { ResolveContext } from './resolver-context';

interface EntitiesResolverService<T extends object> {
  loaded$: Observable<boolean>;
  getAll(options: EntityActionOptions | undefined, context: ResolveContext): Observable<T[]>;
}

/**
 * @deprecated Use `EntityQueryResolver` instead
 */
export class EntitiesResolver<T extends object> implements Resolve<boolean> {
  private errorPolicyService: ErrorPolicyService;
  private routingEvents: RoutingEventsService;
  router: Router;
  constructor(injector: Injector, private entitiesService: EntitiesResolverService<T>) {
    // we're using injector as service lookup so that future services can be acquired without having
    // to modify constructors of inheriting classes (I do NOT like this pattern! but also don't like
    // having to force inheritors having to declare constructor params that are internal details of this
    // EntitiesResolver class)
    this.errorPolicyService = injector.get(ErrorPolicyService);
    this.routingEvents = injector.get(RoutingEventsService);
    this.router = injector.get(Router);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const loaded$ = this.entitiesService.loaded$.pipe(
      exhaustMap(loaded => {
        if (loaded) {
          return of(true);
        }

        return this.routingEvents.isInitialNavigation$.pipe(
          take(1),
          switchMap(isInitialNav => {
            const errorOptions = {
              // prevent duplication notification
              notify: isInitialNav
            };
            return this.entitiesService.getAll(undefined, { route, state }).pipe(
              this.errorPolicyService.catchHandle(() => errorOptions),
              map(fetchResult => ({
                fetchResult,
                isInitialNav
              }))
            );
          }),
          switchMap(({ fetchResult, isInitialNav }) => {
            if (!SanitizedError.is(fetchResult)) {
              return of(fetchResult);
            }

            if (isInitialNav) {
              // navigate to a "safe" page that isn't going to error itself and cause an infinite loop
              this.router.navigateByUrl('/error');
              return NEVER;
            } else {
              // leave the user on the current page
              return throwError(fetchResult);
            }
          }),
          mapTo(true)
        );
      }),
      // make sure obserable completes
      take(1) // important: don't use `first()` as there might never be an success emission
    );
    return loaded$;
  }
}
