import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService, entityNumberIdParser } from '@mri-platform/shared/entity';
import { MetaData } from '../models/meta-data';

@Injectable({ providedIn: 'root' })
export class MetaDataEntityService extends DefaultEntityService<MetaData> {
  parseId = entityNumberIdParser;

  constructor(injector: Injector) {
    super(MetaData.entityName, injector);
  }
}
