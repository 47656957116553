import { createSelectors } from '@mri-platform/shared/entity';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { User, UserType } from '../../models';

export const selectors = createSelectors(User);

export const selectEntitiesByUserType = (userType: UserType) =>
  createSelector(selectors.selectEntities, (users: User[]) => {
    return users.filter(user => user.type === userType);
  });
export const selectEntityListByUserType = (userType?: UserType) =>
  createSelector(selectors.selectEntities, (users: User[]) => {
    const filteredUsers = userType ? users.filter(user => user.type === userType) : users;
    return {
      items: filteredUsers,
      map: (filteredUsers as User[]).reduce((map, user) => {
        map[user.username] = filteredUsers.find(x => x.username === user.username);
        return map;
      }, {} as Dictionary<User>)
    };
  });

export const userSelectors = {
  ...selectors,
  selectEntitiesByUserType,
  selectEntityListByUserType
};
