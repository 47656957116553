import { HttpErrorResponse } from '@angular/common/http';
import { ProblemDetail } from './problem-detail';

const flattenExtensionsField = (problem: ProblemDetail | undefined) => {
  if (!problem) return undefined;

  // note: flattening is a workaround for ASP.NET messing up the JSON serialization of a `ProblemDetails` object and
  // incorrectly returning an `Extensions` dictionary fields rather than flattening these values
  const extensionFields = problem['extensions'];
  if (extensionFields && typeof extensionFields === 'object') {
    Object.assign(problem, extensionFields);
    delete problem.extensions;
  }

  return problem;
};

export const getProblemDetail = (err: HttpErrorResponse): ProblemDetail | undefined =>
  err.headers.get('content-type')?.startsWith('application/problem+json;')
    ? flattenExtensionsField(typeof err.error === 'string' ? JSON.parse(err.error) : err.error)
    : undefined;
