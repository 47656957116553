import { Provider, Type } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FeatureFlagName, FeatureFlags, FeatureFlagsService } from './feature-flags-service';

function fakeFeatureFlagsService(props: { flags: FeatureFlags; enabled: boolean }): Type<FeatureFlagsService> {
  return class FakeFeatureFlagsService implements FeatureFlagsService {
    flags$ = new BehaviorSubject(props.flags).asObservable();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isEnabled$(_name: FeatureFlagName): Observable<boolean> {
      return new BehaviorSubject(props.enabled);
    }
  };
}

export const allEnabledFeatureFlagsProvider: Provider = {
  provide: FeatureFlagsService,
  useClass: fakeFeatureFlagsService({
    flags: FeatureFlags.allEnabled,
    enabled: true
  })
};
export const allDisabledFeatureFlagsProvider: Provider = {
  provide: FeatureFlagsService,
  useClass: fakeFeatureFlagsService({
    flags: FeatureFlags.allDisabled,
    enabled: false
  })
};
