import { NgModule } from '@angular/core';
import { SANITIZER_FUNC } from '@mri-platform/angular-error-handling';
import { RegisteredEntityMetaDataService } from '@mri-platform/shared/core';
import {
  DefaultDataServiceFactory,
  EntityActionFactory,
  EntityCacheAction,
  EntityCacheEffects,
  EntityCollectionReducerMethodsFactory,
  EntityCollectionServiceFactory,
  Logger,
  SaveEntitiesCanceled
} from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { AutoUndoEffects } from './auto-undo-effect';
import { AutoUndoManyEffects } from './auto-undo-many-effect';
import { CustomDefaultDataServiceFactory } from './custom-default-data-service-base';
import { CustomEntityCollectionServiceFactory } from './custom-entity-collection-service-factory';
import { CustomHttpUrlGeneratorProvider } from './custom-http-url-generator';
import { defaultEntityMetadata, DEFAULT_ENTITY_METADATA_TOKEN } from './default-entity-metadata';
import { DelegatingEntityCacheDataServiceProvider } from './delegating-entity-cache-data.service';
import { EntityCacheEffectsBugWorkaround } from './entity-cache-effects-bug-workaround';
import { ErrorLogPolicyEffects } from './error-log-policy-effect';
import {
  ExtendedEntityDefinitionProvider,
  ExtendedEntityDefinitionService
} from './extended-entity-definition.service';
import { IgnoreNgrxErrorLogger } from './ignore-ngrx-error-logger.service';
import { QueryCacheEntityActionFactory, QueryCacheEntityCollectionReducerMethodsFactory } from './query-cache';
import { sanitizeNgrxDataHttpError } from './sanitize-ngrx-data-http-error';

@NgModule({
  imports: [EffectsModule.forFeature([ErrorLogPolicyEffects, AutoUndoEffects, AutoUndoManyEffects])],
  providers: [
    CustomHttpUrlGeneratorProvider,
    ExtendedEntityDefinitionProvider,
    DelegatingEntityCacheDataServiceProvider,
    { provide: DEFAULT_ENTITY_METADATA_TOKEN, useValue: defaultEntityMetadata },
    { provide: Logger, useClass: IgnoreNgrxErrorLogger },
    { provide: SANITIZER_FUNC, useValue: sanitizeNgrxDataHttpError, multi: true },
    { provide: RegisteredEntityMetaDataService, useExisting: ExtendedEntityDefinitionService },
    { provide: EntityCollectionServiceFactory, useClass: CustomEntityCollectionServiceFactory },
    { provide: DefaultDataServiceFactory, useClass: CustomDefaultDataServiceFactory },
    { provide: EntityCollectionReducerMethodsFactory, useClass: QueryCacheEntityCollectionReducerMethodsFactory },
    { provide: EntityActionFactory, useClass: QueryCacheEntityActionFactory },
    // todo: remove this once bug in ngrx-data is fixed so that the correct actions are dispatched
    { provide: EntityCacheEffects, useClass: EntityCacheEffectsBugWorkaround }
  ]
})
export class EntityModule {
  constructor() {
    // todo: remove this once bug in ngrx-data is fixed so that the correct type is assigned
    Object.defineProperty(SaveEntitiesCanceled.prototype, 'type', {
      get: function () {
        return EntityCacheAction.SAVE_ENTITIES_CANCELED;
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      set: function () {},
      enumerable: true
    });
  }
}
