import { Injectable } from '@angular/core';
import {
  EntityAction,
  EntityActionDataServiceError,
  EntityChangeTracker,
  EntityCollection,
  EntityCollectionReducerMethodMap,
  EntityCollectionReducerMethods,
  EntityDefinition,
  EntityDefinitionService,
  EntityOp
} from '@ngrx/data';
import { EntityChangeTrackerBugWorkaround } from '../entity-tracker-bug-workaround';
import { QueryCacheEntityAction } from './query-cache-entity-action-factory';
import {
  QueryCacheEntityCollection,
  removeAllQueryEntries,
  removeOneQueryEntry,
  setLoadedQueryEntry,
  setLoadingQueryEntry
} from './query-cache-entity-collection';

class QueryCacheEntityCollectionReducerMethods<T> extends EntityCollectionReducerMethods<T> {
  readonly allMethods: EntityCollectionReducerMethodMap<T>;

  constructor(entityName: string, definition: EntityDefinition<T>, entityChangeTracker?: EntityChangeTracker<T>) {
    super(entityName, definition, entityChangeTracker);

    this.allMethods = {
      ...this.methods,
      [EntityOp.CANCELED_PERSIST]: this.cancelledPersist.bind(this)
    };
  }

  protected cancelledPersist(collection: EntityCollection<T>, action: EntityAction<T>): EntityCollection<T> {
    return removeOneQueryEntry(action.payload.correlationId, collection as QueryCacheEntityCollection<T>);
  }

  protected queryLoad(collection: EntityCollection<T>): EntityCollection<T> {
    // note: this is a hack to workaround a limitation in the API of `EntityCollectionReducerMethods`
    // eslint-disable-next-line prefer-rest-params
    const [, action] = arguments as unknown as [EntityCollection<T>, QueryCacheEntityAction<T>];

    const baseCollection = super.queryLoad(collection) as QueryCacheEntityCollection<T>;
    return setLoadingQueryEntry(action.payload, baseCollection);
  }

  protected queryLoadError(
    collection: EntityCollection<T>,
    action: EntityAction<EntityActionDataServiceError>
  ): EntityCollection<T> {
    const baseCollection = super.queryLoadError(collection, action) as QueryCacheEntityCollection<T>;
    return removeOneQueryEntry(action.payload.correlationId, baseCollection);
  }

  protected queryLoadSuccess(
    collection: EntityCollection<T>,
    action: QueryCacheEntityAction<T[]>
  ): EntityCollection<T> {
    const baseCollection = super.queryLoadSuccess(collection, action);
    return setLoadedQueryEntry(action.payload, baseCollection as QueryCacheEntityCollection<T>);
  }

  protected queryAll(collection: QueryCacheEntityCollection<T>): EntityCollection<T> {
    // note: this is a hack to workaround a limitation in the API of `EntityCollectionReducerMethods`
    // eslint-disable-next-line prefer-rest-params
    const [, action] = arguments as unknown as [EntityCollection<T>, QueryCacheEntityAction<T>];

    const baseCollection = super.queryAll(collection) as QueryCacheEntityCollection<T>;
    return setLoadingQueryEntry(action.payload, baseCollection);
  }

  protected queryAllError(
    collection: EntityCollection<T>,
    action: QueryCacheEntityAction<EntityActionDataServiceError>
  ): EntityCollection<T> {
    const baseCollection = super.queryAllError(collection, action) as QueryCacheEntityCollection<T>;
    return removeOneQueryEntry(action.payload.correlationId, baseCollection);
  }

  protected queryAllSuccess(collection: EntityCollection<T>, action: QueryCacheEntityAction<T[]>): EntityCollection<T> {
    const baseCollection = super.queryAllSuccess(collection, action) as QueryCacheEntityCollection<T>;
    return setLoadedQueryEntry(action.payload, baseCollection);
  }

  protected queryByKey(
    collection: EntityCollection<T>,
    action: QueryCacheEntityAction<number | string>
  ): EntityCollection<T> {
    const baseCollection = super.queryByKey(collection, action) as QueryCacheEntityCollection<T>;
    return setLoadingQueryEntry(action.payload, baseCollection);
  }

  protected queryByKeyError(
    collection: EntityCollection<T>,
    action: QueryCacheEntityAction<EntityActionDataServiceError>
  ): EntityCollection<T> {
    const baseCollection = super.queryByKeyError(collection, action) as QueryCacheEntityCollection<T>;
    return removeOneQueryEntry(action.payload.correlationId, baseCollection);
  }

  protected queryByKeySuccess(collection: EntityCollection<T>, action: QueryCacheEntityAction<T>): EntityCollection<T> {
    let baseCollection: EntityCollection<T>;
    if (action.payload.replaceCache) {
      const data = this.extractData(action);
      baseCollection = this.replaceCacheWithPartialData(collection, [data]);
    } else {
      baseCollection = super.queryByKeySuccess(collection, action);
    }
    return setLoadedQueryEntry(action.payload, baseCollection as QueryCacheEntityCollection<T>);
  }

  protected queryMany(collection: EntityCollection<T>, action: QueryCacheEntityAction<T>): EntityCollection<T> {
    const baseCollection = super.queryMany(collection, action) as QueryCacheEntityCollection<T>;
    return setLoadingQueryEntry(action.payload, baseCollection);
  }

  protected queryManyError(
    collection: EntityCollection<T>,
    action: QueryCacheEntityAction<EntityActionDataServiceError>
  ): EntityCollection<T> {
    const baseCollection = super.queryManyError(collection, action) as QueryCacheEntityCollection<T>;
    return removeOneQueryEntry(action.payload.correlationId, baseCollection);
  }

  protected queryManySuccess(
    collection: EntityCollection<T>,
    action: QueryCacheEntityAction<T[]>
  ): EntityCollection<T> {
    let baseCollection: EntityCollection<T>;
    if (action.payload.replaceCache) {
      const data = this.extractData(action);
      baseCollection = this.replaceCacheWithPartialData(collection, data);
    } else {
      baseCollection = super.queryManySuccess(collection, action);
    }
    return setLoadedQueryEntry(action.payload, baseCollection as QueryCacheEntityCollection<T>);
  }

  protected removeAll(collection: EntityCollection<T>, action: QueryCacheEntityAction<T>): EntityCollection<T> {
    const baseCollection = super.removeAll(collection, action) as QueryCacheEntityCollection<T>;
    return removeAllQueryEntries(baseCollection);
  }

  private replaceCacheWithPartialData(collection: EntityCollection<T>, data: T[]): EntityCollection<T> {
    return {
      ...this.adapter.setAll(data, collection),
      loaded: false,
      loading: false,
      changeState: {}
    };
  }
}

@Injectable()
export class QueryCacheEntityCollectionReducerMethodsFactory {
  constructor(private entityDefinitionService: EntityDefinitionService) {}

  create<T>(entityName: string): EntityCollectionReducerMethodMap<T> {
    const definition = this.entityDefinitionService.getDefinition<T>(entityName);
    // todo: delete this line this once bug has been fixed in ngrx-data
    const bugWorkaroundService = new EntityChangeTrackerBugWorkaround<T>(definition.entityAdapter, definition.selectId);
    const methodsClass = new QueryCacheEntityCollectionReducerMethods(entityName, definition, bugWorkaroundService);
    return methodsClass.allMethods;
  }
}
