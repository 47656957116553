import { Injectable } from '@angular/core';
import {
  ChangeSet,
  ChangeSetOperation,
  ChangeSetUpdate,
  EntityActionFactory,
  EntityCacheDataService,
  EntityCacheEffects,
  EntityOp,
  Logger,
  SaveEntities,
  SaveEntitiesSuccess
} from '@ngrx/data';
import { Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, merge, of } from 'rxjs';

@Injectable()
export class EntityCacheEffectsBugWorkaround extends EntityCacheEffects {
  constructor(
    actions: Actions,
    dataService: EntityCacheDataService,
    entityActionFactory: EntityActionFactory,
    logger: Logger
  ) {
    super(actions, dataService, entityActionFactory, logger, undefined as never);

    // replace private method that is buggy
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any).handleSaveEntitiesSuccess$ = this.handleSaveEntitiesSuccessWorkaround$.bind(this);
  }

  /** workaround bug in handleSaveEntitiesSuccess$ */
  protected handleSaveEntitiesSuccessWorkaround$(
    action: SaveEntities,
    entityActionFactory: EntityActionFactory
  ): (changeSet: ChangeSet) => Observable<Action> {
    const { url, correlationId, mergeStrategy, tag } = action.payload;
    const options = { correlationId, mergeStrategy, tag };

    return changeSet => {
      // DataService returned a ChangeSet with possible updates to the saved entities
      if (changeSet) {
        return of(new SaveEntitiesSuccess(changeSet, url, options));
      }

      // No ChangeSet = Server probably responded '204 - No Content' because
      // it made no changes to the inserted/updated entities.
      // Respond with success action best on the ChangeSet in the request.
      changeSet = action.payload.changeSet;

      // If pessimistic save, return success action with the original ChangeSet
      if (!action.payload.isOptimistic) {
        return of(new SaveEntitiesSuccess(changeSet, url, options));
      }

      // If optimistic save, avoid cache grinding by just turning off the loading flags
      // for all collections in the original ChangeSet
      const entityNames = changeSet.changes.reduce(
        (acc, item) => (acc.indexOf(item.entityName) === -1 ? acc.concat(item.entityName) : acc),
        [] as string[]
      );

      // BUGFIX starts here (replaces commented out code below)

      // return merge(
      //   entityNames.map((name) =>
      //     entityActionFactory.create(name, EntityOp.SET_LOADING, false)
      //   )
      // );

      const loadingFlagActions = entityNames.map(name => entityActionFactory.create(name, EntityOp.SET_LOADING, false));

      // tell change tracker to commit all entities
      const changeSetEntitiesMap = changeSet.changes.reduce(
        (acc, item) => {
          const entities = acc[item.entityName] ?? [];
          const moreEntities =
            item.op === ChangeSetOperation.Update
              ? (item as ChangeSetUpdate).entities.map(x => x.changes)
              : item.entities;
          return Object.assign(acc, { [item.entityName]: entities.concat(moreEntities) });
        },
        {} as { [entityName: string]: unknown[] }
      );
      const commitManyActions = Object.entries(changeSetEntitiesMap).map(([entityName, entities]) =>
        entityActionFactory.create(entityName, EntityOp.COMMIT_MANY, entities)
      );

      // tell reducers that save was successful
      const successAction = new SaveEntitiesSuccess({ ...changeSet, changes: [] }, url, options);
      return merge(loadingFlagActions, commitManyActions, [successAction]);
    };
  }
}
