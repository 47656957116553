import { createAction, props } from '@ngrx/store';
import { UserInfo } from 'angular-oauth2-oidc';
import { UserAttributes } from '../models/user';

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{
    userInfo: UserInfo;
    hasValidAccessToken: boolean;
  }>()
);

export const tokenInvalidated = createAction('[Auth] Token invalidated');

export const userProfileSuccess = createAction('[Auth/API] OIDC User Profile Success', props<{ userInfo: UserInfo }>());

export const userInfoSuccess = createAction('[Auth/API] User Info Success', props<{ attributes: UserAttributes }>());

export const impersonateClient = createAction(
  '[Auth] Impersonate MRI Client',
  props<{ impersonatedClientId?: string; rehydrated?: boolean }>()
);

export const reloadUserInfo = createAction('[Auth/API] Reload User Info');
