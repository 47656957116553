import { Provider } from '@angular/core';
import { InMemoryBackendConfig, InMemoryBackendConfigArgs } from 'angular-in-memory-web-api';
import { ApiConfig } from '../app-config/api.config';
import { environment } from '../environments/environment';

export const FakeBackendConfigFactory = (apiConfig: ApiConfig) => {
  const configs: InMemoryBackendConfigArgs = {
    apiBase: apiConfig.path,
    passThruUnknownUrl: true,
    delay: environment.integrationTest ? 5 : 1000
  };
  return configs;
};

export const FakeBackendConfigProvider: Provider = {
  provide: InMemoryBackendConfig,
  useFactory: FakeBackendConfigFactory,
  deps: [ApiConfig]
};
