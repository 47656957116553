/* eslint-disable @typescript-eslint/no-explicit-any */
// `unknown` might be a better type than any, but it's border-line and likely not worth changing at this point

import { FormArray, FormGroup } from '@angular/forms';
import { taggedNamespace } from '@mri-platform/shared/core';
import { RxState } from '@rx-angular/state';
import { distinctUntilSomeChanged } from '@rx-angular/state/selections';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchAll } from 'rxjs/operators';
import { createFormGroupProxy } from './create-form-group-proxy';

export interface FormObservableOptions<F = any, V = F> {
  /**
   * The prefix used when logging the form observables using rxjs-spy `tag`
   */
  tagPrefix?: string;
  /**
   * By default `valueChanges$` do not include disabled control values.
   * Supply true to ensure that these values are included in the
   * object emitted by `valueChanges$`
   */
  includeDisabledControlValues?: boolean;

  /**
   * Should `valueChanges$` only emit when there is a distinct change
   * in one of the form control values?
   *
   * @default true
   */
  distinctFormValueChanges?: boolean;

  /**
   * A projection function to shape the object emitted by valueChanges$
   */
  valueSelector?: (value: F) => V;
}

export interface FormObservables<T> {
  dirtyChanges$: Observable<boolean>;
  validChanges$: Observable<boolean>;
  valueChanges$: Observable<T>;
}

export const createFormObservables = <F = any, V = F>(
  form: FormGroup | FormArray,
  options: FormObservableOptions<F, V> = {}
): FormObservables<V> => {
  const { tagPrefix, includeDisabledControlValues = false, distinctFormValueChanges = true, valueSelector } = options;

  const tag = taggedNamespace(tagPrefix);

  const dirtyChanges$ = form.valueChanges.pipe(
    map(() => form.dirty),
    distinctUntilChanged(),
    tag('dirtyChanges')
  );

  const validChanges$ = form.statusChanges.pipe(
    map(status => status === 'VALID'),
    distinctUntilChanged(),
    tag('validChanges')
  );

  let valueChanges$ = includeDisabledControlValues
    ? form.valueChanges.pipe(map(_ => form.getRawValue()))
    : form.valueChanges;

  if (!Array.isArray(form.controls) && distinctFormValueChanges) {
    const controlNames = Object.keys(form.controls);
    valueChanges$ = valueChanges$.pipe(distinctUntilSomeChanged(controlNames));
  }

  valueChanges$ = valueSelector ? valueChanges$.pipe(map(valueSelector)) : valueChanges$;
  valueChanges$ = valueChanges$.pipe(tag('valueChanges'));

  return {
    dirtyChanges$,
    validChanges$,
    valueChanges$
  };
};

export interface FormState<T> {
  isDirty: boolean;
  isValid: boolean;
  model: T;
}

export const connectForm = <V = any, F = V>(
  form: FormGroup | FormArray,
  state: RxState<FormState<V>>,
  options: FormObservableOptions<F, V> = {}
) => {
  const observables = createFormObservables<F, V>(form, options);
  connectFormObservables(observables, state);
};

export const connectForm$ = <S extends FormState<V>, T = any, V = T>(
  form$: Observable<FormGroup | FormArray>,
  state: RxState<S>,
  options: FormObservableOptions<T, V> = {}
) => {
  const observables$ = form$.pipe(
    map(f => createFormObservables<T, V>(f, options)),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
  state.connect(
    'model',
    observables$.pipe(
      map(x => x.valueChanges$),
      switchAll()
    )
  );
  state.connect(
    'isDirty',
    observables$.pipe(
      map(x => x.dirtyChanges$),
      switchAll()
    )
  );
  state.connect(
    'isValid',
    observables$.pipe(
      map(x => x.validChanges$),
      switchAll()
    )
  );
  state.hold(form$, f => f.updateValueAndValidity());
};

export interface FormArrayState<T> extends FormState<T> {
  items: (T & FormGroup)[];
}

export const connectFormArray$ = <S extends FormArrayState<T>, T = any>(
  form$: Observable<FormArray>,
  state: RxState<S>,
  options: FormObservableOptions<T, T> = {}
) => {
  connectForm$(form$, state, options);

  const tag = taggedNamespace(options.tagPrefix);
  const items$ = form$.pipe(
    map(f => (f.controls as FormGroup[]).map(item => createFormGroupProxy<T>(item))),
    tag('items')
  );
  state.connect('items', items$);
};

export const connectFormObservables = <T = any>(observables: FormObservables<T>, state: RxState<FormState<T>>) => {
  const { dirtyChanges$, validChanges$, valueChanges$ } = observables;
  state.connect('model', valueChanges$);
  state.connect('isDirty', dirtyChanges$);
  state.connect('isValid', validChanges$);
};
