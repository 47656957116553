import { Provider } from '@angular/core';
import { ClaimsAuthzService, grantedAuthzResult } from '@mri-platform/resource-authz';

export const createFakeClaimsAuthService = (granted: boolean): Pick<ClaimsAuthzService, 'checkAccess'> => ({
  checkAccess: () => (granted ? grantedAuthzResult : { isGranted: false, reasons: [] })
});

export const fakeGrantedClaimsAuthServiceProvider: Provider = {
  provide: ClaimsAuthzService,
  useValue: createFakeClaimsAuthService(true)
};
export const fakeDeniedClaimsAuthServiceProvider: Provider = {
  provide: ClaimsAuthzService,
  useValue: createFakeClaimsAuthService(false)
};
