import { ChangeSet, ChangeSetItem, changeSetItemFactory as cif, excludeEmptyChangeSetItems } from '@ngrx/data';
import { IdSelector, Update } from '@ngrx/entity';

export interface SaveEntitiesChanges<T> {
  deletes?: T[];
  inserts?: T[];
  updates?: T[];
  upserts?: T[];
}

export function changeSetFactory<T>(
  entityName: string,
  selectId: IdSelector<T>,
  toUpdate: (entity: Partial<T>) => Update<T>
) {
  return <E = unknown>(
    { deletes = [], inserts = [], updates = [], upserts = [] }: SaveEntitiesChanges<T>,
    tag?: string,
    extras?: E
  ): ChangeSet => {
    const changes: ChangeSetItem[] = [
      cif.add(entityName, inserts),
      cif.delete(
        entityName,
        deletes.map(d => selectId(d) as string)
      ),
      cif.update(
        entityName,
        updates.map(u => toUpdate(u))
      ),
      cif.upsert(entityName, upserts)
    ];
    return excludeEmptyChangeSetItems({ changes, tag: tag || `Save ${entityName}(s) data`, extras });
  };
}

export const isChangeSet = <T>(value: ChangeSet | SaveEntitiesChanges<T>): value is ChangeSet =>
  (value as ChangeSet).changes != null;
