import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorPolicyService } from '@mri-platform/angular-error-handling';
import { ApiConfig } from '@mri-platform/shared/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { File, FileType, UserSelection } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private http: HttpClient,
    private apiConfig: ApiConfig,
    private errorPolicyService: ErrorPolicyService
  ) {}

  downloadFile(fileName: string, fileId?: number, extension?: string) {
    this.download(fileName, `${this.apiConfig.url}/files/${fileId}`, extension);
  }

  downloadFileByFileName(fileName: string, extension?: string) {
    const name = fileName.split('.').slice(0, -1).join('.');
    const params = { name: fileName };
    // TODO: Optimize this by reusing function
    this.http
      .get(`${this.apiConfig.url}/files`, {
        responseType: 'blob',
        params
      })
      .subscribe(data => this.generateFile(name, data, extension), this.errorPolicyService.catchHandle());
  }

  private download(fileName: string, url: string, extension?: string) {
    this.http
      .get(url, { responseType: 'blob' })
      .subscribe(data => this.generateFile(fileName, data, extension), this.errorPolicyService.catchHandle());
  }

  findFileType(destination: string) {
    return destination ? destination.split('.').pop() : FileType.Csv;
  }

  downloadConnectionFields(fileName: string, params: { [key: string]: string }) {
    this.http
      .get(`${this.apiConfig.url}/files`, {
        responseType: 'blob',
        params
      })
      .subscribe(data => this.generateFile(fileName, data), this.errorPolicyService.catchHandle());
  }

  downloadFileById(fileId: number, fileName: string) {
    const url = `${this.apiConfig.url}/files/downloadFileById/${fileId}`;
    this.download(fileName, url);
  }

  downloadAlterValueFile(fileId?: number, fileName?: string) {
    if (fileId && fileName) return this.downloadFileById(fileId, fileName);
    const url = `${this.apiConfig.url}/files/downloadAlterValueSampleFile`;
    this.download('AlterMappingSampleFile', url);
  }

  private generateFile(fileName: string, data: Blob, extension: string = FileType.Csv) {
    const blob = new Blob([data], { type: `text/${extension}` });
    saveAs(blob, `${fileName}.${extension}`);
  }

  downloadJobLogerror(fileName: string, fileId?: number, extension?: string) {
    this.download(fileName, `${this.apiConfig.url}/files/downloaderrorrecords/${fileId}`, extension);
  }

  updateFile(fileId: number, body: UserSelection): Observable<File> {
    const url = `${this.apiConfig.url}/files/${fileId}`;
    return this.http.put<File>(url, body).pipe(this.errorPolicyService.catchHandleReplace(null as never));
  }
}
