/**
 * Creates an array of unique values that is the symmetric difference of the provided arrays.
 *
 * @param arrays The arrays to inspect.
 * @return Returns the new array of values.
 */
export function xor<T>(...arrays: Array<Array<T>>): T[] {
  if (arrays.length !== 2) {
    throw new Error('Use lodash-es/xor not this naive implementation!');
  }

  const left = arrays[0];
  const right = arrays[1];
  return [...left.filter(x => !right.includes(x)), ...right.filter(x => !left.includes(x))];
}
