import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavCardInfo } from '../../view-models';

@Component({
  selector: 'mri-ie-nav-card',
  template: `
    <kendo-card width="100%" class="mri-ph-large mri-pv-medium" (click)="navigateTo.emit(navCardInfo.link)">
      <div class="title-container mri-mb-medium">
        <mri-icon [icon]="navCardInfo.icon" size="large"></mri-icon>
        <span class="mri-ml-medium nav-card-title">{{ navCardInfo.title }}</span>
      </div>
      <span class="nav-card-description">{{ navCardInfo.description }}</span>
    </kendo-card>
  `,
  styleUrls: ['./nav-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavCardComponent implements OnInit {
  @Input() navCardInfo!: NavCardInfo;

  @Output() navigateTo = new EventEmitter<string>();

  ngOnInit() {
    if (this.navCardInfo == null) {
      throw new Error('`navCardInfo` cannot be null/undefined');
    }
  }
}
