import { generateNextId, isCollectionNumeric } from './id-generators';
import { CollectionWithMetadata, IdGenerator, Metadata, MetadataFactory, ResourceKeys } from './interfaces';
import { parseCompositeId } from './parse-composite-id';

const pick = <T>(keyFields: Array<keyof T>) => (item: T) => keyFields
  .reduce((result: ResourceKeys, key: keyof T) => Object.assign(result, { [key]: item[key] }), {});

const setId = <T>(item: T, key: ResourceKeys) => ({ ...item, ...key });

/**
 * Return a metadata factory that will generate the metadata of a given
 * in-memory-api collection using the given `keyField`
 * @param fieldName the name of the key field for the entity
 * @param generator the generator for the new entity instances (defaults to `generateNextId`)
 */
export const metadataForKey = <T>(fieldName: keyof T, generator: IdGenerator<T> = generateNextId): MetadataFactory<T> => {
  return (collection: T[]): Metadata<T> => ({
    selectId: pick([fieldName]),
    setId,
    genId: (_item: any) => ({
      [fieldName]: generator(collection, fieldName)
    }),
    parseId: (value: string) => ({
      [fieldName]: isCollectionNumeric(collection, fieldName) ? +value : value
    })
  });
};


/**
 * Return a metadata factory that will generate the metadata of a given
 * in-memory-api collection using the given `keyFields`
 * @param fieldNames the names of the key fields for the entity
 */
export const metadataForKeys = <T>(fieldNames: Array<keyof T>): MetadataFactory<T> => {
  return (): Metadata<T> => ({
    selectId: pick(fieldNames),
    setId,
    genId: (_item: any) => {
      throw new Error('generation of fieldNames not implemented');
    },
    parseId: parseCompositeId(fieldNames)
  });
};

export const defaultMetadata = metadataForKey<any>('id');

export const withMetadata = <T>(metadata: Partial<Metadata<T>>) => {
  return (collection: T[]): Metadata<T> => ({
    ...defaultMetadata(collection),
    ...metadata
  })
}

export const createCollection = <T>(collection: T[], metadataFactory: MetadataFactory<T>): CollectionWithMetadata<T> => {
  const metadata = Object.assign(metadataFactory(collection), { metadataFactory });
  const asArray = function (this: T[]) { return [...this]; };
  return Object.assign(collection, { metadata, asArray });
};
