export enum EntityName {
  Client = 'Client',
  ClientSummary = 'ClientSummary',
  DataSource = 'DataSource',
  DataSourceClient = 'DataSourceClient',
  Job = 'Job',
  MetaData = 'MetaData',
  Platform = 'Platform',
  PlatformSummary = 'PlatformSummary',
  AccessToken = 'AccessToken',
  ApiConnection = 'ApiConnection',
  File = 'File',
  JobLogError = 'JobLogError',
  JobLog = 'JobLog',
  JobSchedule = 'JobSchedule',
  Mapper = 'Mapper',
  Schedule = 'Schedule',
  User = 'User',
  UserConnection = 'UserConnection',
  RefreshApiHistory = 'RefreshApiHistory',
  JobUsage = 'JobUsage',
  AuditLog = 'AuditLog'
}
