import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService, EntityIdType } from '@mri-platform/shared/entity';
import { UserConnection } from '../models';
import { userConnectionSelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class UserConnectionsEntityService extends DefaultEntityService<UserConnection> {
  constructor(injector: Injector) {
    super(UserConnection.entityName, injector);
  }

  entitiesByUserId$(id: EntityIdType) {
    return this.store.select(userConnectionSelectors.selectEntitiesForUserId(id));
  }
}
