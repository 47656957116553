import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { JobLogInterval } from '@mri-platform/import-export/jobs-state';

@Component({
  selector: 'mri-ie-download-job-logs',
  templateUrl: './download-job-logs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadJobLogsComponent {
  @Output() download = new EventEmitter<JobLogInterval>();
  interval = JobLogInterval.PastWeek;
  data = [JobLogInterval.PastWeek, JobLogInterval.PastMonth, JobLogInterval.PastYear];

  changeJobLogInterval(interval: JobLogInterval) {
    this.interval = interval;
  }

  downloadJobLogs() {
    this.download.emit(this.interval);
  }
}
