import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DirtyStatePromptService {
  showNavigationPrompt(): boolean {
    return confirm('You have unsaved data, are you sure you want to continue?');
  }

  showCancelPrompt(): boolean {
    return confirm('Are you sure you want to cancel? You will lose your current changes.');
  }

  showNavigationPrompt$(): Observable<boolean> {
    return of(this.showNavigationPrompt());
  }

  showCancelPrompt$(): Observable<boolean> {
    return of(this.showCancelPrompt());
  }
}
