import type { SvgIconName } from '@mri/svg-icons/dist/ts/mri-icons';

export interface DrawerLink<TMeta extends object = object> {
  metadata?: TMeta;
  text: string;
  url: string;
  isExternalLink?: boolean;
}

export interface DrawerSection<TMeta extends object = object> {
  metadata?: TMeta;
  text: string;
  links: DrawerLink<TMeta>[];
}

export type DrawerItem<TMeta extends object = object> = DrawerLink<TMeta> | DrawerSection<TMeta>;

export const isSection = (item: DrawerItem): item is DrawerSection => !!(item as DrawerSection).links;

export interface DrawerContents {
  header: string;
  links: DrawerItem[];
}

export interface MenuItem<TMeta extends object = object> {
  iconName: SvgIconName;
  title: string;
  metadata?: TMeta;
  cssClass?: string[];
}

export interface LinkMenuItem extends MenuItem {
  url: string;
  supportsToggle?: boolean;
  opener?: (url: string) => void;
}

export const isLink = (item: MenuItem): item is LinkMenuItem => !!(item as LinkMenuItem).url;

export interface DrawerMenuItem<TMeta extends object = object> extends MenuItem<TMeta> {
  links: DrawerItem<TMeta>[];
}

export const isDrawer = (item: MenuItem | DrawerItem): item is DrawerMenuItem => !!(item as DrawerMenuItem).links;

export interface ModalMenuItem<TMeta extends object = object> extends MenuItem<TMeta> {
  open: () => void;
}

export const isModal = (item: MenuItem): item is ModalMenuItem => !!(item as ModalMenuItem).open;

export const drawerContentsFromMenuItem = ({ title: header, links }: DrawerMenuItem): DrawerContents => ({
  header,
  links
});

export const nullDrawerItem: DrawerContents = {
  header: '',
  links: []
};

export interface HeaderLogo {
  url: string | null;
  text: string;
}

export interface AskAgoraMenuItem<TMeta extends object = object> extends MenuItem<TMeta> {
  baseApiUrl: string;
  assetSource?: string;
  //putting this in to avoid the of the front-end to constantly call a service method to do the component type check
  isAskAgora: boolean;
}

export const isAskAgoraComponent = (item: MenuItem): item is AskAgoraMenuItem => (item as AskAgoraMenuItem).isAskAgora;
