import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { isNotNullOrUndefined } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { Client } from '../models';
import { ClientEntityService } from './client-entity.service';

@Injectable({ providedIn: 'root' })
export class ClientResolver implements Resolve<Client> {
  constructor(private authService: AuthService, private clientEntityService: ClientEntityService) {}

  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<Client> {
    return this.authService.currentClientId$.pipe(
      isNotNullOrUndefined(),
      switchMap(licenseId => this.clientEntityService.entitiesByQuery$({ licenseId }).pipe(map(clients => clients[0]))),
      first()
    );
  }
}
