import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OAuthModule, OAuthModuleConfig as LibOAuthModuleConfig } from 'angular-oauth2-oidc';
import { ImpersonateClientEffects, LogoutEffects } from './effects';
import { ImpersonatedClientIdHttpInterceptor } from './impersonated-client-id-http-interceptor';
import { OAuthModuleConfig } from './oauth-module.config';
import * as fromAuth from './reducers/auth.reducer';
import { TryLoginInitializerProvider } from './try-login-initializer.provider';

@NgModule({
  imports: [
    OAuthModule.forRoot(),
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    EffectsModule.forFeature([ImpersonateClientEffects, LogoutEffects])
  ]
})
export class WfeProxyOidcModule {
  static forRoot(config: OAuthModuleConfig | null = null): ModuleWithProviders<WfeProxyOidcModule> {
    return {
      ngModule: WfeProxyOidcModule,
      providers: [
        TryLoginInitializerProvider,
        { provide: HTTP_INTERCEPTORS, useClass: ImpersonatedClientIdHttpInterceptor, multi: true },
        { provide: OAuthModuleConfig, useValue: config },
        { provide: LibOAuthModuleConfig, useExisting: OAuthModuleConfig }
      ]
    };
  }
}
