import type { SortDescriptor } from '@progress/kendo-data-query';
import type { SelectEvent } from '@progress/kendo-angular-layout';
import { map, filter, scan, withLatestFrom, exhaustMap, tap, mapTo } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export function createSortDescriptor<T>(key: keyof T, direction: 'asc' | 'desc'): SortDescriptor {
  return {
    field: key as string,
    dir: direction
  };
}

export function distinctUntilTabChanged() {
  return (tabChanges$: Observable<SelectEvent>) =>
    tabChanges$.pipe(
      scan(
        ([before, prev], curr) => [
          prev && prev.isDefaultPrevented() ? before : prev, // return previous uncancelled event
          curr
        ],
        [] as SelectEvent[]
      ),
      filter(([prev, curr]) => prev === undefined || prev.index !== curr.index),
      map(([, curr]) => curr)
    );
}

export function guardTabChange(dirty$: Observable<boolean>, prompt: () => Observable<boolean>) {
  return (tabChanges$: Observable<SelectEvent>) =>
    tabChanges$.pipe(
      withLatestFrom(dirty$, (change, dirty) => ({ change, dirty })),
      exhaustMap(({ change, dirty }) => {
        if (!dirty) {
          return of(change.index);
        }

        return prompt().pipe(
          tap(accept => {
            if (!accept) {
              // cancel actual tab change
              change.preventDefault();
            }
          }),
          filter(accept => accept),
          mapTo(change.index)
        );
      })
    );
}

export function tabChange(dirty$: Observable<boolean>, prompt: () => Observable<boolean>) {
  return (tabChanges$: Observable<SelectEvent>) =>
    tabChanges$.pipe(distinctUntilTabChanged(), guardTabChange(dirty$, prompt));
}
