import { ApiConnection, ConnectionMode, ConnectionVerb, LicenseType } from '../models';

const baseExample: ApiConnection = {
  id: '1',
  name: 'ApiConnection',
  displayName: 'ApiConnection 1',
  mode: ConnectionMode.Import,
  verb: ConnectionVerb.Post,
  platformId: 2,
  clientId: 3,
  userName: 'ImpExp1@mrisoftware.com',
  databaseId: 1,
  isEnabled: true,
  licenseType: LicenseType.Standard,
  platformLicenseType: LicenseType.None,
  platform: {
    id: 1,
    identifier: 'ide',
    name: 'Sample Platform',
    oAuth2ClientId: 'auth',
    oAuth2Secret: 'sec',
    tokenProviderUrl: 'token',
    uri: 'uri',
    isEnrolled: true,
    concurrencyLimit: 20,
    isBatchingSupported: true,
    username: '',
    certificatePassword: '',
    certificateFile: [],
    clientId: 1,
    clientLicenseId: 'MRIQWEB',
    environmentId: 'sample_environment_id'
  }
};

export const createApis = (list: Partial<ApiConnection>[]): ApiConnection[] =>
  list.map((data, i) => ({ ...baseExample, ...data, displayName: data.displayName? data.displayName : `ApiConnection_${data.mode}_${i + 1}`, id: (i + 1).toString(), tableName: data.displayName? data.displayName : `ApiConnection_${data.mode}_${i + 1}` }));
