import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@mri-platform/ui-icon';
import { DatalistActionsBtnDirective } from './datalist-actions-btn.directive';
import { DatalistActionsIconBtnComponent } from './datalist-actions-icon-btn.component';
import { DatalistAddBtnDirective } from './datalist-add-btn.directive';
import { DatalistAddIconBtnComponent } from './datalist-add-icon-btn.component';
import { DatalistErrorDirective } from './datalist-error.directive';
import { DatalistErrorWithDataDirective } from './datalist-errorwithdata.directive';
import { DatalistFooterComponent } from './datalist-footer.component';
import { DatalistFooterDirective } from './datalist-footer.directive';
import { DatalistItemDataComponent } from './datalist-item-data.component';
import { DatalistItemDescriptionDirective } from './datalist-item-description.directive';
import { DatalistItemHeaderComponent } from './datalist-item-header.component';
import { DatalistItemIconDirective } from './datalist-item-icon.directive';
import { DatalistItemToolbarDirective } from './datalist-item-toolbar.directive';
import { DatalistItemDirective } from './datalist-item.directive';
import { DatalistMetadataDirective } from './datalist-metadata.directive';
import { DatalistRemoveBtnDirective } from './datalist-remove-btn.directive';
import { DatalistRemoveIconBtnComponent } from './datalist-remove-icon-btn.component';
import { DatalistSearchBoxComponent } from './datalist-search-box.component';
import { DatalistSearchDirective } from './datalist-search.directive';
import { DatalistStatisticsDirective } from './datalist-statistics.directive';
import { DatalistComponent } from './datalist.component';
import { LoadingErrorBannerComponent } from './loading-error-banner.component';

const COMPONENTS = [
  DatalistItemIconDirective,
  DatalistItemHeaderComponent,
  DatalistComponent,
  DatalistItemDirective,
  DatalistItemDescriptionDirective,
  DatalistItemDataComponent,
  DatalistSearchDirective,
  DatalistStatisticsDirective,
  DatalistErrorDirective,
  DatalistMetadataDirective,
  DatalistItemToolbarDirective,
  DatalistErrorWithDataDirective,
  DatalistFooterComponent,
  DatalistFooterDirective,
  DatalistAddIconBtnComponent,
  DatalistRemoveIconBtnComponent,
  DatalistActionsIconBtnComponent,
  DatalistAddBtnDirective,
  DatalistRemoveBtnDirective,
  DatalistActionsBtnDirective,
  DatalistSearchBoxComponent
];

@NgModule({
  imports: [CommonModule, ScrollingModule, UiIconModule],
  exports: COMPONENTS,
  declarations: [LoadingErrorBannerComponent, ...COMPONENTS],
  providers: []
})
export class UiDatalistModule {}

export { DatalistItemHeaderComponent, DatalistComponent, DatalistItemDataComponent };
