import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'mri-datalist-item-header',
  template: `
    <span class="mri-datalist__item-title mri-datalist__item-truncated-text">{{ title }}</span>
    <span *ngIf="subtitle" class="mri-datalist__item-subtitle mri-datalist__item-truncated-text">{{ subtitle }}</span>
  `,
  styles: [
    `
      .mri-datalist__item-subtitle {
        color: var(--mri-color-text-datalist-item-subtitle, inherit);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatalistItemHeaderComponent {
  @Input() title: string | undefined;
  @Input() subtitle: string | undefined;

  @HostBinding('class.mri-datalist__item-header') readonly hostClass = true;
}
