import {
  createEntitiesGroupedBySelector,
  createEntityListForIdSelector,
  createSelectors
} from '@mri-platform/shared/entity';
import { RefreshApiHistory } from '../../models';

const selectors = createSelectors(RefreshApiHistory);
const selectEntitiesByPlatformMap = createEntitiesGroupedBySelector(selectors.selectEntities, 'platformId');
const selectEntitiesByPlatformId = createEntityListForIdSelector(selectEntitiesByPlatformMap, selectors.selectId);

export const refreshApiHistorySelectors = {
  ...selectors,
  selectEntitiesByPlatformMap,
  selectEntitiesByPlatformId
};
