import { combineReducers } from '@ngrx/store';
import * as fromState from './view-state.reducer';

export const viewStateFeatureKey = 'viewState';

export interface ViewState {
  [fromState.viewStateFeatureKey]: fromState.State;
}

export const reducer = combineReducers({
  [fromState.viewStateFeatureKey]: fromState.reducer
});
