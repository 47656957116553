import { FactoryProvider, Optional } from '@angular/core';
import { ApiConfig, environment, FAKE_AUTH_ISSUER, HttpEndpoint, Settings } from '@mri-platform/shared/core';
import { AuthConfig, IsAuthzAppUserPredicate } from '@mri/angular-wfe-proxy-oidc';
import { IS_AUTHZ_APP_USER_PREDICATE } from './is-authz-app-user-injection-token';

const getAppUserInfoEndpoint = (settings: Settings, apiConfig: ApiConfig) => {
  if (settings.auth.appUserInfoEndpoint === null) {
    return undefined;
  } else if (settings.auth.appUserInfoEndpoint === undefined) {
    return HttpEndpoint.getUrl({ baseUrl: apiConfig.url, path: 'userinfo' });
  } else {
    return HttpEndpoint.getUrl({ baseUrl: apiConfig.baseUrl, path: settings.auth.appUserInfoEndpoint });
  }
};

const AuthConfigFactory = (
  settings: Settings,
  apiConfig: ApiConfig,
  getIsAuthorizedAppUser?: IsAuthzAppUserPredicate
): AuthConfig => {
  const safeWindow = window || { location: { origin: '' } };

  const defaults: AuthConfig = {
    redirectUri: safeWindow.location.origin,
    scope: `openid profile email${settings.auth.implicitFlow ? '' : ' offline_access'}`,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    // silentRefreshRedirectUri: safeWindow.location.origin + '/silent-refresh.html',
    getIsAuthorizedAppUser
  };

  const cypressSettings: AuthConfig = {
    issuer: FAKE_AUTH_ISSUER
  };

  const finalSettings: AuthConfig = {
    ...defaults,
    ...settings.auth,
    appUserInfoEndpoint: getAppUserInfoEndpoint(settings, apiConfig),
    ...(environment.integrationTest ? cypressSettings : {})
  };

  return new AuthConfig(finalSettings);
};

export const AuthConfigProvider: FactoryProvider = {
  provide: AuthConfig,
  useFactory: AuthConfigFactory,
  deps: [Settings, ApiConfig, [new Optional(), IS_AUTHZ_APP_USER_PREDICATE]]
};
