import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { UserPreference } from '../models';
import { UserPreferenceEntityService } from './user-preference-entity.service';

@Injectable({ providedIn: 'root' })
export class UserPreferenceResolver implements Resolve<UserPreference> {
  constructor(private userEntityService: UserPreferenceEntityService) {}
  resolve() {
    return this.userEntityService.get();
  }
}
