import { QueryEntityActionPayload } from './query-cache-entity-action-factory';

export interface QueryEntry {
  id: string;
  queryHash: string;
  loaded: boolean;
  loading: boolean;
  lastModified: string;
}

function assertQueryHash(value: string | undefined): asserts value is string {
  if (typeof value === 'string') return;
  else throw new Error('queryHash not set');
}

export const createQueryEntry = <P>(
  { queryHash, correlationId }: QueryEntityActionPayload<P>,
  props: Pick<QueryEntry, 'loaded' | 'loading'>
): QueryEntry => {
  assertQueryHash(queryHash);

  return {
    queryHash: queryHash,
    id: correlationId,
    lastModified: new Date().toISOString(),
    ...props
  };
};

export const createLoadingQueryEntry = <P>(payload: QueryEntityActionPayload<P>) =>
  createQueryEntry(payload, {
    loaded: false,
    loading: true
  });

export const createLoadedQueryEntry = <P>(payload: QueryEntityActionPayload<P>) =>
  createQueryEntry(payload, {
    loaded: true,
    loading: false
  });
