import { Injectable } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { EnrollmentDialogComponent } from '../components/enrollment/enrollment-dialog.component';

@Injectable({ providedIn: 'root' })
export class EnrollmentDialogService {
  constructor(private dialogService: DialogService) {}

  showEnrollConfirmationDialog() {
    const dialog = this.dialogService.open({
      title: 'Please enroll in a database',
      content: 'You are not enrolled in any databases, please enroll in a database before continuing.',
      actionsLayout: 'end',
      actions: [{ text: 'Cancel' }, { text: 'Continue', themeColor: 'primary' }]
    });
    return dialog.result;
  }

  openEnrollmentDialog(platformId?: number, platformName?: string) {
    const dialog = this.dialogService.open({
      title: `Enrollment${platformName ? ` - ${platformName}` : ''}`,
      content: EnrollmentDialogComponent,
      minWidth: '50%',
      maxWidth: '60%'
    });
    dialog.dialog.location.nativeElement.classList.add('enrollment-dialog');
    const enrollmentDialogComponent = dialog.content.instance as EnrollmentDialogComponent;
    enrollmentDialogComponent.platformId = platformId;
    return dialog.result;
  }
}
