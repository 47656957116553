import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FileType, FileTypeModel } from '@mri-platform/import-export/common-state';
import { connectForm, FormModel, FormState } from '@mri-platform/shared/common-ui';
import { RxState } from '@rx-angular/state';
import { asapScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

type ComponentState = FormState<FileTypeModel>;

// type PublicState = Pick<ComponentState, 'xxx' | 'yyy'>;

// interface Projections { }

// type ViewModel = PublicState & Projections;

// const initialPublicState: PublicState = { };

const initialState: ComponentState = {
  isDirty: false,
  isValid: false,
  // OK(ish) as by the time our component is initialized, model will be assigned
  model: undefined as never
};

const initialFormState: FormModel<FileTypeModel> = {
  fileType: ['', Validators.required]
};

@Component({
  selector: 'mri-ie-file-type-selection',
  templateUrl: './file-type-selection.component.html',
  providers: [RxState],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileTypeSelectionComponent {
  @Input() set model(value: FileTypeModel) {
    const { fileType } = value ?? { fileType: '' };
    this.state.set({ model: { fileType } });
    this.form.reset(undefined, { emitEvent: false });
    this.form.patchValue({ fileType });
    if (value && value.fileType) {
      this.form.controls.fileType.markAsDirty();
    }
  }

  @Input() fileTypes: FileType[] = [FileType.Csv, FileType.Xlsx];
  form = this.createForm();

  @Output() dirtyChanges = this.state.select('isDirty').pipe(observeOn(asapScheduler));
  @Output() validChanges = this.state.select('isValid').pipe(observeOn(asapScheduler));
  @Output() valueChanges = this.state.select('model');
  @Output() selectionChanged = new EventEmitter();

  constructor(private fb: FormBuilder, private state: RxState<ComponentState>) {
    // Set initial state in RxState
    this.state.set(initialState);

    // Connect any observable-driven items to state for items in ComponentState...

    connectForm(this.form, this.state, { tagPrefix: FileTypeSelectionComponent.name });

    // Create projections (calculations from ComponentState)...
    // none

    // Create ViewModel (Projections + PublicState)...
    // none

    // side effects if any...
    // none
  }

  createForm() {
    return this.fb.group(initialFormState);
  }
}
