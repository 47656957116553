/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityOp, QueryParams } from '@ngrx/data';
import { EntityIdType, partialAsSerializable, toQueryParams } from './entity-functions';

const ALL_ENTITIES_QUERY_HASH = '{}';
const isQueryParams = <T>(value: Partial<T> | QueryParams): value is QueryParams =>
  Object.values(value).every(v => typeof v === 'string');
export const getQueryHash = <T>(
  data: string | number | Partial<T> | QueryParams | undefined | any,
  entityOp: EntityOp
): string | undefined => {
  if (entityOp === EntityOp.QUERY_ALL || entityOp === EntityOp.QUERY_LOAD) {
    return ALL_ENTITIES_QUERY_HASH;
  } else if (entityOp === EntityOp.QUERY_MANY) {
    let hashObj: string | number | QueryParams;
    if (typeof data === 'number') {
      hashObj = +data;
    } else if (typeof data === 'string') {
      hashObj = data;
    } else if (data == null) {
      hashObj = '';
    } else if (isQueryParams(data)) {
      hashObj = data;
    } else {
      const partial = partialAsSerializable(data);
      hashObj = toQueryParams(partial, s => s.toLowerCase());
    }
    return `${EntityOp.QUERY_MANY}:${JSON.stringify(hashObj)}`;
  } else if (entityOp === EntityOp.QUERY_BY_KEY) {
    return `${EntityOp.QUERY_BY_KEY}:${data ?? ''}`;
  } else {
    return undefined;
  }
};

interface QueryDefData<T> {
  /**
   * The ngrx-data store action that the `queryParams` should be
   * dispatched with
   */
  readonly entityOp: EntityOp;
  /**
   * The query in the original format supplied when
   * this instance of a `QueryDef` was created.
   *
   * Typically, this will be used to match entities
   * already in the entity collection
   */
  readonly query: string | number | Partial<T>;

  /**
   * A query string, or a map of key and string value pairs
   * that will be sent to the server as the query string
   */
  readonly queryParams?: QueryParams | string;
}

/**
 * The definition of a query that maybe sent to the server
 * to populate the entity collection, matched against
 * entities already in the entity collection, or both.
 *
 * @example
 * ```
 * // Send a query to the server to pouplate the entity collection
 *
 * const queryDef = QueryDef.getWithQuery<Person>({ firstName: 'Christian', age: 46 });
 * entityService.getWithQueryDef(queryDef);
 * ```
 *
 * @example
 * ```
 * // Match query against entities already local in the entity collection
 *
 * const queryDef = QueryDef.getWithQuery<Person>({ firstName: 'Christian', age: 46 });
 * entityService.entitiesByQuery$(queryDef);
 * ```
 *
 * @example
 * ```
 * // Send a query to the server to pouplate the entity collection, and then
 * // match this same query against entities in the entity collection
 *
 * const queryDef = QueryDef.getWithQuery<Person>({ firstName: 'Christian', age: 46 });
 * entityService.setWithQuery$(queryDef);
 * ```
 *
 * @see `getWithQueryDef`
 */
export class QueryDef<T = never> implements QueryDefData<T> {
  readonly entityOp: EntityOp;
  readonly query: string | number | Partial<T>;
  /**
   * A hash value of this instance that can be used to identify equivalent instances
   */
  readonly queryHash: string;
  readonly queryParams?: QueryParams | string;

  /**
   * A query that represents a call to `getAll` on an ngrx-data `EntityCollectionService`
   */
  static getAll<T>() {
    return new QueryDef<T>({ entityOp: EntityOp.QUERY_ALL, query: {} as Partial<T> });
  }

  /**
   * A query that represents a call to `load` on an ngrx-data `EntityCollectionService`
   */
  static loadAll<T>() {
    new QueryDef({ entityOp: EntityOp.SET_LOADED, query: {} as Partial<T> });
  }

  /**
   * Is this a query that returns all entities?
   */
  get isGetAll() {
    return this.entityOp === EntityOp.QUERY_ALL;
  }

  /**
   * Is this a query that returns an entity by it's key?
   */
  get isGetByKey() {
    return this.entityOp === EntityOp.QUERY_BY_KEY;
  }

  /**
   * Is this a query that matches to zero to many entities?
   */
  get isGetWithQuery() {
    return this.entityOp === EntityOp.QUERY_MANY;
  }

  /**
   * Is this a query that returns all entities?
   *
   * Unlike `isGetAll`, this query is meant to replace all entities
   * already in the entity collection
   */
  get isLoadAll() {
    return this.entityOp === EntityOp.QUERY_LOAD;
  }

  private constructor({ entityOp, query, queryParams }: QueryDefData<T>) {
    this.entityOp = entityOp;
    this.query = query;
    this.queryParams = queryParams;
    const hashObj = queryParams == null ? query : queryParams;
    this.queryHash = getQueryHash(hashObj, entityOp) ?? '';
  }

  /**
   * Convenience method that will create a `QueryDef` instance
   * using `QueryDef.getWithQuery`.
   *
   * Equivalent to:
   * `query instanceof QueryDef ? query : QueryDef.getWithQuery(query)`
   * @param query a `QueryDef` or query parameter object
   */
  static from<Q>(query: QueryDef<Q> | Partial<Q>) {
    if (query instanceof QueryDef) {
      return query;
    } else {
      return QueryDef.getWithQuery<Q>(partialAsSerializable(query));
    }
  }

  /**
   * Create the defintion of a query that will returns an entity by it's key
   * @param key the key of the entity to query for
   */
  static getByKey<Q>(key: EntityIdType) {
    return new QueryDef<Q>({ entityOp: EntityOp.QUERY_BY_KEY, query: key });
  }

  /**
   * Create the defintion of a query that matches zero to many entities.
   *
   * *IMPORTANT*: this query is typically only intended to be executed against the server.
   * If you intended this query to be used to locally match entities in the entity collection,
   * then consider using `getWithSearchQuery` instead
   *
   * @param queryParams the query string to be sent to the server
   */
  static getWithQuery<Q>(queryParams: string): QueryDef<Q>;
  /**
   * Create the definition of a query that matches zero to many entities.
   *
   * The `queryParams` argument supplied will be assgined "as is" to the `query` field and
   * will be converted to a map of key and string value pairs and assigned to the `queryParams`
   * field
   *
   * @param queryParams the query parameters that will be used to match against entities
   */
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  static getWithQuery<Q>(queryParams: Partial<Q>): QueryDef<Q>;
  static getWithQuery<Q>(queryParams: string | Partial<Q>): QueryDef<Q> {
    const serializableQuery = typeof queryParams === 'string' ? queryParams : partialAsSerializable(queryParams);
    return new QueryDef({
      entityOp: EntityOp.QUERY_MANY,
      query: queryParams,
      queryParams: toQueryParams(serializableQuery)
    });
  }

  /**
   * Search the entities against the `searchTerm` supplied
   * @param searchTerm the search string to match entities against
   * @param parameterName the HTTP query param name for the searchTerm value
   */
  static getWithSearchQuery<Q>(searchTerm: string, parameterName: string = 'search'): QueryDef<Q> {
    return new QueryDef<Q>({
      entityOp: EntityOp.QUERY_MANY,
      query: searchTerm,
      queryParams: `${parameterName}=${searchTerm}`
    });
  }
}
