import { EntityName } from '@mri-platform/import-export/core';

export enum AuditLogAction {
  DownloadAuditLogs = 'DownloadAuditLogs'
}
export const AuditLog = {
  entityName: 'AuditLog',
  authorization: {
    [AuditLogAction.DownloadAuditLogs]: {
      action: AuditLogAction.DownloadAuditLogs,
      resource: EntityName.AuditLog
    }
  }
};
