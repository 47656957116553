import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { BehaviorSubject, tap } from 'rxjs';
import { UserPreference } from '../models';

@Injectable({ providedIn: 'root' })
export class UserPreferenceEntityService {
  defaultUserPreference: UserPreference = {
    textLocale: 'en-us',
    numericLocale: 'en-us',
    dateLocale: 'en-us',
    timeFormat: 'h:mm a',
    timeOffset: 0,
    phoneFormat: 'On'
  } as UserPreference;
  loading$ = new BehaviorSubject<boolean>(false);
  userPreferences$ = new BehaviorSubject<UserPreference>(this.defaultUserPreference);

  constructor(
    private http: HttpClient,
    private apiConfig: DefaultDataServiceConfig
  ) {}

  get() {
    this.loading$.next(true);
    const url = `${this.apiConfig.root}/userPreference`;
    return this.http.get<UserPreference>(url).pipe(
      tap(value => {
        this.loading$.next(false);
        this.userPreferences$.next(value);
      })
    );
  }

  save(userPreference: UserPreference) {
    this.loading$.next(true);
    const url = `${this.apiConfig.root}/userPreference`;
    return this.http.post<UserPreference>(url, userPreference).pipe(
      tap(value => {
        this.loading$.next(false);
        this.userPreferences$.next(value);
      })
    );
  }
}
