import { Type } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorPolicyService, SanitizedError, muteError } from '@mri-platform/angular-error-handling';
import type { IdSelector } from '@ngrx/entity';
import { RxState } from '@rx-angular/state';
import { distinctUntilSomeChanged } from '@rx-angular/state/selections';
import { EMPTY, Observable, Subject, asapScheduler, from } from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { distinctUntilChanged, filter, map, observeOn, subscribeOn, switchMap } from 'rxjs/operators';
import { RouteComponentSelector, routerCurrentChildEntityId } from '../routing';

type EntityIdParser = ((value: string) => string) | ((value: string) => number);
type EntityId = string | number;

export interface MasterListService<Entity> {
  parseId: EntityIdParser;
  selectId: IdSelector<Entity>;
  entities$: Observable<Entity[]>;
}

export interface MasterListViewModel<Entity> {
  error: boolean;
  errorMessage?: string;
  isItemRendered: boolean;
  list: Entity[];
  loading: boolean;
  selectedItem: Entity | undefined;
  selectedItemId: EntityId | null;
}

type ItemSelector<Entity> = (list: Entity[]) => Entity | undefined;

export interface MasterListPageComponentOptions<Entity> {
  componentName: string;
  defaultItemSelector?: ItemSelector<Entity>;
  itemComponentType: Type<unknown> | RouteComponentSelector;
  listService: MasterListService<Entity>;
  newId?: EntityId;
  errorPolicy?: ErrorPolicyService;
}

type RequiredMasterListPageComponentOptions<Entity> = Required<
  Omit<MasterListPageComponentOptions<Entity>, 'errorPolicy'>
> & {
  errorPolicy?: ErrorPolicyService;
};

export abstract class MasterListBasePageComponent<
  Entity extends object,
  State extends MasterListViewModel<Entity> = MasterListViewModel<Entity>
> {
  readonly componentName: string;
  readonly selectId: IdSelector<Entity>;
  readonly vm$: Observable<State>;
  readonly options: RequiredMasterListPageComponentOptions<Entity>;

  protected readonly errorPolicy?: ErrorPolicyService;
  private itemComponent = new Subject<unknown>();
  private load = new Subject<boolean>();

  constructor(
    protected state: RxState<State>,
    protected route: ActivatedRoute,
    protected router: Router,
    configuration: MasterListPageComponentOptions<Entity>
  ) {
    this.options = this.createConfigs(configuration);
    const {
      componentName,
      defaultItemSelector,
      errorPolicy,
      itemComponentType,
      listService: { entities$, selectId, parseId }
    } = this.options;

    this.errorPolicy = errorPolicy;
    this.selectId = selectId;
    this.componentName = componentName;

    this.state.set(this.getInitialCoreState() as State);

    this.state.connect('list', entities$);
    this.state.connect(this.load, this.asLoaded);

    if (errorPolicy) {
      const loaded$ = this.load.pipe(switchMap(() => this.fetch(errorPolicy)));
      this.state.connect(loaded$, (_, result) => this.asLoadResult(result));
    }
    const selectedItemId$ = this.router.events.pipe(
      routerCurrentChildEntityId(route, itemComponentType, parseId),
      distinctUntilChanged()
    );
    this.state.connect('selectedItemId', selectedItemId$);

    // Q: why `subscribeOn(asapScheduler)`
    // A: we're causing RxState to wait to the end of the current micro task before
    // subscribing to selectedItemId$; doing this is to avoid a race condition where
    // `selectedItemId$` can emit ealier than the initial state set via `state.set`
    // has been "propogated", and therefore result in `projectSliceFn` receiving an
    // undefined initial state this workaround is potentially due to a bug in RxState
    // (first noticed after upgrading to rx-angular/state@1.3.4)
    this.state.connect('selectedItem', selectedItemId$.pipe(subscribeOn(asapScheduler)), ({ list }, id) =>
      list.find(item => selectId(item) === id)
    );

    this.state.connect('isItemRendered', this.itemComponent.pipe(map(c => c !== undefined)));

    const autoSelectItem$ = this.state.select().pipe(
      distinctUntilSomeChanged(['list', 'isItemRendered']),
      map(({ list, isItemRendered }) => ({
        list,
        isItemRendered
      })),
      filter(({ isItemRendered, list }) => !isItemRendered && list.length > 0),
      map(({ list }) => list),
      // make sure to schedule autoSelectItem navigation to execute at the end of the current event loop
      // this is to avoid the router cancelling our navigation to the item detail page
      observeOn(asapScheduler)
    );

    this.state.hold(autoSelectItem$, list => this.autoSelectItem(list, defaultItemSelector));

    this.vm$ = this.state.select().pipe(this.tagObservable('vm'));
  }

  addItem(queryParams?: { [k: string]: string }) {
    this.routeToItem(this.options.newId, queryParams);
  }

  itemComponentActivated(componentRef: unknown) {
    this.itemComponent.next(componentRef);
  }

  itemComponentDeactivated() {
    this.itemComponent.next(undefined);
  }

  loadList() {
    if (!this.errorPolicy) {
      throw new Error(
        'Calling `loadList` method requires that you supply the `errorPolicy` configuration option to the constructor'
      );
    }
    this.load.next(true);
  }

  selectItem(item?: Entity) {
    if (!item) return;

    this.routeToItem(this.selectId(item));
  }

  protected asLoaded() {
    return {
      error: false,
      errorMessage: undefined,
      loading: true
    } as Partial<State>;
  }

  protected asLoadResult(value: Entity[] | SanitizedError) {
    return {
      loading: false,
      error: SanitizedError.is(value),
      errorMessage: SanitizedError.is(value) ? value.message : undefined
    } as Partial<State>;
  }

  private autoSelectItem(list: Entity[], itemSelector: ItemSelector<Entity>) {
    const isNavigatingToItemDetail = this.route.children.length !== 0;
    if (isNavigatingToItemDetail) {
      return;
    }

    const item = itemSelector(list);
    if (item) {
      this.selectItem(list[0]);
    }
  }

  private fetch(errorPolicy: ErrorPolicyService) {
    return from(this.fetchItems()).pipe(errorPolicy.catchHandle(muteError));
  }

  /*
   * Override this method to fetch entities.
   * It's expected that the `listService.entities$` will emit the list of entities that this fetch has triggered
   * */
  protected fetchItems(): Observable<Entity[]> | Promise<Entity[]> {
    return EMPTY;
  }

  private getInitialCoreState(): MasterListViewModel<Entity> {
    return {
      error: false,
      isItemRendered: false,
      list: [],
      loading: false,
      selectedItem: undefined,
      selectedItemId: null
    };
  }

  protected routeToItem(id: EntityId, queryParams?: Params) {
    return this.router.navigate([id], { relativeTo: this.route, queryParams });
  }

  protected tagObservable(name: string) {
    return <Result>(source$: Observable<Result>) => source$.pipe(tag(`${this.componentName}-${name}`));
  }

  private createConfigs(
    options: MasterListPageComponentOptions<Entity>
  ): RequiredMasterListPageComponentOptions<Entity> {
    const defaults = {
      defaultItemSelector: (list: Entity[]) => list[0],
      newId: -1
    };
    return { ...defaults, ...options };
  }
}
