import isString from 'lodash-es/isString';
export const isEqualIgnoringCase = <T>(left: T, right: T) => {
  //note: undefined and null are treated the same here

  if (left == null && right == null) return true;
  if (left == null || right == null) return false;
  if (!isString(left) || !isString(right)) return false;

  return left.toLowerCase() === right.toLowerCase();
};
