import { Dictionary, IdSelector } from '@ngrx/entity';
import keyBy from 'lodash-es/keyBy';

export interface EntityList<T> {
  items: T[];
  map: Dictionary<T>;
}

export const emptyEntityList = <T>(): EntityList<T> => ({
  items: new Array<T>(),
  map: {}
});

export const createEntityList = <T extends Object>(items: T[], idSelector: IdSelector<T>): EntityList<T> => ({
  items,
  map: keyBy(items, idSelector)
});
