<form class="k-form k-form-horizontal" [formGroup]="form">
  <div class="row">
    <div class="col-lg-5">
      <kendo-formfield>
        <kendo-label class="text-right" [for]="fileType" text="File type"></kendo-label>
        <kendo-dropdownlist
          formControlName="fileType"
          #fileType
          [data]="fileTypes"
          class="mri-k-show-validation k-required"
          (valueChange)="selectionChanged.emit($event)"
        >
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>
  </div>
</form>
