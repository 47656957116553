import { Router } from '@angular/router';
import { ErrorPolicyService, SanitizedError } from '@mri-platform/angular-error-handling';
import { NEVER, Observable, from, of, throwError } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export function routeResolveEntities<T>(
  resultsSelector: () => Observable<T | T[]> | Promise<T | T[]>,
  router: Router,
  errorPolicyService: ErrorPolicyService,
  errorUrl = '/error'
) {
  return (isInitialNavigation$: Observable<boolean>) =>
    isInitialNavigation$.pipe(
      take(1),
      switchMap(isInitialNav => {
        const errorOptions = {
          // prevent duplication notification
          notify: isInitialNav
        };
        return from(resultsSelector()).pipe(
          errorPolicyService.catchHandle(() => errorOptions),
          map(fetchResult => ({
            fetchResult,
            isInitialNav
          }))
        );
      }),
      switchMap(({ fetchResult, isInitialNav }) => {
        //is this a valid use of 'any' since we are checking the type of fetchResult?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let fetchResultVal: any;
        if (Array.isArray(fetchResult)) {
          fetchResultVal = fetchResult as T[];
        } else {
          fetchResultVal = fetchResult as T;
        }

        if (!SanitizedError.is(fetchResultVal)) {
          return of(fetchResultVal);
        }

        if (isInitialNav) {
          // navigate to a "safe" page that isn't going to error itself and cause an infinite loop
          router.navigateByUrl(errorUrl);
          return NEVER;
        } else {
          // leave the user on the current page
          return throwError(fetchResultVal);
        }
      })
    );
}
