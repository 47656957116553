import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap, CrudAction } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';
import { MetaData } from './meta-data';
import { Platform } from './platform';

/* eslint-disable @typescript-eslint/naming-convention */

export interface ApiConnection {
  id: string;
  name: string;
  displayName: string;
  mode: ConnectionMode;
  isEnabled: boolean;
  includeDisabled?: boolean;
  refresh?: boolean;
  verb: ConnectionVerb;
  metaData?: MetaData;
  platformName?: string;
  platformId?: number;
  clientId?: number;
  userName?: string;
  databaseId?: number;
  platform: Platform;
  licenseType: LicenseType;
  platformLicenseType: LicenseType;
  tableName?: string;
  description?: string;
}

export type ApiConnectionParams = Pick<ApiConnection, 'platformId' | 'databaseId'> &
  Record<string, string | boolean | number | undefined>;

export enum ApiConnectionCustomAction {
  Refresh = 'Refresh',
  SetLicense = 'SetLicense',
  ViewPage = 'ViewPage'
}
// make ApiConnectionCustomAction "extend" CrudAction ...
export type ApiConnectionAction = ApiConnectionCustomAction | CrudAction;
export const ApiConnectionAction = { ...ApiConnectionCustomAction, ...CrudAction };
export const ApiConnection = createModelMetadata<ApiConnection>({
  entityName: 'ApiConnection',
  authorization: {
    ...AuthzContextsActionMap.crudFor(EntityName.ApiConnection),
    [ApiConnectionAction.Refresh]: {
      action: [ApiConnectionAction.Refresh, ApiConnectionAction.Read],
      resource: EntityName.ApiConnection
    },
    [ApiConnectionAction.SetLicense]: {
      action: [ApiConnectionAction.SetLicense, ApiConnectionAction.Update],
      resource: EntityName.ApiConnection
    },
    [ApiConnectionAction.ViewPage]: {
      action: [ApiConnectionAction.ViewPage],
      resource: EntityName.ApiConnection
    }
  }
});

export enum ConnectionMode {
  Export = 'Export',
  Import = 'Import',
  None = ''
}

export enum ConnectionVerb {
  Get = 'Get',
  Post = 'Post',
  Delete = 'Delete',
  Put = 'Put',
  Patch = 'Patch'
}

export enum LicenseType {
  Standard = 'Standard',
  Premium = 'Premium',
  None = 'None'
}
