/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ErrorPolicyService, forceError } from '@mri-platform/angular-error-handling';
import { EntityCacheAction, EntityOp, SaveEntitiesError, ofEntityOp } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EntityActionErrorLogPolicy, NonQueryEntityActionOptions } from './entity-action-options';
import { NonQueryEntityAction } from './non-query-entity-action';

export const collectionErrorActions: EntityOp[] = [
  EntityOp.SAVE_ADD_ONE_ERROR,
  EntityOp.SAVE_DELETE_ONE_ERROR,
  EntityOp.SAVE_UPDATE_ONE_ERROR,
  EntityOp.SAVE_UPSERT_ONE_ERROR
];

@Injectable()
export class ErrorLogPolicyEffects {
  constructor(
    private collectionActions: Actions<NonQueryEntityAction>,
    private cacheActions: Actions<SaveEntitiesError>,
    private errorPolicy: ErrorPolicyService
  ) {}

  saveManyLogAll$: Observable<any> = createEffect(
    () =>
      this.cacheActions.pipe(
        ofType(EntityCacheAction.SAVE_ENTITIES_ERROR),
        filter(action => this.extractSaveEntitiesErrorLogPolicy(action) === 'log'),
        map(action => action.payload.error),
        this.errorPolicy.handle(forceError)
      ),
    { dispatch: false }
  );

  saveManyLogErrorsOnly$: Observable<any> = createEffect(
    () =>
      this.cacheActions.pipe(
        ofType(EntityCacheAction.SAVE_ENTITIES_ERROR),
        filter(action => this.extractSaveEntitiesErrorLogPolicy(action) === 'log-errors-only'),
        map(action => action.payload.error),
        this.errorPolicy.handle()
      ),
    { dispatch: false }
  );

  saveOneLogAll$: Observable<any> = createEffect(
    () =>
      this.collectionActions.pipe(
        ofEntityOp<NonQueryEntityAction>(collectionErrorActions),
        filter(action => action.payload.errorLogPolicy === 'log'),
        map(action => action.payload.data),
        this.errorPolicy.handle(forceError)
      ),
    { dispatch: false }
  );

  saveOneLogErrorsOnly$: Observable<any> = createEffect(
    () =>
      this.collectionActions.pipe(
        ofEntityOp<NonQueryEntityAction>(collectionErrorActions),
        filter(action => action.payload.errorLogPolicy === 'log-errors-only'),
        map(action => action.payload.data),
        this.errorPolicy.handle()
      ),
    { dispatch: false }
  );

  private extractSaveEntitiesErrorLogPolicy(action: SaveEntitiesError): EntityActionErrorLogPolicy | undefined {
    const originalOptions: NonQueryEntityActionOptions = action.payload.originalAction.payload;
    return originalOptions.errorLogPolicy;
  }
}
