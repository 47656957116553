import { Injectable } from '@angular/core';
import { UserPermission } from '@mri-platform/resource-authz';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResourceAuthzSessionService {
  permissions$: Observable<UserPermission[] | undefined> = new BehaviorSubject(undefined);
}
