import { createEntitiesForIdSelector, createEntitiesGroupedBySelector } from '@mri-platform/shared/entity';
import { accessTokenSelectors } from './access-token-selectors';

const selectEntitiesByPlatformMap = createEntitiesGroupedBySelector(accessTokenSelectors.selectEntities, 'platformId');
const selectEntitiesByPlatformId = createEntitiesForIdSelector(selectEntitiesByPlatformMap);

export const platformConnectionSelectors = {
  ...accessTokenSelectors,
  selectEntitiesByPlatformMap,
  selectEntitiesByPlatformId
};
