import { RequiredModelMetadata } from '@mri-platform/shared/core';
import { EntityMetadata } from '@ngrx/data';

export type EntityMetadataExtentions<T extends object> = Pick<RequiredModelMetadata<T>, 'authorization'>;
export interface ExtendedEntityMetadata<T extends object> extends EntityMetadata<T>, EntityMetadataExtentions<T> {}

export function isExtendedEntityMetadata<T extends object>(
  value: EntityMetadata<T> | ExtendedEntityMetadata<T>
): value is ExtendedEntityMetadata<T> {
  return (value as ExtendedEntityMetadata<T>).authorization != null;
}
