import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter, tap } from 'rxjs/operators';
import { AuthManagedStorageService } from '../auth-managed-storage.service';

@Injectable()
export class LogoutEffects {
  constructor(private authService: OAuthService, private storage: AuthManagedStorageService) {}

  clearStorageOnLogout$ = createEffect(
    () =>
      this.authService.events.pipe(
        filter(evt => evt.type === 'logout'),
        tap(() => {
          this.storage.clear();
        })
      ),
    { dispatch: false }
  );
}
