import { ApiType, MetaData, PropertyType } from '../models';

const baseExample: MetaData = {
  id: 1,
  path: 'test',
  apiConnectionId: 1,
  apiType: ApiType.HeaderDetail,
  parameters: [
    {
      id: 1,
      key: 'id',
      value: 'Test1',
      label: 'fromQueryString',
      type: 'Text',
    }
  ],
  responseProperties: [
    {
      path: 'First Name',
      type: 'String',
      propertyType: PropertyType.Detail,
      isRequired: true,
      maxLength: 10
    },
    {
      path: 'Last Name',
      type: 'String',
      propertyType: PropertyType.Detail,
      isRequired: false,
      maxLength: 15
    },
    {
      path: 'Address',
      type: 'String',
      propertyType: PropertyType.Detail,
      isRequired: false
    },
    {
      path: 'Item',
      type: 'Number',
      propertyType: PropertyType.Detail,
      isRequired: true,
      maxLength: 5
    }
  ],
  requestProperties: [
    {
      path: 'First Name',
      type: 'String',
      propertyType: PropertyType.Detail,
      isRequired: true,
      maxLength: 10
    },
    {
      path: 'Last Name',
      type: 'String',
      propertyType: PropertyType.Detail,
      isRequired: false,
      maxLength: 15
    },
    {
      path: 'Address',
      type: 'String',
      propertyType: PropertyType.Detail,
      isRequired: true
    },
    {
      path: 'Item',
      type: 'Number',
      propertyType: PropertyType.Detail,
      isRequired: true,
      maxLength: 5
    }
  ]
};

export const createMetaData = (list: Partial<MetaData>[]): MetaData[] =>
  list.map((data, i) => ({ ...baseExample, ...data, id: data.id ? data.id : i + 1 }));
