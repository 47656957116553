import { Injectable } from '@angular/core';
import { DirtyStatePromptService, DirtyStateService } from '@mri-platform/shared/core';
import { AuthService } from '@mri/angular-wfe-proxy-oidc';
import { AG_LOGOUT_REASON, AgBarNotificationService, AppGatewayNavigationEvent } from '@mri/app-gateway-bar';
import { createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { exhaustMap, tap } from 'rxjs/operators';

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class AgBarEffects {
  constructor(
    private dirtyService: DirtyStateService,
    private promptService: DirtyStatePromptService,
    private authService: AuthService,
    private agBarNotifications: AgBarNotificationService
  ) {}

  navProceeding$ = createEffect(
    () =>
      this.agBarNotifications.navigation$.pipe(
        exhaustMap(
          ({
            detail: {
              context: { newTab, reason },
              navigationController: { accept, cancel }
            }
          }: AppGatewayNavigationEvent) =>
            this.shouldProceed(newTab, reason).pipe(tap(proceed => (proceed ? accept() : cancel())))
        )
      ),
    { dispatch: false }
  );

  private shouldProceed(newTab: boolean, reason: string): Observable<boolean> {
    if (reason === AG_LOGOUT_REASON) {
      return of(false).pipe(
        tap(() => {
          this.authService.logOut();
        })
      );
    } else if (newTab) {
      return of(newTab);
    } else {
      return this.dirtyService.confirmWhenDirty$(() => this.promptService.showNavigationPrompt$());
    }
  }
}
