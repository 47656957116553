import { EntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityCrudFacadeExtraState, EntityCrudFacadeState } from './entity-crud-facade-state';
import { hasEntityChanges, TrackedEntityState } from './tracked-entity-state';

export function createChildrenStateSelectors<E, C, S extends EntityCrudFacadeExtraState<S>>(adapter: EntityAdapter<C>) {
  const childrenStateSelector = createFeatureSelector<EntityCrudFacadeState<E, C, S>, TrackedEntityState<C>>(
    'childrenState'
  );

  const { selectAll } = adapter.getSelectors(childrenStateSelector);

  const selectChanges = createSelector(childrenStateSelector, state => state.changeState);

  const selectHasChanges = createSelector(selectChanges, hasEntityChanges);

  return {
    selectAll,
    selectChanges,
    selectHasChanges
  };
}
