import { RequiredModelMetadata } from '@mri-platform/shared/core';
import { ChangeSet, ChangeSetOperation, ChangeSetUpdate } from '@ngrx/data';

export function extractChangeSetEntitiesForOp(...ops: ChangeSetOperation[]) {
  return <T extends object>(model: RequiredModelMetadata<T>, changeset: ChangeSet) =>
    changeset.changes
      .filter(c => c.entityName === model.entityName && c.op !== ChangeSetOperation.Delete && ops.includes(c.op))
      .map(c =>
        c.op === ChangeSetOperation.Update ? (c as ChangeSetUpdate<T>).entities.map(u => u.changes) : c.entities
      )
      .flatMap(c => c as Partial<T>[]);
}

export const extractChangeSetEntitiesOf = extractChangeSetEntitiesForOp(
  ChangeSetOperation.Add,
  ChangeSetOperation.Update,
  ChangeSetOperation.Upsert
);
