import { APP_INITIALIZER, Provider } from '@angular/core';
import { SanitizedError } from '@mri-platform/angular-error-handling';
import { Settings } from '@mri-platform/shared/core';

export function setSanitizedErrorClientVs(settings: Settings) {
  return () => {
    const { clientVersion } = settings;
    if (clientVersion) {
      SanitizedError.setVersionInfo({ clientVersion });
    }
  };
}

export const ClientVsInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: setSanitizedErrorClientVs,
  multi: true,
  deps: [Settings]
};
