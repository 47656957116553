import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ErrorPolicyService, SanitizedError } from '@mri-platform/angular-error-handling';
import { LOCATION_TOKEN } from '@mri-platform/shared/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserEnrollmentRequest, UserEnrollmentResponse } from '../models';
import { UserEnrollmentService } from './user-enrollment.service';

@Injectable({
  providedIn: 'root'
})
export class UserEnrollmentGuard implements CanActivate {
  constructor(
    private userEnrollService: UserEnrollmentService,
    private router: Router,
    public errorPolicy: ErrorPolicyService,
    @Inject(LOCATION_TOKEN) private location: Location
  ) {}
  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    //Replace amp; with empty value
    const params = Object.entries(_next.queryParams).reduce((prev, cur) => {
      const [key, value] = cur;
      return { ...prev, [key.replace('amp;', '')]: value };
    }, {}) as UserEnrollmentRequest;

    const errorOptions = {
      // avoid kendo notification error
      notify: false
    };
    return this.userEnrollService.postUserEnroll(params).pipe(
      this.errorPolicy.catchHandle(() => errorOptions),
      map(userEnroll => {
        if (SanitizedError.is(userEnroll)) {
          this.userEnrollService.setError(userEnroll);
          this.router.navigateByUrl('/');
        } else {
          const result = userEnroll as UserEnrollmentResponse;
          this.location.href = result['url'];
        }
        return false;
      })
    );
  }
}
