import { Injectable, Injector } from '@angular/core';
import {
  EntityCollectionService,
  EntityCollectionServiceElementsFactory,
  EntityCollectionServiceFactory,
  EntitySelectors$
} from '@ngrx/data';
import { DefaultEntityService } from './default-entity-service';

@Injectable()
export class CustomEntityCollectionServiceFactory extends EntityCollectionServiceFactory {
  constructor(private injector: Injector) {
    super(injector.get(EntityCollectionServiceElementsFactory));
  }

  /**
   * Create an EntityCollectionService for an entity type
   * @param entityName - name of the entity type
   */
  create<T, S$ extends EntitySelectors$<T> = EntitySelectors$<T>>(entityName: string): EntityCollectionService<T> {
    return new DefaultEntityService<T, S$>(entityName, this.injector);
  }
}
