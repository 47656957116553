import { createModelMetadata } from '@mri-platform/shared/core';

export interface UploadFileColumn {
  columnPosition: number;
  value: string;
}

export type Cell = string | number | boolean;

export interface FileRow {
  rowNumber: number;
  columns: Cell[];
  isEmpty?: boolean;
}

export interface FileData {
  id: number;
  rows: FileRow[];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FileData = createModelMetadata<FileData>({
  entityName: 'FileData'
});
