import { ChangeSet } from '@ngrx/data';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NonQueryEntityActionOptions } from './entity-action-options';

export interface SaveEntitiesCommand {
  changeSet: ChangeSet;
  url: string;
  options: NonQueryEntityActionOptions;
}

export interface SaveEntitiesCommandResult {
  result: ChangeSet | undefined;
  originalChangeSet: ChangeSet;
  error?: unknown;
}

export function toSaveEntitiesResult(changeSet: ChangeSet) {
  return (source$: Observable<ChangeSet>): Observable<SaveEntitiesCommandResult> =>
    source$.pipe(
      map(result => ({ result, originalChangeSet: changeSet, error: undefined })),
      catchError(error => of({ result: undefined, originalChangeSet: changeSet, error }))
    );
}

export function unwrapSaveEntitiesResult() {
  return (source$: Observable<SaveEntitiesCommandResult>): Observable<ChangeSet> =>
    source$.pipe(mergeMap(({ result, error }) => (error || !result ? throwError(error) : of(result))));
}
