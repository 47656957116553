import { appendDataset } from '@mri-platform/shared/core';
import { Mapper } from '../models';
import { createMapperList } from './mappers';
import { testDb } from './test-dataset';

const mappers = [...Array(50).keys()].map(
  i =>
    ({
      name: `Sample Mapper ${i}`,
      connectionAlias: `connection/${i}`
    } as Mapper)
);

export const devDb = appendDataset(testDb, {
  mappers: {
    additionalData: mappers,
    creator: createMapperList
  }
});
