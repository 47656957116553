import { CollectionWithMetadata, Db, isCollectionWithMetadata } from 'angular-in-memory-web-api';
import cloneDeep from 'lodash-es/cloneDeep';

type Collection<T> = T[] | CollectionWithMetadata<T>;

interface CollectionUpdater<T> {
  additionalData: Partial<T>[];
  creator: (additionalData: Partial<T>[]) => T[];
}

type DatasetUpdates<DbImpl extends Db> = {
  [key in keyof Partial<DbImpl>]: DbImpl[key] extends Collection<infer T> ? CollectionUpdater<T> : never;
};

/**
 * Extend the `baseDb` for the provided keys in `updates.additionalData`.
 * Returns a **copy** of `baseDb` with the original data + the new data, passing the new combined dataset
 * through the creator function for the given entity, which should squash out any id conflicts that might
 * arise from appending the two datasets together directly. This **will** preserve collection metadata if
 * the test-dataset provides it.
 */
export function appendDataset<DbImpl extends Db>(
  baseDb: DbImpl,
  updates: DatasetUpdates<DbImpl> = {} as never
): DbImpl {
  const resultDb = Object.entries(baseDb).reduce((db, [key, originalDs]) => {
    const dataset = cloneDeep([...originalDs]);

    if (updates[key]) {
      const { creator, additionalData } = updates[key];
      const newData = creator(dataset.concat(additionalData));
      dataset.splice(0, originalDs.length, ...newData);
    }

    if (isCollectionWithMetadata(originalDs) && originalDs.metadata.metadataFactory) {
      const ds = dataset as CollectionWithMetadata<unknown>;
      ds.metadata = originalDs.metadata.metadataFactory(dataset);
      ds.asArray = originalDs.asArray;
    }

    return Object.assign(db, { [key]: dataset });
  }, {} as DbImpl);

  return resultDb;
}
