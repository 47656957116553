import { Pipe, PipeTransform } from '@angular/core';
import { ConnectionMode, ConnectionVerb } from '@mri-platform/import-export/common-state';

@Pipe({
  name: 'connectionType'
})
export class ConnectionTypePipe implements PipeTransform {
  transform(value: ConnectionVerb): ConnectionMode {
    if (value === ConnectionVerb.Get) {
      return ConnectionMode.Export;
    }
    if (value === ConnectionVerb.Post || value === ConnectionVerb.Put || value === ConnectionVerb.Patch) {
      return ConnectionMode.Import;
    }
    return ConnectionMode.None;
  }
}
