<ng-container *ngIf="vm$ | async as vm">
  <mri-shared-drawer headerTitle="Audit logs" [isOpen]="true" (closeDrawer)="closeDrawer.next()">
    <div *mriSharedDrawerBody class="container mri-ph-medium">
      <form [formGroup]="form" class="k-form k-form-horizontal">
        <mri-master-detail-header [noBackgroundColor]="true" title="Select Audit(s)"></mri-master-detail-header>
        <div class="row">
          <kendo-formfield class="col-lg-12">
            <kendo-label class="text-right" [for]="audits" text="Audit(s)"></kendo-label>
            <kendo-multiselect
              #audits
              formControlName="audits"
              mriSharedKendoRequired
              [data]="vm.auditLogOptions"
              [autoClose]="false"
              textField="text"
              valueField="value"
              (valueChange)="onValueChange()"
              class="mri-k-show-validation multiselect"
            >
              <ng-template kendoMultiSelectHeaderTemplate>
                <span class="select-all-chk mri-mt-small mri-mb-small">
                  <input
                    type="checkbox"
                    id="chk"
                    kendoCheckBox
                    [checked]="allAuditLogs"
                    [indeterminate]="isIndet"
                    (click)="selectAllAudits()"
                  />
                  <kendo-label for="chk" class="select-label">Select All</kendo-label>
                </span>
              </ng-template>

              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <span>
                  <kendo-label for="chk-{{ dataItem.value }}">
                    <input
                      type="checkbox"
                      id="chk-{{ dataItem.value }}"
                      kendoCheckBox
                      [checked]="isItemSelected(dataItem)"
                    />
                  </kendo-label>
                </span>
                <span>{{ dataItem.text }}</span>
              </ng-template>
            </kendo-multiselect>
          </kendo-formfield>
        </div>
        <mri-master-detail-header [noBackgroundColor]="true" title="Date range"></mri-master-detail-header>
        <div class="row">
          <kendo-formfield class="col-lg-12">
            <kendo-label [for]="fromDate" text="Start date" class="text-right"></kendo-label>
            <kendo-datepicker
              #fromDate
              formControlName="fromDate"
              mriAgKendoRequired
              mriIeKendoDateFormat
              class="mri-k-show-validation"
            ></kendo-datepicker>
          </kendo-formfield>
        </div>
        <div class="row">
          <kendo-formfield class="col-lg-12">
            <kendo-label [for]="toDate" text="End date" class="text-right"></kendo-label>
            <kendo-datepicker
              #toDate
              formControlName="toDate"
              mriAgKendoRequired
              mriIeKendoDateFormat
              class="mri-k-show-validation"
            ></kendo-datepicker>
          </kendo-formfield>
        </div>
      </form>
    </div>
    <mri-shared-drawer-footer-toolbar
      *mriSharedDrawerFooter
      [canCancel]="true"
      [canSave]="vm.isValid"
      (cancel)="closeDrawer.next()"
      saveLabel="Download"
      (save)="save()"
      saveIcon="download"
    ></mri-shared-drawer-footer-toolbar>
  </mri-shared-drawer>
</ng-container>
