import { Injectable, Injector } from '@angular/core';
import { EntitiesResolver } from '@mri-platform/shared/entity';
import { DataSourceClient } from '../models';
import { DataSourceClientEntityService } from './data-source-client-entity.service';

@Injectable({ providedIn: 'root' })
export class DataSourceClientsResolver extends EntitiesResolver<DataSourceClient> {
  constructor(injector: Injector, entityService: DataSourceClientEntityService) {
    super(injector, entityService);
  }
}
