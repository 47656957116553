import { ParsedRequestUrl, RequestInfoUtilities } from 'angular-in-memory-web-api';

export function parseRequestUrl(url: string, utils: RequestInfoUtilities): ParsedRequestUrl {
  if (url.includes('oktapreview.com')) {
    const parsedUrl = utils.parseRequestUrl(url);
    return {
      ...parsedUrl,
      collectionName: parsedUrl.collectionName ? `okta-${parsedUrl.collectionName}` : parsedUrl.collectionName
    };
  }

  const changeAppVisibilityUrlRegex = /\/applications\/changevisibility/;
  const portalUrlRegex = /\/products\/portals/;
  const partnerUrlRegex = /\/products\/partners/;
  const selfHostedUrlRegex = /\/products\/selfhosted/;
  if (url.match(portalUrlRegex)) {
    return utils.parseRequestUrl(url.replace(portalUrlRegex, '/portals'));
  } else if (url.match(partnerUrlRegex)) {
    return utils.parseRequestUrl(url.replace(partnerUrlRegex, '/partners'));
  } else if (url.match(selfHostedUrlRegex)) {
    return utils.parseRequestUrl(url.replace(selfHostedUrlRegex, '/selfhosted'));
  } else if (url.includes('/jobs/')) {
    return utils.parseRequestUrl(`${splitUrl(url, '/jobs/')}/jobs`);
  } else if (url.includes('/apiconnections/')) {
    return utils.parseRequestUrl(`${splitUrl(url, '/apiconnections/')}/apiconnections`);
  } else if (url.includes('/userconnections/')) {
    return utils.parseRequestUrl(`${splitUrl(url, '/userconnections/')}/userconnections`);
  } else if (url.includes('/mappers/upload')) {
    return skipInMemoryApi(utils.parseRequestUrl(url));
  } else if (url.includes('/mappers/')) {
    return utils.parseRequestUrl(`${splitUrl(url, '/mappers/')}/mappers`);
  } else if (url.includes('/uploadicon')) {
    return skipInMemoryApi(utils.parseRequestUrl(url));
  } else if (url.match(changeAppVisibilityUrlRegex)) {
    return utils.parseRequestUrl(url.replace(changeAppVisibilityUrlRegex, '/applications'));
  } else if (url.includes('/joblogs/download')) {
    return skipInMemoryApi(utils.parseRequestUrl(url));
  } else if (url.includes('/filedata')) {
    return skipInMemoryApi(utils.parseRequestUrl(url));
  } else if (url.includes('/users/')) {
    return utils.parseRequestUrl(`${splitUrl(url, '/users/')}/users`);
  } else if (url.includes('/platforms/withCertificate')) {
    return skipInMemoryApi(utils.parseRequestUrl(url));
  } else if (url.includes('/platforms/')) {
    return utils.parseRequestUrl(`${splitUrl(url, '/platforms/')}/platforms`);
  } else if (url.includes('/datasources/byclient')) {
    return utils.parseRequestUrl(`${splitUrl(url, '/datasources')}/datasources`);
  } else {
    return utils.parseRequestUrl(url);
  }
}

const splitUrl = (url: string, value: string) => url.split(value)[0];

const skipInMemoryApi = (parsed: ParsedRequestUrl): ParsedRequestUrl => ({
  ...parsed,
  collectionName: ''
});
