/* eslint-disable @typescript-eslint/no-explicit-any */
// unknown might be a better type than any here, but it's border-line useful to spend time making it so

import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import omit from 'lodash-es/omit';
import { findBySearch } from './find-by-search';

type Comparer<T> = (a: T, b: T) => number;
type IdSelectorStr<T> = (model: T) => string;
type IdSelectorNum<T> = (model: T) => number;
type IdSelector<T> = IdSelectorStr<T> | IdSelectorNum<T>;
/**
 * Filters the `entities` array argument and returns the original `entities`,
 * or a new filtered array of entities.
 * NEVER mutate the original `entities` array itself.
 **/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EntityFilterFn<T> = (entities: T[], pattern?: any) => T[];

export interface ModelMetadata<T extends object, K extends keyof T> {
  brand?: 'ModelMetatata'; // used to differentiate between EntityMetadata and ModelMetadata
  entityName: string;
  idField?: K;
  idFields?: K[];
  idSeparator?: string;
  filterFn?: EntityFilterFn<T>;
  selectId?: IdSelector<T>;
  sortComparer?: false | Comparer<T>;
  authorization?: AuthzContextsActionMap;
}

export type RequiredModelMetadata<T extends object> = Required<ModelMetadata<T, keyof T>> & {
  /**
   * return a copy `entity` with it's id field(s) removed.
   *
   * WARNING: the instance returned will NOT conform to it's interface
   * definition where that entity's id fields are defined as required
   *
   * @param entity instance to remove fields from
   */
  omitId: (entity: T) => T;
  selectIdAs: <U extends string | number>(item: T) => U;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isModelMetadata = <T extends object>(value: any): value is RequiredModelMetadata<T> =>
  value.brand === 'ModelMetatata';

export const defaultIdSeparator = '~';

const createSelectId = <T>(fields: string[], idSeparator: string): IdSelector<T> => {
  // note: "hard coding" functions to optimize perf, only using generic code as a fallback
  switch (fields.length) {
    case 1: {
      const idField1 = fields[0];
      return (item: any) => item[idField1];
    }
    case 2: {
      const idField1 = fields[0];
      const idField2 = fields[1];
      return (item: any) => `${item[idField1]}${idSeparator}${item[idField2]}`;
    }
    case 3: {
      const idField1 = fields[0];
      const idField2 = fields[1];
      const idField3 = fields[2];
      return (item: any) => `${item[idField1]}${idSeparator}${item[idField2]}${idSeparator}${item[idField3]}`;
    }
    default:
      return (item: any) => fields.map(field => item[field]).join(idSeparator);
  }
};

export const createModelMetadata = <T extends object>(
  metadata: ModelMetadata<T, keyof T>
): RequiredModelMetadata<T> => {
  const defaultIdField: any = 'id';
  const idSeparator = metadata.idSeparator ?? defaultIdSeparator;
  const idFields = metadata.idFields ?? [metadata.idField ?? defaultIdField];
  const idField: any = metadata.idField ?? idFields[0];
  const selectId = createSelectId<T>(idFields, idSeparator);
  const omitId = (entity: T) => omit(entity, ...idFields) as T;

  return {
    authorization: {},
    selectId,
    sortComparer: false,
    filterFn: findBySearch<T>(),
    ...metadata,
    brand: 'ModelMetatata',
    idField,
    idFields,
    idSeparator,
    omitId,
    selectIdAs: selectId as any
  };
};
