import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { Observable, ReplaySubject, asapScheduler, from, merge, of, race } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  observeOn,
  shareReplay,
  skip,
  skipUntil,
  switchMap,
  switchMapTo,
  withLatestFrom
} from 'rxjs/operators';
import {
  impersonateClient,
  loginSuccess,
  reloadUserInfo,
  tokenInvalidated,
  userInfoSuccess,
  userProfileSuccess
} from './actions/auth.actions';
import { AuthManagedStorageService } from './auth-managed-storage.service';
import {
  AuthConfig,
  getRequiredAuthzProxyUrl,
  getRequiredLogoutProxyUrl,
  getRequiredRedirectProxyUrl
} from './auth.config';
import { ImpersonatedClientId, LoadedUser, User, UserAttributes } from './models/user';
import * as fromAuth from './reducers/auth.reducer';
import {
  selectCurrentClientId,
  selectImpersonatedClientId,
  selectLoadedUser,
  selectUser
} from './selectors/auth.selectors';
import { UserInfoService } from './user-info.service';
import { UserPermissionService } from './user-permission.service';

interface WfeProxyState {
  source_redirect_url: string;
  authorize_url: string;
  targetUrl?: string;
}

interface WfeProxyLogoutState {
  /**
   * The URL of the actual IDP logout endpoint (eg okta)
   */
  logout_url: string;
}

function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

const wfeQueryParams = ['$mri_clientid_hint'];

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly config: Readonly<AuthConfig>;
  private loginAttemptSubject$ = new ReplaySubject<boolean>(1);
  loginAttempt$ = this.loginAttemptSubject$.asObservable();
  /**
   * Return the current user. Before the user has logged in
   * the user returned represents an anonymous user
   */
  currentUser$: Observable<User>;
  /**
   * Return whether the user is authorized to use this application.
   *
   * This observable will wait until the user is loaded before
   * returning the results of calling the `getIsAuthorizedAppUser`
   * function of the `AuthConfig` object used to configure
   * this library
   *
   * This status will NOT be re-evaluated if the `currentUser$`'s
   * access token used to call the api expires
   *
   */
  isAuthorizedAppUser$: Observable<boolean>;
  /**
   * An event that will emit the fully populated user.
   *
   * Late subscribers will receive the last value emitted.
   *
   * The user emitted will be composed of:
   * * fields returned by the oidc userinfo endpoint
   * * any fields fetched by `UserInfoService`
   * * an optional `permissions` array field fetched by `UserPermissionService`
   */
  userLoaded$: Observable<LoadedUser>;
  /**
   * Return the current MRI client id. The value returned in preference will be:
   * * the impersonated MRI client id
   * * upon loading the user: `UserAttributes.clientId`
   * * prior to the user being loaded: `undefined`
   */
  currentClientId$: Observable<string | undefined>;
  /**
   * The MRI client id that should override the client id that the user logged in with.
   *
   * Typically this will enable the scenario where an MRI support team member has logged in
   * and should be authorized to fetch the records belong to another MRI Client
   *
   * Returns `undefined` when no impersonation is in play
   */
  impersonatedClientId$: Observable<string | undefined>;
  targetUrlOnLogin?: string;

  constructor(
    private oauthService: OAuthService,
    config: AuthConfig,
    private store: Store<fromAuth.State>,
    private userInfoService: UserInfoService,
    private userPermissionService: UserPermissionService,
    private storage: AuthManagedStorageService
  ) {
    this.config = this.getConfig(config);
    this.configAuth();

    const loginAttemptSuccess$ = this.loginAttempt$.pipe(filter(success => success));

    loginAttemptSuccess$.pipe(map(_ => this.oauthService.getIdentityClaims() as UserInfo)).subscribe(userInfo => {
      store.dispatch(loginSuccess({ userInfo, hasValidAccessToken: true }));
    });

    const tokenReceived$ = this.oauthService.events.pipe(
      filter(e => ['token_received'].includes(e.type)),
      mapTo(true)
    );

    const tokens$ = merge(
      race(loginAttemptSuccess$.pipe(first()), tokenReceived$.pipe(first())),
      loginAttemptSuccess$.pipe(skip(1)),
      tokenReceived$.pipe(skip(1))
    );

    const tokenInvalidated$ = this.oauthService.events.pipe(
      filter(_ => !this.oauthService.hasValidAccessToken()),
      skipUntil(tokens$)
    );
    tokenInvalidated$.subscribe(_ => {
      store.dispatch(tokenInvalidated());
    });

    tokenReceived$
      .pipe(
        switchMap(_ => this.oauthService.loadUserProfile()),
        map(_ => this.oauthService.getIdentityClaims() as UserInfo)
      )
      .subscribe(userInfo => {
        store.dispatch(userProfileSuccess({ userInfo }));
      });

    this.currentUser$ = store.select(selectUser);

    const userAttributes$ = this.createUserAttributes$(tokens$, this.currentUser$);
    userAttributes$.subscribe(attributes => {
      store.dispatch(userInfoSuccess({ attributes }));
    });

    this.userLoaded$ = store.select(selectLoadedUser).pipe(filter(isNotNullOrUndefined));

    this.isAuthorizedAppUser$ = this.userLoaded$.pipe(
      map(this.config.getIsAuthorizedAppUser ?? (() => true)),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.impersonatedClientId$ = store.select(selectImpersonatedClientId);
    this.currentClientId$ = store.select(selectCurrentClientId);

    this.rehydrateImpersonatedClientId();
  }

  /**
   * Ensure that login has already occurred and therefore the user object is fully populated.
   * Where login has not already occurred, then initiate the login flow.
   *
   * IMPORTANT: when login has not already occurred, the browser will be redirected to the IDP
   *
   * @param targetUrl The client-side url to redirect to after the user has been authenticated
   * @returns `true` when login has already occurred, `false` otherwise
   * @see `initLoginFlow`
   */
  async ensureLoggedIn(targetUrl?: string) {
    const canAccessApi = await this.loginAttempt$
      .pipe(
        withLatestFrom(this.currentUser$),
        map(([, { hasValidAccessToken }]) => hasValidAccessToken),
        first()
      )
      .toPromise();

    if (!canAccessApi) {
      await this.initLoginFlow(targetUrl);
    }
    return canAccessApi;
  }

  /**
   * Start the oidc flow via the WFE proxy. This will cause:
   * 1. the browser to be redirected to the IDP
   * 2. where there is NOT already a single-sign-on session with the IDP, the user will be prompted for credentials
   *
   * IMPORTANT: currently this will trigger an implicit-flow. However in the future this will switch
   * to the authorization code flow once OKTA supports rotation of the refresh token.
   *
   * @param targetUrl The client-side url to redirect to after the user has been authenticated
   */
  async initLoginFlow(targetUrl?: string) {
    this.configAuth();

    await this.oauthService.loadDiscoveryDocument();

    const proxyState: WfeProxyState = {
      source_redirect_url: this.oauthService.redirectUri || '',
      authorize_url: this.oauthService.loginUrl || ''
    };
    if (targetUrl) {
      proxyState.targetUrl = targetUrl;
    }
    this.oauthService.loginUrl = this.config.authorizeProxyUrl;

    const flowOptions = {
      response_mode: 'fragment',
      $interstitial_tryGetClientIdFromCookie: true
    };

    if (this.config.implicitFlow) {
      sessionStorage.setItem('flow', 'implicit');
      this.oauthService.initLoginFlow(JSON.stringify(proxyState), flowOptions);
    } else {
      sessionStorage.setItem('flow', 'pkce');
      this.oauthService.initCodeFlow(JSON.stringify(proxyState), flowOptions);
    }
  }
  /**
   * Attempt to login the user with materials extracted from the url.
   * For implicit flow, this material will be the token(s) itself.
   * For authorization code flow, this material will be the authorization
   * code that the library will now make post requests to the STS to exchange
   * the code for the token(s).
   *
   * Note: this will NOT trigger a redirect to the IDP as this method is about
   * implementing the second leg of the oidc/auth2 dance
   */
  async tryLogin() {
    this.configAuth();
    this.stripWfeProxyParams();
    // Q: why call `loadDiscoveryDocumentAndTryLogin` ONLY when we know we haven't already got an access token
    // in storage (probably sessionStorage)?
    // A: in case the angular spa uses a deep link to url that has a code and a state parameter BUT that
    // this link has nothing to do with OIDC (at least for this application/api)
    if (!this.oauthService.hasValidAccessToken()) {
      await this.oauthService.loadDiscoveryDocumentAndTryLogin({
        // todo: change WFE proxy so that it returns the `state` param in the redirect back to us then remove disableOAuth2StateCheck
        disableOAuth2StateCheck: true,
        customRedirectUri: getRequiredRedirectProxyUrl(this.config)
      });
    } else {
      await this.oauthService.loadDiscoveryDocument();
    }

    if (this.oauthService.state) {
      this.setTargetUrlOnLogin();
    }

    // todo: implement this (do we need to ensure this goes through wfe proxy?)
    this.oauthService.setupAutomaticSilentRefresh();

    const canAccessApi = this.oauthService.hasValidAccessToken();
    this.loginAttemptSubject$.next(canAccessApi);
    return canAccessApi;
  }

  userLoadedOfType$<T>() {
    return this.userLoaded$ as unknown as Observable<T>;
  }

  private configAuth() {
    this.oauthService.configure(this.config);
  }

  private createUserAttributes$(
    tokens$: Observable<boolean>,
    currentUser$: Observable<User>
  ): Observable<UserAttributes> {
    return tokens$.pipe(
      switchMapTo(from(this.userInfoService.get()).pipe(withLatestFrom(currentUser$))),
      switchMap(([attributes, user]) =>
        from(this.userPermissionService.get({ ...user, ...attributes })).pipe(
          map(permissions => ({
            ...attributes,
            permissions
          }))
        )
      )
    );
  }

  private getConfig(value: AuthConfig) {
    const mandatoryConfig: AuthConfig = {
      clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
      sessionChecksEnabled: false, // todo: consider enabling
      authorizeProxyUrl: getRequiredAuthzProxyUrl(value),
      logoutUrl: getRequiredLogoutProxyUrl(value)
    };

    if (!value.implicitFlow) {
      mandatoryConfig.useIdTokenHintForSilentRefresh = true;
      mandatoryConfig.responseType = 'code';
    }

    return new AuthConfig({ ...value, ...mandatoryConfig });
  }

  private rehydrateImpersonatedClientId() {
    const storageValue = this.storage.getItem(ImpersonatedClientId);
    if (!storageValue) return;

    of(storageValue)
      .pipe(observeOn(asapScheduler))
      .forEach(impersonatedClientId => {
        this.store.dispatch(impersonateClient({ impersonatedClientId, rehydrated: true }));
      });
  }

  private setTargetUrlOnLogin() {
    const proxyState = JSON.parse(this.oauthService.state || '{}') as WfeProxyState;
    this.targetUrlOnLogin = proxyState.targetUrl || '';
  }

  private stripWfeProxyParams() {
    const url = new URL(location.href);
    const params = new URLSearchParams(url.search);
    wfeQueryParams.forEach(p => {
      params.delete(p);
    });
    const search = params.toString();
    const queryString = search ? `?${search}` : '';
    const strippedUrl = url.origin + url.pathname + queryString + url.hash;
    history.replaceState(null, window.name, strippedUrl);
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  /**
   * Method - For switching client by support user
   *  to update the clientId in http header
   * for sending the API to fetch the data w.r.t clientId
   */

  impersonateClient(impersonatedClientId: string | undefined) {
    this.store.dispatch(impersonateClient({ impersonatedClientId }));
  }

  /**
   * Method - for refreshing user Info details for already loadedUser
   */
  reloadUserInfo() {
    this.store.dispatch(reloadUserInfo());
  }

  logOut() {
    // note: at this point oauthService.logoutUrl will be set to the value of 'end_session_endpoint' from the oidc discovery document
    if (!this.oauthService.logoutUrl) {
      throw new Error(
        'logoutUrl has not been configured; this might mean that the OpenID Connect Provider has not been configured or does not support OpenID Connect Session Management'
      );
    }

    const proxyState: WfeProxyLogoutState = {
      logout_url: this.oauthService.logoutUrl
    };

    // we need to set the logoutUrl to point to the wfe proxy logout url
    this.oauthService.logoutUrl = this.config.logoutUrl;

    this.oauthService.logOut(false, JSON.stringify(proxyState));
  }
}
