/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable @typescript-eslint/no-explicit-any */
// `unknown` might be a better type than any, but it's border-line and likely not worth changing at this point

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export type FormGroupValidatorFn = (control: FormGroup) => ValidationErrors | null;

export type FormValidators<T> = {
  [P in keyof T]?: ValidatorFn[];
} & {
  formGroupValidators?: Array<FormGroupValidatorFn | ValidatorFn>;
};

export function addSyncValidators<T>(form: FormGroup, validators: FormValidators<T>, updateValueAndValidity = true) {
  Object.entries(validators)
    .filter(([key, funcs]) => key !== 'formGroupValidators' && funcs)
    .forEach(([key, funcs]) => {
      const control = form.controls[key];
      control.setValidators((funcs as ValidatorFn[]) ?? null);
      if (updateValueAndValidity) {
        control.updateValueAndValidity();
      }
    });

  if (validators.formGroupValidators) {
    form.setValidators((validators.formGroupValidators as ValidatorFn[]) ?? null);
    if (updateValueAndValidity) {
      form.updateValueAndValidity();
    }
  }
}

export function enableControl(
  control: AbstractControl,
  enabled: boolean,
  opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }
) {
  if ((enabled && control.enabled) || (!enabled && control.disabled)) return;

  if (enabled) {
    control.enable(opts);
  } else {
    control.disable(opts);
  }
}

export function removeSyncValidators(form: FormGroup, updateValueAndValidity = true) {
  Object.values(form.controls).forEach(control => {
    control.clearValidators();
  });
  form.clearValidators();
  if (updateValueAndValidity) {
    updateAllValueAndValidity(form);
  }
}

export function setSyncValidators<T>(form: FormGroup, validators: FormValidators<T>, updateValueAndValidity = true) {
  removeSyncValidators(form, false);
  addSyncValidators(form, validators, false);
  if (updateValueAndValidity) {
    updateAllValueAndValidity(form);
  }
}

export function updateAllValueAndValidity(form: FormGroup) {
  Object.values(form.controls).forEach(control => {
    control.updateValueAndValidity();
  });
  form.updateValueAndValidity();
}
