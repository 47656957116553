import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DefaultEntityService, EntityIdType } from '@mri-platform/shared/entity';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccessToken } from '../models';
import { platformConnectionSelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class AccessTokenEntityService extends DefaultEntityService<AccessToken> {
  constructor(injector: Injector, private apiConfig: DefaultDataServiceConfig, private http: HttpClient) {
    super(AccessToken.entityName, injector);
  }

  entitiesByPlatformId$(platformId: EntityIdType): Observable<AccessToken[]> {
    return this.store.select(platformConnectionSelectors.selectEntitiesByPlatformId(platformId));
  }

  tryRenewAccess(accessTokenId: number) {
    this.setLoading(true);
    const url = `${this.apiConfig.root}/accessTokens/refresh/${accessTokenId}`;
    return this.http.get(url, { responseType: 'text' }).pipe(finalize(() => this.setLoading(false)));
  }
}
