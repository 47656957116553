import { Injectable, Injector } from '@angular/core';
import { AppRoutes } from '@mri-platform/import-export/core';
import { LoadedEntityResolver } from '@mri-platform/shared/entity';
import { Client } from '../models';
import { ClientEntityService } from './client-entity.service';

@Injectable({ providedIn: 'root' })
export class LoadedClientResolver extends LoadedEntityResolver<Client> {
  constructor(injector: Injector, entityService: ClientEntityService) {
    super(injector, entityService, {
      notFoundFallbackUrl: AppRoutes.ClientManagement.rootPath
    });
  }
}
