import { EntityCollection } from '@ngrx/data';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createEntitiesForIdSelector, createEntitiesGroupedBySelector } from '../selector-factories';
import { QueryEntityActionPayload } from './query-cache-entity-action-factory';
import { createLoadedQueryEntry, createLoadingQueryEntry, QueryEntry } from './query-entry';

export interface EntityCollectionExtras {
  queryCache: EntityState<QueryEntry>;
}

export type QueryCacheEntityCollection<T> = EntityCollection<T> & EntityCollectionExtras;

export const queryCacheAdapter = createEntityAdapter<QueryEntry>();

export const { selectAll: selectQueries, selectEntities: selectQueriesMap } = queryCacheAdapter.getSelectors();

const selectQueriesByHash = createEntitiesGroupedBySelector(selectQueries, 'queryHash');
const selectQueriesForHash = createEntitiesForIdSelector(selectQueriesByHash);

export function removeAllQueryEntries<T>(collection: QueryCacheEntityCollection<T>): QueryCacheEntityCollection<T> {
  const queryCache = queryCacheAdapter.removeAll(collection.queryCache);
  return {
    ...collection,
    queryCache
  };
}

export function removeOneQueryEntry<T>(
  queryId: string,
  collection: QueryCacheEntityCollection<T>
): QueryCacheEntityCollection<T> {
  const existingEntry = selectQueriesMap(collection.queryCache)[queryId];
  if (!existingEntry) {
    return collection;
  }

  const queryCache = queryCacheAdapter.removeOne(queryId, collection.queryCache);
  return {
    ...collection,
    queryCache
  };
}

function upsertOneQueryEntry<T>(
  entry: QueryEntry,
  collection: QueryCacheEntityCollection<T>
): QueryCacheEntityCollection<T> {
  const queryCache = queryCacheAdapter.upsertOne(entry, collection.queryCache);
  return {
    ...collection,
    queryCache
  };
}

export function setLoadingQueryEntry<P, T>(
  actionPayload: QueryEntityActionPayload<P>,
  collection: QueryCacheEntityCollection<T>
): QueryCacheEntityCollection<T> {
  const queryEntry = createLoadingQueryEntry(actionPayload);
  return upsertOneQueryEntry(queryEntry, collection);
}

export function setLoadedQueryEntry<P, T>(
  actionPayload: QueryEntityActionPayload<P>,
  collection: QueryCacheEntityCollection<T>
): QueryCacheEntityCollection<T> {
  const selectOldQueries = actionPayload.replaceCache
    ? () => true
    : (e: QueryEntry) => e.queryHash === queryEntry.queryHash && e.loaded;

  const queryEntry = createLoadedQueryEntry(actionPayload);
  const oldQueries = selectQueries(collection.queryCache)
    .filter(selectOldQueries)
    .map(e => e.id);

  let queryCache = queryCacheAdapter.removeMany(oldQueries, collection.queryCache);
  queryCache = queryCacheAdapter.upsertOne(queryEntry, queryCache);

  return {
    ...collection,
    queryCache
  };
}

export { selectQueriesByHash, selectQueriesForHash };
