import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { DataSourceClient } from '../models';

@Injectable({ providedIn: 'root' })
export class DataSourceClientEntityService extends EntityCollectionServiceBase<DataSourceClient> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(DataSourceClient.entityName, serviceElementsFactory);
  }
}
