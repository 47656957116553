import { Directive, ElementRef, Host, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[mriSharedKendoRequired]'
})
export class KendoRequiredDirective {
  @HostBinding('class.k-required')
  @HostBinding('class.mri-k-show-validation')
  @Input('mriSharedKendoRequired')
  set isRequired(value: boolean | string) {
    value = typeof value === 'string' ? true : value;
    this.input.nativeElement.required = value;
  }
  get isRequired() {
    return this.input.nativeElement.required;
  }

  constructor(@Host() private input: ElementRef<HTMLInputElement>) {}
}
