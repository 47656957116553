export const AG_LOGOUT_REASON = 'Sign-out';

/**
 * The event structure that AG Bar will dispatch (bubble) to DOM whenever it's about to perform a navigation.
 * For example when about to sign out to welcome
 */
export interface AppGatewayNavigationEvent {
  detail: {
    context: {
      /**
       * The target url of the navigation
       */
      targetUrl: string;
      /**
       * Whether the navigation will be performed by opening another tab in the browser
       */
      newTab: boolean;
      /**
       * The reason for the navigation.
       * For a sign-out this will be the value of the constant {@link AG_LOGOUT_REASON}
       */
      reason: string;
    };
    navigationController: {
      /**
       * call this to inform AG Bar to continue and perform the navigation
       */
      accept: VoidFunction;
      /**
       * call this to inform AG Bar to cancel the navigation
       */
      cancel: VoidFunction;
    };
  };
}
