import { UserPermission } from './user-permission';

export enum CrudAction {
  Create = 'Create',
  Read = 'View',
  Update = 'Update',
  Delete = 'Delete'
}

export enum CrudAllAction {
  Create = 'CreateAll',
  Read = 'ViewAll',
  Update = 'UpdateAll',
  Delete = 'DeleteAll'
}

export interface AuthzResource {
  type: string;
  prop: string[];
}

export interface AuthzLongResource {
  type: string;
  property?: string | string[];
}

interface RolePermission {
  rolePermissions: UserPermission[];
}
interface AuthzContextBase {
  /**
   * Is this context an alias to an authorization
   * context that requires resolving in full before
   * authorization?
   */
  requiresResolve?: boolean;
  user?: RolePermission;
}

export type Resource = string | string[] | AuthzResource[];
export interface AuthzContextLong extends AuthzContextBase {
  action: string | string[];
  resource: Resource;
}

export interface AuthzContextShort extends AuthzContextBase {
  a: string | string[];
  r: string | string[] | AuthzResource[];
}

export type AuthzContext = AuthzContextLong | AuthzContextShort;

const resourceAuthzContext =
  (...action: string[]) =>
  (...resource: string[]): AuthzContext => ({
    action,
    resource
  });

export const AuthzContext = {
  crudFor: resourceAuthzContext(CrudAction.Create, CrudAction.Read, CrudAction.Update, CrudAction.Delete),
  crudAllFor: resourceAuthzContext(
    CrudAllAction.Create,
    CrudAllAction.Read,
    CrudAllAction.Update,
    CrudAllAction.Delete
  ),
  createFor: resourceAuthzContext(CrudAction.Create),
  readFor: resourceAuthzContext(CrudAction.Read),
  updateFor: resourceAuthzContext(CrudAction.Update),
  deleteFor: resourceAuthzContext(CrudAction.Delete),
  createAllFor: resourceAuthzContext(CrudAllAction.Create),
  readAllFor: resourceAuthzContext(CrudAllAction.Read),
  updateAllFor: resourceAuthzContext(CrudAllAction.Update),
  deleteAllFor: resourceAuthzContext(CrudAllAction.Delete)
};

export const isAuthContextShort = (value: AuthzContextLong | AuthzContextShort): value is AuthzContextShort =>
  (value as AuthzContextShort).r != null;

export const getAuthzContextResourceTypes = (authzContext: AuthzContext): string[] => {
  const value = isAuthContextShort(authzContext) ? authzContext.r : authzContext.resource;
  const values = Array.isArray(value) ? value : ([value] as Array<string | AuthzResource>);
  return values.map<string>(v => (typeof v === 'string' ? v : v.type));
};

export const getAuthzContextAction = (authzContext: AuthzContext) =>
  isAuthContextShort(authzContext) ? authzContext.a : authzContext.action;

export interface AuthzContextsActionMap {
  [action: string]: AuthzContext | AuthzContext[];
}

export const AuthzContextsActionMap = {
  crudFor: (...resourceNames: string[]): AuthzContextsActionMap => ({
    [CrudAction.Create]: { action: CrudAction.Create, resource: resourceNames },
    [CrudAction.Read]: { action: CrudAction.Read, resource: resourceNames },
    [CrudAction.Update]: { action: CrudAction.Update, resource: resourceNames },
    [CrudAction.Delete]: { action: CrudAction.Delete, resource: resourceNames }
  })
};
