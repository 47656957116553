import { DataResult, process as processImpl, State } from '@progress/kendo-data-query';

export interface GridDataResult extends DataResult {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filteredData: any[];
}

export const emptyGridDataResult: GridDataResult = {
  data: [],
  filteredData: [],
  total: 0
};

export const processGridData = <T>(gridData: T[], state: State): GridDataResult => {
  const { filter, group, take } = state;

  if (group?.length) {
    throw new Error('processGridData does not yet support grouped grid results');
  }

  if (!take) {
    const { total, data } = processImpl(gridData, state);
    return { data, filteredData: data, total };
  } else {
    const { total, data: filteredData } = processImpl(gridData, { filter });

    const { filter: ignoredFilter, ...nonFilterState } = state;
    const { data: pagedData } = processImpl(filteredData, nonFilterState);
    return {
      total,
      filteredData,
      data: pagedData
    };
  }
};
