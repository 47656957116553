import { AuthConfig as LibAuthConfig } from 'angular-oauth2-oidc';
import { LoadedUser } from './models/user';

export type IsAuthzAppUserPredicate<A = unknown> = (user: LoadedUser & A) => boolean;

const wfeRootPathSegment = 'OpenIdConnect';

const getPathSegments = (urlBuilder: URL) => urlBuilder.pathname.split('/').filter(s => !!s);

const appendPathSegments = (url: string, ...segments: string[]) => {
  const urlBuilder = new URL(url);
  const existingSegments = getPathSegments(urlBuilder);
  urlBuilder.pathname = [...existingSegments, ...segments].join('/');
  return urlBuilder.toString();
};

const replaceLastPathSegment = (url: string, newSegment: string) => {
  const urlBuilder = new URL(url);
  const segments = getPathSegments(urlBuilder);
  segments.pop();
  urlBuilder.pathname = [...segments, newSegment].join('/');
  return urlBuilder.toString();
};

export const getRequiredAuthzProxyUrl = ({ authorizeProxyUrl, proxyBaseUrl }: AuthConfig): string => {
  if (authorizeProxyUrl) {
    return authorizeProxyUrl;
  } else if (proxyBaseUrl) {
    return appendPathSegments(proxyBaseUrl, wfeRootPathSegment, 'Forward');
  } else {
    throw new Error("'authorizeProxyUrl' is mandatory and cannot be derived as 'proxyBaseUrl' was not supplied");
  }
};

export const getRequiredRedirectProxyUrl = ({ authorizeProxyUrl, proxyBaseUrl }: AuthConfig): string => {
  const redirectPathSegment = 'Redirect';
  if (authorizeProxyUrl) {
    return replaceLastPathSegment(authorizeProxyUrl, redirectPathSegment);
  } else if (proxyBaseUrl) {
    return appendPathSegments(proxyBaseUrl, wfeRootPathSegment, redirectPathSegment);
  } else {
    throw new Error(
      "authorization redirect url cannot be derived as 'authorizeProxyUrl' or 'proxyBaseUrl' was supplied"
    );
  }
};

export const getRequiredLogoutProxyUrl = ({ authorizeProxyUrl, logoutUrl, proxyBaseUrl }: AuthConfig): string => {
  const logoutPathSegment = 'ForwardLogout';
  if (logoutUrl) {
    return logoutUrl;
  } else if (authorizeProxyUrl) {
    return replaceLastPathSegment(authorizeProxyUrl, logoutPathSegment);
  } else if (proxyBaseUrl) {
    return appendPathSegments(proxyBaseUrl, wfeRootPathSegment, logoutPathSegment);
  } else {
    throw new Error(
      "'logoutUrl' is mandatory and cannot be derived as neither 'authorizeProxyUrl' or 'proxyBaseUrl' was supplied"
    );
  }
};

export class AuthConfig extends LibAuthConfig {
  constructor(json?: Partial<AuthConfig>) {
    super(json);
  }

  /**
   * The wfe proxied oidc authorize endpoint url. If not set this will be derived from the value of `proxyBaseUrl`.
   * **IMPORTANT** this url will be used to derive other url's that will be matched to configuration in the IDP on a
   * case-sensitive manner
   */
  authorizeProxyUrl?: string;

  /**
   * Url of a userinfo endpoint in your application backend
   *
   * When supplied, this endpoint will be called after the user has been authenticated
   * The attributes of the object returned will be used to enrich the user object with
   * information that is also returned by the OpenId Connect userinfo endpoint.
   *
   * @see DefaultUserInfoService
   */
  appUserInfoEndpoint?: string;

  /**
   * Function that will be supplied a reference to the fully _loaded_ authenticated user
   * object and should return whether or not that the user is authorized to use the
   * application.
   *
   * If not supplied, will assume that all authenticated users are also authorized
   *
   * @see AuthzAppUserGuard
   * @see AuthService.isAuthorizedAppUser$
   */
  getIsAuthorizedAppUser?: IsAuthzAppUserPredicate;

  /**
   * The wfe proxied oidc logout endpoint url. If not set this will be derived from the value of `authorizeProxyUrl` or
   * `proxyBaseUrl` when `authorizeProxyUrl` is not supplied
   */
  logoutUrl?: string;

  /**
   * The base url of WFE proxy. This is optional when `authorizeProxyUrl` is supplied.
   */
  proxyBaseUrl?: string;

  /**
   * The client-side route to redirect to when the user is found to be unauthorized
   *
   * @default 'unauthorized'
   */
  unauthorizedRoute?: string;
  /**
   * By default PKCE flow is enabled
   * assign true to make implicit flow
   */
  implicitFlow?: boolean;

  /**
   * Reload the app user attributes when impersonating another client?
   *
   * Causes the `userLoaded$` observable on the `AuthService` to emit the latest "live" values
   * returned from `appUserInfoEndpoint` REST api whenever the `AuthService.impersonateClient`
   * method is used to switch client id.
   * @default false
   * */
  reloadAppUserInfoOnClientImpersonation?: boolean;
}
