import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContextsActionMap } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';
import { StringKeyedSerializablePartial } from '@mri-platform/shared/entity';

export interface AccessToken {
  id: number;
  platformId: number;
  clientName?: string;
  clientId?: number;
  userName?: string;
  databaseId?: number;
  databaseName?: string;
  tokenExpiryTime?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AccessToken = createModelMetadata<AccessToken>({
  entityName: 'AccessToken',
  authorization: AuthzContextsActionMap.crudFor(EntityName.AccessToken)
});

export type AccessTokenQuery = Pick<AccessToken, 'platformId' | 'clientId' | 'databaseId' | 'userName'> &
  StringKeyedSerializablePartial<AccessToken>;
