import { forwardRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { AppGatewayNavigationEvent } from './ag-bar-navigation-event';

export type BarReadyStatus = 'pending' | 'ready' | 'fail';

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => AgBarService)
})
export abstract class AgBarNotificationService {
  /**
   * The status of the bar on its way to becoming usable by the user.
   *
   * Late subscribers will receive the last value emitted.
   *
   * Application Gateway will not render any menus (eg the 9-box applications menu) until after it has fetched
   * configuration settings from it's web api server. As a consequence there will be a delay between the
   * application-gateway-app component (the blue bar with the MRI logo) becoming visible and when core functionality is
   * ready to use.
   *
   * Three states for readiness: "pending", "fail", "success". The status will start at "pending." If the status is
   * "fail" you'll either see a failed network request or an error in the console explaining what the problem was.
   * When "success" the bar is ready to use.
   */
  abstract readonly barReadyStatusChange$: Observable<BarReadyStatus>;
  /**
   * Returns whether the AG Bar is visible to the user or no.
   *
   * On initial load, the shell of the application-gateway-app component (the blue bar with the MRI logo) will not be
   * rendered until the user is validated. Subsequently, once the bar has rendered on a particular browser, it will be
   * optimistically rendered and be visible closer to page load.
   */
  abstract readonly isBarVisible$: Observable<boolean>;
  /**
   * Emits whenever the AG Bar wants to perform a navigation but has been configured to allow these navigations to be
   * intercepted by the hosting app.
   */
  abstract readonly navigation$: Observable<AppGatewayNavigationEvent>;
}

/**
 * @ignore
 */
@Injectable({ providedIn: 'root' })
export class AgBarService implements AgBarNotificationService {
  private barReadyStatusChange = new ReplaySubject<BarReadyStatus>(1);
  readonly barReadyStatusChange$ = this.barReadyStatusChange.asObservable();

  private isBarVisible = new BehaviorSubject<boolean>(false);
  readonly isBarVisible$ = this.isBarVisible.asObservable();

  private navigation = new Subject<AppGatewayNavigationEvent>();
  navigation$ = this.navigation.asObservable();

  setBarVisibleStatus(value: boolean) {
    this.isBarVisible.next(value);
  }

  setBarReadyStatus(value: BarReadyStatus) {
    this.barReadyStatusChange.next(value);
  }

  publishBarNavigationEvent(evt: AppGatewayNavigationEvent) {
    this.navigation.next(evt);
  }
}
