<ng-container *ngIf="vm$ | async as vm">
  <mri-shared-loading [show]="vm.isLoading"></mri-shared-loading>
  <kendo-grid [data]="vm.gridData" [resizable]="true" [height]="500">
    <kendo-grid-column field="name" title="Platform"></kendo-grid-column>
    <kendo-grid-column field="dataSourceName" title="Database"></kendo-grid-column>
    <kendo-grid-column title="Status">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.accessTokenId ? 'Enrolled' : 'Not enrolled' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Action">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button class="mri-button mri-button--quiet" (click)="enrollOrRenew(dataItem)">
          {{ dataItem.accessTokenId ? 'Renew Access' : 'Enroll' }}
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</ng-container>
<div #confirmDialogContainerRef></div>
<ng-template #confirmDialogContentRef>
  <div class="confirmation-content">{{ dialogText }}</div>
</ng-template>
