import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { defineCustomElements } from '@mri/ag-widget/loader';

import { DIRECTIVES } from '../generated';
import { AgWidgetConfig, AgWidgetConfigData } from './ag-widget-config';

defineCustomElements();

@NgModule({
  imports: [CommonModule],
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES]
})
export class AgWidgetAngularModule {
  static forRoot(config?: AgWidgetConfigData): ModuleWithProviders<AgWidgetAngularModule> {
    return {
      ngModule: AgWidgetAngularModule,
      providers: [config ? [{ provide: AgWidgetConfig, useValue: new AgWidgetConfig(config) }] : []]
    };
  }
}
