import { ModelMetadata } from '@mri-platform/shared/core';
import { EntitySelectorsFactory } from '@ngrx/data';
import { Dictionary, IdSelector } from '@ngrx/entity';
import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import groupBy from 'lodash-es/groupBy';
import { EntityIdType } from './entity-functions';
import { createEntityList, emptyEntityList, EntityList } from './entity-list';

function createEntityForIdSelector<State extends Object, S>(
  selectEntityMap: Selector<State, Dictionary<S>>
): (id: EntityIdType) => MemoizedSelector<State, S | undefined, DefaultProjectorFn<S | undefined>>;
function createEntityForIdSelector<State extends Object, S, R = S>(
  selectEntityMap: Selector<State, Dictionary<S>>,
  defaultValue: R
): (id: EntityIdType) => MemoizedSelector<State, R | NonNullable<S>, DefaultProjectorFn<R | NonNullable<S>>>;
function createEntityForIdSelector<State extends Object, S, R = S>(
  selectEntityMap: Selector<State, Dictionary<S>>,
  defaultValue?: R
): (
  id: EntityIdType
) => MemoizedSelector<State, R | NonNullable<S> | undefined, DefaultProjectorFn<R | NonNullable<S> | undefined>> {
  return (id: EntityIdType) => createSelector(selectEntityMap, entityMap => entityMap[id] ?? defaultValue);
}

export { createEntityForIdSelector };

export function createEntitiesForIdSelector<State extends Object, S>(
  selectEntityMap: Selector<State, Dictionary<S[] | undefined>>
) {
  return createEntityForIdSelector(selectEntityMap, new Array<S>());
}

export function createEntityListForIdSelector<S extends Object>(
  selectEntityMap: Selector<Object, Dictionary<S[]>>,
  idSelector: IdSelector<S>
) {
  const noList = emptyEntityList<S>();

  return (id: EntityIdType) =>
    createSelector(selectEntityMap, (entityMap): EntityList<S> => {
      const items = entityMap[id];
      return items ? createEntityList(items, idSelector) : noList;
    });
}

export function createEntitiesGroupedBySelector<State, S, GroupKey extends keyof S>(
  selectEntities: Selector<State, S[]>,
  key: GroupKey
) {
  return createSelector(selectEntities, entities => groupBy(entities, key));
}

export function createSelectors<State extends Object, T extends Object>(
  modelMetadata: Required<ModelMetadata<T, keyof T>>
) {
  const selectors = new EntitySelectorsFactory().create<T>(modelMetadata);
  const selectEntityById = createEntityForIdSelector<State, T>(selectors.selectEntityMap);

  const selectEntityList = createSelector(
    selectors.selectEntities,
    selectors.selectEntityMap,
    (items, map): EntityList<T> => ({ items, map })
  );

  return {
    ...selectors,
    selectEntityById,
    selectEntityList,
    selectId: modelMetadata.selectId
  };
}
