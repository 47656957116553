/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  ChangeSetItem,
  ChangeSetOperation,
  EntityActionFactory,
  EntityCacheAction,
  EntityOp,
  SaveEntities,
  SaveEntitiesError
} from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { NonQueryOptions } from './entity-action-options';

@Injectable()
export class AutoUndoManyEffects {
  constructor(
    private actions: Actions<SaveEntitiesError>,
    private actionFactory: EntityActionFactory
  ) {}

  undoMany$ = createEffect(() =>
    this.actions.pipe(
      ofType(EntityCacheAction.SAVE_ENTITIES_ERROR),
      map(action => action.payload.originalAction),
      filter(originalAction => {
        const originalOptions = originalAction.payload as NonQueryOptions;
        return originalAction.payload.isOptimistic === true && originalOptions.autoUndoOnError !== false;
      }),
      concatMap(action => this.undoEntities(action))
    )
  );

  undoEntities(originalAction: SaveEntities) {
    const undoManyActions = originalAction.payload.changeSet.changes.map(c =>
      this.actionFactory.create(c.entityName, EntityOp.UNDO_MANY, this.extractEntitiesOrKeys(c))
    );
    return from(undoManyActions);
  }

  private extractEntitiesOrKeys(change: ChangeSetItem): any[] | string[] | number[] {
    return change.op === ChangeSetOperation.Update ? change.entities.map(u => u.changes) : change.entities;
  }
}
