import { createReducer, on } from '@ngrx/store';
import { UserEnrollActions } from '../actions';

export const userEnrollStateFeatureKey = 'userEnrollState';

export type State = string[];

export const initialState: State = [];

export const reducer = createReducer(
  initialState,
  on(UserEnrollActions.showError, (state, { error }) => (!state.includes(error) ? [...state, error] : state)),
  on(UserEnrollActions.removeError, state => state.filter(k => k === ''))
);
