<header class="mri-datalist__header" *ngIf="showHeader">
  <label *ngIf="headerTitle" class="mri-datalist__heading">{{ headerTitle }}</label>
  <ng-container
    *ngIf="showSearch"
    [ngTemplateOutlet]="searchTemplate"
    [ngTemplateOutletContext]="{
      $implicit: filterValue,
      setValue: setFilterValue,
      placeholderValue: searchPlaceholder
    }"
  ></ng-container>
  <span *ngIf="stateMessage" mriDatalistMetadata left>{{ stateMessage }}</span>
  <ng-container
    *ngIf="!stateMessage && showStatistics && (showRecordCount || showSelectCount)"
    [ngTemplateOutlet]="statisticsTemplate"
    [ngTemplateOutletContext]="{
      recordCountEnabled: showRecordCount,
      selectCountEnabled: showSelectCount,
      showingCount: filteredList.length,
      totalCount: list.length,
      selectionCount: selectedCount,
      recordCountLabel: recordCountLabel
    }"
  ></ng-container>
</header>
<!--Error when no data-->
<ng-container
  *ngIf="isEmptyList() && showLoadingError && !loading"
  [ngTemplateOutlet]="errorTemplate"
  [ngTemplateOutletContext]="{
    showLoadingError: showLoadingError,
    showLoadingRetry: showLoadingRetry,
    loadingErrorRetryLabel: loadingErrorRetryLabel,
    loadingErrorMessage: loadingErrorMessage
  }"
></ng-container>
<!--Error when data is present-->
<ng-container
  *ngIf="!isEmptyList() && showLoadingError && !loading"
  [ngTemplateOutlet]="errorWithDataTemplate"
  [ngTemplateOutletContext]="{
    showLoadingError: showLoadingError,
    showLoadingRetry: showLoadingRetry,
    loadingErrorRetryLabel: loadingErrorRetryLabel,
    loadingErrorMessage: loadingErrorMessage
  }"
></ng-container>

<!--when data is empty-->
<p *ngIf="isEmptyList() && !showLoadingError && !loading" class="display-message">No items to show</p>

<!-- TODO : We need to revisit this and de-dup the ul. -->
<cdk-virtual-scroll-viewport *ngIf="rowHeight" [itemSize]="rowHeight">
  <ul tabindex="0" role="listbox" class="mri-datalist__list">
    <li
      (click)="selectItemInternal(itemsEquivalent(item, selectedItem) ? undefined : item, false)"
      role="option"
      class="mri-datalist__item"
      [ngClass]="getItemClasses(item)"
      aria-selected="false"
      *cdkVirtualFor="let item of filteredList; trackBy: trackByFunc"
      [attr.aria-selected]="itemsEquivalent(item, selectedItem)"
    >
      <!-- todo: `parentList` from `ngTemplateOutletContext` and replace with `ngTemplateOutletInjector` once on angular v14-->
      <ng-container
        *ngIf="itemTemplate"
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{
          $implicit: item,
          parentList: this,
          isItemSelected: itemsEquivalent(item, selectedItem)
        }"
      ></ng-container>
    </li>
  </ul>
  <!--Loading when data present-->
  <mri-loading [show]="loading && !isEmptyList()"></mri-loading>
</cdk-virtual-scroll-viewport>

<ul tabindex="0" role="listbox" class="mri-datalist__list" *ngIf="!rowHeight">
  <li
    (click)="selectItemInternal(itemsEquivalent(item, selectedItem) ? undefined : item, false)"
    role="option"
    class="mri-datalist__item"
    [ngClass]="getItemClasses(item)"
    aria-selected="false"
    *ngFor="let item of filteredList; trackBy: trackByFunc"
    [attr.aria-selected]="itemsEquivalent(item, selectedItem)"
  >
    <!-- todo: `parentList` from `ngTemplateOutletContext` and replace with `ngTemplateOutletInjector` once on angular v14-->
    <ng-container
      *ngIf="itemTemplate"
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{
        $implicit: item,
        parentList: this,
        isItemSelected: itemsEquivalent(item, selectedItem)
      }"
    ></ng-container>
  </li>
  <!--Loading when data present-->
  <mri-loading [show]="loading && !isEmptyList()"></mri-loading>
</ul>
<!--Loading when data is not present-->
<mri-loading [show]="loading && isEmptyList()"></mri-loading>

<ng-template #defaultSearchTemplate let-value let-setValue="setValue" let-placeholder="placeholderValue">
  <mri-datalist-search-box
    [value]="value"
    (valueChange)="setValue($event)"
    [placeholder]="placeholder"
    [disabled]="loading"
  ></mri-datalist-search-box>
</ng-template>

<ng-template
  #defaultStatisticsTemplate
  let-recordCountEnabled="recordCountEnabled"
  let-selectCountEnabled="selectCountEnabled"
  let-showingCount="showingCount"
  let-totalCount="totalCount"
  let-selectionCount="selectionCount"
  let-recordCountLabel="recordCountLabel"
>
  <span mriDatalistMetadata left *ngIf="recordCountEnabled">
    Showing {{ showingCount }} of {{ totalCount }} {{ recordCountLabel }}
  </span>
  <span mriDatalistMetadata right *ngIf="selectCountEnabled && selectionCount">{{ selectionCount }} selected</span>
</ng-template>

<!--Default template when data is present-->
<ng-template
  #defaultErrorWithDataTemplate
  let-showLoadingError="showLoadingError"
  let-loadingErrorMessage="loadingErrorMessage"
  let-showLoadingRetry="showLoadingRetry"
  let-loadingErrorRetryLabel="loadingErrorRetryLabel"
>
  <mri-loading-error-banner
    *ngIf="!isEmptyList() && showLoadingError"
    [loadingErrorMessage]="loadingErrorMessage"
    [showLoadingRetry]="showLoadingRetry"
    [loadingErrorRetryLabel]="loadingErrorRetryLabel"
    (retry)="onRetryClick()"
  ></mri-loading-error-banner>
</ng-template>

<!--Default template when no data-->
<ng-template
  #defaultErrorTemplate
  let-showLoadingError="showLoadingError"
  let-loadingErrorMessage="loadingErrorMessage"
  let-showLoadingRetry="showLoadingRetry"
  let-loadingErrorRetryLabel="loadingErrorRetryLabel"
>
  <div *ngIf="isEmptyList() && !loading" class="display-message">
    <p><mri-fld-icon class="mri-mt-medium" icon="alert-circle" size="large"></mri-fld-icon></p>
    <p>{{ loadingErrorMessage }}</p>
    <p>
      <button *ngIf="showLoadingRetry" (click)="onRetryClick()" class="mri-button mri-mb-small">
        {{ loadingErrorRetryLabel }}
      </button>
    </p>
  </div>
</ng-template>

<ng-container
  [ngTemplateOutlet]="footerTemplate"
  [ngTemplateOutletContext]="{
    addItem: addItemToList,
    removeItem: removeItemFromList,
    actionsBtnTemplate: actionsBtnTemplate,
    canAdd: canAddItem,
    canRemove: canRemoveItem && !!selectedItem,
    selectedItem: selectedItem,
    showAdd: showAddItem,
    showRemove: showRemoveItem,
    showActions: showItemsActions
  }"
></ng-container>

<ng-template
  *ngIf="showFooter"
  #defaultFooterTemplate
  let-actionsBtnTemplate="actionsBtnTemplate"
  let-add="addItem"
  let-remove="removeItem"
  let-canAdd="canAdd"
  let-canRemove="canRemove"
  let-selectedItem="selectedItem"
  let-showActions="showActions"
  let-showAdd="showAdd"
  let-showRemove="showRemove"
>
  <mri-datalist-footer
    [actionsBtnTemplate]="actionsBtnTemplate"
    [canAdd]="canAdd"
    [canRemove]="canRemove"
    [showAdd]="showAdd"
    [showRemove]="showRemove"
    [showActions]="showActions"
    [selectedItem]="selectedItem"
    (addItemToList)="add()"
    (removeItemFromList)="remove($event)"
  ></mri-datalist-footer>
</ng-template>
