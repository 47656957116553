import { ViewStateKey } from '@mri-platform/shared/core';
import { createSelector } from '@ngrx/store';
import { selectViewState } from './view-state.selectors';

export const selectStates = createSelector(selectViewState, state => state.state);

export const selectStateByKey = ({ componentName, key }: ViewStateKey) =>
  createSelector(
    selectStates,
    state => state.find(data => data.componentName === componentName && data.key === key)?.value
  );
