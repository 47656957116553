import { Type } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, Event, NavigationEnd, Scroll } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '../rxjs/operators';

export function routerEventOfType<T extends Event>(type: Type<T>) {
  return (source$: Observable<Event>) =>
    source$.pipe(
      switchMap(v => {
        // Problem: Router Events of type ActivationEnd only emits in the app component when deeplinking to a details page
        // Work-around: When the component is deeplinked to the details page only the Scroll event is emitted to the master router
        // so Scroll Event is used in place of ActivationEnd.
        if (v instanceof type || (v instanceof Scroll && v.routerEvent instanceof NavigationEnd)) return of(v);
        return EMPTY;
      })
    );
}

export type RouteComponentSelector = (currentRoute: ActivatedRouteSnapshot) => boolean;

export function routerActivationEndForComponent(
  componentType: Type<unknown> | RouteComponentSelector,
  route: ActivatedRoute
) {
  const componentSelector: RouteComponentSelector = isConstructor(componentType)
    ? (snapshot: ActivatedRouteSnapshot) => snapshot.component === componentType
    : componentType;
  return (source$: Observable<Event>) =>
    source$.pipe(
      routerEventOfType(ActivationEnd),
      map(x => (x instanceof Scroll ? route.firstChild : x)),
      isNotNullOrUndefined(),
      filter(evt => componentSelector(evt.snapshot))
    );
}

function isConstructor(value: unknown): value is Type<unknown> {
  return typeof value === 'function' && !!value.prototype && value.prototype.constructor === value;
}
