import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@mri-platform/ui-icon';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { KendoToastErrorAppenderProviders } from './kendo-toast-error-appender.service';
import { KendoUiErrorToastComponent } from './kendo-ui-error-toast.component';

@NgModule({
  imports: [CommonModule, NotificationModule, UiIconModule],
  providers: [KendoToastErrorAppenderProviders],
  declarations: [KendoUiErrorToastComponent]
})
export class KendoUiToastAppenderModule {}
