import { AbstractControl } from '@angular/forms';

export function ValidateURL(control: AbstractControl) {
  if (!control.value || !(typeof control.value === 'string')) {
    return null;
  }
  const pattern = /^(https?:\/\/)?(www\.)?([a-z0-9]+([-]+[a-z0-9]+)*\.)+([a-z]{2,})/i;
  if (!pattern.test(control.value.trim())) {
    return { isNotValidURL: true };
  }
  return null;
}
