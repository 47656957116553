import { EntityName } from '@mri-platform/import-export/core';
import { AuthzContext, CrudAction } from '@mri-platform/resource-authz';
import { createModelMetadata } from '@mri-platform/shared/core';

export interface JobLogError {
  jobLogId: number;
  name: string;
  message: string;
  errorType?: string;
  errorCount: number;
  isMessageShow?: boolean;
}

export const JobLogError = createModelMetadata<JobLogError>({
  entityName: 'JobLogError',
  authorization: {
    [CrudAction.Read]: AuthzContext.readFor(EntityName.JobLogError)
  },
  selectId: (jobLogError: JobLogError) => jobLogError.jobLogId + '_' + jobLogError.name
});
