import { createEntitiesForIdSelector, createEntitiesGroupedBySelector } from '@mri-platform/shared/entity';
import { mapperSelectors } from './mapper-selectors';

const selectEntitiesByDestinationMap = createEntitiesGroupedBySelector(
  mapperSelectors.selectEntities,
  'destinationConnectionId'
);
const selectEntitiesForDestinationConnectionId = createEntitiesForIdSelector(selectEntitiesByDestinationMap);

const selectEntitiesBySourceMap = createEntitiesGroupedBySelector(mapperSelectors.selectEntities, 'sourceConnectionId');
const selectEntitiesForSourceConnectionId = createEntitiesForIdSelector(selectEntitiesBySourceMap);

export const platformMapperSelectors = {
  ...mapperSelectors,
  selectEntitiesByDestinationMap,
  selectEntitiesForDestinationConnectionId,
  selectEntitiesForSourceConnectionId
};
