import { Injectable } from '@angular/core';
import { ClientDataService, ClientSwitchInfo } from '@mri-platform/shared/core';
import { QueryDef } from '@mri-platform/shared/entity';
import { Observable } from 'rxjs';
import { ClientEntityService } from './client-entity.service';

@Injectable()
export class AppClientDataService implements ClientDataService {
  constructor(private entityService: ClientEntityService) {}
  get(): Observable<ClientSwitchInfo[]> {
    return this.entityService.setWithQuery(QueryDef.getAll());
  }
}
