import { Injectable, Injector } from '@angular/core';
import { EntityQueryResolver } from '@mri-platform/shared/entity';
import { Client } from '../models';
import { ClientEntityService } from './client-entity.service';

@Injectable({ providedIn: 'root' })
export class ClientsResolver extends EntityQueryResolver<Client> {
  constructor(injector: Injector, entityService: ClientEntityService) {
    super(injector, entityService);
  }
}
