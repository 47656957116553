import { Injectable, Injector } from '@angular/core';
import { EntitiesResolver } from '@mri-platform/shared/entity';
import { ApiConnection } from '../models';
import { ApiEntityService } from './api-entity.service';

@Injectable({ providedIn: 'root' })
export class ApiConnectionsResolver extends EntitiesResolver<ApiConnection> {
  constructor(injector: Injector, entityService: ApiEntityService) {
    super(injector, entityService);
  }
}
