import { ModuleWithProviders, NgModule } from '@angular/core';
import { ErrorHandlingModuleConfig } from './error-handling-module.config';
import {
  DefaultErrorMessageTranslationService,
  ErrorMessageTranslationService
} from './error-message-translation.service';
import { ErrorSanitizerFactoryService } from './error-sanitizer-factory.service';
import { SANITIZER_FUNC_ARRAY } from './sanitizer-func-token';

@NgModule({})
export class ErrorHandlingModule {
  static forRoot(config: ErrorHandlingModuleConfig = {}): ModuleWithProviders<ErrorHandlingModule> {
    return {
      ngModule: ErrorHandlingModule,
      providers: [
        ErrorSanitizerFactoryService,
        {
          provide: ErrorMessageTranslationService,
          useClass: config.translatorType ? config.translatorType : DefaultErrorMessageTranslationService
        },
        config.sanitizers
          ? [
              {
                provide: SANITIZER_FUNC_ARRAY,
                useValue: config.sanitizers
              }
            ]
          : []
      ]
    };
  }
}

export { ErrorSanitizerFactoryService, ErrorHandlingModuleConfig, ErrorMessageTranslationService };
