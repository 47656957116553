<ng-container *ngIf="vm$ | async as vm">
  <div
    [ngClass]="{ 'mri-is-hidden': !vm.isOpen }"
    [ngStyle]="vm.containerOverlayStyles"
    class="mri-overlay mri-drawer__overlay"
    (click)="overlayClick()"
  ></div>
  <div [ngStyle]="vm.containerOverlayStyles" class="mri-drawer__container mri-drawer__container--{{ vm.position }}">
    <div [ngClass]="{ 'mri-is-closed': !vm.isOpen }" class="mri-drawer mri-drawer--{{ vm.position }}">
      <header *ngIf="!vm.hideHeader" class="mri-drawer__header align-center">
        <mri-btn-icon [icon]="vm.headerIcon" *ngIf="vm.headerIcon"></mri-btn-icon>
        <h1 class="mri-heading mri-drawer__heading word-break-all">{{ vm.headerTitle }}</h1>
        <!-- todo: use mri-toolbar --->
        <div class="mri-toolbar mri-toolbar--align-end mri-drawer__header-toolbar">
          <button
            class="mri-icon-button"
            data-mri-tooltip="Close"
            data-drop-position="bottom center"
            class="mri-icon-button"
            aria-label="Close"
            (click)="closeDrawer.emit()"
          >
            <mri-btn-icon icon="x" [direction]="'none'"></mri-btn-icon>
          </button>
        </div>
      </header>
      <div class="mri-drawer__body">
        <ng-container *ngTemplateOutlet="drawerBodyTemplate"></ng-container>
      </div>
      <footer class="mri-drawer__footer">
        <ng-container *ngTemplateOutlet="drawerFooterTemplate"></ng-container>
      </footer>
    </div>
  </div>
</ng-container>
