import { Pipe, PipeTransform } from '@angular/core';

export interface JoinOptions {
  delimiter?: string;
  sort?: boolean;
}

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform<T, K extends keyof T>(value: T[], key: K, { delimiter = ',', sort = true }: JoinOptions): string {
    if (value?.length) {
      let mapValue = value.map(v => v[key]);
      // sort the list
      mapValue = sort ? mapValue.sort() : mapValue;
      return mapValue.join(`${delimiter} `);
    }

    return '';
  }
}
