import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Client } from '../models';
import { ClientEntityService } from './client-entity.service';

@Injectable({ providedIn: 'root' })
export class UserClientsResolver implements Resolve<Client[]> {
  constructor(private clientEntityService: ClientEntityService) {
  }

  resolve(): Observable<Client[]> {
    return this.clientEntityService.getClientsByUser();
  }
}